import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { userDataService } from '../services/data-share';
import { tab } from '../utils'
import { GetReservationParamsDTO } from '../Model/getReservationParamsDTO';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['../../assets/css/style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParkingComponent implements OnInit {

  showLoader: boolean = false;
  data: tab[];
  data_api: tab[];
  selectedReservation: any;
  showPopUp: boolean = false;
  showReservationPopUp: boolean = false;
  showMsgPopUp: boolean = false;
  bookingFor: string;
  selfvalue: string = 'self';
  currentDomain: string;
  passingParams: GetReservationParamsDTO;
  selDeskNumber: string;
  ParkingType: string;
  parkingBay: boolean = true;

  constructor(private ServiceService: ServiceService, private titleService: Title, private dataService: userDataService, private router: Router, public datepipe: DatePipe, private dom: DomSanitizer, public translate: TranslateService) {
  }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle("Parking Reservations");
    localStorage.setItem('PageName', 'Park');
    this.bookingFor = "self";
    var params = {
      CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
    };

    this.ServiceService.getParkingReservations(params).subscribe((data: any[]) => {
      this.data_api = data;
      if (this.bookingFor = "self") {
        this.data = this.data_api.filter(x => x.bookedFor == 'Self');
      }
      this.showLoader = false;
    });
    this.currentDomain = localStorage.getItem('currentDomain');
  }

  PageTab(evt, tabName) {
    var i, x, tablinks;
    x = document.getElementsByClassName("tabContent");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("pagelink");
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";

    if (tabName == 'Waiting List') {
      this.parkingBay = false;
      document.getElementById('Waiting Lists').classList.add('active');
      document.getElementById('Upcomings').classList.remove('active');
    }
    else {
      this.parkingBay = true;
      document.getElementById('Upcomings').classList.add('active');
      document.getElementById('Waiting Lists').classList.remove('active');
    }

  }

  Booking() {
    var selectedRegion= localStorage.getItem('SelectedCountry');
    this.showLoader=true;
    this.ServiceService.getOffices(selectedRegion, false).subscribe((data: any[]) => {
     this.showLoader=false;
     var officeList = data.filter(o => o.hasParking == true);
       if (officeList.length > 0){
    //When coming from reservation screen, set Today's date and tab to Self booking
    localStorage.setItem('bookingFor', 'self');

    localStorage.setItem('RedirectFromSelectDesk', "0");

    localStorage.setItem('ParkingBookingFor', "");

    var currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    localStorage.setItem('SelectedDateToBook', currentDate);
    localStorage.setItem('SelectedDateToBookWithUTC', new Date().toString());

    //(localStorage.getItem('SelectedCountry') == 'United States' && this.bookingFor == 'self') ? this.showPopUp = true : this.router.navigateByUrl('/reserveparking');
    this.router.navigateByUrl('/reserveparking');

    this.dataService.setBookedDeskDetails(undefined);
  }
  else{
    var alert_msg='';
     this.translate.get('NoAnyOfficeIsConfigured').subscribe((data: string) => { alert_msg= data; });
    Swal.fire({
     text: alert_msg,
     icon: 'info',
     confirmButtonColor: '#3f32f1',
   });
  }
});
  }
  closeBooking() {
    this.showPopUp = false;
    this.showReservationPopUp = false;
  }
  onItemChange(value) {
    this.bookingFor = value;
    this.data = (this.bookingFor == "self") ? this.data_api.filter(x => x.bookedFor == 'Self') : this.data_api.filter(x => x.bookedFor == 'Others')
  }

  resHealthFormCheck(showMsgPopup: boolean) {
    this.showPopUp = false
    if (showMsgPopup == true) {
      this.showMsgPopUp = true;
    }
  }

  ResSelected(detail) {
    this.selectedReservation = detail;
    if(this.selectedReservation.type!=null|| this.selectedReservation.type=="")
    {
     if(this.selectedReservation.type=="AM (Before Noon)")
      {
        this.ParkingType="ParkingTabHead2";
      }
      else if(this.selectedReservation.type=="PM (Noon-Close)")
      {
        this.ParkingType="ParkingTabHead3";
      }
      else if(this.selectedReservation.type=="All Day")
      {
        this.ParkingType="ParkingTabHead4";
      }
    }
    else {
      this.ParkingType = "";
    }
    this.showReservationPopUp = true;
  }

  cancelBooking() {
    if (this.parkingBay == false) {
      var reservationType = "WaitingList";
    }
    else {
      var reservationType = "";
    }
    this.showLoader = true;
    var res_details = {
      ID: this.selectedReservation.id,
      ReservationType: reservationType
    };

    this.ServiceService.cancelParkReservation(res_details).subscribe((data: any) => {
      var params = {
        CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
        Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
      };
      this.parkingBay = true;
      // TO REFRESH THE DATA SOURCE
      this.ServiceService.getParkingReservations(params).subscribe((data: any[]) => {
        this.data_api = data;
        if (this.bookingFor = "self") {
          this.data = this.data_api.filter(x => x.bookedFor == 'Self');
        }
        this.showLoader = false;
      });
      this.closeBooking();
      //alert('Your booking is cancelled');
    });
  }

}
