import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-legend-floorplan',
  templateUrl: './legend-floorplan.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class LegendFloorplanComponent implements OnInit {
  selCountry: string;
  kbcSNC: boolean;
  bcHydro: boolean;
  constructor(public translate: TranslateService) { }

  ngOnInit() {
    this.selCountry = localStorage.getItem('SelectedCountry');
    this.kbcSNC= localStorage.getItem('BUId')=="204"?true:false;
    this.bcHydro=localStorage.getItem('BUId')=="201"?true:false;
  }

}
