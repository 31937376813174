import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from "@angular/common";
import * as moment from 'moment';

@Component({
  selector: 'app-generate-token',
  templateUrl: './generate-token.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class GenerateTokenComponent implements OnInit {
  showLoader: boolean = false;
  versionTitle: string;
  constructor(private route: ActivatedRoute, private service: ServiceService, private router: Router, public translate: TranslateService) {
    let _langs = [];
    service.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    this.versionTitle = service.LastAppRelease;
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(service.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {
    this.showLoader = true;
    if (this.route.queryParams['_value']["t"] != undefined) {
      let encryptedEmailUrlEncoded = encodeURI(this.route.queryParams['_value']["t"]).replace(/\+/gi, '%2B');
      this.service.DecryptEmail(encryptedEmailUrlEncoded).subscribe((data: any[]) => {
        let email = data['email'];

        if (email.indexOf('live.com#') !== -1) {
          email = email.replace('live.com#', '');
        }

        localStorage.setItem('CurrentUserEmail', email);

        //Generating Token
        this.TokenGenerationMethod(email);
      }, error => {
        this.showLoader = false;
        if (error.status == 400) {
          this.router.navigate(["/globalerror"]);
          return
        }
        alert('Something went wrong!!!\nPlease try again.');
        this.router.navigate(["/"]);
      });
    }
    else {
      if (localStorage.getItem('currentDomain') == 'external') {
        this.TokenGenerationMethod(localStorage.getItem('CurrentUserEmail'));
      }
    }

    this.service.getPublishTime().subscribe((data: any[]) => {
      localStorage.setItem('PublishTime', data['email']);
    }
      // , error => {
      //   this.showLoader = false;
      //   if(error.status == 400){
      //     this.router.navigate(["/globalerror"]);
      //     return
      //   }
      //   alert('Something went wrong!!!\nPlease try again.');
      //   this.router.navigate(["/"]);
      // }
    );
  }
  TokenGenerationMethod(email: string) {
    this.service.GenerateTokenwithRole(this.service.encrypt(email)).subscribe(response => {
      const token = (<any>response).token;
      const expiry = (<any>response).expireAt;
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiry", expiry);
      //this.showLoader = false;

      if (JSON.stringify(response) == "{}") {
        localStorage.clear();
        this.router.navigate(["/globalerror"]);
        return
      }

      if (localStorage.getItem("Visited") == null) {
        localStorage.setItem("Visited", "1");
      }
      //Changes Done for Outlook Code
      if (localStorage.getItem('IsExternalUser') != 'true') {
        var url = window.location.origin;
        if (localStorage.getItem('Clicked') == 'Booking' || localStorage.getItem('Clicked') == 'CheckIn'|| localStorage.getItem('Clicked') == 'CheckOut') {
          window.location.href = "https://login.microsoftonline.com/87d70b0f-5efc-4991-a065-e205bc3db308/oauth2/v2.0/authorize?client_id=dbc96e7f-4d0d-4607-80e2-075e58f7bc36&grant_type=Authorization_Code&scope=https%3a%2f%2fgraph.microsoft.com%2fCalendars.ReadWrite.Shared&response_type=code&redirect_uri=" + url;
        }
        else {
          window.location.href = "https://login.microsoftonline.com/87d70b0f-5efc-4991-a065-e205bc3db308/oauth2/v2.0/authorize?client_id=dbc96e7f-4d0d-4607-80e2-075e58f7bc36&grant_type=Authorization_Code&scope=https%3a%2f%2fgraph.microsoft.com%2fCalendars.ReadWrite.Shared&response_type=code&redirect_uri=" + url + "/home";
        }
      }
      else {
        this.router.navigate(['/home']);
      }
    }, error => {
      //this.showLoader = false;
      if (error.status == 400) {
        this.router.navigate(["/globalerror"]);
        return
      }
      this.router.navigate(["/"]);

    });
  }
}
