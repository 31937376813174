import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CheckValidDateSelectedToBook, CheckBookingLimit } from '../Model/CheckValidDateSelectedToBook';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as $ from 'jquery';
import * as powerbiClient from 'powerbi-client';
import * as models from 'powerbi-models';


let loadedResolve: any, reportLoaded = new Promise((res) => { loadedResolve = res; });
let renderedResolve: any, reportRendered = new Promise((res) => { renderedResolve = res; });
const powerbi: powerbiClient.service.Service = new powerbiClient.service.Service(powerbiClient.factories.hpmFactory, powerbiClient.factories.wpmpFactory, powerbiClient.factories.routerFactory); //window["powerbi"];
let report:powerbiClient.Report=null;
var IsReportLoaded:boolean=false;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class BookingComponent implements OnInit {
  showLoader = false;
  displayUserName: string;
  displayUserEMail: string;

  constructor(private ServiceService: ServiceService,
    private titleService: Title,
    private dataService: userDataService,
    public datepipe: DatePipe,
    private router: Router, public translate: TranslateService) { }
  showMsgPopUp: boolean = false;
  validateButton: boolean = false;
  bookingFor: string;
  selfvalue: string = 'self';
  currentDomain: string;
  givenText: string;
  UserDetails: any;
  today: Date;
  //minDate: string;
  minDate: Date;
  maxDate: string;
  currentDate: any;
  BookingType: string = 'single';
  CheckValidDateSelectedToBookModel: CheckValidDateSelectedToBook;
  checkBookingLimit: CheckBookingLimit;
  public init = new Date();
  desksData: any[];
  displayPersonalDetails: boolean = true;
  public selectedDateModel = [];
  public resetModel = new Date(0);
  @ViewChild('picker') _picker: MatDatepicker<Date>;
  public CLOSE_ON_SELECTED = false;
  selectedCarParking: string;
  msgTitle: string;
  PopUpMessage: string;
  isVisitor: boolean = false;
  showMeeting: boolean = false;
  showPopUpHealth: boolean = false;
  showMsgPopUpHealth: boolean = false;
  MultiSelectDisbled: boolean = false;
  selectFromParking: boolean = false;
  meetingActive: boolean = false;

  selectedCountry: string = "";
  reportButtonShow: boolean = true;
  reportValue;
  floorNameForReport: string = "";
  officeName: string = "";
  reportDisplayName: string = "";
  reportType:string = "CustomerFacing";

  ngOnInit() {
    this.selectedCountry = localStorage.getItem('SelectedCountry');
    this.currentDomain = localStorage.getItem('currentDomain');

    IsReportLoaded=false;
    this.floorNameForReport = localStorage.getItem('FloorNameInEnglish');
    this.officeName= localStorage.getItem('OfficeName');
    this.collapseReportDiv(1);
    if (this.currentDomain == 'external' || localStorage.getItem('pastdaybooking') == 'true') {
      this.reportButtonShow = false;
    }

    //For Redirection from Parking Reservation for Health Form Popup
    let US = localStorage.getItem('SelectedCountry') === 'United States' ? true : false;
    let SA = localStorage.getItem('SelectedCountry') == 'South America' ? true : false;

    if (US) {
      if (localStorage.getItem('ShowHealthForm') === "true") { this.showPopUpHealth = true; }
      else { this.showPopUpHealth = false; }
    }
    else { this.showPopUpHealth = false; }
    //End

    localStorage.setItem("ShowHealthForm", undefined);
    var selectedOfficeIDFormParking = parseInt(localStorage.getItem('RedirectFromParking'));
    if (selectedOfficeIDFormParking != 0) {
      this.selectFromParking = true;
    }
    this.bookingFor = localStorage.getItem('bookingFor') == undefined ? 'self' : localStorage.getItem('bookingFor');
    this.selfvalue = this.bookingFor;
    this.displayUserName = this.bookingFor == 'self' ? localStorage.getItem('CurrentUser') : (this.selectFromParking ? localStorage.getItem('selectedOtherUserNameParking') : localStorage.getItem('selectedOtherUserName'));
    this.displayUserEMail = this.bookingFor == 'self' ? localStorage.getItem('CurrentUserEmail') : (this.selectFromParking ? localStorage.getItem('selectedOtherUserEmailParking') : localStorage.getItem('selectedOtherUserEmail'));

    this.currentDate = new Date()
    //this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    //this.today = localStorage.getItem('SelectedDateToBook') == undefined ? this.currentDate : localStorage.getItem('SelectedDateToBook');
    //this.today=new Date;
    let selection = this.dataService.getSingleOrMultipleDateSelection();
    this.dataService.setSingleOrMultipleDateSelection(undefined);
    this.BookingType = selection != undefined ? selection : 'single';

    // if (this.BookingType == 'single') {
    //   this.today = localStorage.getItem('SelectedDateToBookForParking') === 'undefined' ? this.currentDate : localStorage.getItem('SelectedDateToBookForParking');
    //   this.MultiSelectDisbled = false;
    // }
    // else {
    //   this.BookingType = 'multiple';
    //   this.MultiSelectDisbled = true;
    //   this.selectedDateModel = this.dataService.getSelectedDateList();
    //   this.dataService.setSelectedDatesToBook(this.selectedDateModel);
    // }

    if (localStorage.getItem('BackfromSelectSeatPage') == 'selectseat' && localStorage.getItem('SelectedDateToBookForParking') == 'undefined')  {
      if(localStorage.getItem('SelectedBookingTypefromBooking') == 'single')
      {
        this.today = localStorage.getItem('SelectedSingleDatefromBooking') === 'undefined' ? this.currentDate : new Date(localStorage.getItem('SelectedSingleDatefromBooking'));
        this.MultiSelectDisbled = false;
      }
      else
      {
        this.BookingType = 'multiple';
        this.MultiSelectDisbled = false;
        if(localStorage.getItem('SelectedMultiDatesfromBooking')!='undefined')
          this.selectedDateModel = JSON.parse(localStorage.getItem('SelectedMultiDatesfromBooking'));
        else
          this.dataService.getSelectedDateList();

        this.dataService.setSelectedDatesToBook(this.selectedDateModel);
      }
    }
    else
    {
      if (this.BookingType == 'single') {
        this.today = localStorage.getItem('SelectedDateToBookForParking') === 'undefined' ? this.currentDate :new Date(localStorage.getItem('SelectedDateToBookForParking'));
        this.MultiSelectDisbled = false;
      }
      else {
        this.BookingType = 'multiple';
        this.MultiSelectDisbled = true;
        this.selectedDateModel = this.dataService.getSelectedDateList();
        this.dataService.setSelectedDatesToBook(this.selectedDateModel);
      }
    }

    localStorage.removeItem('BackfromSelectSeatPage');
    localStorage.removeItem('SelectedBookingTypefromBooking');
    localStorage.removeItem('SelectedSingleDatefromBooking');
    localStorage.removeItem('SelectedMultiDatesfromBooking');


    //this.minDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.minDate = new Date;
    this.maxDate = this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');
    if (localStorage.getItem('pastdaybooking') == 'true') {
      //this.minDate = this.datepipe.transform(this.CalculateMinForPastDayBookingDate(), 'yyyy-MM-dd');
      this.minDate = this.CalculateMinForPastDayBookingDate();
      this.maxDate = this.currentDate;

    }
    if (localStorage.getItem('Predefined') == 'true') {
      this.validateButton = true;
    }

    this.currentDomain = localStorage.getItem('currentDomain');
    this.titleService.setTitle("Make a booking");
    //this.dataService.setPageName('Res');
    localStorage.setItem('PageName', 'Res');
    //(localStorage.getItem('CurrentBookingType') == undefined) ? localStorage.setItem('CurrentBookingType', this.BookingType) : localStorage.getItem('CurrentBookingType');
    localStorage.setItem('CurrentBookingType', this.BookingType);

    this.selectedDateModel = (this.dataService.getSelectedDatesToBook() != undefined) ? this.dataService.getSelectedDatesToBook() : [];
    this.selectedCarParking = (localStorage.getItem('CarParkingSelection') != null) ? localStorage.getItem('CarParkingSelection') : 'false';

    this.isVisitor = (localStorage.getItem('IsExternalUser') == "true");
  }
  getDateFormatString(): string {
    return 'MM/DD/YYYY';
  }
  onKeyDownEvent(event: any) {
    event.preventDefault();
  }
  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }
  CalculateMinForPastDayBookingDate() {
    var date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }
  changeDate() {
    // this.validateButton = true;
    this.checkValidateButton();
  }
  currentSelection(value) {
    this.selectedDateModel = [];
    this.BookingType = (value == 'single') ? 'single' : 'multiple';
    if (this.BookingType == 'single') {
      this.today = this.currentDate;
      this.MultiSelectDisbled = false;
    }
    else {
      this.selectedDateModel = [];
    }
    localStorage.setItem('CurrentBookingType', value);
    this.maxChange(false);
  }
  selectSeat() {
    localStorage.setItem("View", "Desk");
    // localStorage.setItem('pastdaybooking', 'false');
    //if (this.BookingType == 'single' && this.currentDate <= this.today) {
    //Making Issue while coming from Parking for MultipleDay
    if (this.BookingType != 'single') {
      this.today = this.currentDate;
    }

    let todayForConversation = this.datepipe.transform(new Date(this.today), 'yyyy-MM-dd');

    if (this.BookingType == 'single' && this.datepipe.transform(this.currentDate, 'yyyy-MM-dd') <= todayForConversation) {
      this.BookingAfterValidation();
    }
    else if (this.BookingType != 'single') {
      this.BookingAfterValidation();
    }
    else {
      if (localStorage.getItem('pastdaybooking') == 'true') {
        localStorage.setItem('pastdaybooking', 'false');
        this.BookingAfterValidation();
      }
      else {
        this.translate.get('BkngDateErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('BkngDateErrorMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        this.showMsgPopUp = true;
      }
    }
  }
  resHealthFormCheck(showMsgPopup: boolean) {
    this.showPopUpHealth = false
    if (showMsgPopup == true) {
      this.showMsgPopUpHealth = true;
    }
  }
  BookingAfterValidation() {
    this.showLoader = true;
    if (this.BookingType == 'single') {
      this.selectedDateModel = [];
      this.selectedDateModel.push(this.datepipe.transform(this.today, 'yyyy-MM-dd'));
    }
    this.selectedDateModel.forEach(element => {
      element = this.datepipe.transform(element, 'yyyy-MM-dd');
    });

    if (this.selectedDateModel.length == 0) {
      this.showLoader = false;
      this.translate.get('DateNotSelTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('DateNotSelMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      this.showMsgPopUp = true;
      return;
    }
    if (this.selectedDateModel.toString() > this.maxDate) {
      this.showLoader = false;
      this.translate.get('Date').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('DateNotSelMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      this.showMsgPopUp = true;
      return;
    }
    var details = {
      BookedBy: this.displayUserEMail,//localStorage.getItem('CurrentUserEmail'),
      selectedDate: this.selectedDateModel
    };
    localStorage.setItem('SelectedDateToBook', this.selectedDateModel[0]);
    localStorage.setItem('SelectedDateToBookWithUTC',this.today.toString());
    localStorage.setItem('BookingDatesSelected', JSON.stringify(this.selectedDateModel));
    this.dataService.setSelectedDatesToBook(this.selectedDateModel);
    this.CheckValidDateSelectedToBookModel = details;

    let isCheckReservationforSelectedDate: boolean = false;
    this.ServiceService.CheckReservationforSelectedDate(this.CheckValidDateSelectedToBookModel).subscribe((data: boolean) => {
      isCheckReservationforSelectedDate = data;


      if (isCheckReservationforSelectedDate) {
        this.showLoader = false;
        this.translate.get('BookingAlreadyExisMsg').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SelectAnotherdateMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        this.ServiceService.getOfficeData(localStorage.getItem('OfficeID')).subscribe((data: any) => {
          if (data.meetingActive) {
            this.meetingActive = true;
          }
          if (this.meetingActive && this.router.url == '/booking' && localStorage.getItem('IsExternalUser') != 'true') {
            this.showMeeting = true;
          }
          this.showMsgPopUp = true;
        });
      }
      else if (this.displayUserEMail == null) {
        this.showLoader = false;
        this.translate.get('SelectMailForOther').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SelectMailForOtherMsg').subscribe((data: string) => { this.PopUpMessage = data; });

        this.showMsgPopUp = true;
      }
      else {
        this.BookSelectedSeat();
      }
    });
  }


  BookParking() {
    this.showLoader = true;
    if (this.BookingType == 'single') {
      this.selectedDateModel = [];
      this.selectedDateModel.push(this.today);
    }
    this.selectedDateModel.forEach(element => {
      element = this.datepipe.transform(element, 'yyyy-MMM-dd');
    });

    var details = {
      BookedBy: localStorage.getItem('CurrentUserEmail'),
      selectedDate: this.selectedDateModel
    };
    this.dataService.setSelectedDatesToBook(this.selectedDateModel);
    this.showLoader = false;
    this.router.navigateByUrl('/BookParking');
  }
  BookSelectedSeat() {
    // this.dataService.setSelectedDateToBook(this.today);
    localStorage.setItem('bookingFor', this.bookingFor);
    this.dataService.setSelectedDeskNumber(undefined);
    // (this.zones != undefined && this.zones.length > 0) ? this.dataService.setSelectTypeofSeat(this.zones.find(x => x.id == this.selectedZone).hasDeskCategory) : this.dataService.setSelectTypeofSeat(this.floors.find(x => x.id == this.selectedFloor).hasDeskCategory);
    // this.selOfficeObj = (this.zones != undefined && this.zones.length > 0) ? this.zones.find(value => value.id === parseInt(this.dataService.getZoneId())) : this.selOfficeObj = this.floors.find(value => value.id === parseInt(this.dataService.getFloorID()));
    // this.dataService.setFloorPlanPath(this.selOfficeObj.floorPlanPath);
    var details = {
      selectedDate: this.CheckValidDateSelectedToBookModel.selectedDate,
      OfficeId: parseInt(localStorage.getItem('OfficeID'))
    }
    this.checkBookingLimit = details;
    this.ServiceService.CheckBookingLimit(this.checkBookingLimit).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.translate.get('BookingCapTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('BookingCapMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        this.showMsgPopUp = true;
      }
      else {
        localStorage.setItem('SelectedBookingTypefromBooking', this.BookingType);
        if (this.BookingType == 'single') {
          var selectedBookingDate = this.today.toString(); //this.datepipe.transform(this.today, 'yyyy-MM-dd');
          localStorage.setItem('SelectedSingleDatefromBooking', selectedBookingDate);
        }
        else
        {
          localStorage.setItem('SelectedMultiDatesfromBooking', JSON.stringify(this.selectedDateModel));
        }
        this.dataService.showDesk.next('Yes');
        this.router.navigateByUrl('/selectseat');
      }
    });
  }

  onItemChange(value) {
    var healthform = localStorage.getItem('HealthForm'); //healthform="" or healthform="Submit"
    var selectedCountry = localStorage.getItem('SelectedCountry');
    if (value == 'self' && (healthform == "" || healthform == '') && (selectedCountry == "South America" || selectedCountry == "United States")) {
      this.router.navigateByUrl('/reservation');
    }
    if (value == 'self') {
      this.displayUserName = localStorage.getItem('CurrentUser');
      this.displayUserEMail = localStorage.getItem('CurrentUserEmail');
      this.displayPersonalDetails = true;
      //this.validateButton = true;
    } else {
      this.displayUserName = localStorage.getItem('selectedOtherUserName');
      this.displayUserEMail = localStorage.getItem('selectedOtherUserEmail');
      this.displayPersonalDetails = (this.displayUserEMail != undefined ? true : false);
      // if (localStorage.getItem('selectedOtherUserEmail') != null) {
      //   this.validateButton = true;
      // } else {
      //   this.validateButton = false;
      // }
    }
    this.bookingFor = value;
    localStorage.setItem('bookingFor', value);
    this.checkValidateButton();
  }

  checkValidateButton() {
    if (parseInt(localStorage.getItem('OfficeID')) == 0 || localStorage.getItem('OfficeID') == 'undefined') {
      this.validateButton = false;
    } else {
      if (this.bookingFor == 'self') {
        this.validateButton = true;
      } else {
        if (localStorage.getItem('selectedOtherUserEmail') != null) {
          this.validateButton = true;
        } else {
          this.validateButton = false;
        }
      }
    }
  }

  search() {
    if (this.isVisitor == false) {
      this.showLoader = true;
      this.ServiceService.searchForBookOthers(this.givenText).subscribe((data: any) => {
        this.showLoader = false;
        this.UserDetails = data.filter(x => x.mail != null && x.mail != localStorage.getItem('CurrentUserEmail'));
        //this.validateButton = true;
        this.checkValidateButton();
      })
    }
  }

  getUserDetail(value) {
    var selectedOtherUser = this.UserDetails.find(x => x.mail == value);
    //this.dataService.setselectedOtherUserEmail(selectedOtherUser.mail);
    localStorage.setItem('selectedOtherUserEmail', selectedOtherUser.mail);
    localStorage.setItem('selectedOtherUserName', selectedOtherUser.displayName);
    this.displayUserName = selectedOtherUser.displayName;
    this.displayUserEMail = selectedOtherUser.mail;
    this.displayPersonalDetails = true;
  }
  public dateClass = (date: Date) => {
    if (this._findDate(this.datepipe.transform(date, 'yyyy-MM-dd')) !== -1) {
      return ['selected'];
    }
    return [];
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const date = event.value;
      const index = this._findDate(this.datepipe.transform(date, 'yyyy-MM-dd'));
      if (index === -1) {
        let Bookingdate = this.datepipe.transform(date, 'yyyy-MM-dd');
        this.selectedDateModel.push(Bookingdate);
      } else {
        this.selectedDateModel.splice(index, 1)
      }
      this.resetModel = new Date(0);
      if (!this.CLOSE_ON_SELECTED) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        this._picker['_popupComponentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
      this.dataService.setSelectedDatesToBook(this.selectedDateModel);
      // this.validateButton = true;
      this.checkValidateButton();
    }
  }

  private _findDate(date: string): number {
    return this.selectedDateModel.indexOf(date);//.map((m) => +m)
  }
  CancelSeat() {
    if (localStorage.getItem('pastdaybooking') == "true") {
      localStorage.setItem('pastdaybooking', 'false');
      this.router.navigateByUrl('/admin')
    }
    else {
      this.router.navigateByUrl('/reservation')
    }
  }

  meetingOffice(officeId) {
    if ((parseInt(localStorage.getItem('OfficeID')) == 20 || parseInt(localStorage.getItem('OfficeID')) == 24)) {
      return true;
    }
  }

  maxChange(officeChanged) {
    var date = new Date();
    var days;
    var officeSelected = localStorage.getItem('OfficeID');

    if (localStorage.getItem('pastdaybooking') != 'true') {
      if (officeSelected == "0" || officeSelected == "undefined") {
        days = 28;
        date.setDate(date.getDate() + days);
      }
      else {
        this.ServiceService.getOfficeData(officeSelected).subscribe((data: any) => {
          var CalendarValue = parseInt(data.maxCalendar);
          days = CalendarValue;
          date.setDate(date.getDate() + days);
          this.maxDate = this.datepipe.transform(date, 'yyyy-MM-dd');
          if (officeChanged == true) {
            this.selectedDateModel = [];
            if(this.selectedCountry.toLowerCase() == 'apac'){
              this.today = new Date();
            }
          }
        })
      }
    }
    else {
      //this.minDate = this.datepipe.transform(this.CalculateMinForPastDayBookingDate(), 'yyyy-MM-dd');
      this.minDate = this.CalculateMinForPastDayBookingDate();
      this.maxDate = this.currentDate;

    }
  }

  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    var officeSelected = localStorage.getItem('OfficeID');
    if (officeSelected == "0" || officeSelected == "undefined" || (officeSelected != "197" && officeSelected != "198" && officeSelected != "200" && officeSelected != "213")) {
      return (day == 0 || day == 1 || day == 2 || day == 3 || day == 4 || day == 5 || day == 6)
    }
    else {
      /* Prevent Saturday and Sunday for select. */
      return day !== 0 && day !== 6;
    }
  }

//----------- Emebedded Pbi Report 15-Sep-2023
swalCall(errorMsg,iconType,title)
{
  Swal.fire({
    title: title,
    text: errorMsg,
    confirmButtonColor: '#3f32f1',
    confirmButtonText: "Ok",
    icon: iconType
  })
}


async LoadOfficePbiEmbeddedReport()
{
  var OfficeChanged = localStorage.getItem('OfficeChangedOnBooking');
  this.floorNameForReport = localStorage.getItem('FloorNameInEnglish');
  this.officeName= localStorage.getItem('OfficeName');
  if((this.officeName!=null && this.officeName!=undefined && this.officeName!="undefined" && this.officeName!="") && (this.floorNameForReport!=null && this.floorNameForReport!=undefined && this.floorNameForReport!="undefined" && this.floorNameForReport!=""))
  {
    if(IsReportLoaded && OfficeChanged !=undefined && OfficeChanged == "false")
    {
      this.collapseReportDiv(2);
      var CountryKey=this.selectedCountry.toLowerCase();
      switch (CountryKey) {
        case "united states":
          CountryKey="USA"
          break;
        case "canada":
          CountryKey="canada"
          break;
        case "south america":
          CountryKey="SA";
          break;
        case "apac":
          CountryKey="apac"
          break;
        case "united kingdom":
          CountryKey="UK"
          break;
      case "europe":
          CountryKey="europe"
          break;
      }
      this.reportDisplayName= CountryKey+" " + this.officeName+"-"+this.floorNameForReport;

      await reportLoaded;
      try {
          const pages: powerbiClient.Page[] = await report.getPages();
          var index =  pages.findIndex(a => a.displayName.toLowerCase() === this.reportDisplayName.toLowerCase());
          if(index && index>-1)
          {
            await pages[index].setActive();
            console.log("Active page was set to: \"" + index+"-" + pages[index].name + " - " + pages[index].displayName + "\"");
          }
          else{
            pages[0].setActive();
            console.log("Active page was set to: \"" + index+"-" + pages[0].name + " - " + pages[0].displayName + "\"");
          }
      }
      catch (errors) {
        console.log(errors);
      }
      await reportRendered;
    }
    else
    {
      let reportContainer = <HTMLElement>document.getElementById('booking_reportContainer');
      let powerbiService = new powerbiClient.service.Service(powerbiClient.factories.hpmFactory, powerbiClient.factories.wpmpFactory, powerbiClient.factories.routerFactory); //window["powerbi"];
      powerbiService.reset(reportContainer);
      this.embedPowerBIReport(false);
    }
  }
  else
  {
    var errorMsg = "";
    this.translate.get('ReportButtonError').subscribe((data: string) => { errorMsg = data;
      this.swalCall(errorMsg,'warning','');
    });
  }
}

async embedPowerBIReport(IsRefreshed) {
  var CountryKey = this.selectedCountry.toLowerCase();
  this.floorNameForReport = localStorage.getItem('FloorNameInEnglish');
  this.officeName= localStorage.getItem('OfficeName');
  switch (CountryKey) {
    case "united states":
      CountryKey="USA"
      break;
    case "canada":
      CountryKey="canada"
      break;
    case "south america":
      CountryKey="SA";
      break;
    case "apac":
      CountryKey="apac"
      break;
    case "united kingdom":
        CountryKey="UK"
        break;
    case "europe":
        CountryKey="europe"
        break;
  }
  var reportForSelectedOfficeFloor = CountryKey +" "+ this.officeName+"-"+this.floorNameForReport;
  $('#div_reportShowLoader').get(0).style.display='block';

  this.ServiceService.getPowerbiEmbedReportData(this.selectedCountry, this.officeName,this.reportType).subscribe((res: any) => {
    $('#div_reportShowLoader').get(0).style.display='none';
    if (res && res != 400 && res != 406) {
      if(!IsRefreshed){
        this.collapseReportDiv(2);
      }
      var responseData = res as any;
      this.LoadCustomerEmbedReport(responseData, reportForSelectedOfficeFloor);
    }
    else if(res && res == 406)
    {
      var errorMsg = "";
      this.translate.get('PbiReportNotExist').subscribe((data: string) => {
        errorMsg = data;
        this.swalCall(errorMsg,'warning','');
      });
      //this.collapseReportDiv(1);
    }
    else
    {
      var errorMsg = "";
      this.translate.get('ErrorMsg').subscribe((data: string) => {
        errorMsg = data;
        this.swalCall(errorMsg,'warning','');
      });
    // this.collapseReportDiv(1);
    }
  });
}

async LoadCustomerEmbedReport(embedReportData,reportForSelectedOfficeFloor) {
  try {
      //var reportForSelectedOfficeFloor=  this.officeName+"-"+this.floorNameForReport;
      let accessToken: string = embedReportData.embedToken;
      let embedUrl: string = embedReportData.embedUrl;
      let embedReportId: string = embedReportData.reportId;
      let tokenType = models.TokenType.Embed;
      let permissions: models.Permissions = models.Permissions.All;

      let config: models.IReportEmbedConfiguration = {
        type: 'report',
        tokenType: tokenType,
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: embedReportId,
        permissions: permissions,
        settings: {
            panes: {
                filters: {
                    visible: false
                },
                pageNavigation: {
                    visible: false
                }
            }
        }


      }
      // Get a reference to the embedded report HTML element
      var $reportContainer = $('#booking_reportContainer');
      let embedContainer = $reportContainer.get(0);//: HTMLElement = document.getElementById('embedContainer');

      // Embed the report and display it within the div container.
      report =   <powerbiClient.Report>(powerbi.embed(embedContainer,config));
      this.reportValue = report as powerbiClient.Report;
      //powerbi.embed(embedContainer, config) as powerbiClient.Report;

      report.off("loaded");
      report.on("loaded", async (event) => {
        console.log("report load successfully.");
        IsReportLoaded=true;
        localStorage.setItem('OfficeChangedOnBooking', 'false');
        Promise.all([report.getPages()]).then(response => {
          var pages=response[0];
          var index =  pages.findIndex(a => a.displayName.toLowerCase() === reportForSelectedOfficeFloor.toLowerCase());
          if(index && index>-1)
          {
            pages[index].setActive();
            console.log("Active page was set to: \"" + index+"-" + pages[index].name + " - " + pages[index].displayName + "\"");
          }
          else{
            pages[0].setActive();
            console.log("Active page was set to: \"" + index+"-" + pages[0].name + " - " + pages[0].displayName + "\"");
          }
        })
        $('#div_reportShowLoader').get(0).style.display='none';
        loadedResolve();
        report.off("loaded");
      });

      report.off("error");
      report.on("error", function (event: powerbiClient.service.ICustomEvent<any>) {
        $('#div_reportShowLoader').get(0).style.display='none';
        console.log(event.detail);
      });

      report.off("rendered");
      report.on("rendered", function () {
        $('#div_reportShowLoader').get(0).style.display='none';
        renderedResolve();
        report.off("rendered");
      });
  }
  catch (errors) {
    console.log(errors);
    $('#div_reportShowLoader').get(0).style.display='none';
  }

}

collapseReportDiv(val)
{
var bookingElement =  $('#btn_collapse1').get(0);
var reportElement =  $('#btn_collapse2').get(0);
var bookingContent =  $('#div_collapsibleContent1').get(0);
var reportcontent =  $('#div_collapsibleContent2').get(0);
var backToBookingButtonDiv=$('#div1_up').get(0);
if(val==1)
{
  bookingElement.style.display="block";
  bookingElement.classList.toggle("report_active");

  reportElement.style.display="none";

  backToBookingButtonDiv.style.display="none";
  if (bookingContent.style.maxHeight){
    bookingContent.style.maxHeight = null;
    reportcontent.style.maxHeight = null;
  } else {
    bookingContent.style.maxHeight = "fit-content";
    reportcontent.style.maxHeight = null;
  }
}
else
{
  reportElement.style.display="block";
  reportElement.classList.toggle("report_active");
  reportElement.style.paddingTop="0px";

  bookingElement.style.display="none";

  backToBookingButtonDiv.style.display=  "block";
  if (reportcontent.style.maxHeight){
    bookingContent.style.maxHeight = null;
    reportcontent.style.maxHeight = null;
  } else {
    bookingContent.style.maxHeight = null;
    reportcontent.style.maxHeight ="fit-content";// content1.scrollHeight + "px";

  }
}
}

async RefreshOnDemand() {
  var region = this.selectedCountry.toLowerCase();
  this.showLoader = true;
    this.ServiceService.getPBIRefreshCustomerReport(this.selectedCountry, this.officeName,this.reportType).subscribe((data: any) => {
      this.showLoader = false;
      if (data && data != 400) {
        if(data.status == "Completed"){
        var PbiReportRefreshSuccess = "";
        this.translate.get('PbiReportRefreshSuccess').subscribe((data: string) => { PbiReportRefreshSuccess = data; });
        var successMsg = localStorage.getItem('SelectedCountry') + ' ' + PbiReportRefreshSuccess;
          Swal.fire({
            title: "",
            text: successMsg,
            confirmButtonColor: '#3f32f1',
            confirmButtonText: "Ok",
            icon: "success"
          }).then((result) => {
            if (result.value) {
              let reportContainer = <HTMLElement>document.getElementById('booking_reportContainer');
              let powerbiService = new powerbiClient.service.Service(powerbiClient.factories.hpmFactory, powerbiClient.factories.wpmpFactory, powerbiClient.factories.routerFactory); //window["powerbi"];
              powerbiService.reset(reportContainer);
              this.embedPowerBIReport(true);
            }
          })
        }
        else if(data.status == "InProgress")
        {
          var InProgressMsg ="";
          this.translate.get('PbiReportRefreshInProgress').subscribe((data: string) => { InProgressMsg = data; });
          this.swalCall(InProgressMsg,"warning","");
        }
        else if(data.status =="Failed")
        {
          var failedMsg="";
          this.translate.get('PbiReportRefreshFailed').subscribe((data: string) => { failedMsg = data; });
          failedMsg = localStorage.getItem('SelectedCountry') + ' - ' + failedMsg;
          this.swalCall(failedMsg,"warning","");
        }
      }
      else
      {
        var failedMsg="";
        this.translate.get('PbiReportRefreshFailed').subscribe((data: string) => { failedMsg = data; });
        failedMsg = localStorage.getItem('SelectedCountry') + ' - ' + failedMsg;
        this.swalCall(failedMsg,"warning","");
      }
    });
}






}
