import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class HomeComponent implements OnInit {
  AllowAdmin: boolean = false;
  //NonGTCEmployees: boolean = false;
  showLoader: boolean = false;
  showPopupWrap = false;
  showPopupWraphelp = false;
  CurrentUserName: string;
  validateButton: boolean = false;
  showMsgPopUp: boolean = false;
  logoClass: string;
  versionTitle: string;
  showSuperAdminFeatures: boolean = false;
  showDailyReportPopUp: boolean = false;
  ShowGymIcon: boolean = false;
  constructor(public ServiceService: ServiceService,
    private dataService: userDataService,
    private titleService: Title, private router: Router, public datepipe: DatePipe, private route: ActivatedRoute, private http: HttpClient, public translate: TranslateService,
    private activatedRoute: ActivatedRoute) {

    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    this.versionTitle = ServiceService.LastAppRelease;
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(ServiceService.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }
  data: any;
  FullCountryList: any;
  selectedCountry: any = "Please";
  utcDateTime: any;
  showPopupBanner: boolean = false;

  showhidebanner() {
    if (localStorage.getItem("Visited") != "2" && localStorage.getItem('IsExternalUser') != "true") {
      this.showPopupBanner = true;
    }
  }

  closePopup() {
    this.showPopupBanner = false;
    localStorage.setItem("Visited", "2");
  }
  ngOnInit(): void {
    localStorage.removeItem("Clicked");
    this.versionTitle = "Last Update On: " + localStorage.getItem('PublishTime');
    localStorage.setItem("Visited", "2");
    this.showhidebanner();
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if (userRole == 'Manager' || userRole == "user") {
      this.showSuperAdminFeatures = false;
    }
    else {
      this.showSuperAdminFeatures = true;
    }
    let code = this.activatedRoute.snapshot.queryParams['code'];
    if (code != undefined) {
      localStorage.setItem("code", code);
      let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
      this.router.navigateByUrl(url);
      if (localStorage.getItem('IsExternalUser') != 'true') {
        this.ServiceService.getAccessToken().subscribe((accessToken: string) => {
          localStorage.setItem("AccessToken", (accessToken));
        })
      }
    }
    this.utcDateTime = new Date();
    let local_date = moment.utc(this.utcDateTime).local().format('YYYY-MM-DD');
    localStorage.setItem('localdate', local_date);
    localStorage.setItem('pastdaybooking', 'false');
    // this.dataService.getcurrentDomain() == undefined &&
    if (localStorage.getItem('CurrentUserEmail') != null && localStorage.getItem('CurrentUser') == null) {
      // let encryptedEmailUrlEncoded = encodeURI(this.route.queryParams['_value']["t"]).replace(/\+/gi, '%2B');
      // this.showLoader = true;
      //this.ServiceService.DecryptEmail(encryptedEmailUrlEncoded).subscribe((data: any[]) => {
      let email = localStorage.getItem('CurrentUserEmail');

      //Generating Token
      // this.ServiceService.GenerateTokenwithRole(email).subscribe(response => {
      //   const token = (<any>response).token;
      //   localStorage.setItem("token", token);
      //   this.GetCountryList();
      //   this.EnableAdminButton();
      // });

      this.CurrentUserName = email.split('@')[0].split('.')[0] + " " + email.split('@')[0].split('.')[1];
      localStorage.setItem('CurrentUser', this.CurrentUserName);
      //localStorage.setItem('CurrentUserEmail', email);
      // set user settings
      this.ServiceService.getUserPreference().subscribe((userPrefs: any) => {
        if (userPrefs != undefined) {
          localStorage.setItem('SelectedCountry', userPrefs.selCountry);
          this.selectedCountry = localStorage.getItem('SelectedCountry');
          this.validateButton = true;

          localStorage.setItem('OfficeID', userPrefs.selOfficeId);
          // this.dataService.setOfficeID(userPrefs.selOfficeId);
          localStorage.setItem('FloorID', userPrefs.selFloorId);
          localStorage.setItem('ZoneId', userPrefs.selZoneId);
          localStorage.setItem('BUId', userPrefs.selBuid);
          localStorage.setItem('DeskId', userPrefs.deskId);
          localStorage.setItem('Purpose', userPrefs.purpose);
          localStorage.setItem('ShowName', userPrefs.showName);
          localStorage.setItem('IsHS', userPrefs.isHs);
          localStorage.setItem('IsFireWarden', userPrefs.isFireWarden);
        }
      });

      // if (localStorage.getItem('CurrentUserEmail') != undefined && localStorage.getItem('CurrentUserEmail') != 'null' && localStorage.getItem('CurrentUserEmail') != 'undefined') {
      //   this.ServiceService.CheckIfAdminUser(localStorage.getItem('CurrentUserEmail')).subscribe((result: boolean) => {
      //     this.AllowAdmin = result;
      //   }, err => {
      //     this.AllowAdmin = false;
      //   });
      // }

      //   this.showLoader = false;
      // }, error => {
      //   this.showLoader = false;
      //   this.showMsgPopUp = true;
      //   //alert('Something went wrong!!!\nPlease try again.');
      // });
    }
    localStorage.setItem('PageName', 'Home');
    this.titleService.setTitle("Save My Seat - Home");
    this.CurrentUserName = localStorage.getItem('CurrentUser');
    if (localStorage.getItem('SelectedCountry') != undefined)
      this.validateButton = true;

    this.GetCountryList();
    this.selectedCountry = localStorage.getItem('SelectedCountry');
    this.translate.get('logoClass').subscribe((data: string) => { this.logoClass = data; });

    if (localStorage.getItem('token') != null) {
      this.EnableAdminButton();
    }
    this.ServiceService.getSAofficeAccess().subscribe((data: any) => {
      localStorage.setItem('AdminAccessForSA', data);
      //this.AdminAccessForSA=data=='200'?true:false;
    });
  }
  EnableAdminButton() {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if (userRole != 'user')
      this.AllowAdmin = true;
    // if (payLoad.Location != 'GTC')
    //   this.NonGTCEmployees = true;
  }
  GetCountryList() {
    this.showLoader = true;
    this.ServiceService.getOption().subscribe((data: any[]) => {
      this.FullCountryList = data;
      var presentGym = this.FullCountryList.filter(x => x.country == this.selectedCountry);
      if (presentGym.length > 0) {
        this.ShowGymIcon = presentGym[0].hasGYM;
        var GymRegion;
        if (this.ShowGymIcon == false ? GymRegion = "No" : GymRegion = "Yes")
          localStorage.setItem("GymRegion", GymRegion);
        this.dataService.setShowGymIcon(this.ShowGymIcon);
      }
      this.data = data.filter(x => x.langKey == localStorage.getItem('locale'));
      this.showLoader = false;
    }, err => {
      alert("something went wrong");
      window.location.reload();
    });
  }
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    this.translate.get('logoClass').subscribe((data: string) => { this.logoClass = data; });
    // this.translate.get(language).subscribe((ds: any) => {
    //   this.data = this.FullCountryList.filter(x => x.langKey == ds)
    //   console.log(this.data);
    // });
    this.data = this.FullCountryList.filter(x => x.langKey == localStorage.getItem('locale'));
    // if (language == 'en') {
    //   this.logoClass = 'logoSaveseat dashlogo'
    // } else {
    //   this.logoClass = 'logoSaveseatfr dashlogo'
    // }
  }
  changeCountry(option) {
    this.validateButton = true;
    this.selectedCountry = option.target.value;
    //this.dataService.setSelectedCountry(this.selectedCountry);
    //Temporary Country Storing
    var presentGym = this.data.filter(x => x.country == this.selectedCountry);
    if (presentGym.length > 0) {
      this.ShowGymIcon = presentGym[0].hasGYM;
      var GymRegion;
      if (this.ShowGymIcon == false ? GymRegion = "No" : GymRegion = "Yes")
        localStorage.setItem("GymRegion", GymRegion);
      this.dataService.setShowGymIcon(this.ShowGymIcon);
    }
    this.showLoader = true;
    this.ServiceService.GetSelectedCountryName(this.selectedCountry).subscribe((data: any) => {
      this.showLoader = false;
      var country = JSON.parse(data).filter(x => x.langKey == localStorage.getItem('locale'));
      localStorage.setItem('SelectedCountry', country[0].key);
    }, err => {
      this.showLoader = false;
      localStorage.setItem('SelectedCountry', this.selectedCountry)
    });

    //Temporary Country Storing

    localStorage.setItem('OfficeID', "0");
  }
  logout() {

    this.ServiceService.logout().subscribe((data: any[]) => {
      window.location.reload();
      var a = localStorage.myusername;
      var b = localStorage.mypassword;
      localStorage.clear();
      localStorage.setItem('myusername', a);
      localStorage.setItem('mypassword', b);
    }, error => {

    })


    window.location.reload();
    var a = localStorage.myusername;
    var b = localStorage.mypassword;
    localStorage.clear();
    localStorage.setItem('myusername', a);
    localStorage.setItem('mypassword', b);
  }
  QuickBook() {
    //var currentDate = ;
    localStorage.setItem('SelectedDateToBook', this.datepipe.transform(new Date(), 'yyyy-MM-dd'));
    var currentDateWithUTC = new Date();
    localStorage.setItem('SelectedDateToBookWithUTC', currentDateWithUTC.toString());

    if (localStorage.getItem('ZoneId') != "null" && localStorage.getItem('ZoneId') != undefined) {
      this.ServiceService.getzoneFloorPlanPath(parseInt(localStorage.getItem('ZoneId'))).subscribe((selzonObj: any) => {
        localStorage.setItem('FloorPlanPath', selzonObj[0].floorPlanPath);
        localStorage.setItem('FullFloorPlanPath', selzonObj[0].fullFloorplanPath);
        localStorage.setItem('ShowFullFloorplan', selzonObj[0].showFullFloorplan);
        this.router.navigateByUrl('/selectseat');
      });
    }
    else if (localStorage.getItem('FloorID') != "null" && localStorage.getItem('FloorID') != undefined) {
      this.ServiceService.getfloorFloorPlanPath(parseInt(localStorage.getItem('FloorID'))).subscribe((selfloorObj: any) => {
        localStorage.setItem('FloorPlanPath', selfloorObj[0].floorPlanPath);
        localStorage.setItem('FullFloorPlanPath', selfloorObj[0].fullFloorplanPath);
        localStorage.setItem('ShowFullFloorplan', selfloorObj[0].showFullFloorplan);
        this.router.navigateByUrl('/selectseat');
      });
    }

  }

  onAdminClick() {
    this.router.navigateByUrl('/admin');
  }

  showHidePopupDropDown(type, isCheck) {
    if (type == 'userClick' && isCheck == false) {
      this.showPopupWrap = true;
      this.showPopupWraphelp = false;
    }
    else if (type == 'helpClick' && isCheck == false) {
      this.showPopupWrap = false;
      this.showPopupWraphelp = true;
    }
    else if ((type == 'helpClick' && isCheck == true) || (type == 'userClick' && isCheck == true) || (type == 'langClick')) {
      this.showPopupWrap = false;
      this.showPopupWraphelp = false;
    }
  }

  openPopup() {
    this.showDailyReportPopUp = true;
  }
}
