import { Component, OnInit, Input, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-res-details',
  templateUrl: './res-details.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class ResDetailsComponent implements OnInit {
  isFullDay: boolean = true;
  booking_start_time: string;
  booking_end_time: string;
  selectedDateDetails = [];
  validateButton: boolean = true;
  today: Date;
  showLoader = false;
  showMsgPopUp: boolean = false;
  @Input() selectedReservation: any;
  @Input() meetingDetails: any;
  showStatus:boolean;
  Available:boolean;
  TimeZoneSelected;
  ZoneSelected;
  constructor(private ngzone: NgZone, public datepipe: DatePipe, private ServiceService: ServiceService, public translate: TranslateService) { }
  TimingSlots = [
    {
      "value": "00:00",
      "text": "00:00"
    },
    {
      "value": '00:30',
      "text": "00:30"
    },
    {
      "value": '01:00',
      "text": "01:00"
    },
    {
      "value": '01:30',
      "text": "01:30"
    },
    {
      "value": '02:00',
      "text": "02:00"
    },
    {
      "value": '02:30',
      "text": "02:30"
    },
    {
      "value": '03:00',
      "text": "03:00"
    },
    {
      "value": '03:30',
      "text": "03:30"
    },
    {
      "value": '04:00',
      "text": "04:00"
    },
    {
      "value": '04:30',
      "text": "04:30"
    },
    {
      "value": '05:00',
      "text": "05:00"
    },
    {
      "value": '05:30',
      "text": "05:30"
    },
    {
      "value": '06:00',
      "text": "06:00"
    },
    {
      "value": '06:30',
      "text": "06:30"
    },
    {
      "value": '07:00',
      "text": "07:00"
    },
    {
      "value": '07:30',
      "text": "07:30"
    },
    {
      "value": '08:00',
      "text": "08:00"
    },
    {
      "value": '08:30',
      "text": "08:30"
    },
    {
      "value": '09:00',
      "text": "09:00"
    },
    {
      "value": '09:30',
      "text": "09:30"
    },
    {
      "value": '10:00',
      "text": "10:00"
    },
    {
      "value": '10:30',
      "text": "10:30"
    },
    {
      "value": '11:00',
      "text": "11:00"
    },
    {
      "value": '11:30',
      "text": "11:30"
    },
    {
      "value": '12:00',
      "text": "12:00"
    },
    {
      "value": '12:30',
      "text": "12:30"
    }, {
      "value": '13:00',
      "text": "13:00"
    }, {
      "value": '13:30',
      "text": "13:30"
    }, {
      "value": '14:00',
      "text": "14:00"
    }, {
      "value": '14:30',
      "text": "14:30"
    }, {
      "value": '15:00',
      "text": "15:00"
    }, {
      "value": '15:30',
      "text": "15:30"
    }, {
      "value": '16:00',
      "text": "16:00"
    }, {
      "value": '16:30',
      "text": "16:30"
    }, {
      "value": '17:00',
      "text": "17:00"
    },
    {
      "value": '17:30',
      "text": "17:30"
    },
    {
      "value": '18:00',
      "text": "18:00"
    },
    {
      "value": '18:30',
      "text": "18:30"
    },
    {
      "value": '19:00',
      "text": "19:00"
    }, {
      "value": '19:30',
      "text": "19:30"
    },
    {
      "value": '20:00',
      "text": "20:00"
    },
    {
      "value": '20:30',
      "text": "20:30"
    },
    {
      "value": '21:00',
      "text": "21:00"
    },
    {
      "value": '21:30',
      "text": "21:30"
    },
    {
      "value": '22:00',
      "text": "22:00"
    },
    {
      "value": '22:30',
      "text": "22:30"
    },
    {
      "value": '23:00',
      "text": "23:00"
    },
    {
      "value": '23:30',
      "text": "23:30"
    }
  ];
  EndTimeSlots = this.TimingSlots;
  minDate: Date;
  maxDate: string;
  allDay:boolean=true;
  PopUpMessage: string;
  msgTitle: string;
  showAlreadyMsgPopUp: boolean = false;
  currentUserName: string;
  Response:string;
  Date;
  currentTime;
  TimingSlotsNew;
  OutlookTimeZones=[
    { 
      "value": "(-1200)",
      "text": "(UTC-12:00) International Date Line West"
  },
  { "value": 
    "(-1100)","text":
            "(UTC-11:00) Coordinated Universal Time-11"
        },
        { "value": 
    "(-1000)","text":
            "(UTC-10:00) Aleutian Islands"
        },
        { "value": 
    "(-1000)","text":
            "(UTC-10:00) Hawaii"
        },
        { "value": 
    "(-0930)","text":
            "(UTC-09:30) Marquesas Islands"
        },
        { "value": 
    "(-0900)","text":
            "(UTC-09:00) Alaska"
        },
        { "value": 
    "(-0900)","text":
            "(UTC-09:00) Coordinated Universal Time-09"
        },
        { "value": 
    "(-0800)","text":
            "(UTC-08:00) Baja California"
        },
        { "value": 
    "(-0800)","text":
            "(UTC-08:00) Coordinated Universal Time-08"
        },
        { "value": 
    "(-0800)","text":
            "(UTC-08:00) Pacific Time (US & Canada)"
        },
        { "value": 
    "(-0700)","text":
            "(UTC-07:00) Yukon"
        },
        { "value": 
    "(-0700)","text":
            "(UTC-07:00) Arizona"
        },
        { "value": 
    "(-0700)","text":
            "(UTC-07:00) Chihuahua, La Paz, Mazatlan"
        },
        { "value": 
    "(-0700)","text":
            "(UTC-07:00) Mountain Time (US & Canada)"
        },
        { "value": 
    "(-0600)","text":
            "(UTC-06:00) Central America"
        },
        { "value": 
    "(-0600)","text":
            "(UTC-06:00) Central Time (US & Canada)"
        },
        { "value": 
    "(-0600)","text":
            "(UTC-06:00) Easter Island"
        },
        { "value": 
    "(-0600)","text":
            "(UTC-06:00) Guadalajara, Mexico City, Monterrey"
        },
        { "value": 
    "(-0600)","text":
            "(UTC-06:00) Saskatchewan"
        },
        { "value": 
    "(-0500)","text":
            "(UTC-05:00) Chetumal"
        },
        { "value": 
    "(-0500)","text":
            "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
        },
        { "value": 
    "(-0500)","text":
            "(UTC-05:00) Eastern Time (US & Canada)"
        },
        { "value": 
    "(-0500)","text":
            "(UTC-05:00) Haiti"
        },
        { "value": 
    "(-0500)","text":
            "(UTC-05:00) Havana"
        },
        { "value": 
    "(-0500)","text":
            "(UTC-05:00) Indiana (East)"
        },
        { "value": 
    "(-0500)","text":
            "(UTC-05:00) Turks and Caicos"
        },
        { "value": 
    "(-0400)","text":
            "(UTC-04:00) Caracas"
        },
        { "value": 
    "(-0400)","text":
            "(UTC-04:00) Asuncion"
        },
        { "value": 
    "(-0400)","text":
            "(UTC-04:00) Atlantic Time (Canada)"
        },
        { "value": 
    "(-0400)","text":
            "(UTC-04:00) Cuiaba"
        },
        { "value": 
    "(-0400)","text":
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
        },
        { "value": 
    "(-0400)","text":
            "(UTC-04:00) Santiago"
        },
        { "value": 
    "(-0330)","text":
            "(UTC-03:30) Newfoundland"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Punta Arenas"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Araguaina"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Brasilia"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Cayenne, Fortaleza"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) City of Buenos Aires"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Greenland"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Montevideo"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Saint Pierre and Miquelon"
        },
        { "value": 
    "(-0300)","text":
            "(UTC-03:00) Salvador"
        },
        { "value": 
    "(-0200)","text":
            "(UTC-02:00) Coordinated Universal Time-02"
        },
        { "value": 
    "(-0200)","text":
            "(UTC-02:00) Mid-Atlantic - Old"
        },
        { "value": 
    "(-0100)","text":
            "(UTC-01:00) Azores"
        },
        { "value": 
    "(-0100)","text":
            "(UTC-01:00) Cabo Verde Is."
        },
        { "value": 
    "(UTC) Coordinated Universal Time","text":
            "(UTC) Coordinated Universal Time"
        },
        { "value": 
    "(+0000)","text":
            "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"
        },
        { "value": 
    "(+0000)","text":
            "(UTC+00:00) Monrovia, Reykjavik"
        },
        { "value": 
    "(+0100)","text":
            "(UTC+01:00) Casablanca"
        },
        { "value": 
    "(+0100)","text":
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
        },
        { "value": 
    "(+0100)","text":
            "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
        },
        { "value": 
    "(+0100)","text":
            "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
        },
        { "value": 
    "(+0100)","text":
            "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
        },
        { "value": 
    "(+0100)","text":
            "(UTC+01:00) West Central Africa"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Tripoli"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Windhoek"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Amman"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Athens, Bucharest"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Beirut"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Cairo"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Chisinau"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Damascus"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Gaza, Hebron"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Harare, Pretoria"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Jerusalem"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Juba"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Kaliningrad"
        },
        { "value": 
    "(+0200)","text":
            "(UTC+02:00) Khartoum"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Istanbul"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Minsk"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Baghdad"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Kuwait, Riyadh"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Moscow, St. Petersburg"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Nairobi"
        },
        { "value": 
    "(+0300)","text":
            "(UTC+03:00) Volgograd"
        },
        { "value": 
    "(+0330)","text":
            "(UTC+03:30) Tehran"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Astrakhan, Ulyanovsk"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Izhevsk, Samara"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Saratov"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Abu Dhabi, Muscat"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Baku"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Port Louis"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Tbilisi"
        },
        { "value": 
    "(+0400)","text":
            "(UTC+04:00) Yerevan"
        },
        { "value": 
    "(+0430)","text":
            "(UTC+04:30) Kabul"
        },
        { "value": 
    "(+0500)","text":
            "(UTC+05:00) Ashgabat, Tashkent"
        },
        { "value": 
    "(+0500)","text":
            "(UTC+05:00) Ekaterinburg"
        },
        { "value": 
    "(+0500)","text":
            "(UTC+05:00) Islamabad, Karachi"
        },
        { "value": 
    "(+0500)","text":
            "(UTC+05:00) Qyzylorda"
        },
        { "value": 
    "(+0530)","text":
            "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
        },
        { "value": 
    "(+0530)","text":
            "(UTC+05:30) Sri Jayawardenepura"
        },
        { "value": 
    "(+0545)","text":
            "(UTC+05:45) Kathmandu"
        },
        { "value": 
    "(+0600)","text":
            "(UTC+06:00) Nur-Sultan"
        },
        { "value": 
    "(+0600)","text":
            "(UTC+06:00) Dhaka"
        },
        { "value": 
    "(+0600)","text":
            "(UTC+06:00) Omsk"
        },
        { "value": 
    "(+0630)","text":
            "(UTC+06:30) Yangon (Rangoon)"
        },
        { "value": 
    "(+0700)","text":
            "(UTC+07:00) Barnaul, Gorno-Altaysk"
        },
        { "value": 
    "(+0700)","text":
            "(UTC+07:00) Novosibirsk"
        },
        { "value": 
    "(+0700)","text":
            "(UTC+07:00) Tomsk"
        },
        { "value": 
    "(+0700)","text":
            "(UTC+07:00) Bangkok, Hanoi, Jakarta"
        },
        { "value": 
    "(+0700)","text":
            "(UTC+07:00) Hovd"
        },
        { "value": 
    "(+0700)","text":
            "(UTC+07:00) Krasnoyarsk"
        },
        { "value": 
    "(+0800)","text":
            "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
        },
        { "value": 
    "(+0800)","text":
            "(UTC+08:00) Irkutsk"
        },
        { "value": 
    "(+0800)","text":
            "(UTC+08:00) Kuala Lumpur, Singapore"
        },
        { "value": 
    "(+0800)","text":
            "(UTC+08:00) Perth"
        },
        { "value": 
    "(+0800)","text":
            "(UTC+08:00) Taipei"
        },
        { "value": 
    "(+0800)","text":
            "(UTC+08:00) Ulaanbaatar"
        },
        { "value": 
    "(+0845)","text":
            "(UTC+08:45) Eucla"
        },
        { "value": 
    "(+0900)","text":
            "(UTC+09:00) Chita"
        },
        { "value": 
    "(+0900)","text":
            "(UTC+09:00) Pyongyang"
        },
        { "value": 
    "(+0900)","text":
            "(UTC+09:00) Osaka, Sapporo, Tokyo"
        },
        { "value": 
    "(+0900)","text":
            "(UTC+09:00) Seoul"
        },
        { "value": 
    "(+0900)","text":
            "(UTC+09:00) Yakutsk"
        },
        { "value": 
    "(+0930)","text":
            "(UTC+09:30) Adelaide"
        },
        { "value": 
    "(+0930)","text":
            "(UTC+09:30) Darwin"
        },
        { "value": 
    "(+1000)","text":
            "(UTC+10:00) Brisbane"
        },
        { "value": 
    "(+1000)","text":
            "(UTC+10:00) Canberra, Melbourne, Sydney"
        },
        { "value": 
    "(+1000)","text":
            "(UTC+10:00) Guam, Port Moresby"
        },
        { "value": 
    "(+1000)","text":
            "(UTC+10:00) Hobart"
        },
        { "value": 
    "(+1000)","text":
            "(UTC+10:00) Vladivostok"
        },
        { "value": 
    "(+1030)","text":
            "(UTC+10:30) Lord Howe Island"
        },
        { "value": 
    "(+1100)","text":
            "(UTC+11:00) Bougainville Island"
        },
        { "value": 
    "(+1100)","text":
            "(UTC+11:00) Magadan"
        },
        { "value": 
    "(+1100)","text":
            "(UTC+11:00) Sakhalin"
        },
        { "value": 
    "(+1100)","text":
            "(UTC+11:00) Chokurdakh"
        },
        { "value": 
    "(+1100)","text":
            "(UTC+11:00) Norfolk Island"
        },
        { "value": 
    "(+1100)","text":
            "(UTC+11:00) Solomon Is., New Caledonia"
        },
        { "value": 
    "(+1200)","text":
            "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky"
        },
        { "value": 
    "(+1200)","text":
            "(UTC+12:00) Auckland, Wellington"
        },
        { "value": 
    "(+1200)","text":
            "(UTC+12:00) Coordinated Universal Time+12"
        },
        { "value": 
    "(+1200)","text":
            "(UTC+12:00) Fiji"
        },
        { "value": 
    "(+1200)","text":
            "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"
        },
        { "value": 
    "(+1245)","text":
            "(UTC+12:45) Chatham Islands"
        },
        { "value": 
    "(+1300)","text":
            "(UTC+13:00) Coordinated Universal Time+13"
        },
        { "value": 
    "(+1300)","text":
            "(UTC+13:00) Nuku'alofa"
        },
        { 
          "value": "(+1300)",
          "text": "(UTC+13:00) Samoa"
      },
        { "value": 
    "(+1400)","text":
            "(UTC+14:00) Kiritimati Island"
        }
]
  ngOnInit() {
    this.TimeZoneSelected = "(-1200)";
    this.ZoneSelected = "(UTC-12:00) International Date Line West";
    this.Date = new Date()
    this.currentTime = this.datepipe.transform(this.Date, 'HH:mm');
    var selectedBookedDate = this.datepipe.transform(this.selectedReservation.date , 'yyyy-MM-dd');
    var todayDate = this.datepipe.transform(localStorage.getItem('localdate'), 'yyyy-MM-dd');
    if(selectedBookedDate==todayDate)
    {
    this.ngzone.run(() => { this.TimingSlotsNew = this.TimingSlots.filter(x => x.value > this.currentTime) });
    }
    this.TimingSlotsNew = this.TimingSlots.filter(x => x.value > this.currentTime)
    //this.showLoader = true;
    var response={
      Code:localStorage.getItem('AccessToken'),
      OutlookMeetingId:this.selectedReservation.iCalUId
    }
    this.minDate =new Date;
    this.maxDate = this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');
    this.today=new Date;
    this.meetingDetails;
    this.currentUserName = localStorage.getItem('CurrentUserEmail').toLowerCase()
    this.ServiceService.checkResponse(response).subscribe((response: string) => {
    if(response!="")
    {
    (response=="accepted"|| response=="tentativelyAccepted")?this.Response="Accepted":this.Response="Declined";
    //this.showLoader = false;
    }
    else
    {
      this.Response="";
      //this.showLoader = false;
    }
  })
  }

  getDateFormatString(): string {
    return 'MM/DD/YYYY';
  }

  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }

  changeIsFullDay(event) {
    this.isFullDay = (event == 'fullDay') ? true : false;
    if (this.isFullDay == true) {
      this.ngzone.run(() => { this.booking_end_time = undefined; this.booking_start_time = undefined });
    }
    if (this.selectedDateDetails.length > 0) {
      var objIndex = this.selectedDateDetails.findIndex((obj => obj.BookedDate == this.today));
      //  if (this.selectedDateDetails[objIndex].IsFullDay == undefined || this.selectedDateDetails[objIndex].IsFullDay == false) {
      //     this.selectedDateDetails[objIndex].BookingEndTime = this.booking_end_time;
      this.selectedDateDetails[objIndex].IsFullDay = false;
      }
    this.validateButton = this.ButtonValidation();
  }

  changeStartTime(timechanged)
  {
    this.booking_start_time = timechanged.target.value;
    this.EndTimeSlots=this.TimingSlotsNew;
    this.EndTimeSlots=this.EndTimeSlots.filter(x=>x.value>timechanged.target.value);
    this.validateButton = this.ButtonValidation();
    this.roomAvailability();
  }

  changeEndTime(timechanged)
  {
    this.booking_end_time = timechanged.target.value;
    this.validateButton = this.ButtonValidation();
    this.roomAvailability();
  }

  changedAllDayCheck()
  {
    this.allDay = !this.allDay;
    this.booking_start_time = undefined;
    this.booking_end_time = undefined;
    this.validateButton=this.ButtonValidation();
    this.roomAvailability();
  }

  changeDate() {
    // this.validateButton = true;
    this.validateButton=this.ButtonValidation();
    this.roomAvailability();
    this.Date = new Date()
    this.currentTime = this.datepipe.transform(this.Date, 'HH:mm');
    var selectedBookedDate = this.datepipe.transform(this.today , 'yyyy-MM-dd');
    var todayDate = this.datepipe.transform(localStorage.getItem('localdate'), 'yyyy-MM-dd');
    if(selectedBookedDate==todayDate)
    {
    this.ngzone.run(() => { this.TimingSlotsNew = this.TimingSlots.filter(x => x.value > this.currentTime) });
    }
    else
    {
      this.ngzone.run(() => { this.TimingSlotsNew = this.TimingSlots });
    }

  }

  ButtonValidation() {
      return (this.today!=null?(this.allDay ? true : (this.booking_start_time != undefined && this.booking_end_time != undefined) ? true : false):false);
  }

  Reschedule() {
    this.showLoader = true;
    var res_details = {
      ID: this.meetingDetails.reservationID,
      BookedDate:this.datepipe.transform(this.today, 'yyyy-MM-dd'),
      StartTime:this.booking_start_time,
      EndTime:this.booking_end_time,
      IsFullday:this.allDay,
      OutlookMeetingId:this.meetingDetails.outlookId,
      Code:localStorage.getItem("AccessToken"),
      TimeZone:'',
      ZoneSelected:''
    };
    if(this.allDay)
    {
      res_details.TimeZone = new Date().toString().split('GMT')[1].split(' ')[0];
      res_details.ZoneSelected = new Date().toString().split('GMT')[1].split(' ')[0];
    }
    else
    {
      res_details.TimeZone = this.TimeZoneSelected;
      res_details.ZoneSelected = this.ZoneSelected;
    }
    this.ServiceService.rescheduleMeeting(res_details).subscribe((data: any) => {
      this.showLoader = false;
      this.showMsgPopUp = true;
      if (data == 300) {
        this.showAlreadyMsgPopUp = true;
        this.translate.get('MeetingBookingError').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('MeetingAlreadyBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      }
    });
  }

    //Outlook Meeting Room Availability
    roomAvailability()
    {
      var checkRoom=((this.allDay) ||
      (!this.allDay && this.booking_end_time!=undefined && this.booking_start_time!=undefined))?"true":"false"
      if(checkRoom=="true")
  {
    this.showLoader = true;
    var timeZone=new Date().toString().split('GMT')[1].split(' ')[0];
    var token=localStorage.getItem('AccessToken');
    var roomDetails = {
      MeetingName: this.meetingDetails.meetingName,    
      StartTime: this.booking_start_time,
      EndTime: this.booking_end_time,
      TimeZone: timeZone,
      Code: token,
      BookedDate: this.datepipe.transform(this.today, 'yyyy-MM-dd'),
      IsFullday:this.allDay
    };
    this.ServiceService.checkRoomAvailability(roomDetails).subscribe((data: any) => {
      if(data==200)
      {
        this.ngzone.run(() => { 
        this.Available=false;
        this.validateButton=false;
        this.showStatus=true;
        this.showLoader = false; });
      }
      else
      {
        this.ngzone.run(() => { 
        this.Available=true;
        this.validateButton=true;
        this.showStatus=true;
        this.showLoader = false;
        });
      }
    })
  }
    }

    SendResponse(Response,detail)
    {
      this.showLoader = true;
      var response={
        ReservationType:Response,
        Code:localStorage.getItem('AccessToken'),
        OutlookMeetingId:detail.iCalUId,
        Attendee:this.currentUserName
      }
      this.ServiceService.sendResponse(response).subscribe((data: any) => {
        this.Response=Response;
        Response=="Accept"?this.Response="Accepted":this.Response="Declined";
          this.showLoader = false;
        });
    }

    ReResponse()
    {
      this.Response="";
    }
    TimeZoneChange(event)
    {
      this.ZoneSelected = event.target.value;
      this.TimeZoneSelected = this.OutlookTimeZones.filter(x=>x.text==this.ZoneSelected)[0].value;
    }
}
