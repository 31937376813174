import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class TestComponent implements OnInit {

  public progress: number;
  public message: string;

  @Output() public onUploadFinished = new EventEmitter();

  constructor(private fileService: ServiceService) { }
  ngOnInit() { }

  public uploadFile = (files) => {
   
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('CurrentUserEmailId',localStorage.getItem('CurrentUserEmail'));

    this.fileService.uploadSpreadSheet(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Upload success.';
        this.onUploadFinished.emit(event.body);
      }
    });
  };

}
