import { ErrorHandler, Injectable } from '@angular/core';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import keys from './key.json';

@Injectable({
  providedIn: 'root'
})
export class AzureBlobStorageService {

  // picturesAccount = "savemyspot";
  // picturesContainer = "floorplansdev";

   picturesAccount = keys[0]['BlobAccount'];
   picturesContainer = keys[0]['BlobContainer'];

  public downloadImage(sas: string, name: string, handler: (blob: Blob) => void) {
    this.downloadBlob(name, this.containerClient(sas), handler)
  }

  private async listBlobs(client: ContainerClient): Promise<string[]> {
    let result: string[] = []

    let blobs = client.listBlobsFlat();
    for await (const blob of blobs) {
      result.push(blob.name)
    }
    return result;
  }

  private downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
    const blobClient = client.getBlobClient(name);
    blobClient.download().then(resp => {
      resp.blobBody.then(blob => {
        handler(blob)
      })
    })
  }

  private containerClient(sas: string): ContainerClient {
    try {
      return new BlobServiceClient(`https://${this.picturesAccount}.blob.core.windows.net?${sas}`)
        // return new BlobServiceClient(`https://savemyseat.azureedge.net?${sas}`)
        .getContainerClient(this.picturesContainer);
    }
    catch (Error) {
      console.log(Error);
    }

  }
}
