import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core'
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { FAQ } from '../Model/FAQ';
import { Router } from '@angular/router';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['../../assets/css/style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {

  // constructor(private titleService: Title,public translate: TranslateService,
  //   private ServiceService: ServiceService,private dataService: userDataService,) { }
  constructor(private titleService: Title,public translate: TranslateService,
    private ServiceService: ServiceService,private dataService: userDataService,private router: Router,) { }


  ngOnInit() {
    this.titleService.setTitle("Save My Seat - FAQ");
    this.GetAllFAQs();
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      var userRole = payLoad.role;
      // if(userRole == 'SuperAdmin' ||  userRole == 'RegionalLeader')
      if(userRole == 'SuperAdmin')
      {
        this.isAdmin=true;
      }
  }

  faqs: FAQ[];
  uniqueCats: string[];
  faqDictionary: {};
  ValueForDoc: boolean;
  isAdmin:boolean;

  //Get all FAQs
  GetAllFAQs(){
    this.ValueForDoc=localStorage.getItem('locale')!='es'?true:false;    
    let partialUrl = 'api/FAQ/GetAllFAQs';
    this.ServiceService.getWithoutParams(partialUrl).subscribe((data: FAQ[]) => {
      //this.faqs = data;
      this.faqs=data.filter(x=>x.langKey==localStorage.getItem('locale'));
      if(this.faqs.length==0)
      {
        this.faqs=data.filter(x=>x.langKey=='en');
      }
      this.uniqueCats = Array.from(new Set(this.faqs.map(item => item.category)));

    });
  }
  LoadFAQBasedonLang() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  } 

  //Show the details relevant to selected tab ('FAQ's, etc)
  PageTab(evt, tabName) {
    var i, x, tablinks;
    x = document.getElementsByClassName("tabContent");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("pagelink");
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  //Filter faqs based on category
  filterFAQsByCategory(category){
    return this.faqs.filter(x => x.category == category);
  }

  //Expand the selected FAQ Question and Answer
  ExpandFAQ(event){
    event.preventDefault();
    let obj = event.target;
    if (obj.nextSibling.classList.contains('show')) {
			obj.nextSibling.classList.remove('show');
      obj.nextSibling.style.display = 'none'
    } else {
      [].forEach.call(obj.parentElement.parentElement.childNodes, function(child1) {
        if(child1.nextSibling != null){
          [].forEach.call(child1.nextSibling.children, function(child2) {
            if (child2.classList.contains('inner')){
              child2.classList.remove('show');
              child2.style.display = 'none';
            }
        });
        }
      })
			obj.nextSibling.classList.add( "show" )
      obj.nextSibling.style.display = 'block'
		}
  }


}


