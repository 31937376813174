import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import { Router } from '@angular/router';
import { tab } from '../utils';
import * as moment from 'moment';
import { concatMapTo } from 'rxjs/operators';
import { debug } from 'console';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-swapdesk',
  templateUrl: './swapdesk.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class SwapdeskComponent implements OnInit {
  selectedReservation: any;
  meetingDetails: any;
  data: tab[];
  DeskData: tab[];
  ParkingData: tab[];
  GymData: tab[];
  showPopUp: boolean = false;
  // canSwap:boolean = false;
  showRescheduleMeeting: boolean = false;
  RegionCountrySelect: boolean = false;
  showLoader = false;
  isCheckedIn: boolean = false;
  currentLoggedIn: string = localStorage.getItem("CurrentUserEmail").toLowerCase();
  Deskvalue: string = 'Desk';
  ParkingType: string;
  showReservationPopUp: boolean = false;
  //OfficeNameNotToShowSwapPopup: string[] =["Safina","test","Test Manchester","Floor Testing"];
  showErrSwapNotPossible: boolean;
  msgTitle: string;
  PopUpMessage: string;
  okBtnText: string = "OK";
  OfficeNameNotToShowSwapPopup: any;
  constructor(private ServiceService: ServiceService,
    private titleService: Title,
    private dataService: userDataService,
    private router: Router, public translate: TranslateService) { }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle("Swap desk");
    localStorage.setItem('PageName', 'Swap');
    localStorage.setItem('isCheckedIn', 'false');
    localStorage.setItem('Country1', 'null');
    localStorage.setItem('Office1', 'null');
    localStorage.setItem('BUId', 'null')
    //to swap current day bookings also
    var previousDate = new Date();
    localStorage.setItem('OfficeID', '0');
    localStorage.setItem('SwapType', 'Desk');
    //previousDate.setDate(previousDate.getDate() - 1);

    // alert('hi');
    // alert('Zone id-'+localStorage.getItem("ZoneId") +'FloorID-'+localStorage.getItem("FloorID"));


    var params = {
      CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      Date: moment.utc(previousDate).local().format('YYYY-MM-DD'),
      Code: localStorage.getItem('AccessToken')
    };
    this.ServiceService.getSelfReservationsForSwap(params).subscribe((data: any[]) => {
      this.data = data;
      this.DeskData = data;
    });
    this.ServiceService.getParkingReservations(params).subscribe((data: any[]) => {
      this.ParkingData = data.filter(x => x.title == "Upcoming");
    });
    this.ServiceService.getSelfReservationsGymForSwap(params).subscribe((data: any[]) => {
      this.GymData = data;
    });
    this.ServiceService.getOfficesNotForSwap().subscribe((data: any[]) => {
      this.OfficeNameNotToShowSwapPopup = data;
    });
    this.sleep(2000).then(() => { this.showLoader = false; });
    //this.showLoader = false;  
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ResSelected(selRes) {
    //Swap Only in Reserved Country and Office
    this.ServiceService.getCheckedInStatus(selRes.reservationID).subscribe((data: any) => {
      localStorage.setItem('isCheckedIn', data.isCheckedIn);
      this.isCheckedIn = data.isCheckedIn;
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });

    // if (this.isCheckedIn) {
    this.ServiceService.getCountryBasedOnOffice(selRes.location).subscribe((data: any[]) => {
      localStorage.setItem('Country1', data['country']);
      localStorage.setItem('Office1', data['office']);
    });
    //End
    //}
    // this.canSwap=false;
    if (!this.showRescheduleMeeting) {
      this.showPopUp = true;
      // if(localStorage.getItem('CurrentUserEmail').toLowerCase()==selRes.emailId.toLowerCase())
      // {
      // this.canSwap=true;
      // }
      this.selectedReservation = selRes;
      localStorage.setItem('GroupedBU', 'false');
    }
  }

  SwapBooking() {
    if (parseInt(localStorage.getItem('OfficeID')) == 0 || localStorage.getItem('OfficeID') == 'undefined') {
      return;
    }
    this.showLoader = true;
    localStorage.setItem('SelectedDateToBook', this.selectedReservation.date);
    localStorage.setItem('bookingFor', this.selectedReservation.emailId);
    this.dataService.setSwapResDetails(this.selectedReservation);

    this.ServiceService.getDeskDetails(this.selectedReservation.deskID).subscribe((data: any) => {
      // localStorage.setItem('FloorID', data.floorID);
      // localStorage.setItem('ZoneId', data.zoneID);
      // localStorage.setItem('FloorPlanPath', data.floorPlanPath);
      // localStorage.setItem('FullFloorPlanPath', data.fullFloorplanPath);
      // localStorage.setItem('ShowFullFloorplan', data.showFullFloorplan);
      // localStorage.setItem('OfficeID', data.officeID);
      if (localStorage.getItem('BUId') != "201") {
        //localStorage.setItem('BUId', data.buID);
      }
      localStorage.setItem('SelectTypeofSeat', data.hasDeskCategory);
      //localStorage.setItem('GroupedBU', data.isGroupedBU);
      this.closeBooking();
      this.showLoader = false;
      if (localStorage.getItem('SwapType') == "Desk")
        this.router.navigateByUrl('/swapseat');
      else
        this.router.navigateByUrl('/gymSwapSeat');
    });
  }

  RescheduleMeeting(resBooking) {
    this.showRescheduleMeeting = true;
    this.meetingDetails = resBooking;
    this.selectedReservation = resBooking;
  }


  closeBooking() {
    this.selectedReservation = undefined;
    this.showReservationPopUp = false;
    this.showPopUp = false;
  }

  closeMeeting() {
    this.meetingDetails = undefined
    this.showRescheduleMeeting = false;
    this.selectedReservation = false;
  }
  onItemChange(value) {
    if (this.Deskvalue == "Desk") {
      localStorage.setItem('SwapType', 'Desk');
      this.data = this.DeskData;
    }
    else if (this.Deskvalue == "Parking") {
      localStorage.setItem('SwapType', 'Parking');
      this.data = this.ParkingData;
    }
    else {
      localStorage.setItem('SwapType', 'Gym');
      this.data = this.GymData;
    }
  }

  ResSelectedParking(detail) {
    this.selectedReservation = detail;
    if (this.selectedReservation.type != null || this.selectedReservation.type == "") {
      if (this.selectedReservation.type == "AM (Before Noon)") {
        this.ParkingType = "ParkingTabHead2";
      }
      else if (this.selectedReservation.type == "PM (Noon-Close)") {
        this.ParkingType = "ParkingTabHead3";
      }
      else if (this.selectedReservation.type == "All Day") {
        this.ParkingType = "ParkingTabHead4";
      }
    }

    if (this.OfficeNameNotToShowSwapPopup.indexOf(this.selectedReservation.officeName) > -1) {
      this.showErrSwapNotPossible = true;
      this.translate.get('Warning').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('ParkingSwapNotShow').subscribe((data: string) => { this.PopUpMessage = data; });
    }
    else {
      this.showReservationPopUp = true;
    }
  }
  formSubmit() {
    this.showLoader = false;
    this.showErrSwapNotPossible = false;
  }
  SwapParking() {
    this.dataService.redirectToParking.next('Yes');
    this.dataService.countryName.next(this.selectedReservation.countryName);
    this.dataService.setParkingDetails(this.selectedReservation);
    this.router.navigateByUrl('/reserveparking');
    this.showLoader = true;
  }
}
