import { Component, OnInit, ViewEncapsulation, EventEmitter, NgZone, ViewChild } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { TranslateService } from '@ngx-translate/core'
import { Data, Router } from '@angular/router';
import { DatePipe,formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ParkingItemApproved } from '../Model/CarParkingDTO';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { th } from 'date-fns/locale';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import * as moment from 'moment';

@Component({
  selector: 'app-new-parking',
  templateUrl: './new-parking.component.html',
  styleUrls: ['../../assets/css/style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewParkingComponent implements OnInit {
  readonly bookingTypeMap = new Map<string, number>();
  offices: any;
  officesFilter: any;
  parkSpaces: ParkingItemApproved[];
  filteredParkSpaces: ParkingItemApproved[];
  parkingTypes: any[];
  selParkSpace: any;
  selectedOffice: any;
  selParkType: any;
  today: Date;
  minDate: Date;//string;
  maxDate: Date;//string;
  currentDate: any;
  showMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  validateButton: boolean = false;
  showLoader: boolean = false;
  licenseText: string = "";
  selSpaceId: number;
  selBkngType: number;
  selSpacePermitReq: boolean = false;
  isSpaceSelected: boolean = false;
  parkingReservedFor: any;
  isSouthAmerica: boolean;
  showMsgPopUpError: boolean;
  redirectTo: string = 'undefined';
  showPopupCancelBtn: boolean = false;
  cancelBtnText: string = "Cancel";
  okBtnText: string = "OK";
  public selectedDateModel = [];
  sendToBooking: boolean = true;
  bookingSlot: number;
  BookingType: string = 'single';
  selectedDateModelSelected: any;
  selectedDateDetails = [];
  MultiSelectDisbled: boolean = false;
  selPrefType: string = "Default";
  selVehicleType: number;
  showMsgPopUpErr: boolean;
  UserhasPermit: boolean = false;
  Show: boolean = false;
  ShowType: boolean = false;
  ShowLic: boolean = false;
  Showofc: boolean = false;
  selectseat: boolean = false;
  //OthersCheck:boolean=false;
  public items: any[] = [
    { field: 'ALL', id: 1 },
    { field: 'AM', id: 2 },
    { field: 'PM', id: 3, selected: true },

  ];

  public init = new Date();
  desksData: any[];
  displayPersonalDetails: boolean = true;
  public resetModel = new Date(0);
  @ViewChild('picker') _picker: MatDatepicker<Date>;
  SwapParkingData: any;
  RedirectFromSwap: boolean = false;
  OfficeIdForSwap: string = null;
  redirecToSwap: string = '/parking';
  navigateToSwap: string = '/parking';
  currentDomain: string;
  displayUserName: string;
  displayUserEMail: string;
  bookingFor: string;
  selfvalue: string = 'self';
  UserDetails: any;
  isVisitor: boolean = false;
  givenText: string;
  selectFromDesk: boolean = false;
  CheckAllWaitingList: boolean = true;
  IsMultipleDay: boolean = true;
  RedirectFromSelectDeskTemp: number;
  public CLOSE_ON_SELECTED = false;
  RedirectFromSelectDesk: boolean = false;

  constructor(private ServiceService: ServiceService, private ngzone: NgZone, private router: Router
    , public translate: TranslateService, private titleService: Title,
    private dataService: userDataService,
    public datepipe: DatePipe) {

    this.bookingTypeMap.set('ALL', 1);
    this.bookingTypeMap.set('AM', 2);
    this.bookingTypeMap.set('PM', 3);
  }

  ngOnInit() {
    this.IsMultipleDay = localStorage.getItem('SelectedCountry') != "APAC" ? true : false;
    this.currentDomain = localStorage.getItem('currentDomain');
    this.isSouthAmerica = localStorage.getItem('SelectedCountry') != "South America" ? false : true;
    var countryTemp: string = null;
    this.bookingFor = localStorage.getItem('bookingFor') == undefined ? 'self' : localStorage.getItem('bookingFor');
    this.selfvalue = this.bookingFor;
    if (this.dataService.redirectToParking.getValue() == 'Yes') {
      countryTemp = this.dataService.countryName.getValue();
      //For In Progess working
      if (countryTemp != 'South America') { this.isSouthAmerica = false; }
      else { this.isSouthAmerica = true; }
      //End
      this.dataService.countryName.next('No');
      this.SwapParkingData = this.dataService.getParkingDetails();
      this.licenseText = this.SwapParkingData.licencePlate;
      this.RedirectFromSwap = true;
      this.navigateToSwap = '/swap';
      this.dataService.setParkingDetails(null);
    }
    else {
      countryTemp = localStorage.getItem('SelectedCountry');
    }
    this.ServiceService.getOffices(countryTemp, false).subscribe((data: any[]) => {
      this.offices = data.filter(o => o.hasParking == true);
      if (this.RedirectFromSwap) {
        var officeSelectForSwap = this.offices.filter(o => o.name == this.SwapParkingData.officeName);
        this.OfficeIdForSwap = officeSelectForSwap[0].id;
        this.officesFilter = this.offices.filter(o => o.id == this.OfficeIdForSwap);
      }

      if (this.offices.length > 0 && (localStorage.getItem('OfficeID') != undefined)) {
        this.selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
        this.selectedOffice=undefined;
        // let _officeDetail = this.offices.find(x => x.id == this.selectedOffice);
        if (this.RedirectFromSwap) {
          this.selectedOffice = this.OfficeIdForSwap;
        }
        var selectedOfficeIDForParking = parseInt(localStorage.getItem('RedirectFromSelectDesk'));
        if (isNaN(selectedOfficeIDForParking)) {
          selectedOfficeIDForParking = 0;
        }
        if (selectedOfficeIDForParking != 0 && this.RedirectFromSwap==false) {
          this.RedirectFromSelectDesk = true;
          this.RedirectFromSelectDeskTemp=selectedOfficeIDForParking;
          this.selectFromDesk = true;
          if (this.dataService.getSelectedDateList().length > 1) {
            this.BookingType = 'multiple';
            this.MultiSelectDisbled = true;
            this.selectedDateDetails = this.dataService.getSelectedDateList();

            this.selectedDateDetails.forEach(element => {
              element.SpaceId = '';
            });
            this.filteredParkSpaces = null;
            this.today = undefined;
            this.ServiceService.getParkingTypes(this.selectedOffice).subscribe((data: any[]) => {
              this.parkingTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));;
            })
          }
          this.offices = data.filter(o => o.id == selectedOfficeIDForParking);
          this.selectedOffice = localStorage.getItem('RedirectFromSelectDesk');
          //localStorage.setItem('RedirectFromSelectDesk', "0");
          this.selectedOffice=undefined;
          this.sendToBooking = false;
        }
        this.displayUserName = this.bookingFor == 'self' ? localStorage.getItem('CurrentUser') : (this.selectFromDesk ? localStorage.getItem('selectedOtherUserName') : localStorage.getItem('selectedOtherUserNameParking'));
        this.displayUserEMail = this.bookingFor == 'self' ? localStorage.getItem('CurrentUserEmail') : (this.selectFromDesk ? localStorage.getItem('selectedOtherUserEmail') : localStorage.getItem('selectedOtherUserEmailParking'));
        this.officeLoader();
      }
    });
    this.currentDate = new Date();
    //this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    //this.today = localStorage.getItem('SelectedDateToBook') == undefined ? this.currentDate : localStorage.getItem('SelectedDateToBook');
    this.today = localStorage.getItem('SelectedDateToBookWithUTC') == undefined ? this.currentDate :new Date(localStorage.getItem('SelectedDateToBookWithUTC'));

    if (this.RedirectFromSwap) {
     // this.currentDate = this.datepipe.transform(this.SwapParkingData.bookingDate, 'yyyy-MM-dd');
      this.today = this.SwapParkingData.bookingDate;//this.currentDate;

      // this.selectedOffice=this.OfficeIdForSwap;

      // this.selectedOffice=this.offices.filter(o => o.name == this.SwapParkingData.officeName).id;
    }

    this.minDate =new Date();// this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.maxDate = this.CalculateDate(); //this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');
    this.titleService.setTitle("Make a Parking Reservation");
    if (!this.RedirectFromSwap) {
      localStorage.setItem('PageName', 'Park');
    }
    this.dataService.redirectToParking.next('No');
  }

  parkSpacesLoader() {
    if (this.selectedOffice == undefined || this.selectedOffice == 'undefined') return;
    if (this.today.toString() != "") {
      this.showLoader = true;
    }
    //------Date Format changes 12Oct
    //this.ServiceService.getParkingSpaces(this.selectedOffice, this.today).subscribe((data: any) => {
    this.ServiceService.getParkingSpaces(this.selectedOffice, this.datepipe.transform(this.today, 'yyyy-MM-dd')).subscribe((data: any) => {
      let allSpaces = data.allSpaces;
      let reservedSpaces = data.reservedSpaces;

      this.parkSpaces = [];
      this.filteredParkSpaces = [];
      allSpaces.filter(x => x.langKey == localStorage.getItem('locale')).forEach(e => {
        let _space = {} as ParkingItemApproved;
        _space.id = e.id;
        _space.name = e.spaceNumber;
        _space.AMvalue = "AM_" + e.id;
        _space.PMvalue = "PM_" + e.id;
        _space.ALLvalue = "ALL_" + e.id;
        _space.type = e.spaceType;
        _space.typeId = e.spaceTypeId;
        _space.permitRequired = e.permitRequired;
        _space.location = e.location;

        var spaceBooked = reservedSpaces.filter(value => value.id === e.id);
        if (spaceBooked.length > 0) {
          let bkngType = spaceBooked[spaceBooked.length - 1].bookingType;
          let bkngStatus = spaceBooked[spaceBooked.length - 1].isBooked;
          let Approved = spaceBooked[spaceBooked.length - 1].approved;
          if (bkngStatus) {
            if (bkngType == 1) {
              _space.amBooked = false; _space.pmBooked = false; _space.allBooked = true;
              if (Approved == true) { _space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = true; }
            }
            if (bkngType == 2) {
              _space.amBooked = true; _space.pmBooked = false; _space.allBooked = false;
              if (Approved == true) {
                _space.amBookedApproved = true; _space.pmBookedApproved = false; _space.allBookedApproved = false;
              }
            }
            if (bkngType == 3) {
              _space.amBooked = false; _space.pmBooked = true; _space.allBooked = false;
              if (Approved == true) {
                _space.amBookedApproved = false; _space.pmBookedApproved = true; _space.allBookedApproved = false;
              }
            }
          }
          else {
            _space.amBooked = false; _space.pmBooked = false; _space.allBooked = false;
            //_space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = false;
          }
          if (spaceBooked.length > 4) {
            let bkngType = spaceBooked[spaceBooked.length - 2].bookingType;
            let bkngStatus = spaceBooked[spaceBooked.length - 2].isBooked;
            let Approved = spaceBooked[spaceBooked.length - 2].approved;
            if (bkngStatus) {
              if (bkngType == 2) {
                _space.amBooked = true; _space.allBooked = false;
                if (Approved == true) {
                  _space.amBookedApproved = true; _space.allBookedApproved = false;
                }
              }
              if (bkngType == 3) {
                _space.pmBooked = true; _space.allBooked = false;
                if (Approved == true) {
                  _space.pmBookedApproved = true; _space.allBookedApproved = false;
                }
              }
            }
          }
        } else {
          _space.amBooked = false; _space.pmBooked = false; _space.allBooked = false;
          _space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = false;
        }
        this.parkSpaces.push(_space);
        this.filteredParkSpaces.push(_space);
      });
      this.checkWaitingListValidation(this.selPrefType);
      this.showLoader = false;
      this.validateButton = false;
    });
  }

  officeLoader() {
    this.selParkType = undefined;
    if (this.selectedOffice == undefined || this.selectedOffice == 'undefined') return;
    if(this.BookingType == 'single') {
    this.parkSpacesLoader();
    }
    this.ServiceService.getParkingTypes(this.selectedOffice).subscribe((data: any[]) => {
      this.parkingTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));;
    })
  }

  LoadSpacedBasedonLang() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  changeOffice(e) {
    var _offID = e.target.value;
    this.officesFilter = this.offices.filter(o => o.id == _offID);
    if (this.officesFilter[0].isParkingMultipleDay != true) {
      this.IsMultipleDay = false;
      this.BookingType = 'single';
      this.filteredParkSpaces = null;
      this.selectedDateDetails = [];
    }
    else{
      this.IsMultipleDay = true;


      if (this.RedirectFromSelectDesk == true || this.RedirectFromSwap == true) {
      }
      else{
        this.BookingType = 'single';
        this.filteredParkSpaces = null;
        this.selectedDateDetails = [];
      }
    }
    this.currentDate = new Date();
    this.currentDate.setDate(this.currentDate.getDate() + parseInt(this.officesFilter[0].resParkingDurationStart));


    //this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    if (this.RedirectFromSelectDeskTemp == 0 || this.RedirectFromSelectDeskTemp == undefined) {
    // this.currentDate = new Date();
    // this.currentDate.setDate(this.currentDate.getDate() + parseInt(this.officesFilter[0].resParkingDurationStart));


    // this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.today = this.currentDate;

    this.minDate =this.today;// this.datepipe.transform(this.today, 'yyyy-MM-dd');
    // this.maxDate = this.datepipe.transform(this.CalculateDateOfficeSelection(this.officesFilter[0].resParkingDurationEnd), 'yyyy-MM-dd');
    }
    else{this.minDate = this.currentDate;}//this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');}

    this.maxDate = this.CalculateDateOfficeSelection(this.officesFilter[0].resParkingDurationEnd);//this.datepipe.transform(this.CalculateDateOfficeSelection(this.officesFilter[0].resParkingDurationEnd), 'yyyy-MM-dd');

    var s=this.today;
    if(new Date(s) >= new Date(this.minDate) && new Date(s) <= new Date(this.maxDate))
    {}
    else{
      this.today=this.currentDate;
    }

    localStorage.setItem('SelectedOfficeIdParking', this.selectedOffice);
    this.officeLoader();
    this.checkWaitingListValidation(this.selPrefType);
  }
  CalculateDateOfficeSelection(day) {
    var date = new Date();
    date.setDate(date.getDate() + parseInt(day));
    return date;
  }

  changeParkType(e) {
    this.selVehicleType = parseInt(e.target.value);
    this.filteredParkSpaces = this.parkSpaces.filter(v => v.typeId == e.target.value);
    //this.validateButton = false;
    this.ShowType = false;
    this.checkWaitingListValidation(this.selPrefType);
  }

  changeSlotType(e) {
    this.selBkngType = parseInt(e.target.value);
    this.Show = false;
    this.checkWaitingListValidation(this.selPrefType);

    //this.validateButton = false;
  }
  currentSelectionSlot(e) {
    this.selPrefType = e;
    if (this.selPrefType == "Preferred") {
      document.getElementById("WaitingParkType").hidden = false;
      document.getElementById("WaitingSlotType").hidden = false;
      this.checkWaitingListValidation(this.selPrefType);
    }
    else {
      document.getElementById("WaitingParkType").hidden = true;
      document.getElementById("WaitingSlotType").hidden = true;
      this.checkWaitingListValidation(this.selPrefType);
    }
  }

  checkWaitingListValidation(selPrefType) {
    if (this.BookingType == "multiple") {
      if (this.selectedDateDetails.length == 0) {
        return;
      }
    }
    if (this.selectedOffice == undefined || this.selectedOffice == 'undefined') {
      this.Showofc = true;
      //this.showLoader = false;
      return;
    }
    if (this.licenseText == "") {
      this.ShowLic = true;
      //this.showLoader = false;
      return;
    }
    if (this.selPrefType == "Preferred") {
      if (this.selVehicleType == undefined) {
        this.ShowType = true;
        document.getElementById("btnWaitingList").hidden = true;
        // this.showLoader = false;
        return;
      }
    }
    if (this.selPrefType == "Preferred") {
      if (this.selBkngType == undefined) {
        this.Show = true;
        document.getElementById("btnWaitingList").hidden = true;
        //this.showLoader = false;
        return;
      }
    }
    if(localStorage.getItem('bookingFor')=='others') {
      this.parkingReservedFor= localStorage.getItem('selectedOtherUserEmailParking');
    }
    else{
      this.parkingReservedFor=null;
    }
    var res_details = {
      SpaceId: this.selVehicleType,
      //DeskReservationId: 0,
      ReservedFor: (this.parkingReservedFor == null ? localStorage.getItem('CurrentUserEmail') : this.parkingReservedFor),
      ReservedDate:this.datepipe.transform(this.today, 'yyyy-MM-dd'),// this.today,
      CreatedBy: null,
      CreatedDate: new Date(),
      TypeId: this.selBkngType,
      LicenseDetail: this.licenseText,
      OfficeId: parseInt(this.selectedOffice)

      // DeskDetails: this.BookingType == 'single' ? null : this.selectedDateDetails
    };
    this.ServiceService.CheckUserPermit(res_details).subscribe((data: any) => {
      if (data == true) {
        this.UserhasPermit = true;
      }else{this.UserhasPermit = false;}
      if (selPrefType == "Default") {
        if (!this.UserhasPermit) {
          var amslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false && x.permitRequired == false);
          var pmslotavailbleslot = this.filteredParkSpaces.filter(x => x.pmBooked == false && x.allBooked == false && x.permitRequired == false);
          var allslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false && x.pmBooked == false && x.permitRequired == false);
        }
        else {
          var amslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false);
          var pmslotavailbleslot = this.filteredParkSpaces.filter(x => x.pmBooked == false && x.allBooked == false);
          var allslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false && x.pmBooked == false);
        }
        if (amslotavailbleslot.length > 0 || pmslotavailbleslot.length > 0 || allslotavailbleslot.length > 0) {
          document.getElementById("btnWaitingList").hidden = true;
        }
        else if (this.BookingType == 'multiple') {
          if (this.selectseat) {
            document.getElementById("btnWaitingList").hidden = false;
          }
          else {
            document.getElementById("btnWaitingList").hidden = true;
          }
        }
        else {
          document.getElementById("btnWaitingList").hidden = false;
        }
      }
      if (selPrefType == "Preferred") {
        if (res_details.TypeId == 2) {
          if (!this.UserhasPermit) {
            var amslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false && x.permitRequired == false && x.typeId == this.selVehicleType);
          }
          else {
            var amslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false && x.typeId == this.selVehicleType);
          }
          if (amslotavailbleslot.length > 0) {
            // this.showMsgPopUpErr = true;
            // this.translate.get('available').subscribe((data: string) => { this.msgTitle = data; });
            // this.translate.get('AvailableParkingSlot').subscribe((data: string) => { this.PopUpMessage = data; });
            // this.showLoader = false;
            // return;
            document.getElementById("btnWaitingList").hidden = true;
          }
          else if (this.BookingType == 'multiple') {
            if (this.selectseat) {
              document.getElementById("btnWaitingList").hidden = false;
            }
            else {
              document.getElementById("btnWaitingList").hidden = true;
            }
          }
          else {
            document.getElementById("btnWaitingList").hidden = false;
          }
        }
        else if (res_details.TypeId == 3) {
          if (!this.UserhasPermit) {
            var pmslotavailbleslot = this.filteredParkSpaces.filter(x => x.pmBooked == false && x.allBooked == false && x.permitRequired == false && x.typeId == this.selVehicleType);
          }
          else {
            var pmslotavailbleslot = this.filteredParkSpaces.filter(x => x.pmBooked == false && x.allBooked == false && x.typeId == this.selVehicleType);
          }
          if (pmslotavailbleslot.length > 0) {
            // this.showMsgPopUpErr = true;
            // this.translate.get('available').subscribe((data: string) => { this.msgTitle = data; });
            // this.translate.get('AvailableParkingSlot').subscribe((data: string) => { this.PopUpMessage = data; });
            // this.showLoader = false;
            // return;
            document.getElementById("btnWaitingList").hidden = true;
          }
          else if (this.BookingType == 'multiple') {
            if (this.selectseat) {
              document.getElementById("btnWaitingList").hidden = false;
            }
            else {
              document.getElementById("btnWaitingList").hidden = true;
            }
          }
          else {
            document.getElementById("btnWaitingList").hidden = false;
          }
        }
        else {
          if (!this.UserhasPermit) {
            var allslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false && x.pmBooked == false && x.permitRequired == false && x.typeId == this.selVehicleType);
          }
          else {
            var allslotavailbleslot = this.filteredParkSpaces.filter(x => x.amBooked == false && x.allBooked == false && x.pmBooked == false && x.typeId == this.selVehicleType);
          }
          if (allslotavailbleslot.length > 0) {
            // this.showMsgPopUpErr = true;
            // this.translate.get('available').subscribe((data: string) => { this.msgTitle = data; });
            // this.translate.get('AvailableParkingSlot').subscribe((data: string) => { this.PopUpMessage = data; });
            // this.showLoader = false;
            // return;
            document.getElementById("btnWaitingList").hidden = true;
          }
          else if (this.BookingType == 'multiple') {
            if (this.selectseat) {
              document.getElementById("btnWaitingList").hidden = false;
            }
            else {
              document.getElementById("btnWaitingList").hidden = true;
            }
          }
          else {
            document.getElementById("btnWaitingList").hidden = false;
          }
        }
      }
    });
  }

  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }

  changeDate() {
    this.selParkType = undefined;
    this.parkSpacesLoader();
    this.checkWaitingListValidation(this.selPrefType);
    var res_details = {
      SpaceId: -1,
      DeskReservationId: 0,
      ReservedFor: this.ServiceService.encrypt(this.parkingReservedFor == null ? localStorage.getItem('CurrentUserEmail') : this.parkingReservedFor),
      ReservedDate: this.datepipe.transform(this.today, 'yyyy-MM-dd'),//this.today,
      CreatedBy: localStorage.getItem('CurrentUserEmail'),
      CreatedDate: new Date(),
      TypeId: -1,
      LicenseDetail: ''
    };

    this.ServiceService.CheckUserCarParking(res_details).subscribe((data: any) => {
      if (data == true) {
        this.showMsgPopUp = true;
        this.redirectTo = '/parking';
        this.translate.get('MultiBkngWarning').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('UserAlreadyBkdSpaceTitle').subscribe((data: string) => { this.PopUpMessage = data; });
      }
    }, error => { })
  }

  changeParkSpace(item, timeSlot: string) {
    var Name = item.name;
    this.selSpaceId = item.id;
    this.selSpacePermitReq = item.permitRequired;
    this.selBkngType = this.bookingTypeMap.get(timeSlot);
    this.isSpaceSelected = true;


    if (this.BookingType == 'multiple') {
      var selDeskNumber = undefined;
      var selTypeID = undefined;
      //check and find desk number selected for the day

      // TypeId: this.selBkngType,

      //const index = this.selectedDateDetails.findIndex(x => x.BookedDate === this.today);
      const index = this.selectedDateDetails.findIndex(x => x.BookedDate === this.datepipe.transform(this.today, 'yyyy-MM-dd'));
      if (index != -1) {
        //selDeskNumber = this.selectedDateDetails[index].SpaceId;
        this.selectedDateDetails[index].SpaceId = this.selSpaceId;
        this.selectedDateDetails[index].TypeId = this.selBkngType;
        this.selectedDateDetails[index].Name = Name;
        selDeskNumber = this.selectedDateDetails[index].SpaceId;
        selTypeID = this.selectedDateDetails[index].TypeId;
      }
      this.dataService.setSelectedDeskNumber(selDeskNumber);
      this.dataService.setSelectedDeskNumber(selTypeID);
    }

    this.validateBtnCheck();

    // if (this.selSpacePermitReq) {
    //   this.showMsgPopUp = true;
    //   this.translate.get('PermitReqTitle').subscribe((data: string) => { this.msgTitle = data; });
    //   this.translate.get('PermitReqWarn').subscribe((data: string) => { this.PopUpMessage = data; });
    // }
  }

  validateBtnCheck() {
    if (this.licenseText != ""&& this.licenseText != null && this.isSpaceSelected == true) {
      if (this.bookingFor == 'self') {
        this.validateButton = true;
        document.getElementById("btnWaitingList").hidden = true;
      }
      else {
        var selectedOfficeIDForParking = parseInt(localStorage.getItem('RedirectFromSelectDesk'));
        if (localStorage.getItem('selectedOtherUserEmailParking') != null || selectedOfficeIDForParking != 0) {
          this.validateButton = true;
          document.getElementById("btnWaitingList").hidden = true;
        } else {
          this.validateButton = false;
        }
      }
    } else {
      this.validateButton = false;
    }


    if (this.BookingType == 'multiple') {
      this.selectedDateDetails.forEach(element => {
        if (element.SpaceId == undefined || element.SpaceId == 'undefined' || element.SpaceId == 0 || element.SpaceId == '0') {
          this.validateButton = false;
        }
      });
      // this.selectedDateDetails.forEach(element => {
      //   if (element.SpaceId != 'undefined' || element.SpaceId != 'undefined' || element.SpaceId != 0 || element.SpaceId != '0') {
      //     this.validateButton = true;
      //   }
      // });

    }
  }

  search() {
    this.validateBtnCheck();
    if (this.licenseText != "") {

      this.ShowLic = false;
      this.checkWaitingListValidation(this.selPrefType);
    }
  }

  BookParking() {

    if (this.officesFilter[0].parkingStartTime != '00:00:00') {

      var ss1=(this.officesFilter[0].parkingStartTime).toString().substring(0,5);
    var ss2=(this.officesFilter[0].parkingEndTime).toString().substring(0,5);
    var ss3=this.datepipe.transform((new Date), 'H:mm').toString().substring(0,5);
    let diff = moment(ss3, 'HH:mm').diff(moment(ss1, 'HH:mm'))
    let diff2 = moment(ss3, 'HH:mm').diff(moment(ss2, 'HH:mm'))

      if (diff>0 && diff2<0) {
      }
      else {
        this.showMsgPopUpError = true;
        this.translate.get('ParkingTimingTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('ParkingTimingBody').subscribe((data: string) => { this.PopUpMessage = data + ss1 + " - " + ss2; });
        return;
      }
    }



    if (this.selSpacePermitReq) {
      if (localStorage.getItem('ParkingBookingFor') != null && localStorage.getItem('ParkingBookingFor') != "" && localStorage.getItem('ParkingBookingFor') != undefined) {
        this.parkingReservedFor = this.displayUserEMail;
      }
      else {
        this.parkingReservedFor = this.displayUserEMail;
      }
      var user_details = {
        OfficeId: Number(this.selectedOffice),
        ReservedFor: this.ServiceService.encrypt(this.parkingReservedFor)
      }
      this.ServiceService.CheckUserPermit(user_details).subscribe((data: any) => {
        if (data == false) {
          this.showMsgPopUp = true;
          this.translate.get('PermitReqTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('PermitReqMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          this.redirectTo = '/parking';
          return;
        } else {
          this.BookParking2();
        }
      });
    } else {
      this.BookParking2();
    }
  }

  BookParking2() {
    this.showLoader = true;
    if (localStorage.getItem('ParkingBookingFor') != null && localStorage.getItem('ParkingBookingFor') != "null" && localStorage.getItem('ParkingBookingFor') != "") {
      this.parkingReservedFor = this.displayUserEMail;
    }
    else {
      this.parkingReservedFor = this.displayUserEMail;
    }
    var res_details = {
      SpaceId: this.selSpaceId,
      // DeskReservationId: 0,
      DeskReservationId: this.SwapParkingData == undefined ? 0 : this.SwapParkingData.id,
      ReservedFor: this.ServiceService.encrypt(this.parkingReservedFor == null ? localStorage.getItem('CurrentUserEmail') : this.parkingReservedFor),
      ReservedDate: this.datepipe.transform(new Date(this.today), 'yyyy-MM-dd'),//this.today,
      CreatedBy: localStorage.getItem('CurrentUserEmail'),
      CreatedDate: new Date(),
      TypeId: this.selBkngType,
      LicenseDetail: this.licenseText,
      DeskDetails: this.BookingType == 'single' ? null : this.selectedDateDetails,
      officeid: parseInt(this.selectedOffice)
    };

    this.ServiceService.PostCarParking(res_details).subscribe((data: any) => {

      //this.showMsgPopUp = true;
      if (data == 100) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('ParkRestrictTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('ParkRestrictBody').subscribe((data: string) => { this.PopUpMessage = data; });
        // this.errorCode = '';
      }
      else if (data == 200 || data == 402) {
        if (this.sendToBooking === true && res_details.DeskReservationId == 0 && (this.displayUserEMail.toLowerCase() != ("Atkins.Caribe@atkinsrealis.com").toLowerCase())) {
          this.showMsgPopUp = true;
          this.showPopupCancelBtn = true;
          this.redirectTo = '/booking';
          localStorage.setItem('RedirectFromParking', this.selectedOffice);
          localStorage.setItem('OfficeID', this.selectedOffice);
          localStorage.setItem('NotShowParkingPopup', "0");
          if (this.BookingType == 'single') {
            this.selectedDateModel.push(this.datepipe.transform(this.today, 'yyyy-MM-dd'));
            //localStorage.setItem('SelectedDateToBookForParking', this.selectedDateModel[0]);
            localStorage.setItem('SelectedDateToBookForParking', this.today.toString());
            this.dataService.setSingleOrMultipleDateSelection('single');
          }
          else {
            let result: string[] = []
            this.selectedDateDetails.forEach(element => {
              let Bookingdate = this.datepipe.transform(element.BookedDate, 'yyyy-MM-dd');
              result.push(Bookingdate);
            });
            this.dataService.setSelectedDateList(result);
            this.dataService.setSingleOrMultipleDateSelection('multiple');
          }
          this.translate.get('ParkingBookedNoDesk').subscribe((data: string) => { this.cancelBtnText = data; });
          this.translate.get('BookDesk').subscribe((data: string) => { this.okBtnText = data; });
          if (data == 200) {
            this.translate.get('ParkingBookedWithDeskMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          }
          else { this.translate.get('ParkingMissDateBookedWithDeskMsg').subscribe((data: string) => { this.PopUpMessage = data; }); }
        }
        else {
          if (res_details.DeskReservationId == 0) {
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('SpaceBkdTitle').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('SpaceBkdBody').subscribe((data: string) => { this.PopUpMessage = data; });
          }
          else {
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('SpaceSwapTitle').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('SpaceSwapBody').subscribe((data: string) => { this.PopUpMessage = data; });
            this.redirecToSwap = '/swap';
          }
        }
      }
      else if (data == 301) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('MultiBkngWarning').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('UserAlreadyBkdSpaceTitle').subscribe((data: string) => { this.PopUpMessage = data; });
        if (res_details.DeskReservationId == 0) { }
        else {
          this.redirecToSwap = '/swap';
        }
        // this.errorCode = '/reservation';
      }
      else if (data == 302) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('SpaceAlreadyBookedMsg').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SpaceAlreadyBookedTitle').subscribe((data: string) => { this.PopUpMessage = data; });
        // this.errorCode = '/reservation';
      }
      else if (data == 303) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('BookingTimeRestrictedTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('BookingAlreadyExistSameSlotTitle').subscribe((data: string) => { this.PopUpMessage = data; });
        // this.errorCode = '/reservation';
      }
      else if (data == 304) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('ElectricFullDayErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('ElectricFullDayErrorBody').subscribe((data: string) => { this.PopUpMessage = data; });
        // this.errorCode = '/reservation';
      }
      else if (data == 305 || data == 405) {
        if (this.sendToBooking === true && res_details.DeskReservationId == 0 && (this.displayUserEMail.toLowerCase() != ("Atkins.Caribe@atkinsrealis.com").toLowerCase())) {
          this.showMsgPopUp = true;
          this.showPopupCancelBtn = true;
          this.redirectTo = '/booking';
          localStorage.setItem('RedirectFromParking', this.selectedOffice);
          localStorage.setItem('NotShowParkingPopup', "0");
          localStorage.setItem('OfficeID', this.selectedOffice);
          // this.selectedDateModel.push(this.datepipe.transform(this.today, 'yyyy-MM-dd'));
          // localStorage.setItem('SelectedDateToBookForParking', this.selectedDateModel[0]);
          this.translate.get('ParkingBookedNoDesk').subscribe((data: string) => { this.cancelBtnText = data; });
          this.translate.get('BookDesk').subscribe((data: string) => { this.okBtnText = data; });

          if (this.BookingType == 'single') {
            this.selectedDateModel.push(this.datepipe.transform(this.today, 'yyyy-MM-dd'));
            //localStorage.setItem('SelectedDateToBookForParking', this.selectedDateModel[0]);
            localStorage.setItem('SelectedDateToBookForParking', this.today.toString());
            this.dataService.setSingleOrMultipleDateSelection('single');
          }
          else {
            let result: string[] = []
            this.selectedDateDetails.forEach(element => {
              let Bookingdate = this.datepipe.transform(element.BookedDate, 'yyyy-MM-dd');
              result.push(Bookingdate);
            });
            this.dataService.setSelectedDateList(result);
            this.dataService.setSingleOrMultipleDateSelection('multiple');
          }

          //this.translate.get('SouthAmericaParkingTitle').subscribe((data: string) => { this.msgTitle = data; });
          if (data == 305) {
            this.translate.get('SouthAmericaParkingBody').subscribe((data: string) => { this.PopUpMessage = data; });
          }
          else {
            this.translate.get('SouthAmericaParkingBodyMiss').subscribe((data: string) => { this.PopUpMessage = data; });
          }
          // this.errorCode = '/reservation';
        }
        else {
          if (res_details.DeskReservationId == 0) {
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('SouthAmericaParkingTitle').subscribe((data: string) => { this.msgTitle = data; });
            if (data == 305) {
              this.translate.get('SouthAmericaParkingBody').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else {
              this.translate.get('SouthAmericaParkingBodyMiss').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            // this.errorCode = '/reservation';
          }
          else {
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('SpaceSwapTitle').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('SpaceSwapBody').subscribe((data: string) => { this.PopUpMessage = data; });
            this.redirecToSwap = '/swap';
          }
        }
      }
      else if (data == 400) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('SpaceBkngErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SpaceBkngErrorBody').subscribe((data: string) => { this.PopUpMessage = data; });
        // this.errorCode = '/reservation';
      }
      this.showLoader = false;
    }, error => {
      this.showMsgPopUpError = true;
      this.showLoader = false;
      this.translate.get('SpaceBkngErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('SpaceBkngError').subscribe((data: string) => { this.PopUpMessage = data; });
      // this.errorCode = '';
    })
  }
  BookWaitingList() {

    //else{
    this.showLoader = true;

    if (localStorage.getItem('ParkingBookingFor') != null && localStorage.getItem('ParkingBookingFor') != "null" && localStorage.getItem('ParkingBookingFor') != "") {
      this.parkingReservedFor = localStorage.getItem('ParkingBookingFor');
    }
    else {
      this.parkingReservedFor = localStorage.getItem('CurrentUserEmail');
    }
    if (this.displayUserEMail != undefined || this.displayUserEMail == null) {
      this.parkingReservedFor = this.displayUserEMail;
    }
    var res_details = {
      SpaceId: (this.selPrefType == "Default" ? 0 : this.selBkngType),
      //DeskReservationId: 0,
      ReservedFor: (this.parkingReservedFor == null ? localStorage.getItem('CurrentUserEmail') : this.parkingReservedFor),
      ReservedDate: this.datepipe.transform(new Date(this.today), 'yyyy-MM-dd'),//this.today,
      CreatedBy: localStorage.getItem('CurrentUserEmail'),
      CreatedDate: new Date(),
      TypeId: (this.selPrefType == "Default" ? 0 : this.selVehicleType),
      LicenseDetail: this.licenseText,
      OfficeId: parseInt(this.selectedOffice)

      // DeskDetails: this.BookingType == 'single' ? null : this.selectedDateDetails
    };

    this.ServiceService.PostWaitingList(res_details).subscribe((data: any) => {
      if (this.BookingType == 'multiple') {
        this.showLoader = false;
        // const index = this.selectedDateDetails.findIndex(x => x.BookedDate === this.today);
        const index = this.selectedDateDetails.findIndex(x => x.BookedDate ===  this.datepipe.transform(new Date(this.today), 'yyyy-MM-dd'));
        if (index != -1) {
          //selDeskNumber = this.selectedDateDetails[index].SpaceId;
          this.selectedDateDetails[index].SpaceId = 456;
          this.selectedDateDetails[index].TypeId = (this.selPrefType == "Default" ? 0 : this.selBkngType);
          this.selectedDateDetails[index].Name = 'WL';
          document.getElementById("btnWaitingList").hidden = true;
          //selDeskNumber = this.selectedDateDetails[index].SpaceId;
          //selTypeID = this.selectedDateDetails[index].TypeId;
          this.validateBtnCheck();
          // this.selectedDateDetails.forEach(element => {
          var WLCount = this.selectedDateDetails.filter(x => x.SpaceId == 456).length;
          if (this.selectedDateDetails.length == WLCount) {
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('WaitingList').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('WaitingListBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          }

          //});
          // if(this.CheckAllWaitingList){
          //   this.showLoader = false;
          //   this.showMsgPopUpError = true;
          //   this.translate.get('WaitingList').subscribe((data: string) => { this.msgTitle = data; });
          //   this.translate.get('WaitingListBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          // }

        }
      }
      //this.showMsgPopUp = true;
      if (data == 100) {
        if (this.BookingType != 'multiple') {
          this.showLoader = false;
          this.showMsgPopUpError = true;
          this.translate.get('ParkRestrictTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ParkRestrictBody').subscribe((data: string) => { this.PopUpMessage = data; });
          // this.errorCode = '';
        }
      }
      else if (data == 200 || data == 402) {
        if (this.BookingType != 'multiple') {
          if (this.sendToBooking === true && (this.displayUserEMail.toLowerCase() != ("Atkins.Caribe@atkinsrealis.com").toLowerCase())) {
            this.showLoader = false;
            this.showMsgPopUp = true;
            this.showPopupCancelBtn = true;
            this.redirectTo = '/booking';
            localStorage.setItem('RedirectFromParking', this.selectedOffice);
            localStorage.setItem('OfficeID', this.selectedOffice);
            localStorage.setItem('NotShowParkingPopup', "0");
            if (this.BookingType == 'single') {
              this.selectedDateModel.push(this.datepipe.transform(this.today, 'yyyy-MM-dd'));
              //localStorage.setItem('SelectedDateToBookForParking', this.selectedDateModel[0]);
              localStorage.setItem('SelectedDateToBookForParking', this.today.toString());
              this.dataService.setSingleOrMultipleDateSelection('single');
            }
            else {
              let result: string[] = []
              this.selectedDateDetails.forEach(element => {
                let Bookingdate = this.datepipe.transform(element.BookedDate, 'yyyy-MM-dd');
                result.push(Bookingdate);
              });
              this.dataService.setSelectedDateList(result);
              this.dataService.setSingleOrMultipleDateSelection('multiple');
            }
            this.translate.get('ParkingBookedNoDesk').subscribe((data: string) => { this.cancelBtnText = data; });
            this.translate.get('BookDesk').subscribe((data: string) => { this.okBtnText = data; });
            if (data == 200) {
              this.translate.get('WaitingListBookedWithDeskMsg').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else { this.translate.get('ParkingMissDateBookedWithDeskMsg').subscribe((data: string) => { this.PopUpMessage = data; }); }
          }
          else {
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('WaitingList').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('WaitingListBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          }
        }
      }
      else if (data == 301) {
        if (this.BookingType != 'multiple') {
          this.showLoader = false;
          this.showMsgPopUpError = true;
          this.translate.get('MultiBkngWarning').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('UserAlreadyBkdSpaceTitle').subscribe((data: string) => { this.PopUpMessage = data; });
          // this.errorCode = '/reservation';
        }
      }
      else if (data == 302) {
        if (this.BookingType != 'multiple') {
          this.showLoader = false;
          this.showMsgPopUpError = true;
          this.translate.get('SpaceAlreadyBookedMsg').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('SpaceAlreadyBookedTitle').subscribe((data: string) => { this.PopUpMessage = data; });
          // this.errorCode = '/reservation';
        }
      }
      else if (data == 303) {
        if (this.BookingType != 'multiple') {
          this.showLoader = false;
          this.showMsgPopUpError = true;
          this.translate.get('BookingTimeRestrictedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('BookingAlreadyExistSameSlotTitle').subscribe((data: string) => { this.PopUpMessage = data; });
          // this.errorCode = '/reservation';
        }
      }
      else if (data == 304) {
        if (this.BookingType != 'multiple') {
          this.showLoader = false;
          this.showMsgPopUpError = true;
          this.translate.get('WaitingList').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('WaitingListBookedAlready').subscribe((data: string) => { this.PopUpMessage = data; });
          // this.errorCode = '/reservation';
        }
      }
      else if (data == 305 || data == 405) {
        if (this.BookingType != 'multiple') {
          if (this.sendToBooking === true && (this.displayUserEMail.toLowerCase() != ("Atkins.Caribe@atkinsrealis.com").toLowerCase())) {
            this.showLoader = false;
            this.showMsgPopUp = true;
            this.showPopupCancelBtn = true;
            this.redirectTo = '/booking';
            localStorage.setItem('RedirectFromParking', this.selectedOffice);
            localStorage.setItem('NotShowParkingPopup', "0");
            localStorage.setItem('OfficeID', this.selectedOffice);
            // this.selectedDateModel.push(this.datepipe.transform(this.today, 'yyyy-MM-dd'));
            // localStorage.setItem('SelectedDateToBookForParking', this.selectedDateModel[0]);
            this.translate.get('ParkingBookedNoDesk').subscribe((data: string) => { this.cancelBtnText = data; });
            this.translate.get('BookDesk').subscribe((data: string) => { this.okBtnText = data; });

            if (this.BookingType == 'single') {
              this.selectedDateModel.push(this.datepipe.transform(this.today, 'yyyy-MM-dd'));
              //localStorage.setItem('SelectedDateToBookForParking', this.selectedDateModel[0]);
              localStorage.setItem('SelectedDateToBookForParking', this.today.toString());
              this.dataService.setSingleOrMultipleDateSelection('single');
            }
            else {
              this.showLoader = false;
              let result: string[] = []
              this.selectedDateDetails.forEach(element => {
                let Bookingdate = this.datepipe.transform(element.BookedDate, 'yyyy-MM-dd');
                result.push(Bookingdate);
              });
              this.dataService.setSelectedDateList(result);
              this.dataService.setSingleOrMultipleDateSelection('multiple');
            }

            //this.translate.get('SouthAmericaParkingTitle').subscribe((data: string) => { this.msgTitle = data; });
            if (data == 305) {
              this.showLoader = false;
              this.translate.get('WaitingListBookedWithDeskMsg').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else {
              this.showLoader = false;
              this.translate.get('SouthAmericaParkingBodyMiss').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            // this.errorCode = '/reservation';
          }
          else {
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('SouthAmericaParkingTitle').subscribe((data: string) => { this.msgTitle = data; });
            if (data == 305) {
              this.translate.get('SouthAmericaParkingBody').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else {
              this.translate.get('SouthAmericaParkingBodyMiss').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            // this.errorCode = '/reservation';
          }
        }
      }
      else if (data == 400) {
        if (this.BookingType != 'multiple') {
          this.showLoader = false;
          this.showMsgPopUpError = true;
          this.translate.get('SpaceBkngErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('SpaceBkngErrorBody').subscribe((data: string) => { this.PopUpMessage = data; });
          // this.errorCode = '/reservation';
        }
        this.showLoader = false;
      }
    }, error => {
      if (this.BookingType != 'multiple') {
        this.showMsgPopUpError = true;
        this.showLoader = false;
        this.translate.get('SpaceBkngErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SpaceBkngError').subscribe((data: string) => { this.PopUpMessage = data; });
        // this.errorCode = '';
      }
    })

  }
  currentSelection(value) {
    this.selectedDateModel = [];
    this.BookingType = (value == 'single') ? 'single' : 'multiple';
    localStorage.setItem('CurrentBookingType', value);
    if (this.BookingType == 'multiple') {
      this.filteredParkSpaces = null;
      this.selectedDateDetails = [];
      //this.checkWaitingListValidation(this.selPrefType);
    }
    else {
      this.today = this.currentDate;
      this.changeDate();
      this.MultiSelectDisbled = false;
      this.checkWaitingListValidation(this.selPrefType);
    }
  }
  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    this.selectedDateModelSelected = [];
    if (event.value) {
      const date = event.value;
      const index = this._findDate(this.datepipe.transform(date, 'yyyy-MM-dd'));
      if (index === -1) {
        let Bookingdate = this.datepipe.transform(date, 'yyyy-MM-dd');
        this.selectedDateModel.push(Bookingdate);
        this.selectedDateModelSelected.push(Bookingdate);
      } else {
        this.selectedDateModel.splice(index, 1);
        this.selectedDateModelSelected.splice(index, 1);
      }
      this.resetModel = new Date(0);
      if (!this.CLOSE_ON_SELECTED) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        this._picker['_popupComponentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
      this.dataService.setSelectedDatesToBook(this.selectedDateModel);
      // this.validateButton = true;
      //this.checkValidateButton();
    }
    this.selectedDateModel = this.dataService.getSelectedDatesToBook();

    if (this.BookingType == 'multiple') {
      this.selectedDateDetails = [];
      this.selectedDateModel.forEach(selDate => {
        var deskdetails = {
          DeskID: undefined,
          BookedDate: selDate,
          SpaceId: undefined,
          TypeId: undefined// this.isFullDay
        };
        var index = this.selectedDateModel.indexOf(deskdetails.BookedDate);
        this.selectedDateDetails.splice(index, 1);
        this.selectedDateDetails.push(deskdetails);
      });
      this.checkWaitingListValidation(this.selPrefType);
    }
  }
  private _findDate(date: string): number {
    return this.selectedDateModel.indexOf(date);//.map((m) => +m)
  }
  public dateClass = (date: Date) => {
    if (this._findDate(this.datepipe.transform(date, 'yyyy-MM-dd')) !== -1) {
      return ['selected'];
    }
    return [];
  }
  selectDate(value) {
    this.today = value;
    this.selectseat = true;
    //this.selParkType = undefined;
    this.parkSpacesLoader();
    // this.checkWaitingListValidation(this.selPrefType);
  }
  getDateFormatString(): string {
    //return 'DD/MM/YYYY';
    return 'MM/DD/YYYY';
  }
  onKeyDownEvent(event: any) {
    event.preventDefault();
  }
  onItemChange(value) {
    this.licenseText=null;
    document.getElementById("btnWaitingList").hidden = true;
    if (value == 'self') {
      this.displayUserName = localStorage.getItem('CurrentUser');
      this.displayUserEMail = localStorage.getItem('CurrentUserEmail');
      this.displayPersonalDetails = true;
    } else {
      this.displayUserName = localStorage.getItem('selectedOtherUserNameParking');
      this.displayUserEMail = localStorage.getItem('selectedOtherUserEmailParking');
      this.displayPersonalDetails = (this.displayUserEMail != undefined ? true : false);
    }
    this.bookingFor = value;
    localStorage.setItem('bookingFor', value);
    this.validateBtnCheck();
  }
  searchName() {
    if (this.isVisitor == false) {
      this.showLoader = true;
      this.ServiceService.searchForBookOthers(this.givenText).subscribe((data: any) => {
        this.showLoader = false;
        this.UserDetails = data.filter(x => x.mail != null && x.mail != localStorage.getItem('CurrentUserEmail'));
        //this.validateButton = true;
        this.validateBtnCheck();
      })
    }
  }

  getUserDetail(value) {
    var selectedOtherUser = this.UserDetails.find(x => x.mail == value);
    localStorage.setItem('selectedOtherUserEmailParking', selectedOtherUser.mail);
    localStorage.setItem('selectedOtherUserNameParking', selectedOtherUser.displayName);
    this.displayUserName = selectedOtherUser.displayName;
    this.displayUserEMail = selectedOtherUser.mail;
    this.displayPersonalDetails = true;
  }

  // checkValidateButton() {
  //   if (parseInt(localStorage.getItem('OfficeID')) == 0 || localStorage.getItem('OfficeID') == 'undefined') {
  //     this.validateButton = false;
  //   } else {
  //     if (this.bookingFor == 'self') {
  //       this.validateButton = true;
  //     } else {
  //       if (localStorage.getItem('selectedOtherUserEmail') != null) {
  //         this.validateButton = true;
  //       } else {
  //         this.validateButton = false;
  //       }
  //     }
  //   }
  // }
}
