import { Component, OnInit, AfterViewInit, ViewChild, Input} from '@angular/core';
import { TranslationsDTO } from '../Model/TranslationsDTO';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { Router, NavigationExtras } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from  '@angular/material';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-Translations',
  templateUrl: './Translations.component.html',
  styleUrls: ['../../assets/css/style.scss']
})


export class TranslationsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: ServiceService, public translate: TranslateService,
    private router: Router) {

    }

    translations: TranslationsDTO[];
    dataSource = new MatTableDataSource<TranslationsDTO>();
    showMsgPopUp: boolean = false;
    showLoader = false;
    PopUpMessage: string;
    msgTitle: string;
    givenText: string;

    TranslationKeyType:any;
    TranslationKeyType_selected:string;

    columnsToDisplay = ['id', 'langKey', 'key', 'value', 'keyType','isVerified','action'];

    LanguagesList=["English","French","Portuguese","Spanish"];
    Languages_selected:string;

  ngOnInit() {
     this.TranslationKeyType_selected="";
     this.Languages_selected="";
     this.GetAllTranslationsKeyType();
     this.GetAllTranslations('','');
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  GetAllTranslationsKeyType() {
    this.showLoader = true;
    this.service.getAllTranslationKeyType().subscribe((result: any[]) => {
      this.showLoader = false;
      this.TranslationKeyType = result;
    });
  }

  GetAllTranslations(keyType,languageKey) {
    this.showLoader = true;
    this.service.getAllTranslation(keyType,languageKey).subscribe((result: any[]) => {
      this.showLoader = false;
      this.translations = result;
      this.dataSource.data = this.translations;

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeTranslationKeyType(e) {
    var keyType=e;
    var langKey=(document.getElementById("selectLanguage") as HTMLInputElement).value;

    (document.getElementById("txt_filter") as HTMLInputElement).value='';
    this.dataSource = new MatTableDataSource<TranslationsDTO>();

    this.GetAllTranslations(keyType,langKey);
  }

  changeLanguage(e) {
    var keyType=(document.getElementById("selectKeyType") as HTMLInputElement).value;
    var langKey=e;

    (document.getElementById("txt_filter") as HTMLInputElement).value='';
    this.dataSource = new MatTableDataSource<TranslationsDTO>();

    this.GetAllTranslations(keyType,langKey);
  }


  EditTranslation(element)
  {
    var elementId=element.id;
    document.getElementById('lblTranslationValue_'+elementId).classList.add('d-none');
    document.getElementById('txtTranslationValue_'+elementId).classList.remove('d-none');

    document.getElementById('btnTranslationEdit_'+elementId).classList.add('d-none');
    document.getElementById('btnTranslationCancel_'+elementId).classList.remove('d-none');
    document.getElementById('btnTranslationUpdate_'+elementId).classList.remove('d-none');
  }

  CancelEditTranslation(element)
  {
    var elementId=element.id;
    (document.getElementById("lblTranslationValue_"+elementId) as HTMLInputElement).value=element.value;
    (document.getElementById("txtTranslationValue_"+elementId) as HTMLInputElement).value=element.value;

    document.getElementById('lblTranslationValue_'+elementId).classList.remove('d-none');
    document.getElementById('txtTranslationValue_'+elementId).classList.add('d-none');

    document.getElementById('btnTranslationEdit_'+elementId).classList.remove('d-none');
    document.getElementById('btnTranslationCancel_'+elementId).classList.add('d-none');
    document.getElementById('btnTranslationUpdate_'+elementId).classList.add('d-none');


  }

  VerifyTranslation(isChecked, element) {
    var isVerify= isChecked.target.checked;
      this.showLoader = true;
      var TranslationData = element;
      this.service.postIsTranslationVerify(TranslationData).subscribe((data: any) => {
        this.showLoader = false;
        var approveMsg='';
        if(isVerify)
         this.translate.get('TranslationApproved').subscribe((data: string) => { approveMsg= data; });
        else
         this.translate.get('TranslationNotApproved').subscribe((data: string) => { approveMsg= data; });
          Swal.fire({
            text: approveMsg,
            icon: 'success',
            confirmButtonColor: '#3f32f1'
          }).then((result) => {
            if (result.value) {
              this.showLoader = false;
              var keyType=(document.getElementById("selectKeyType") as HTMLInputElement).value;
              var langKey=(document.getElementById("selectLanguage") as HTMLInputElement).value;
              this.GetAllTranslations(keyType,langKey);
            }
            else
            {
              this.showLoader = false;
            }
          })
      }, err => {
        this.showLoader = false;
      });

  }

  UpdateEditTranslation(element)
  {
    var elementId=element.id;
    var txtTranslation = document.getElementById('txtTranslationValue_'+elementId);
    var txtValue= ((document.getElementById("txtTranslationValue_"+elementId) as HTMLInputElement).value);

    if(txtValue =='' || txtValue == null || txtValue==undefined || txtValue=="undefined"){
      var errorRequiredMsg='';
      this.translate.get('TranslationValueRequired').subscribe((data: string) => { errorRequiredMsg= data; });
      Swal.fire({
        text: errorRequiredMsg,
        icon: "warning",
        confirmButtonColor: "#3f32f1"
      });
    }
    else{
      this.showLoader = true;
      element.value=txtValue;
      var TranslationData = element;
      this.service.postTranslation(TranslationData).subscribe((data: any) => {
        this.showLoader = false;
        var errorSuccessMsg='';
        this.translate.get('TranslationSavedSuccessfully').subscribe((data: string) => { errorSuccessMsg= data; });
          Swal.fire({
            text: errorSuccessMsg,
            icon: 'success',
            confirmButtonColor: '#3f32f1'
          }).then((result) => {
            if (result.value) {
              this.showLoader = false;
              var keyType=(document.getElementById("selectKeyType") as HTMLInputElement).value;
              var langKey=(document.getElementById("selectLanguage") as HTMLInputElement).value;
              this.GetAllTranslations(keyType,langKey);
            }
            else
            {
              this.showLoader = false;
            }
          })
      }, err => {
        this.showLoader = false;
      });
    }

  }
}
