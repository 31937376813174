import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { CarParkingPostDTO } from '../Model/CarParkingDTO';
import { CheckBookingLimit } from '../Model/CheckValidDateSelectedToBook';

@Component({
  selector: 'app-book-parking',
  templateUrl: './book-parking.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class BookParkingComponent implements OnInit {

  constructor(private ServiceService: ServiceService, private dataService: userDataService) { }
  selectedDateModel: any;
  today: Date;
  data: any;
  carParkingPostDTO: CarParkingPostDTO;
  showMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  selectedDateDetails = [];
  checkBookingLimit: CheckBookingLimit;
  selectedEmployeeType: string;
  showLoader = false;
  EmpTypes = [{ type: 'Employee' }, { type: 'Executive' }];


  ngOnInit() {
    this.selectedDateModel = this.dataService.getSelectedDatesToBook();
    this.today = this.selectedDateModel[0];
    var details = {
      selectedDate: this.selectedDateModel,
      OfficeId: parseInt(localStorage.getItem('OfficeID'))
    }
    this.checkBookingLimit = details;
    this.showMsgPopUp = true;



    // var Parkingdetails = {
    //   BookedDate: this.today
    // };
    this.selectedDateDetails = [];
    this.selectedDateDetails.push(details);
    // this.ServiceService.GetParkingLimits(localStorage.getItem('OfficeID'), this.today).subscribe((data: any) => {
    //   this.data = data;
    // })
  }
  formSubmit() {
    this.showLoader = true;
    this.showMsgPopUp = false;
    this.ServiceService.GetParkingLimits(this.checkBookingLimit).subscribe((data: any) => {
      this.data = data;
      this.showLoader = false;
    });
  }
  changeEmployeeType(value) {
    this.selectedEmployeeType = value;
  }
  selectDate(value) {
    this.today = value.target.value;
    var Parkingdetails = {
      BookedDate: this.today
    };
    // this.DeskReservationsToBook.DeskDetails.push(deskdetails);
    const index = this.selectedDateDetails.findIndex(x => x.BookedDate === this.today);
    // this.selectedDateDetails.splice(this.selectedDateDetails.findIndex(x => x.BookedDate === "2021-02-03"), 1)
    if (index === -1) {
      this.selectedDateDetails.push(Parkingdetails);
    }
    else {
      this.selectedDateDetails.splice(index, 1);
      this.selectedDateDetails.push(Parkingdetails);
    }

    // this.ServiceService.GetParkingLimits(localStorage.getItem('OfficeID'), this.today).subscribe((data: any) => {
    //   this.data = data;

    // })
  }
  ConfirmParking() {
    this.showLoader=true;
    var park_Details = {
      EmployeeType: this.selectedEmployeeType,
      OfficeId: parseInt(localStorage.getItem('OfficeID')),
      BookedDate: this.selectedDateModel,
      ReservedFor: localStorage.getItem('bookingFor') == 'self' ? localStorage.getItem('CurrentUserEmail') : localStorage.getItem('selectedOtherUserEmail'),
      CreatedBy: localStorage.getItem('CurrentUserEmail')
    };
    this.carParkingPostDTO = park_Details;
    this.ServiceService.PostCarParking(this.carParkingPostDTO).subscribe((data: any) => {
      this.showMsgPopUp = true;
      if (data == 100) {
        this.msgTitle = 'Desk Booking Restrcited';
        this.PopUpMessage = 'You cannot book the Parking, booking restricted.';
        // this.errorCode = '';
      }
      else if (data == 200) {
        this.msgTitle = 'Car Parking booked';
        this.PopUpMessage = 'Your reservation booking is confirmed. You will receive an email with the details';
        // this.errorCode = '/reservation';
      }
      this.showLoader=false;
      // this.router.navigateByUrl('/reservation');
    }, error => {
      this.showLoader=false;
      this.msgTitle = 'Parking has not booked';
      this.PopUpMessage = 'There is some technical error, Please contact administrator';
      // this.errorCode = '';
    });
  }
}
