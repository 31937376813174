import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
@Component({
  selector: 'app-usersettings',
  templateUrl: './usersettings.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class UsersettingsComponent implements OnInit {
  data: any;
  selectedCountry: any = "Please";
  showMsgPopUp: boolean = false;
  showNameAllowed: boolean = false;
  empIsHS: boolean = false;
  fireWarden: boolean = false;
  selectedLanguage: string;
  constructor(private titleService: Title,
    private ServiceService: ServiceService,
    private dataService: userDataService) { }

  ngOnInit() {
    localStorage.setItem('PageName', 'X');
    this.titleService.setTitle("Settings");
    // this.showNameAllowed = (localStorage.getItem('ShowName') == "true");

    this.showNameAllowed = localStorage.getItem('ShowName') == null?true:(localStorage.getItem('ShowName') == "true"?true:false);

    this.empIsHS = (localStorage.getItem('IsHS') == "true");
    this.fireWarden = (localStorage.getItem('IsFireWarden') == "true");

    // this.ServiceService.getOption().subscribe((data: any[]) => {
    //   this.data = data;
    // });
    // this.selectedCountry = this.dataService.getSelectedCountry();
  }

  // changeCountry(option) {
  //   this.selectedCountry = option.target.value;
  //   this.dataService.setSelectedCountry(this.selectedCountry);
  //   this.dataService.setOfficeID("0");
  // }

  SaveUserPreference() {
    var UserPrefDetails = {
      UserId: localStorage.getItem('CurrentUserEmail'),
      SelCountry: localStorage.getItem('SelectedCountry'),
      SelOfficeId: parseInt(localStorage.getItem('OfficeID')),
      SelFloorId: localStorage.getItem('FloorID') != undefined ? parseInt(localStorage.getItem('FloorID')) : undefined,
      SelZoneId: localStorage.getItem('ZoneId') != undefined ? parseInt(localStorage.getItem('ZoneId')) : undefined,
      SelBuid: localStorage.getItem('BUId') != undefined ? parseInt(localStorage.getItem('BUId')) : undefined,
      PrefLang: 'NA',//this.selectedLanguage,
      ShowName: this.showNameAllowed,
      IsHS: this.empIsHS,
      IsFireWarden: this.fireWarden
    };

    this.ServiceService.createUserPreference(UserPrefDetails).subscribe((data: any[]) => {
      // this.data = data;
      localStorage.setItem('ShowName', this.showNameAllowed.toString());
      localStorage.setItem('IsHS', this.empIsHS.toString());
      localStorage.setItem('IsFireWarden', this.fireWarden.toString());
      this.showMsgPopUp = true;
    },err=>{
      //this.showMsgPopUp = true;
    });

  }
}
