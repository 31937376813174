import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ExternalUserCredentialsDTO } from '../Model/ExternalUserCredentialsDTO';
import { TranslateService } from '@ngx-translate/core'
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import keys from '../key.json';

@Component({
  selector: 'app-external-user-configuration',
  templateUrl: './external-user-configuration.component.html',
  styleUrls: ['../../assets/css/style.scss']
})

export class ExternalUserConfigurationComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;


  versionTitle: string;
  constructor(private service: ServiceService, public translate: TranslateService) {
    let _langs = [];
    service.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    this.versionTitle = service.LastAppRelease;
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(service.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }
  showLoader: boolean = false;
  public DeskConfigDTO: ExternalUserCredentialsDTO;
  data: any;
  givenText: string;
  offices: any;
  showMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  isCaptchaValidated:boolean=false;
  CaptchaResponse:string;
  captchaKey:string;
  //dataSource: ExternalUserCredentialsDTO[];
  dataSource = new MatTableDataSource<ExternalUserCredentialsDTO>();

  displayedColumns: string[] = ['id', 'emailAddress', 'fullName', 'allowedOffice', 'UpdateDesk'];//, 'UpdateDesk'
  ErrorMsg: string;
  FormisnotValid: string;
  AreyousuretoremovetheUser: string;
  changeOffice;
  DeskConfigForm = new FormGroup({
    FullName: new FormControl('', [Validators.required, Validators.minLength(3)]),
    Country: new FormControl('', Validators.required),
    EmailAddress: new FormControl('', [Validators.required, Validators.email]),
    AllowedOffice: new FormControl('', Validators.required),
  });
  get FullName() { return this.DeskConfigForm.get('FullName'); }
  get EmailAddress() { return this.DeskConfigForm.get('EmailAddress'); }
  get Country() { return this.DeskConfigForm.get('Country'); }
  get AllowedOffice() { return this.DeskConfigForm.get('AllowedOffice'); }


  ngOnInit() {
    this.captchaKey=keys[0]['Captcha'];
    this.showLoader = true;
    // this.service.getOption().subscribe((data: any[]) => {
    //   this.data = data.filter(x => x.langKey == localStorage.getItem('locale'));
    //   this.GetAllExternalUsers();
    // }, err => {
    //   this.translate.get('ErrorMsg').subscribe((data: string) => { this.ErrorMsg = data; });
    //   window.location.reload();
    // });

    this.service.getUserAllowedRegion().subscribe((data: any[]) => {
      this.data = data.filter(x => x.langKey == localStorage.getItem('locale'));
      this.GetAllExternalUsers();
    }, err => {
      this.translate.get('ErrorMsg').subscribe((data: string) => { this.ErrorMsg = data; });
      window.location.reload();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  public resolved(captchaResponse: string): void {
    this.isCaptchaValidated=true;
    this.CaptchaResponse=captchaResponse;
  }




  /*
  GetAllExternalUsers() {
    this.service.getAllExternalUsers().subscribe((result: any) => {
      this.dataSource = result as ExternalUserCredentialsDTO[];
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });
  }
  */

  GetAllExternalUsers() {
    this.service.getAllExternalUsers().subscribe((data: ExternalUserCredentialsDTO[]) => {
      this.offices = data;
      this.dataSource.data = this.offices;
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });
  }

  // search() {
  //   this.showLoader = true;
  //   if (this.givenText.length > 0) {
  //     this.service.getExternalSearchData(this.givenText).subscribe((res: any) => {
  //       this.dataSource = res as ExternalUserCredentialsDTO[];
  //       console.log(this.dataSource);
  //       this.showLoader = false;
  //     });
  //     //this.dataSource=this.dataSource.filter(x=>x.EmailAddress)
  //   }
  //   else {
  //     //this.FilterDesks();
  //     this.GetAllExternalUsers();
  //     this.showLoader = false;
  //   }

  // }


  numberOnly(event): boolean {
    // const charCode = (event.which) ? event.which : event.keyCode;
    // alert(charCode);
    // //65 122
    // if (charCode > 65 && (charCode < 122 || charCode > 65)) {
    //   //if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    // return true;
    var regex = new RegExp("^[a-zA-Z ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }
  changeCountry(option) {
    this.showLoader = true;
    this.service.getOffices(option.target.value, false).subscribe((data: any[]) => {
      this.offices = data;
      this.showLoader = false;
    });
  }
  submit() {
    if (this.DeskConfigForm.status === 'VALID' && this.DeskConfigForm.value.Name != '' && this.isCaptchaValidated!=false) {
      this.showLoader = true;
      this.DeskConfigDTO = this.DeskConfigForm.value;
      this.DeskConfigDTO.EmailAddress = this.service.encrypt(this.DeskConfigDTO.EmailAddress);
      this.DeskConfigDTO.FullName = this.service.encrypt(this.DeskConfigDTO.FullName);
      this.DeskConfigDTO.captchaResponse=this.CaptchaResponse;

      this.service.AddExternalUser(this.DeskConfigDTO).subscribe((result: any) => {
        this.showMsgPopUp = true;
        if (result == 300) {
          this.translate.get('ExternalUserExistTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ExternalUserExistMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else if (result == 404) {
          this.translate.get('ExternalUserDataTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ExternalUserDataNotSaved').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else if (result == 405) {
          this.translate.get('ExternalUserDataHold1MinTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ExternalUserDataHold1Min').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else {
          this.translate.get('ExternalUserSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ExternalUserSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
      })
    }
    else {
      this.translate.get('FormisnotValid').subscribe((data: string) => { this.FormisnotValid = data; });
      alert(this.FormisnotValid);
    }
  }
  get f() {
    return this.DeskConfigForm.controls;
  }
  DeleteRecord(selectedUser) {
    this.translate.get('AreyousuretoremovetheUser').subscribe((data: string) => { this.AreyousuretoremovetheUser = data; });
    if (confirm(this.AreyousuretoremovetheUser)) {
      this.showLoader = true;
      this.service.deleteExternalUser(selectedUser.fullName).subscribe((result: any) => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('UserDeletedSuccessfully').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('UserDeletedSuccessfully').subscribe((data: string) => { this.PopUpMessage = data; });
      }, err => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('UserDeletionFailed').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('UserDeletionFailed').subscribe((data: string) => { this.PopUpMessage = data; });
      });
    }
  }
  // search() {
  //   this.showLoader = true;
  //   alert(this.givenText);
  //   if (this.givenText.length > 0) {
  //     //this.dataSource = this.dataSource.filter(x => x.EmailAddress.includes(this.givenText)) as ExternalUserCredentialsDTO[];
  //     //getexternalUsersearchdata
  //     this.service.getexternalUsersearchdata(this.givenText).subscribe((res: any) => {
  //       this.dataSource = res as ExternalUserCredentialsDTO[];
  //       this.showLoader = false;
  //     });
  //     this.showLoader = false;
  //   }
  // }

  search() {
    this.showLoader = true;
    //if (this.givenText.length > 0) {
  
      if(this.givenText==undefined )
      {
        this.givenText='';
      }
      if (this.givenText!='') {
        this.dataSource.data = this.offices.filter(x => x.emailAddress.toLowerCase().startsWith(this.givenText.toLowerCase()));
        this.showLoader = false;
    }
    else {
      this.dataSource.data = this.offices;
        this.showLoader = false;
    }
  }
}
