import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { ServiceService } from '../services/service.service'
import { userDataService } from '../services/data-share';
import { Router } from '@angular/router';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-alert-msg',
  templateUrl: './alert-msg.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class AlertMsgComponent implements OnInit {
  @Input() redirectTo: string;
  @Input() msgTitle: string;
  @Input() msgBody: string;
  @Output() showPopUp = new EventEmitter();
  @Input() showCancelBtn: boolean = false;
  @Input() cancelRedirect: string;
  @Input() cancelBtnText: string = 'Cancel';
  @Input() okBtnText: string = 'OK';
  @Input() downloadBtnText: string = 'Download';
  @Input() showMeeting: boolean;
  showDesk: string;
  @Input() QuickBookAlert: boolean = false;
  @Input() QuickBookData;
  showQuickBooking: boolean = false;
  data: any;
  officedata: any;
  FullCountryList: any;
  @Input() DailyReport: boolean = false;
  country: string;
  office: string;
  dailydata: any;
  validateButton: boolean = false;
  CancelUrl: string;
  constructor(private dataService: userDataService, public translate: TranslateService,
    private ServiceService: ServiceService, private router: Router) {
  }

  ngOnInit() {
    this.ServiceService.getAccessibleCountry().subscribe((data: any[]) => {
      this.FullCountryList = data;
      this.data = data.filter(x => x.langKey == localStorage.getItem('locale'));
    }, err => {
      alert("something went wrong");
      window.location.reload();
    });
    if (this.router.url == "/gymSelectSeat") {
      this.CancelUrl = '/gymReservation';
    }
    else {
      this.CancelUrl = '/reservation';
    }
  }

  formSubmit() {
    if (this.redirectTo != undefined) {
      if (this.router.url == "/swap") {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(["/swap"]);
        });
      }
      else {
        this.router.navigateByUrl(this.redirectTo);
      }
    } else {
      this.showPopUp.emit(false);
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });

    }
  }
  bookMeeting() {
    if (localStorage.getItem('CurrentBookingType') == "single") {
      localStorage.setItem("View", "Meeting");
      this.router.navigateByUrl('/selectseat');
      this.dataService.showDesk.next('No');
    }
    else {
      this.translate.get('MultipleMeeting').subscribe((data: string) => { alert(data); })
    }
  }

  hidePopup() {
    this.showPopUp.emit(false);
    let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
    this.router.navigateByUrl(url);
  }

  bookQuickBook() {
    if (this.okBtnText == "Rebook") {
      this.ServiceService.bookLastBookings(this.QuickBookData).subscribe((data: any) => {
        if (data == 200) {
          this.cancelBtnText = "OK";
          document.getElementById("hideRebook").classList.add('d-none');
          document.getElementById("cancelButton").classList.remove('btnSec');
          document.getElementById("cancelButton").classList.add('btn');
          this.translate.get('DeskBooked').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('DeskBookedMsg').subscribe((data: string) => { this.msgBody = data; });
        }
      })
    }
    else if (this.okBtnText == "Check In") {
      this.ServiceService.checkin(this.QuickBookData).subscribe((data: any) => {
        if (data == 200) {
          this.cancelBtnText = "OK";
          document.getElementById("hideRebook").classList.add('d-none');
          document.getElementById("cancelButton").classList.remove('btnSec');
          document.getElementById("cancelButton").classList.add('btn');
          this.translate.get('CheckIn').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('CheckInMsgCfrm').subscribe((data: string) => { this.msgBody = data; });
        }
      })
    }
    else if (this.okBtnText == "Check Out") {
      this.ServiceService.checkout(this.QuickBookData).subscribe((data: any) => {
        if (data == 200) {
          this.cancelBtnText = "OK";
          document.getElementById("hideRebook").classList.add('d-none');
          document.getElementById("cancelButton").classList.remove('btnSec');
          document.getElementById("cancelButton").classList.add('btn');
          this.translate.get('CheckOut').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('CheckOutMsgCnfrm').subscribe((data: string) => { this.msgBody = data; });
        }
      })
    }
  }

  changeCountry(Country) {
    this.country = Country.target.value;
    if (this.country != "Choose your Location" && (this.office != "Choose your Location" && this.office != undefined))
      this.validateButton = true;
    else
      this.validateButton = false;
    let checkAdminOfficeIds: boolean = true;
    this.ServiceService.getOffices(Country.target.value, checkAdminOfficeIds).subscribe((data: any[]) => {
      this.officedata = data;
    });
  }

  changeOffice(Office) {
    this.office = Office.target.value;
    this.validateButton = true;
  }

  showDailyReport() {
    this.ServiceService.getDailyReport(this.country, this.office).subscribe((data: any[]) => {
      this.dailydata = data;
      const documentDefinition = this.getDocumentDefinition(data);
      pdfMake.createPdf(documentDefinition).download('Daily Report.pdf', function () { window.location.reload(); });
    });
  }

  getDocumentDefinition(data: any[]): any {
    return {
      content: [
        {
          text: 'Daily Report',
          style: 'header',
          alignment: 'center'
        },
        this.getTable(data)
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,

        },
        tableHeader: {
          bold: true,
          fontSize: 6,
          color: 'black'
        }
      }
    };
  }
  getTable(data): any {
    return {
      table: {
        headerRows: 1,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Country', style: 'tableHeader' },
            { text: 'Office Name', style: 'tableHeader' },
            { text: 'Name', style: 'tableHeader' },
            { text: 'Office Floor Zone', style: 'tableHeader' },
            { text: 'Business Unit', style: 'tableHeader' },
            { text: 'User BU', style: 'tableHeader' },
            { text: 'Purpose', style: 'tableHeader' },
            { text: 'Desk Number', style: 'tableHeader' },
            { text: 'In Time', style: 'tableHeader' },
            { text: 'Out time', style: 'tableHeader' },
            { text: 'First Aider', style: 'tableHeader' },
            { text: 'Fire Warden', style: 'tableHeader' },
            { text: 'Fire Warden Office', style: 'tableHeader' },
            { text: 'Booking Type', style: 'tableHeader' },
            { text: 'Partial Booking Start Time', style: 'tableHeader' },
            { text: 'Partial Booking End Time', style: 'tableHeader' }
          ],
          ...data.map(item => [{ text: item.country || '', style: 'tableHeader' }, { text: item.officeName || '', style: 'tableHeader' }, { text: item.name || '', style: 'tableHeader' }, { text: item.officeFloorZone || '', style: 'tableHeader' }, { text: item.bu || '', style: 'tableHeader' }, { text: item.bU_Name || '', style: 'tableHeader' }, { text: item.purpose || '', style: 'tableHeader' }, { text: item.deskNumber || '', style: 'tableHeader' }, { text: item.in_time || '', style: 'tableHeader' }, { text: item.out_time || '', style: 'tableHeader' }, { text: item.isHS || '', style: 'tableHeader' }, { text: item.isFireWarden || '', style: 'tableHeader' }, { text: item.fireWarendOffice || '', style: 'tableHeader' }, { text: item.fullPartialdaybooking || '', style: 'tableHeader' }, { text: item.partialBookingStartTime || '', style: 'tableHeader' }, { text: item.partialBookingEndTime || '', style: 'tableHeader' }
          ])
        ]
      }
    }
  }
}
