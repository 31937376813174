import { Component, OnInit, Injectable, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { FloorDTO } from '../Model/FloorDTO';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface Floors {
  id: number;
  // office_id: number;
  // sequence_id: number;
  name: number;
  floorPlanPath: string;
  hasDeskCategory: boolean;
  hasZones: boolean;
  officeName: string;
  officeId: number;
  ShowFullFloorplan: boolean;
  FullFloorplanPath: string;
  SequenceId: number;
  FloorplanAttachmentPath: string;
  isAvailable: boolean;
}
@Component({
  selector: 'app-add-floor',
  templateUrl: './add-floor.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class AddFloorComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: Floors[];
  dataSource1 = new MatTableDataSource<Floors>();
  // displayedColumns: string[] = ['id', 'name', 'officeName', 'sequenceId', 'hasDeskCategory', 'hasZones', 'floorPlanPath', 'UpdateDesk'];//, 'sequence_id', 'office_id'
  displayedColumns: string[] = ['id', 'name', 'officeName', 'hasDeskCategory', 'hasZones', 'floorPlanPath', 'UpdateDesk'];
  public floorDTO: FloorDTO;
  showErrPopUpWarning: boolean;
  // ExistingFloors: FloorDTO;

  constructor(private Service: ServiceService, public translate: TranslateService) { }

  regexStr = '^[a-zA-Z0-9 -]+$';
  onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }
  showErrPopUp: boolean = false;
  OfficeList: any;
  showMsgPopUp: boolean = false;
  showLoader = false;
  PopUpMessage: string;
  msgTitle: string;
  formDetails: FormData;
  floorplan: any;
  showFloorPlanUpload: boolean = false;
  showFullFloorPlanFlag: boolean = false;
  givenText: string;
  givenTextOffice: string;
  FormTest: FormData;
  files: string[] = [];
  FloorPlan: boolean = false;
  FullFloorPlan: boolean = false;
  EmailAttachment: boolean = false;
  IsOnlyNumber: boolean;
  EditRedirect: boolean;
  okBtnText: string = "OK";

  FloorForm = new FormGroup({
    Id: new FormControl(0),
    Name: new FormControl('', Validators.required),
    OfficeId: new FormControl('', Validators.required),
    FloorplanBlobPath: new FormControl(''),
    // SequenceId: new FormControl('', [Validators.required, Validators.max(50)]),
    SequenceId: new FormControl(''),
    HasDeskCategory: new FormControl(true),
    HasZones: new FormControl(true),
    DeskFloorPlan: new FormControl('', Validators.required),
    ShowFullFloorplan: new FormControl(false),
    FullFloorplanPath: new FormControl(''),
    FloorplanAttachmentPath: new FormControl('', Validators.required),
    IsAvailable: new FormControl(true)
  });

  get Name() { return this.FloorForm.get('Name'); }
  get OfficeId() { return this.FloorForm.get('OfficeId'); }
  get SequenceId() { return this.FloorForm.get('SequenceId'); }
  get HasDeskCategory() { return this.FloorForm.get('HasDeskCategory') }
  get HasZones() { return this.FloorForm.get('HasZones') }
  get ShowFullFloorplan() { return this.FloorForm.get('ShowFullFloorplan') }
  get FullFloorplanPath() { return this.FloorForm.get('FullFloorplanPath') }
  get FloorplanAttachmentPath() { return this.FloorForm.get('FloorplanAttachmentPath') }
  get DeskFloorPlan() { return this.FloorForm.get('DeskFloorPlan') }
  get IsAvailable() { return this.FloorForm.get('IsAvailable') }

  ngOnInit() {
    this.showLoader = true;
    this.formDetails = new FormData();
    this.Service.getAllOffices().subscribe((result: any) => {
      this.OfficeList = result;
    });
    this.Service.getAllFloors().subscribe((res: any) => {
      this.dataSource = res as Floors[];
      this.dataSource1.data = res;
      this.showLoader = false;
    });

  }
  ngAfterViewInit() {

    this.dataSource1.paginator = this.paginator;

  }
  submit() {
    let isnum = /^\d+$/.test(this.FloorForm.value.Name.trim());
    if (isnum) {
      this.IsOnlyNumber = true;
      document.getElementById('Name').focus();
      this.Name.markAsTouched();
      return;
    }

    if (this.FloorForm.value.Name.trim() == '') {
      this.Name.markAsTouched();
      document.getElementById('Name').focus();
      return;
    }

    if (this.FloorForm.value.HasZones != true && this.FloorForm.value.IsAvailable == true) {

      var deskFloorPlan = /[^\\]*$/.exec(this.FloorForm.value.DeskFloorPlan)[0];
      var floorplanAttachmentPath = /[^\\]*$/.exec(this.FloorForm.value.FloorplanAttachmentPath)[0];

      var deskFloorPlanWhiteSpaceCheck=this.hasWhiteSpace(deskFloorPlan);
      var floorplanAttachmentPathWhiteSpaceCheck=this.hasWhiteSpace(floorplanAttachmentPath);

      if(deskFloorPlanWhiteSpaceCheck || floorplanAttachmentPathWhiteSpaceCheck)
      {
        this.showErrPopUpWarning=true;
        this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('FileWhiteSpaceErrorMsg').subscribe((data: string) => { this.PopUpMessage = data; });

        this.DeskFloorPlan.markAsTouched();
        document.getElementById('DeskFloorPlan').focus();
        return;
      }

      if (this.FloorForm.value.DeskFloorPlan.trim() == '') {
        this.showErrPopUpWarning=true;
        this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('Mandetory').subscribe((data: string) => { this.PopUpMessage = data; });

        this.DeskFloorPlan.markAsTouched();
        document.getElementById('DeskFloorPlan').focus();
        return;
      }
      if (this.FloorForm.value.FloorplanAttachmentPath.trim() == '') {
        this.showErrPopUpWarning=true;
        this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('Mandetory').subscribe((data: string) => { this.PopUpMessage = data; });
        
        this.FloorplanAttachmentPath.markAsTouched();
        document.getElementById('FloorplanAttachmentPath').focus();
        return;
      }
    }

    this.getFloorplanDetails();
    //if (this.FloorForm.status === 'VALID' && this.FloorForm.value.Name != '') {
    if (this.FloorForm.value.Name != '') {
      this.showLoader = true;

      // if (!this.showFloorPlanUpload)
      //   this.getFloorplanDetails(undefined);

      this.Service.uploadFloorPlan(this.formDetails).subscribe((result: any) => {
        this.showMsgPopUp = true;
        if (result == 400) {
          this.translate.get('UploadFile').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('FileUploadFail').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else if (result == 700) {
          this.translate.get('UploadFile').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('FileUploadLimit').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else if (result == 401) {
          this.translate.get('CkeckedInTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('CkeckedInBody').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else {
          this.translate.get('FloorSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('FloorSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
      });
    }
    else {
      this.showErrPopUp = true;
      this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('FormError').subscribe((data: string) => { this.PopUpMessage = data; });
      //alert('Form is not Valid, Please check once again.')
    }
  }
  onFileChange(event, UploadFolder: string) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);
      if (UploadFolder == 'floorplan') { this.FloorPlan = true; }
      else if (UploadFolder == 'fullfloorplan') { this.FullFloorPlan = true; }
      else if (UploadFolder == 'emailattach') { this.EmailAttachment = true; }
    }
  }
  formSubmit()
  {
    this.showLoader=false;
    this.showErrPopUpWarning=false;
  }
  getFloorplanDetails() {//fileToUpload: any
    this.floorDTO = this.FloorForm.value;
    const formData = new FormData();
    // if (fileToUpload != undefined) {
    //   formData.append('file', fileToUpload, fileToUpload.name);
    // }
    for (var i = 0; i < this.files.length; i++) {
      formData.append("file[]", this.files[i]);
    }
    formData.append('Id', this.floorDTO.Id.toString());
    formData.append('Name', this.floorDTO.Name);
    formData.append('OfficeId', (this.floorDTO.OfficeId).toString());
    formData.append('SequenceId', (this.floorDTO.SequenceId).toString());
    formData.append('HasDeskCategory', (this.floorDTO.HasDeskCategory).toString());
    formData.append('HasZones', (this.floorDTO.HasZones).toString());
    formData.append('ShowFullFloorplan', this.floorDTO.ShowFullFloorplan == null ? 'false' : (this.floorDTO.ShowFullFloorplan).toString());
    formData.append('FullFloorplanPath', this.floorDTO.FullFloorplanPath == null ? '' : (this.floorDTO.FullFloorplanPath).toString().replace(/^.*[\\\/]/, ''));
    formData.append('FloorplanAttachmentPath', this.floorDTO.FloorplanAttachmentPath == null ? '' : (this.floorDTO.FloorplanAttachmentPath).toString().replace(/^.*[\\\/]/, ''));
    // formData.append('DeskFloorPlan', (this.floorDTO.FloorplanBlobPath).toString());
    formData.append('IsAvailable', (this.floorDTO.IsAvailable).toString());
    this.formDetails = formData;
  }
  hasZonesEvent(Option) {
    if (this.FloorForm.value.IsAvailable.toString() != 'false') {
      this.showFloorPlanUpload = (Option.value) ? false : true;
    }
    else {
      this.showFloorPlanUpload = false;
    }
  }
  isAvailableEvent(Option) {
    this.showFloorPlanUpload = (Option.value) ? true : false;

    if (Option.value) {
      if (this.FloorForm.value.HasZones.toString() != 'false') {
        this.showFloorPlanUpload = false;
      }
    }
  }
  ShowFullFloorplanEvent(Option) {
    this.showFullFloorPlanFlag = (Option.value) ? false : true;
  }
  GetRecordToUpdate(e) {
    this.FloorForm.get('Id').setValue(e.id);
    this.FloorForm.get('Name').setValue(e.name);
    this.FloorForm.get('OfficeId').setValue(e.officeId);
    this.FloorForm.get('FloorplanBlobPath').setValue(e.floorPlanPath);
    this.FloorForm.get('SequenceId').setValue(e.sequenceId);
    this.FloorForm.get('HasDeskCategory').setValue(e.hasDeskCategory);
    this.FloorForm.get('HasZones').setValue(e.hasZones);
    this.FloorForm.get('FullFloorplanPath').setValue(e.fullFloorplanPath);
    this.FloorForm.get('ShowFullFloorplan').setValue(e.showFullFloorplan);
    this.FloorForm.get('IsAvailable').setValue(e.isAvailable);
    // this.showFloorPlanUpload = (e.hasZones) ? false : true;
    // this.showFullFloorPlanFlag = (e.showFullFloorplan) ? false : true;
    this.EditRedirect = true; 
    if((e.hasZones==true && e.isAvailable==false) || e.isAvailable==false || (e.hasZones==true && e.isAvailable==true))
    {
      this.showFloorPlanUpload=false;
    }
    else{this.showFloorPlanUpload=true;}
    window.scroll(0, 0);    
  }
  search() {
    this.showLoader = true;
    if (this.givenText != undefined || this.givenTextOffice != undefined) {
      if (this.givenText == undefined) {
        this.givenText = '';
      }
      if (this.givenTextOffice == undefined) {
        this.givenTextOffice = '';
      }
      this.Service.getFloorsearchdata(this.givenText, this.givenTextOffice).subscribe((res: any) => {
        this.dataSource = res as Floors[];
        this.dataSource1.data = res;
        this.showLoader = false;
      });
    }
    else {
      this.Service.getAllFloors().subscribe((res: any) => {
        this.dataSource = res as Floors[];
        this.dataSource1.data = res;
        this.showLoader = false;
      });
    }
  }
  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
}
