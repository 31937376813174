export interface ELEMENT_DATA {
    location: string;
    emailid: string;
    date: string
  }
  
export interface tab {
    title: string;
    bookedFor : string;
    reservationDetails: ELEMENT_DATA[]
  }
  
export interface DATA {
    title: string
    location: string;
    emailId: string;
    date: string
  }

  //sort by ascending order
 export function sortDataByDate(dateList) {
    return dateList.sort((a, b) => {
      return <any>new Date(b) - <any>new Date(a);
    }).reverse();
  }