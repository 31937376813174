import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core'
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import * as powerbi from 'powerbi-client';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';



@Component({
  selector: 'app-regional-dashboard',
  templateUrl: './regional-dashboard.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class RegionalDashboardComponent implements OnInit {

  constructor(private titleService: Title, public translate: TranslateService,
    private ServiceService: ServiceService, private dataService: userDataService,
    private router: Router,
    public sanitizer: DomSanitizer) { }



  reportType: string = "Regional";
  userRole: string = "";
  region: string = "";
  pbiAccessToken: string = "";
  allowedOfficesIdList: any[];
  showLoader = false;
  mode = 'Dashboard';
  selectedCountry: string;
  dashboardUrl: string;
  reportValue;
  RegionalDashboardDownloadReports = [
    { name: 'Download Consolidated Occupancy Report', reportdata: '9c297980-ba60-4a28-903a-e04b23f9b76e' },
    { name: 'Download Daily Report', reportdata: 'fc31dc12-bdf5-4a7e-899a-1895531a0943' },
    { name: 'Download Report Between Dates', reportdata: '507b7261-cbfd-4658-8568-5bd4286cc4c4' }
    // { name: 'Download Report (Aggregated)', reportdata: '9be66488-45ce-4676-9750-830b7734d759' },
    // { name: 'Download Change Log', reportdata: '98600d7b-528e-4d62-80ed-293f8ddc0175' },
    // { name: 'Download Past Booking Details', reportdata: '3ad451ef-f402-4770-bb23-b3fe831d9538' }
  ];
  RegionalReport_selected: string;
  reportId = "9c297980-ba60-4a28-903a-e04b23f9b76e";
  allowedDataForDashboard = [];
  parkingDataForDashboard = [];
  OfficeFloor_selected;
  ParkingOffice_selected;
  showDropdown = false;
  pbiOffice;
  pbiFloor;
  pbiParkingOffice;

  ngOnInit() {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    this.userRole = payLoad.role;
    this.titleService.setTitle("Save My Seat - Regional Leadership Dashboard");
    this.SetDashboardUrl();
    this.RegionalReport_selected = '9c297980-ba60-4a28-903a-e04b23f9b76e';
    this.mode = 'Dashboard';
  }


  SetDashboardUrl() {
    this.selectedCountry = localStorage.getItem('SelectedCountry');
    this.region = this.selectedCountry.toLowerCase();
    this.GetEmbedReportDataV1();


    // switch (this.selectedCountry.toLowerCase()) {
    //   case null:
    //     this.dashboardUrl = "";
    //     break;
    //   case "united states":
    //     this.dashboardUrl = "https://app.powerbi.com/reportEmbed?reportId=690682a2-d341-43d9-b89a-d27705ae0b16&autoAuth=true&ctid=87d70b0f-5efc-4991-a065-e205bc3db308&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1kLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
    //     break;
    //   case "united kingdom":
    //     this.dashboardUrl = "https://app.powerbi.com/reportEmbed?reportId=e24614ea-9a70-4a0b-929e-b5ac99c622f0&autoAuth=true&ctid=87d70b0f-5efc-4991-a065-e205bc3db308&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1kLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
    //     break;
    //   case "canada":
    //     this.dashboardUrl = "https://app.powerbi.com/reportEmbed?reportId=51b504f6-bdcf-4e9a-b018-2d0860aa1224&autoAuth=true&ctid=87d70b0f-5efc-4991-a065-e205bc3db308&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1kLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
    //     break;
    //   case "europe":
    //     this.dashboardUrl = "https://app.powerbi.com/reportEmbed?reportId=3af9b48a-c6dd-403d-8f93-5e2d8b4488cd&autoAuth=true&ctid=87d70b0f-5efc-4991-a065-e205bc3db308&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1kLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
    //     break;
    //   case "south america":
    //     this.dashboardUrl = "https://app.powerbi.com/reportEmbed?reportId=4aac91ef-17f1-4cb2-bb59-db3562f3eb03&autoAuth=true&ctid=87d70b0f-5efc-4991-a065-e205bc3db308&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1kLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
    //     break;
    //   default:
    //     this.dashboardUrl = "";
    //     break;
    // }

  }

  //--------For PBI app based reports currently not in use (if in case need to use this then we will use)
  //(When we will use reports that is created in same PBI service which is using in our api then no need to use UserBased
  //token to get embedded data)
  goToRegionalDashboardReportLinkV1(e) {
    var url = e.target.value;
    this.reportId = url;
    let reportContainer = <HTMLElement>document.getElementById('reportContainer');
    let powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
    powerbiService.reset(reportContainer);
    this.LoadEmbeddedReportV1(this.allowedOfficesIdList, false);
  }

  GetEmbedReportDataV1() {
    this.showLoader = true;
    this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), true).subscribe((data: any[]) => {
      if (data.length == 0) {
        var OfficePermissionIssueTitle = "";
        var OfficePermissionIssuePart1 = "";
        var OfficePermissionIssuePart2 = "";
        this.translate.get('OfficePermissionIssueTitle').subscribe((data: string) => { OfficePermissionIssueTitle = data; });
        this.translate.get('OfficePermissionIssuePart1').subscribe((data: string) => { OfficePermissionIssuePart1 = data; });
        this.translate.get('OfficePermissionIssuePart2').subscribe((data: string) => { OfficePermissionIssuePart2 = data; });

        //  var errorTitle = 'Permission Restricted';
        //  var errorMsg = 'Sorry, you dont have permission to access any of the offices in ' + localStorage.getItem('SelectedCountry') + '. Please select other country';

        var errorMsg = OfficePermissionIssuePart1 + ' ' + localStorage.getItem('SelectedCountry') + ' ' + OfficePermissionIssuePart2;
        Swal.fire({
          title: "",
          text: errorMsg,
          confirmButtonColor: '#3f32f1',
          confirmButtonText: "Ok",
          icon: "warning"
        }).then((result) => {
          if (result.value) {
            this.router.navigateByUrl('/home');
          }
        })

      }
      var allowedOfficesList = data;
      this.allowedOfficesIdList = [];

      if (allowedOfficesList != undefined && allowedOfficesList != null) {
        this.allowedOfficesIdList = allowedOfficesList.filter(f => f.id).map(({ id }) => id);
      }
      this.ServiceService.getAllowedOfficeFloor(this.allowedOfficesIdList, this.selectedCountry).subscribe((data: any) => {
        this.allowedDataForDashboard = data.filter(a => a.hasDeskCategory == true  && a.name!=null);
        this.parkingDataForDashboard = data.filter(a => a.hasDeskCategory == false  && a.name!=null);
        if(this.allowedDataForDashboard.length>0)
        {
        this.OfficeFloor_selected = this.allowedDataForDashboard[0].name;
        }
        if(this.parkingDataForDashboard.length>0)
        {
        this.ParkingOffice_selected = this.parkingDataForDashboard[0].name;
        }
        else{
          this.ParkingOffice_selected = this.OfficeFloor_selected;
        }
        if (this.mode == "Dashboard") {
          this.showDropdown = true;
          this.LoadEmbeddedReportV1(this.allowedOfficesIdList, false);
        }
        else {
          this.showDropdown = false;
          this.LoadEmbeddedReportV1(this.allowedOfficesIdList, false);
        }
      });
    });
  }


  LoadEmbeddedReportV1(allowedOfficesIdList, IsCanEdit) {
    this.showLoader = true;
    this.ServiceService.getPBIEmbedReportDataV1(this.region, this.reportType, this.mode, this.reportId).subscribe((res: any) => {
      this.showLoader = false;
      if (res) {
        if (res != 400) {
          var responseData = res as any;
		  if(this.mode=="Dashboard")
          {
          this.LoadfilterOfficeEmbedReportV1(responseData, allowedOfficesIdList, IsCanEdit);
          }
          else
          {
            this.LoadfilterReportOfficeEmbedReportV1(responseData, allowedOfficesIdList, IsCanEdit);
          }
        }
      }
    });
  }

  LoadfilterOfficeEmbedReportV1(embedReportData, allowedOfficesIdList, IsCanEdit) {
    var officesList = null;
    let models = powerbi.models;
    let accessToken = embedReportData.embedToken;
    let embedUrl = embedReportData.embedUrl;
    let embedReportId = embedReportData.reportId;
    let datasetId = embedReportData.dataSetId;
    this.pbiOffice = this.OfficeFloor_selected.split(":")[0];
    this.pbiFloor = this.OfficeFloor_selected.split(":")[1];
    this.pbiParkingOffice = this.ParkingOffice_selected;
    const basicFilter = [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "Reservations",
          column: "office_id"
        },
        operator: "In",
        values: allowedOfficesIdList,// [69],
        filterType: 1 // pbi.models.FilterType.BasicFilter
      },
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target:
        {
          table: "Reservations",
          column: "Office"
        },
        operator: "In",
        values: [this.pbiOffice],
        filterType: 1
      },
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target:
        {
          table: "Reservations",
          column: "FloorName"
        },
        operator: "In",
        values: [this.pbiFloor],
        filterType: 1
      }
      ,
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target:
        {
          table: "ParkingData",
          column: "dashboard_name"
        },
        operator: "In",
        values: [this.pbiParkingOffice],
        filterType: 1
      }
    ];

    try {
      var mode = null;
      if (IsCanEdit) {
        mode = models.ViewMode.Edit
      }
      else {
        mode = models.ViewMode.View
      }
      if (mode != null) {
        let config = {
          type: 'report',
          accessToken: accessToken,
          embedUrl: embedUrl,
          id: embedReportId,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.All,
          viewMode: mode,
          filters: basicFilter,
          settings: {
            panes: {
              filters: {
                visible: false
              },
              pageNavigation: {
                visible: true
              }
            }
          },

          datasetbinding: {
            datasetid: datasetId
          },
          datasetId: datasetId
        };

        let reportContainer = <HTMLElement>document.getElementById('reportContainer');
        let powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);

        let report = powerbiService.embed(reportContainer, config);
        this.reportValue = report as powerbi.Report;
        report.off("loaded");
        report.on("loaded", () => {
          console.log("Report Loaded successful");
        });

        report.off("rendered");
        report.on("rendered", async function () {
          console.log("Report render successful");
        });
        report.on("pageChanged", function (event) {
          if (event.detail["newPage"].displayName == "Menu") {
            document.getElementById("selectOfficeFloor").classList.remove("d-none");
            document.getElementById("selectOfficeFloor").hidden = false;
            document.getElementById("selectParkingOffice").hidden = true;
          }
          else if (event.detail["newPage"].displayName == "Parking") {
            document.getElementById("selectParkingOffice").classList.remove("d-none");
            document.getElementById("selectOfficeFloor").hidden = true;
            document.getElementById("selectParkingOffice").hidden = false;
          }
          else {
            document.getElementById("selectOfficeFloor").hidden = true;
            document.getElementById("selectParkingOffice").hidden = true;
          }
        })

        report.off("error");
        report.on("error", function (event) {
          var errorMsg = event.detail;
          console.error(errorMsg);
          return;
        });
      }
    }
    catch (errors) {
      console.log(errors);
      this.showLoader = false;
    }
  }

LoadfilterReportOfficeEmbedReportV1(embedReportData, allowedOfficesIdList, IsCanEdit) {
    var officesList = null;
    let models = powerbi.models;
    let accessToken = embedReportData.embedToken;
    let embedUrl = embedReportData.embedUrl;
    let embedReportId = embedReportData.reportId;
    let datasetId = embedReportData.dataSetId;
    const basicFilter:powerbi.models.IBasicFilter =
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "Reservations",
          column: "office_id"
        },
        operator: "In",
        values: allowedOfficesIdList,// [69],
        filterType: 1 // pbi.models.FilterType.BasicFilter
      }
    try {
      var mode = null;
      if (IsCanEdit) {
        mode = models.ViewMode.Edit
      }
      else {
        mode = models.ViewMode.View
      }
      if (mode != null) {
        let config = {
          type: 'report',
          accessToken: accessToken,
          embedUrl: embedUrl,
          id: embedReportId,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.All,
          viewMode: mode,
          filters: [basicFilter],
          settings: {
            panes: {
              filters: {
                visible: false
              },
              pageNavigation: {
                visible: true
              }
            }
          },

          datasetbinding: {
            datasetid: datasetId
          },
          datasetId: datasetId
        };

        let reportContainer = <HTMLElement>document.getElementById('reportContainer');
        let powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
        let report = powerbiService.embed(reportContainer, config);
        this.reportValue = report as powerbi.Report;
        report.off("loaded");
        report.on("loaded", () => {
          console.log("Report Loaded successful");
        });
        report.off("rendered");
        report.on("rendered", async function () {
          console.log("Report render successful");
        });

        report.off("error");
        report.on("error", function (event) {
          var errorMsg = event.detail;
          console.error(errorMsg);
          return;
        });
      }
    }
    catch (errors) {
      console.log(errors);
      this.showLoader = false;
    }
  }

  onItemChangeV1(value) {
    let reportContainer = <HTMLElement>document.getElementById('reportContainer');
    let powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
    powerbiService.reset(reportContainer);
    if (value == "Edit") {
      this.LoadEmbeddedReportV1(this.allowedOfficesIdList, true);
    }
    else {
      this.LoadEmbeddedReportV1(this.allowedOfficesIdList, false);
    }

  }

  onModeChangeV1(value) {
    if (value == "Dashboard") {
      this.mode = "Dashboard";
      this.showDropdown = true;
    }
    else {
      this.mode = "Report";
      this.showDropdown = false;
    }
    let reportContainer = <HTMLElement>document.getElementById('reportContainer');
    let powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
    powerbiService.reset(reportContainer);
    this.LoadEmbeddedReportV1(this.allowedOfficesIdList, false);
  }

  RefreshOnDemandV1() {
    var region = this.selectedCountry.toLowerCase();
    this.showLoader = true;
      this.ServiceService.getPBIRefreshReportOnDemandV1(region, this.reportType).subscribe((data: any) => {
        this.showLoader = false;
        if (data && data != 400) {
          if(data.status == "Completed"){
            var PbiReportRefreshSuccess = "";
            this.translate.get('PbiReportRefreshSuccess').subscribe((data: string) => { PbiReportRefreshSuccess = data; });
              var successMsg = localStorage.getItem('SelectedCountry') + ' ' + PbiReportRefreshSuccess;
              Swal.fire({
                title: "",
                text: successMsg,
                confirmButtonColor: '#3f32f1',
                confirmButtonText: "Ok",
                icon: "success"
              }).then((result) => {
                if (result.value) {
                  let reportContainer = <HTMLElement>document.getElementById('reportContainer');
                  let powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
                  powerbiService.reset(reportContainer);
 
                  var isEdit = false;
                  if (this.userRole == "SuperAdmin") {
                    isEdit = (document.getElementById("toggle-off") as HTMLInputElement).checked;
                  }
                  this.LoadEmbeddedReportV1(this.allowedOfficesIdList, isEdit);
                }
            })
          }
          else if(data.status == "InProgress")
          {
            var InProgressMsg ="";
            this.translate.get('PbiReportRefreshInProgress').subscribe((data: string) => { InProgressMsg = data; });
            this.swalFunction(InProgressMsg,"warning");
          }
          else if(data.status =="Failed")
          {
            var failedMsg="";
            this.translate.get('PbiReportRefreshFailed').subscribe((data: string) => { failedMsg = data; });
            failedMsg = localStorage.getItem('SelectedCountry') + ' - ' + failedMsg;
            this.swalFunction(failedMsg,"warning");
          }
        }
        else
        {
          var failedMsg="";
          this.translate.get('PbiReportRefreshFailed').subscribe((data: string) => { failedMsg = data; });
          failedMsg = localStorage.getItem('SelectedCountry') + ' - ' + failedMsg;
          this.swalFunction(failedMsg,"warning");
        }
      });
  }
 
  swalFunction(msg, icon)
  {
    Swal.fire({
      title: "",
      text: msg,
      confirmButtonColor: '#3f32f1',
      confirmButtonText: "Ok",
      icon: icon
    })
  }



  //--------Close

  changeOffice(selectedFloor) {
    this.showLoader = true;
    this.pbiOffice = selectedFloor.target.value.split(":")[0];
    this.pbiFloor = selectedFloor.target.value.split(":")[1];
    const filter = [{
      $schema: "http://powerbi.com/product/schema#basic",
      target:
      {
        table: "Reservations",
        column: "Office"
      },
      operator: "In",
      values: [this.pbiOffice],
      filterType: 1
    },
    {
      $schema: "http://powerbi.com/product/schema#basic",
      target:
      {
        table: "Reservations",
        column: "FloorName"
      },
      operator: "In",
      values: [this.pbiFloor],
      filterType: 1
	  },
    {
      $schema: "http://powerbi.com/product/schema#basic",
      target:
      {
        table: "ParkingData",
        column: "dashboard_name"
      },
      operator: "In",
      values: [this.pbiParkingOffice],
      filterType: 1
    }
    ];

    this.reportValue.setFilters(filter)
      .then(() => {
        setTimeout(() => {
          this.showLoader = false;
        }, 1500)
      });
  }

  changeParkingOffice(selectedOffice) {
    this.showLoader = true;
    this.pbiParkingOffice = selectedOffice.target.value;
    const filter = [{
      $schema: "http://powerbi.com/product/schema#basic",
      target:
      {
	    table: "Reservations",
        column: "Office"
      },
      operator: "In",
      values: [this.pbiOffice],
      filterType: 1
    },
    {
      $schema: "http://powerbi.com/product/schema#basic",
      target:
      {
        table: "Reservations",
        column: "FloorName"
      },
      operator: "In",
      values: [this.pbiFloor],
      filterType: 1
    },
      {
      $schema: "http://powerbi.com/product/schema#basic",
      target:
      {
        table: "ParkingData",
        column: "dashboard_name"
      },
      operator: "In",
      values: [this.pbiParkingOffice],
      filterType: 1
    }
    ];

    this.reportValue.setFilters(filter)
      .then(() => {
        setTimeout(() => {
          this.showLoader = false;
        }, 1500)
      });
  }
}







