import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import * as moment from 'moment';
import { bypassSanitizationTrustStyle } from '@angular/core/src/sanitization/bypass';
import { TranslateService } from '@ngx-translate/core'
import { tr } from 'date-fns/locale';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { isMatch } from 'date-fns';
import { isNumber } from 'util';

@Component({
  selector: 'app-add-desktype',
  templateUrl: './add-desktype.component.html',
  // styleUrls: ['./add-desktype.component.sass']
  styleUrls: ['../../assets/css/style.scss']
})
export class AddDesktypeComponent implements OnInit {
   @Input() redirectTo: string;
 // @Input() fromReservationId: number;
  @Output() showPopUp = new EventEmitter<boolean>();


  AddDeskType_offices: any;
  AddDeskType_selectedOffice: string;

  AddDeskType_showMsgPopUp: boolean = false;
  AddDeskType_msgTitle: string = null;
  AddDeskType_PopUpMessage: string = null;
  AddDeskType_showLoader: boolean = false;

  AddDeskType_OfficeDeskTypeList:any;
  AddDeskType_DeskTypeList:any;
  AddDeskType_givenText: string;
  AddDeskType_DeskTypeDetails: any;

  DeskTypeForm = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });
  get Name() { return this.DeskTypeForm.get('Name'); }

  constructor(private ServiceService: ServiceService,
    private titleService: Title,
    private dataService: userDataService, private router: Router, public translate: TranslateService) {
    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(ServiceService.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }

    this.AddDeskType_selectedOffice = null;
  }

  ngOnInit() {
    this.NgOnIntFunctionality();
  }

  NgOnIntFunctionality() {


    // get list of offcies for country
    let checkAdminOfficeIds: boolean = (this.router.url == '/deskConfiguration' ? true : false);
    this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), checkAdminOfficeIds).subscribe((data: any[]) => {
      if (data.length == 0) {
        this.AddDeskType_msgTitle = 'Permission Restricted';
        this.AddDeskType_PopUpMessage = 'Sorry, you dont have permission to access any of the offices in ' + localStorage.getItem('SelectedCountry') + '. Please select other country';
        this.AddDeskType_showMsgPopUp = true;
      }
      this.AddDeskType_offices = data;
    });

    this.AddDeskType_selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
    localStorage.setItem('AddDeskType_OfficeID', this.AddDeskType_selectedOffice);

    this.getAllDeskTypes();
    this.getOfficeDeskTypeList();
  }

  changeOffice_AddDesk(e) {
    var _offID = e.target.value;
    localStorage.setItem('AddDeskType_OfficeID', _offID);
    this.getOfficeDeskTypeList();
  }

  formSubmit() {
    this.AddDeskType_showLoader = true;
    var offId=parseInt(localStorage.getItem('AddDeskType_OfficeID')) == 0 ? undefined : localStorage.getItem('AddDeskType_OfficeID');
    var deskTypeName=this.DeskTypeForm.value.Name;

    var deskTypesList=this.AddDeskType_OfficeDeskTypeList;
    var numberValue = Number(deskTypeName);


    if(offId ==undefined || offId =="undefined"  || deskTypeName ==undefined || deskTypeName == "undefined" || deskTypeName.trim() =='')
    {
      this.AddDeskType_showLoader = false;
      var errorMsg='';
      this.translate.get('DeskTypeInvalidForm').subscribe((data: string) => { errorMsg= data; });
      Swal.fire({
        text: errorMsg,
        icon: "warning",
        confirmButtonColor: "#3f32f1"
      });
    }

   else if (deskTypesList && deskTypesList.length >= 10){
      this.AddDeskType_showLoader = false;
      var errorMsg='';
      this.translate.get('DeskTypeNotAllowMoreThan10Types').subscribe((data: string) => { errorMsg= data; });
      Swal.fire({
        text: errorMsg,
        icon: "warning",
        confirmButtonColor: "#3f32f1"
      });
    }

    else if(isNumber(numberValue) && !isNaN(numberValue))
    {
      this.AddDeskType_showLoader = false;
      var errorMsg='';
      this.translate.get('DeskTypeEnterValidData').subscribe((data: string) => { errorMsg= data; });
      Swal.fire({
        text: errorMsg,
        icon: "warning",
        confirmButtonColor: "#3f32f1"
      });
    }

    else if (deskTypesList && (deskTypesList.findIndex((item) => item.name.toLowerCase() === deskTypeName.trim().toLowerCase()) >= 0)){
      this.AddDeskType_showLoader = false;
      var errorMsg='';
      this.translate.get('DeskTypeAlreadyExists').subscribe((data: string) => { errorMsg= data; });
      Swal.fire({
        text: errorMsg,
        icon: "warning",
        confirmButtonColor: "#3f32f1"
      });
    }

    else if (deskTypeName.length > 0) {
      var DeskTypeData = {
        Id:0,
        Name: deskTypeName,
        Office: offId
      };
      this.ServiceService.postDeskTypeData(DeskTypeData).subscribe((data: any) => {
        this.AddDeskType_showLoader = false;
        var errorMsg='';
        this.translate.get('DeskTypeSavedSuccessfully').subscribe((data: string) => { errorMsg= data; });
        Swal.fire({
          text: errorMsg,
          icon: 'success',
          confirmButtonColor: '#3f32f1',
        });
        this.showPopUp.emit(true);
      }, err => {
        this.AddDeskType_showLoader = false;
      });

    }
  }

 getAllDeskTypes()
  {
    this.ServiceService.getAllDeskTypes().subscribe((result: any[]) => {
      //this.AddDeskType_DeskTypeList = result;
      this.AddDeskType_DeskTypeList =result.filter(x => x.langKey == localStorage.getItem('locale'));
    });
  }

  getOfficeDeskTypeList()
  {
    var _offID = parseInt(localStorage.getItem('AddDeskType_OfficeID')) == 0 ? undefined : localStorage.getItem('AddDeskType_OfficeID');
    if(_offID!=undefined && _offID!="undefined")
    {
      this.ServiceService.getDeskTypes(parseInt(_offID)).subscribe((result: any[]) => {
       // this.AddDeskType_OfficeDeskTypeList = result;
        this.AddDeskType_OfficeDeskTypeList =result.filter(x => x.langKey == localStorage.getItem('locale'));
      });
    }
  }

  AddDeskType_search() {

      this.AddDeskType_showLoader = true;
     if(this.AddDeskType_DeskTypeList && this.AddDeskType_DeskTypeList.length>0){
        this.AddDeskType_showLoader = false;
        this.AddDeskType_DeskTypeDetails = this.AddDeskType_DeskTypeList.filter(x => x.name.includes(this.AddDeskType_givenText));
    }
    else{
     this.AddDeskType_showLoader = false;
    }
  }

  deleteDeskType(deskTypeId)
  {
    var _offID = parseInt(localStorage.getItem('AddDeskType_OfficeID')) == 0 ? undefined : localStorage.getItem('AddDeskType_OfficeID');
    if(_offID!=undefined && _offID!="undefined")
    {
        this.AddDeskType_showLoader = true;
        this.ServiceService.getIsIncludeDeskType(parseInt(deskTypeId),parseInt(_offID)).subscribe((result: any) => {
            if(result)
            {
               this.AddDeskType_showLoader = false;
               var errorMsg_CanNotDelete='';
               this.translate.get('DeskTypeCanNotDeleteLikedWithSoemtOtherDeskNumber').subscribe((data: string) => { errorMsg_CanNotDelete= data; });
               Swal.fire({
                text: errorMsg_CanNotDelete,
                icon: 'info',
                confirmButtonColor: '#3f32f1',
              });
            }
            else
            {
              this.AddDeskType_showLoader = false;
              var errorMsg_DeleteConfirmation='';
              var errorMsg_YesDeleteIt='';
              var errorMsg_Nokeepit='';
              this.translate.get('DeskTypeAreYouSureWantToDelete').subscribe((data: string) => { errorMsg_DeleteConfirmation= data; });
              this.translate.get('DeskTypeYesDeleteIt').subscribe((data: string) => { errorMsg_YesDeleteIt= data; });
              this.translate.get('DeskTypeNokeepit').subscribe((data: string) => { errorMsg_Nokeepit= data; });

              Swal.fire({
                title: errorMsg_DeleteConfirmation,
               // text: 'You will not be able to recover this file!',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3f32f1',
                confirmButtonText: errorMsg_YesDeleteIt,
                cancelButtonText: errorMsg_Nokeepit
              }).then((result) => {
                if (result.value) {
                  var errorMsg_DeleteSuccess='';
                  this.translate.get('DeskTypeRemoveSuccessfully').subscribe((data: string) => { errorMsg_DeleteSuccess= data; });

                  this.ServiceService.deleteDeskType(parseInt(deskTypeId),parseInt(_offID)).subscribe((result: any) => {
                    this.AddDeskType_showLoader = false;
                    if(result==200){
                     // alert("Success!! Remove desk type successfully.");
                      Swal.fire({
                        text: errorMsg_DeleteSuccess,
                        icon: 'success',
                        confirmButtonColor: '#3f32f1',
                      });
                      this.getOfficeDeskTypeList();
                      this.showPopUp.emit(true);
                    }
                  }, err => {
                    this.AddDeskType_showLoader = false;
                  });
                }
                else
                {
                  this.AddDeskType_showLoader = false;
                }
              })
            }
        }, err => {
          this.AddDeskType_showLoader = false;
        });
    }
  }


  closeDeskTypePopup() {
    this.showPopUp.emit(false);
  }

  regexStr = '^[a-zA-Z0-9 -]+$';
  onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }


}
