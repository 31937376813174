import { Component, OnInit, ElementRef, ViewChild, NgZone, Output, EventEmitter } from '@angular/core';
import { ServiceService } from '../services/service.service'
import { Title, DomSanitizer } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import { DeskDetails, DeskReservationsToBookDTO } from '../Model/DeskReservationsToBookDTO';
import { ChildActivationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'
import { OfficeSelectorComponent } from '../office-selector/office-selector.component';
//import { DomSanitizer } from '@angular/platform-browser';
import { Time } from '@angular/common';
import { MultiDateSelectedToBookDTO } from '../Model/CheckValidDateSelectedToBook';
import { AzureBlobStorageService } from './../azure-blob-storage.service';
import { Console } from '@angular/core/src/console';
import { DatePipe } from '@angular/common';
import keys from '../key.json';

@Component({
  selector: 'app-selectseat',
  templateUrl: './selectseat.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class SelectseatComponent implements OnInit {
  constructor(private ngzone: NgZone,
    private titleService: Title,
    private dataService: userDataService,
    private ServiceService: ServiceService, private router: Router, public translate: TranslateService,
    private dom: DomSanitizer,
    private blobService: AzureBlobStorageService,
    public datepipe: DatePipe) {
    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(ServiceService.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
    this.dataService.showDesk.subscribe(radio => {
      this.radioDesk = radio;
    })
  }
  showLoader = false;
  TimingSlotsNew;

  TimingSlots = [
    {
      "value": "00:00",
      "text": "00:00"
    },
    {
      "value": '00:30',
      "text": "00:30"
    },
    {
      "value": '01:00',
      "text": "01:00"
    },
    {
      "value": '01:30',
      "text": "01:30"
    },
    {
      "value": '02:00',
      "text": "02:00"
    },
    {
      "value": '02:30',
      "text": "02:30"
    },
    {
      "value": '03:00',
      "text": "03:00"
    },
    {
      "value": '03:30',
      "text": "03:30"
    },
    {
      "value": '04:00',
      "text": "04:00"
    },
    {
      "value": '04:30',
      "text": "04:30"
    },
    {
      "value": '05:00',
      "text": "05:00"
    },
    {
      "value": '05:30',
      "text": "05:30"
    },
    {
      "value": '06:00',
      "text": "06:00"
    },
    {
      "value": '06:30',
      "text": "06:30"
    },
    {
      "value": '07:00',
      "text": "07:00"
    },
    {
      "value": '07:30',
      "text": "07:30"
    },
    {
      "value": '08:00',
      "text": "08:00"
    },
    {
      "value": '08:30',
      "text": "08:30"
    },
    {
      "value": '09:00',
      "text": "09:00"
    },
    {
      "value": '09:30',
      "text": "09:30"
    },
    {
      "value": '10:00',
      "text": "10:00"
    },
    {
      "value": '10:30',
      "text": "10:30"
    },
    {
      "value": '11:00',
      "text": "11:00"
    },
    {
      "value": '11:30',
      "text": "11:30"
    },
    {
      "value": '12:00',
      "text": "12:00"
    },
    {
      "value": '12:30',
      "text": "12:30"
    }, {
      "value": '13:00',
      "text": "13:00"
    }, {
      "value": '13:30',
      "text": "13:30"
    }, {
      "value": '14:00',
      "text": "14:00"
    }, {
      "value": '14:30',
      "text": "14:30"
    }, {
      "value": '15:00',
      "text": "15:00"
    }, {
      "value": '15:30',
      "text": "15:30"
    }, {
      "value": '16:00',
      "text": "16:00"
    }, {
      "value": '16:30',
      "text": "16:30"
    }, {
      "value": '17:00',
      "text": "17:00"
    },
    {
      "value": '17:30',
      "text": "17:30"
    },
    {
      "value": '18:00',
      "text": "18:00"
    },
    {
      "value": '18:30',
      "text": "18:30"
    },
    {
      "value": '19:00',
      "text": "19:00"
    }, {
      "value": '19:30',
      "text": "19:30"
    },
    {
      "value": '20:00',
      "text": "20:00"
    },
    {
      "value": '20:30',
      "text": "20:30"
    },
    {
      "value": '21:00',
      "text": "21:00"
    },
    {
      "value": '21:30',
      "text": "21:30"
    },
    {
      "value": '22:00',
      "text": "22:00"
    },
    {
      "value": '22:30',
      "text": "22:30"
    },
    {
      "value": '23:00',
      "text": "23:00"
    },
    {
      "value": '23:30',
      "text": "23:30"
    }
  ];

  OutlookTimeZones = [
    {
      "value": "(-1200)",
      "text": "(UTC-12:00) International Date Line West"
    },
    {
      "value":
        "(-1100)", "text":
        "(UTC-11:00) Coordinated Universal Time-11"
    },
    {
      "value":
        "(-1000)", "text":
        "(UTC-10:00) Aleutian Islands"
    },
    {
      "value":
        "(-1000)", "text":
        "(UTC-10:00) Hawaii"
    },
    {
      "value":
        "(-0930)", "text":
        "(UTC-09:30) Marquesas Islands"
    },
    {
      "value":
        "(-0900)", "text":
        "(UTC-09:00) Alaska"
    },
    {
      "value":
        "(-0900)", "text":
        "(UTC-09:00) Coordinated Universal Time-09"
    },
    {
      "value":
        "(-0800)", "text":
        "(UTC-08:00) Baja California"
    },
    {
      "value":
        "(-0800)", "text":
        "(UTC-08:00) Coordinated Universal Time-08"
    },
    {
      "value":
        "(-0800)", "text":
        "(UTC-08:00) Pacific Time (US & Canada)"
    },
    {
      "value":
        "(-0700)", "text":
        "(UTC-07:00) Yukon"
    },
    {
      "value":
        "(-0700)", "text":
        "(UTC-07:00) Arizona"
    },
    {
      "value":
        "(-0700)", "text":
        "(UTC-07:00) Chihuahua, La Paz, Mazatlan"
    },
    {
      "value":
        "(-0700)", "text":
        "(UTC-07:00) Mountain Time (US & Canada)"
    },
    {
      "value":
        "(-0600)", "text":
        "(UTC-06:00) Central America"
    },
    {
      "value":
        "(-0600)", "text":
        "(UTC-06:00) Central Time (US & Canada)"
    },
    {
      "value":
        "(-0600)", "text":
        "(UTC-06:00) Easter Island"
    },
    {
      "value":
        "(-0600)", "text":
        "(UTC-06:00) Guadalajara, Mexico City, Monterrey"
    },
    {
      "value":
        "(-0600)", "text":
        "(UTC-06:00) Saskatchewan"
    },
    {
      "value":
        "(-0500)", "text":
        "(UTC-05:00) Chetumal"
    },
    {
      "value":
        "(-0500)", "text":
        "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
    },
    {
      "value":
        "(-0500)", "text":
        "(UTC-05:00) Eastern Time (US & Canada)"
    },
    {
      "value":
        "(-0500)", "text":
        "(UTC-05:00) Haiti"
    },
    {
      "value":
        "(-0500)", "text":
        "(UTC-05:00) Havana"
    },
    {
      "value":
        "(-0500)", "text":
        "(UTC-05:00) Indiana (East)"
    },
    {
      "value":
        "(-0500)", "text":
        "(UTC-05:00) Turks and Caicos"
    },
    {
      "value":
        "(-0400)", "text":
        "(UTC-04:00) Caracas"
    },
    {
      "value":
        "(-0400)", "text":
        "(UTC-04:00) Asuncion"
    },
    {
      "value":
        "(-0400)", "text":
        "(UTC-04:00) Atlantic Time (Canada)"
    },
    {
      "value":
        "(-0400)", "text":
        "(UTC-04:00) Cuiaba"
    },
    {
      "value":
        "(-0400)", "text":
        "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
    },
    {
      "value":
        "(-0400)", "text":
        "(UTC-04:00) Santiago"
    },
    {
      "value":
        "(-0330)", "text":
        "(UTC-03:30) Newfoundland"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Punta Arenas"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Araguaina"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Brasilia"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Cayenne, Fortaleza"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) City of Buenos Aires"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Greenland"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Montevideo"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Saint Pierre and Miquelon"
    },
    {
      "value":
        "(-0300)", "text":
        "(UTC-03:00) Salvador"
    },
    {
      "value":
        "(-0200)", "text":
        "(UTC-02:00) Coordinated Universal Time-02"
    },
    {
      "value":
        "(-0200)", "text":
        "(UTC-02:00) Mid-Atlantic - Old"
    },
    {
      "value":
        "(-0100)", "text":
        "(UTC-01:00) Azores"
    },
    {
      "value":
        "(-0100)", "text":
        "(UTC-01:00) Cabo Verde Is."
    },
    {
      "value":
        "(UTC) Coordinated Universal Time", "text":
        "(UTC) Coordinated Universal Time"
    },
    {
      "value":
        "(+0000)", "text":
        "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"
    },
    {
      "value":
        "(+0000)", "text":
        "(UTC+00:00) Monrovia, Reykjavik"
    },
    {
      "value":
        "(+0100)", "text":
        "(UTC+01:00) Casablanca"
    },
    {
      "value":
        "(+0100)", "text":
        "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
      "value":
        "(+0100)", "text":
        "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    {
      "value":
        "(+0100)", "text":
        "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
      "value":
        "(+0100)", "text":
        "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
      "value":
        "(+0100)", "text":
        "(UTC+01:00) West Central Africa"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Tripoli"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Windhoek"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Amman"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Athens, Bucharest"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Beirut"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Cairo"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Chisinau"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Damascus"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Gaza, Hebron"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Harare, Pretoria"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Jerusalem"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Juba"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Kaliningrad"
    },
    {
      "value":
        "(+0200)", "text":
        "(UTC+02:00) Khartoum"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Istanbul"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Minsk"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Baghdad"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Kuwait, Riyadh"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Moscow, St. Petersburg"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Nairobi"
    },
    {
      "value":
        "(+0300)", "text":
        "(UTC+03:00) Volgograd"
    },
    {
      "value":
        "(+0330)", "text":
        "(UTC+03:30) Tehran"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Astrakhan, Ulyanovsk"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Izhevsk, Samara"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Saratov"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Abu Dhabi, Muscat"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Baku"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Port Louis"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Tbilisi"
    },
    {
      "value":
        "(+0400)", "text":
        "(UTC+04:00) Yerevan"
    },
    {
      "value":
        "(+0430)", "text":
        "(UTC+04:30) Kabul"
    },
    {
      "value":
        "(+0500)", "text":
        "(UTC+05:00) Ashgabat, Tashkent"
    },
    {
      "value":
        "(+0500)", "text":
        "(UTC+05:00) Ekaterinburg"
    },
    {
      "value":
        "(+0500)", "text":
        "(UTC+05:00) Islamabad, Karachi"
    },
    {
      "value":
        "(+0500)", "text":
        "(UTC+05:00) Qyzylorda"
    },
    {
      "value":
        "(+0530)", "text":
        "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
      "value":
        "(+0530)", "text":
        "(UTC+05:30) Sri Jayawardenepura"
    },
    {
      "value":
        "(+0545)", "text":
        "(UTC+05:45) Kathmandu"
    },
    {
      "value":
        "(+0600)", "text":
        "(UTC+06:00) Nur-Sultan"
    },
    {
      "value":
        "(+0600)", "text":
        "(UTC+06:00) Dhaka"
    },
    {
      "value":
        "(+0600)", "text":
        "(UTC+06:00) Omsk"
    },
    {
      "value":
        "(+0630)", "text":
        "(UTC+06:30) Yangon (Rangoon)"
    },
    {
      "value":
        "(+0700)", "text":
        "(UTC+07:00) Barnaul, Gorno-Altaysk"
    },
    {
      "value":
        "(+0700)", "text":
        "(UTC+07:00) Novosibirsk"
    },
    {
      "value":
        "(+0700)", "text":
        "(UTC+07:00) Tomsk"
    },
    {
      "value":
        "(+0700)", "text":
        "(UTC+07:00) Bangkok, Hanoi, Jakarta"
    },
    {
      "value":
        "(+0700)", "text":
        "(UTC+07:00) Hovd"
    },
    {
      "value":
        "(+0700)", "text":
        "(UTC+07:00) Krasnoyarsk"
    },
    {
      "value":
        "(+0800)", "text":
        "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
    },
    {
      "value":
        "(+0800)", "text":
        "(UTC+08:00) Irkutsk"
    },
    {
      "value":
        "(+0800)", "text":
        "(UTC+08:00) Kuala Lumpur, Singapore"
    },
    {
      "value":
        "(+0800)", "text":
        "(UTC+08:00) Perth"
    },
    {
      "value":
        "(+0800)", "text":
        "(UTC+08:00) Taipei"
    },
    {
      "value":
        "(+0800)", "text":
        "(UTC+08:00) Ulaanbaatar"
    },
    {
      "value":
        "(+0845)", "text":
        "(UTC+08:45) Eucla"
    },
    {
      "value":
        "(+0900)", "text":
        "(UTC+09:00) Chita"
    },
    {
      "value":
        "(+0900)", "text":
        "(UTC+09:00) Pyongyang"
    },
    {
      "value":
        "(+0900)", "text":
        "(UTC+09:00) Osaka, Sapporo, Tokyo"
    },
    {
      "value":
        "(+0900)", "text":
        "(UTC+09:00) Seoul"
    },
    {
      "value":
        "(+0900)", "text":
        "(UTC+09:00) Yakutsk"
    },
    {
      "value":
        "(+0930)", "text":
        "(UTC+09:30) Adelaide"
    },
    {
      "value":
        "(+0930)", "text":
        "(UTC+09:30) Darwin"
    },
    {
      "value":
        "(+1000)", "text":
        "(UTC+10:00) Brisbane"
    },
    {
      "value":
        "(+1000)", "text":
        "(UTC+10:00) Canberra, Melbourne, Sydney"
    },
    {
      "value":
        "(+1000)", "text":
        "(UTC+10:00) Guam, Port Moresby"
    },
    {
      "value":
        "(+1000)", "text":
        "(UTC+10:00) Hobart"
    },
    {
      "value":
        "(+1000)", "text":
        "(UTC+10:00) Vladivostok"
    },
    {
      "value":
        "(+1030)", "text":
        "(UTC+10:30) Lord Howe Island"
    },
    {
      "value":
        "(+1100)", "text":
        "(UTC+11:00) Bougainville Island"
    },
    {
      "value":
        "(+1100)", "text":
        "(UTC+11:00) Magadan"
    },
    {
      "value":
        "(+1100)", "text":
        "(UTC+11:00) Sakhalin"
    },
    {
      "value":
        "(+1100)", "text":
        "(UTC+11:00) Chokurdakh"
    },
    {
      "value":
        "(+1100)", "text":
        "(UTC+11:00) Norfolk Island"
    },
    {
      "value":
        "(+1100)", "text":
        "(UTC+11:00) Solomon Is., New Caledonia"
    },
    {
      "value":
        "(+1200)", "text":
        "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky"
    },
    {
      "value":
        "(+1200)", "text":
        "(UTC+12:00) Auckland, Wellington"
    },
    {
      "value":
        "(+1200)", "text":
        "(UTC+12:00) Coordinated Universal Time+12"
    },
    {
      "value":
        "(+1200)", "text":
        "(UTC+12:00) Fiji"
    },
    {
      "value":
        "(+1200)", "text":
        "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"
    },
    {
      "value":
        "(+1245)", "text":
        "(UTC+12:45) Chatham Islands"
    },
    {
      "value":
        "(+1300)", "text":
        "(UTC+13:00) Coordinated Universal Time+13"
    },
    {
      "value":
        "(+1300)", "text":
        "(UTC+13:00) Nuku'alofa"
    },
    {
      "value": "(+1300)",
      "text": "(UTC+13:00) Samoa"
    },
    {
      "value":
        "(+1400)", "text":
        "(UTC+14:00) Kiritimati Island"
    }
  ]
  EndTimeSlots = this.TimingSlots;


  DeskReservationsToBook: DeskReservationsToBookDTO;
  selectedDateDetails = [];
  _selectedDateDetails = [];
  selected_desk: string;
  selected_desk_ID: number;
  selected_meeting: string;
  src_fp_path: any = "";
  SelectTypeofSeat: boolean;
  ShowReservableType: boolean = false;
  BookingPurposes: any;
  selectedPurpose: string;
  validateButton: boolean = false;
  showMsgPopUp: boolean = false;
  showAlreadyMsgPopUp: boolean = false;
  showErrorMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  msgBody: string;
  errorCode: string;
  selectedDateModel: any;
  BookingType: string;
  showLegent: string;
  today: Date;
  showFullPlan: boolean = false;
  fullPlanPath;
  showFPpopup: boolean = false;
  hasParking: boolean = false;
  redirectTo: string = 'undefined';
  showPopupCancelBtn: boolean = false;
  isFullDay: boolean = true;
  bookMeeting: boolean = false;
  cancelBtnText: string = "Cancel";
  okBtnText: string = "OK";
  BookingPurposeId: number;
  IsQuickBook: string;
  officeName: string;
  maxDate: string;
  currentDate: any;
  //sas = "?sp=rwl&st=2023-01-02T05:03:02Z&se=2024-12-31T18:29:59Z&spr=https&sv=2021-06-08&sr=c&sig=qMmEdTPN1cxNg6QmqpFIPYQjtZAvRgPQaxSSFwA1rz8%3D";
  sas = keys[0]['sas'];

  multiDayAutoDatesSelected = 0;
  multiDayAutoDatesNotSelected = 0;
  docurl: string;

  multiDaySingleSelect: boolean = false;
  showMulitdayMsgPopUp: boolean = false;
  // Header
  CurrentUserName: string;
  showPopupWrap = false;
  showPopupWraphelp = false;
  AllowAdmin: boolean = false;
  AdminUserRole: string = null;
  showMeeting: boolean = false;
  radioDesk: string;
  deskDisabled: boolean = false;
  meetingDisabled: boolean = false;
  meetingAttendeeText: string;
  AttendeeDetails: any;
  attendee: string;
  duplicateValues: boolean = false;
  isHybrid: boolean = false;
  onlineAttendeeName = '';
  selectedOnlineAttendees = [];
  givenText = '';
  // Header End


  //------- DeskType Implement 11Aug2022 --------
  OfficeDeskTypeList = [];
  DeskTypescheckedIDs = [];
  selectedDeskTypesIds = '';
  //Outlook Room Availability
  showStatus: boolean;
  Available: boolean;
  Date;
  currentTime;
  TimeZoneSelected;
  ZoneSelected;
  selectFromParking: boolean = false;
  meetingActive: boolean = false;
  showUnavailablity: boolean = true;
  ngOnInit() {
    this.showUnavailablity = true;
    this.day_Selection = localStorage.getItem('CurrentBookingType');
    var selectedOfficeIDFormParking = parseInt(localStorage.getItem('RedirectFromParking'));
    if (selectedOfficeIDFormParking != 0) {
      this.selectFromParking = true;
    }
    this.TimeZoneSelected = "(-1200)";
    this.ZoneSelected = "(UTC-12:00) International Date Line West";
    this.TimingSlotsNew = this.TimingSlots;
    this.Date = new Date()
    this.currentTime = this.datepipe.transform(this.Date, 'HH:mm');
    var selectedBookedDate = this.datepipe.transform(localStorage.getItem('SelectedDateToBook'), 'yyyy-MM-dd');
    var todayDate = this.datepipe.transform(localStorage.getItem('localdate'), 'yyyy-MM-dd');
    if (selectedBookedDate == todayDate && localStorage.getItem('CurrentBookingType') != 'multiple') {
      this.ngzone.run(() => { this.TimingSlots = this.TimingSlots.filter(x => x.value > this.currentTime) });
    }
    window.scroll(0, 0);
    this.showLoader = true;
    this.CurrentUserName = localStorage.getItem('CurrentUser');
    if (localStorage.getItem('token') != null) {
      this.EnableAdminButton();
    }
    this.titleService.setTitle("Select a desk");
    this.SelectTypeofSeat = (localStorage.getItem('SelectTypeofSeat') == "true");//this.dataService.getSelectTypeofSeat();
    // this.src_fp_path = localStorage.getItem('FloorPlanPath');//'https://savemyspot.blob.core.windows.net/floorplans/DEV%2FAPAC%2FRMZ%2FCOLOR_8TH_FLOOR%20_BASIC%20PLAN_with%20numbering_04.04.2022-RM8-1006-FURNITURE_LAYOUT.svg';
    this.src_fp_path = '/cal-icon.svg';

    this.showFullPlan = (localStorage.getItem('ShowFullFloorplan') == "true");
    if (this.showFullPlan == true) {
      this.blobService.downloadImage(this.sas, localStorage.getItem('FullFloorPlanPath'), blob => {
        let url = window.URL.createObjectURL(blob);
        this.ngzone.run(() => { this.fullPlanPath = this.dom.bypassSecurityTrustResourceUrl(url); });//this.dom.sanitize(SecurityContext.HTML, this.dom.bypassSecurityTrustResourceUrl(url)) });
      });
    }

    this.hasParking = (localStorage.getItem('HasParking') == "true");

    if (localStorage.getItem("ZoneId") != 'undefined' && localStorage.getItem("ZoneId") != null && localStorage.getItem("ZoneId") != 'null') {
      this.ServiceService.checkAssignedorDeskCatBasedonZoneId(parseInt(localStorage.getItem("ZoneId"))).subscribe((result: boolean) => {
        this.ShowReservableType = result;
      });
    }
    else if (localStorage.getItem("FloorID") != 'undefined' && localStorage.getItem("FloorID") != null && localStorage.getItem("FloorID") != 'null') {
      this.ServiceService.checkAssignedorDeskCatBasedonFloorId(parseInt(localStorage.getItem("FloorID"))).subscribe((result: boolean) => {
        this.ShowReservableType = result;
      });
    }
    else {
      this.ShowReservableType = false;
    }
    this.BookingType = localStorage.getItem('CurrentBookingType');
    this.selectedDateModel = this.dataService.getSelectedDatesToBook();
    if (this.selectedDateModel == undefined) {
      this.selectedDateModel = JSON.parse(localStorage.getItem('BookingDatesSelected'));
    }
    this.showLegent = localStorage.getItem('SelectedCountry');//;
    var getPurposesDTO = {
      officeId: parseInt(localStorage.getItem('OfficeID')),
      Lang: localStorage.getItem('locale')
    };
    this.ServiceService.getSelectingSeatPurposes(getPurposesDTO).subscribe((data: any[]) => {
      this.showLoader = false;
      data = data.filter(x => x.forGym == false);
      this.BookingPurposes = data;
    });
    this.today = this.selectedDateModel == undefined ? localStorage.getItem('SelectedDateToBook') : this.selectedDateModel[0];
    this.officeName = localStorage.getItem('OfficeName');

    //Add all dates to table
    if (this.BookingType == 'multiple') {
      this.selectedDateModel.forEach(selDate => {
        var deskdetails = {
          DeskID: undefined,
          BookedDate: selDate,
          DeskNumber: undefined,
          MeetingName: undefined,
          BookingStartTime: this.booking_start_time,
          BookingEndTime: this.booking_end_time,
          IsFullDay: undefined// this.isFullDay
        };
        this.selectedDateDetails.push(deskdetails);
      });
    }

    // ------- DeskType Implement 11Aug2022 --------
    this.getOfficeDeskTypeList();
  }

  backToBooking() {
    localStorage.setItem('BackfromSelectSeatPage', 'selectseat');
    this.router.navigateByUrl('/booking');
  }

  public downloadImage(fileName: string) {
    this.blobService.downloadImage(this.sas, fileName, blob => {
      let url = window.URL.createObjectURL(blob);
    });
  }
  selectDate(value) {
    this.today = value;
    if (this.isFullDay == true) {
      this.ngzone.run(() => { this.booking_end_time = undefined; this.booking_start_time = undefined });
    }
    // else {
    //   this.Date = new Date()
    //   this.currentTime = this.datepipe.transform(this.Date, 'HH:mm');
    //   var selectedBookedDate = this.datepipe.transform(localStorage.getItem('SelectedDateToBook'), 'yyyy-MM-dd');
    //   var todayDate = this.datepipe.transform(localStorage.getItem('localdate'), 'yyyy-MM-dd');
    //   if (value == todayDate) {
    //     this.ngzone.run(() => { this.TimingSlots = this.TimingSlots.filter(x => x.value > this.currentTime) });
    //   }
    //   else
    //   {
    //     this.ngzone.run(() => { this.TimingSlots = this.TimingSlotsNew });
    //   }
    // }
    var selDeskNumber = undefined;
    //check and find desk number selected for the day
    const index = this.selectedDateDetails.findIndex(x => x.BookedDate === this.today);
    if (index != -1) {
      selDeskNumber = this.selectedDateDetails[index].DeskNumber;
    }
    this.dataService.setSelectedDeskNumber(selDeskNumber);
    this.ButtonValidation();
    this.multiDaySingleSelect = true;
  }
  showDeskNumber(deskDetail) {
    this.selected_desk_ID = deskDetail.DID;
    this.selected_desk = deskDetail.DNUM;
    this.selected_meeting = deskDetail.DeskMeetingName;
    if (this.BookingType == 'multiple' && this.multiDaySingleSelect == false) {
      const index = this.selectedDateDetails.findIndex(x => x.BookedDate === this.today && x.DeskID === this.selected_desk_ID);
      if (index === -1) {
        this.ValidateDeskMultipleDays();
      } else {
        return;
      }
    } else {
      this.ValidateDeskSingleDay(deskDetail);
    }
  }

  ValidateDeskSingleDay(meetingDeskDetail) {
    if (this.bookMeeting) {
      this.selectedDateDetails = [];
      meetingDeskDetail.forEach(element => {
        var deskdetails = {
          DeskID: element.deskID,
          BookedDate: this.today,
          DeskNumber: element.deskNumber,
          MeetingName: element.meetingName,
          AttendeeName: '',
          BookingStartTime: this.booking_start_time,
          BookingEndTime: this.booking_end_time,
          IsFullDay: this.isFullDay,
        }
        this.selectedDateDetails.push(deskdetails);
      });

      this.selectedDateDetails = this.orderDeskDetails(this.selectedDateDetails);
      this.ngzone.run(() => {
        this.selected_desk = this.selected_desk;
        this.showReservedData = false;
        this.multiDaySingleSelect = false;
      });
      if (this.ButtonValidation()) {
        this.ngzone.run(() => { this.validateButton = true; });
      }
    }
    else {
      var deskdetails = {
        DeskID: this.selected_desk_ID,
        BookedDate: this.today,
        DeskNumber: this.selected_desk,
        BookingStartTime: this.booking_start_time,
        BookingEndTime: this.booking_end_time,
        IsFullDay: this.isFullDay,
      };
      // this.DeskReservationsToBook.DeskDetails.push(deskdetails);
      const index = this.selectedDateDetails.findIndex(x => x.BookedDate === this.today);
      // this.selectedDateDetails.splice(this.selectedDateDetails.findIndex(x => x.BookedDate === "2021-02-03"), 1)
      if (index === -1) {
        this.selectedDateDetails.push(deskdetails);
      }
      else {
        this.selectedDateDetails.splice(index, 1);
        this.selectedDateDetails.push(deskdetails);
      }

      this.selectedDateDetails = this.orderDeskDetails(this.selectedDateDetails);
      this.ngzone.run(() => {
        this.selected_desk = this.selected_desk;
        this.showReservedData = false;
        this.multiDaySingleSelect = false;
      });
      if (this.ButtonValidation()) {
        this.ngzone.run(() => { this.validateButton = true; });
      }
    }
  }

  orderDeskDetails(deskDetails: any[]) {
    return deskDetails.sort((a, b) => {
      return <any>new Date(b.BookedDate) - <any>new Date(a.BookedDate);
    }).reverse();

  }

  resDeskNum;
  resUserName;
  resMeetingName;
  resBy;
  showReservedData: boolean = false;
  organiser = false;
  BookAllowed: boolean = true;
  showBookedData(bookedDetail) {
    this.ngzone.run(() => {
      this.showReservedData = true;
      this.resDeskNum = bookedDetail.DNUM;
      this.resUserName = bookedDetail.REV;
      this.resMeetingName = bookedDetail.DeskMeetingName;
      this.resBy = bookedDetail.REVBy;
      // if (this.resBy.toLowerCase() == localStorage.getItem('CurrentUserEmail').toLowerCase()) {
      //   this.organiser = true;
      // }
    });
  }

  desk_quiet_chk: boolean = false;
  desk_dualmon_chk: boolean = false;
  desk_sitstand_chk: boolean = false;
  desk_sncl_chk: boolean = false;
  desk_assigned_chk: boolean = false;
  desk_touchdown_chk: boolean = false;
  booking_start_time: string;
  booking_end_time: string;
  day_Selection: string;

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.desk_quiet_chk = true;
    } else {
      this.desk_quiet_chk = false;
    }
  }

  BookDesk() {
    this.showLoader = true;
    this.selectedDateModel;
    this.selectedDateDetails;

    //when in out time is blank at partil booking

    for (let i = 0; i < this.selectedDateDetails.length; i++) {
      if ((this.selectedDateDetails[i].IsFullDay == undefined || this.selectedDateDetails[i].IsFullDay == false) && this.selectedDateDetails[i].BookingEndTime == undefined) {
        this.selectedDateDetails[i].BookingEndTime = this.booking_end_time;
      }
      if ((this.selectedDateDetails[i].IsFullDay == undefined || this.selectedDateDetails[i].IsFullDay == false) && this.selectedDateDetails[i].BookingStartTime == undefined) {
        this.selectedDateDetails[i].BookingStartTime = this.booking_start_time;
      }
      this.Date = new Date()
      this.currentTime = this.datepipe.transform(this.Date, 'HH:mm');
      if (this.selectedDateDetails[i].BookedDate == this.datepipe.transform(localStorage.getItem('localdate'), 'yyyy-MM-dd') && this.selectedDateDetails[i].BookingStartTime < this.currentTime) {
        this.showErrorMsgPopUp = true;
        this.translate.get('OldBookingTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('OldBookingMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        this.BookAllowed = false;
        this.showLoader = false;
      }
    }
    //
    if (this.BookAllowed) {
      if ((!this.bookMeeting && this.selectedDateModel.length == this.selectedDateDetails.filter(d => d.DeskID != undefined).length) || this.bookMeeting) {
        if (this.bookMeeting && localStorage.getItem('bookingFor') == 'self' && this.selectedDateDetails.findIndex(x => x.AttendeeName.toLowerCase() === localStorage.getItem('CurrentUserEmail').toLowerCase()) == -1) {
          this.translate.get('SelfMeetingDesk').subscribe((data: string) => { alert(data); })
          this.showLoader = false;
        }
        else if (this.bookMeeting && localStorage.getItem('bookingFor') != 'self' && this.selectedDateDetails.findIndex(x => x.AttendeeName.toLowerCase() === localStorage.getItem('selectedOtherUserEmail').toLowerCase()) == -1) {
          this.translate.get('OtherMeetingDesk').subscribe((data: string) => { alert(data); })
          this.showLoader = false;
        }
        else if (this.bookMeeting && (this.checkSameAttendee())) {
          this.translate.get('SameAttendee').subscribe((data: string) => { alert(data); })
          this.showLoader = false;
        }
        else {
          this.selectedDateDetails = this.selectedDateDetails.filter(d => d.AttendeeName != '')
          //this.selectedDateDetails=this.selectedDateDetails.concat(this.selectedOnlineAttendees);
          var res_details = {
            // DeskID: this.selected_desk_ID,//selected_desk,
            CreatedDate: new Date(),
            CreatedBy: localStorage.getItem('CurrentUserEmail'),
            ReservedFor: (this.bookMeeting && localStorage.getItem('bookingFor') == 'others') ? localStorage.getItem('CurrentUserEmail') : localStorage.getItem('bookingFor') == 'self' ? localStorage.getItem('CurrentUserEmail') : (this.selectFromParking ? localStorage.getItem('selectedOtherUserEmailParking') : localStorage.getItem('selectedOtherUserEmail')),
            DelegatedBy: (this.bookMeeting && localStorage.getItem('bookingFor') == 'others') ? localStorage.getItem('selectedOtherUserEmail') : '',
            // BookedDate: this.dataService.getSelectedDateToBook(),
            Purpose: this.selectedPurpose,
            Status: 1,
            //To send code for Outlook. Will split in the backend
            Comments: 'NA?' + localStorage.getItem("AccessToken"),
            // ZoneId: parseInt((localStorage.getItem('ZoneId') == "undefined") ? '0' : localStorage.getItem('ZoneId')),
            ZoneId: this.getCorrectID('ZoneId'),
            FloorId: this.getCorrectID('FloorID'),
            OfficeId: parseInt(localStorage.getItem('OfficeID')),
            Country: localStorage.getItem('SelectedCountry'), //this.dataService.getSelectedCountry(),
            DeskDetails: this.selectedDateDetails,
            OnlineDetails: this.selectedOnlineAttendees,
            CommLang: localStorage.getItem('CommsLang'),
            DateFormat: localStorage.getItem('DateFormat'),
            Buinfo: this.getCorrectID('BUId'),
            Reservation_Type: (this.bookMeeting) ? "Meeting" : "Desk",
            TimeZone: '',
            ZoneSelected: '',
            isForGym: false
          };
          if (res_details.Reservation_Type == "Meeting" && res_details.DeskDetails[0].IsFullDay) {
            res_details.TimeZone = res_details.CreatedDate.toString().split('GMT')[1].split(' ')[0];
            res_details.ZoneSelected = res_details.CreatedDate.toString().split('GMT')[1].split(' ')[0];
          }
          else {
            res_details.TimeZone = this.TimeZoneSelected;
            res_details.ZoneSelected = this.ZoneSelected;
          }
          localStorage.setItem('ParkingBookingFor', res_details.ReservedFor);
          res_details.ReservedFor = this.ServiceService.encrypt(res_details.ReservedFor);
          if (res_details.DelegatedBy != '') {
            res_details.DelegatedBy = this.ServiceService.encrypt(res_details.DelegatedBy)
          }
          else {
            res_details.DelegatedBy = '';
          }
          this.DeskReservationsToBook = res_details;

          // this.hasParking=localStorage.getItem('NotShowParkingPopup')==="0"?false:true;
          this.hasParking = this.hasParking === false ? false : (localStorage.getItem('NotShowParkingPopup') == '0' ? false : true);
          localStorage.setItem('NotShowParkingPopup', "1");
          this.ServiceService.postReservation(this.DeskReservationsToBook).subscribe((data: any) => {
            // this.errorCode=data;

            if (data == 100) {
              this.showErrorMsgPopUp = true;
              this.translate.get('BookingRestrictedTitle').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('BookingRestrictedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 300 && !this.bookMeeting) {
              this.showAlreadyMsgPopUp = true;
              this.translate.get('DeskBookingError').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('DeskAlreadyBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 300 && this.bookMeeting) {
              this.showAlreadyMsgPopUp = true;
              this.translate.get('MeetingBookingError').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('MeetingAlreadyBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 400) {

              this.showAlreadyMsgPopUp = true;
              this.translate.get('BookingAlreadyExisMsg').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('SelectAnotherdateMsg').subscribe((data: string) => { this.PopUpMessage = data; });

            }
            else if (data == 500) {
              this.showErrorMsgPopUp = true;
              this.translate.get('BookingAlreadyExistSameSlotTitle').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('BookingAlreadyExistSameSlotMsg').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 600) {
              this.showErrorMsgPopUp = true;
              this.translate.get('MultiBkngWarning').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('BookingAlreadyExistFullDayMsg').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 700) {
              this.showErrorMsgPopUp = true;
              this.translate.get('InTime').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('ChoosetheStartTime').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 800) {
              this.showErrorMsgPopUp = true;
              this.translate.get('OutTime').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('ChoosetheEndTime').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 900) {
              this.showErrorMsgPopUp = true;
              this.translate.get('PartialBooking').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('PartialNotFullDayBooking').subscribe((data: string) => { this.PopUpMessage = data; });
            }
            else if (data == 200 || data.value.statuscode == 305) {
              this.showMsgPopUp = true;
              this.translate.get('DeskBooked').subscribe((data: string) => { this.msgTitle = data; });

              if (this.hasParking && !this.bookMeeting) {
                if (localStorage.getItem('CurrentBookingType') == 'multiple' && localStorage.getItem('IsMultipleDayParking') == 'false') {
                  this.showPopupCancelBtn = false;
                  if (data == 200) {
                    this.translate.get('DeskBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
                  }
                  else if (data.value.statuscode == 305) {
                    this.translate.get('UnFinishedDeskBookedMsg').subscribe((data1: string) => { this.PopUpMessage = data.value.dates + data1; });
                  }
                  this.redirectTo = '/reservation';
                }
                else {
                  if (localStorage.getItem('CurrentBookingType') == 'single') {

                    this.currentDate = new Date();
                    this.currentDate = this.currentDate.setDate(this.currentDate.getDate() + parseInt(localStorage.getItem('ParkingDurationStart')));

                    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
                    var selectedDate = this.datepipe.transform(this.selectedDateDetails[0].BookedDate, 'yyyy-MM-dd');
                    this.maxDate = this.datepipe.transform(this.CalculateDateOfficeSelection(parseInt(localStorage.getItem('ParkingDurationEnd'))), 'yyyy-MM-dd');

                    if (selectedDate >= this.currentDate && selectedDate <= this.maxDate) {
                      this.showPopupCancelBtn = true;
                      this.dataService.setSelectedDateList(this.selectedDateDetails);
                      this.redirectTo = '/reserveparking';
                      localStorage.setItem('RedirectFromSelectDesk', localStorage.getItem('OfficeID'));
                      this.translate.get('DeskBookedNoParking').subscribe((data: string) => { this.cancelBtnText = data; });
                      this.translate.get('BookParking').subscribe((data: string) => { this.okBtnText = data; });
                      if (data == 200) {
                        this.translate.get('DeskBookedWithParkingMsg').subscribe((data: string) => { this.PopUpMessage = data; });
                      }
                      else if (data.value.statuscode == 305) {
                        this.translate.get('UnFinishedDeskBookedWithParkingMsg').subscribe((data1: string) => { this.PopUpMessage = data.value.dates + data1; });
                      }
                    }
                    else {
                      this.showPopupCancelBtn = false;
                      if (data == 200) {
                        this.translate.get('DeskBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
                      }
                      else if (data.value.statuscode == 305) {
                        this.translate.get('UnFinishedDeskBookedMsg').subscribe((data1: string) => { this.PopUpMessage = data.value.dates + data1; });
                      }
                      this.redirectTo = '/reservation';
                    }
                  }
                  else {
                    this.showPopupCancelBtn = true;
                    this.dataService.setSelectedDateList(this.selectedDateDetails);
                    this.redirectTo = '/reserveparking';

                    localStorage.setItem('RedirectFromSelectDesk', localStorage.getItem('OfficeID'));

                    this.translate.get('DeskBookedNoParking').subscribe((data: string) => { this.cancelBtnText = data; });
                    this.translate.get('BookParking').subscribe((data: string) => { this.okBtnText = data; });
                    if (data == 200) {
                      this.translate.get('DeskBookedWithParkingMsg').subscribe((data: string) => { this.PopUpMessage = data; });
                    }
                    else if (data.value.statuscode == 305) {
                      this.translate.get('UnFinishedDeskBookedWithParkingMsg').subscribe((data1: string) => { this.PopUpMessage = data.value.dates + data1; });
                    }
                  }
                }
              }
              else {
                this.showPopupCancelBtn = false;
                if (data == 200) {
                  this.translate.get('DeskBookedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
                }
                else if (data.value.statuscode == 305) {
                  this.translate.get('UnFinishedDeskBookedMsg').subscribe((data1: string) => { this.PopUpMessage = data.value.dates + data1; });
                }
                this.redirectTo = '/reservation';
                //this.dataService.setBookedDeskDetails(undefined);
              }
            }
            this.showLoader = false;
            // this.router.navigateByUrl('/reservation');
          }, error => {
            this.showLoader = false;
            this.showMsgPopUp = true;
            this.translate.get('DeskBookedErr').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
          });
        }
      }
      else {
        this.showLoader = false;
        this.showErrorMsgPopUp = true;
        this.translate.get('SeatValidation').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('PickSeats').subscribe((data: string) => { this.PopUpMessage = data; });
      }
    }
  }
  selectPurpose(option) {
    this.selectedPurpose = option.target.value;
    this.validateButton = this.ButtonValidation();
  }
  CalculateDateOfficeSelection(day) {
    var date = new Date();
    date.setDate(date.getDate() + parseInt(day));
    return date;
  }

  getCorrectID(paramName: string) {
    let correctID = '0';
    if (localStorage.getItem(paramName) != "undefined") {
      if (localStorage.getItem(paramName) != "null") {
        if (localStorage.getItem(paramName) != null) {
          correctID = localStorage.getItem(paramName);
        }
      }
    }
    return parseInt(correctID);
  }

  goToPrevious() {
    this.dataService.setSelectedDeskNumber(undefined);
    this.router.navigateByUrl('/booking');
  }
  changeIsFullDay(event) {
    this.isFullDay = (event == 'fullDay') ? true : false;
    if (this.isFullDay == true) {
      this.ngzone.run(() => { this.booking_end_time = undefined; this.booking_start_time = undefined });
    }
    if (this.selectedDateDetails.length > 0) {
      var objIndex = this.selectedDateDetails.findIndex((obj => obj.BookedDate == this.today));
      //  if (this.selectedDateDetails[objIndex].IsFullDay == undefined || this.selectedDateDetails[objIndex].IsFullDay == false) {
      //     this.selectedDateDetails[objIndex].BookingEndTime = this.booking_end_time;
      this.selectedDateDetails[objIndex].IsFullDay = false;
    }
    this.validateButton = this.ButtonValidation();
  }
  changeBooking(booking) {
    this.bookMeeting = (booking == 'Meeting') ? true : false;
    if (this.bookMeeting == true) {
      localStorage.setItem("View", "Meeting");
    }
    else {
      localStorage.setItem("View", "Desk");
    }
    this.selectedDateDetails = [];
    this.isFullDay = true;
    this.desk_quiet_chk = false;
    this.desk_sitstand_chk = false;
    this.desk_assigned_chk = false;
    this.desk_dualmon_chk = false;
    this.desk_touchdown_chk = false;
  }
  changeMeeting(onlineoffline) {
    this.isHybrid = (onlineoffline == 'Hybrid') ? true : false;
  }
  changeEndTime(event) {
    this.booking_end_time = event.target.value;
    if (this.booking_start_time > this.booking_end_time) {
      this.showErrorMsgPopUp = true;
      this.translate.get('BookingTimeRestrictedTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('BookingTimeRestrictedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
    }
    if (this.selectedDateDetails.length > 0) {
      if (!this.bookMeeting) {
        var objIndex = this.selectedDateDetails.findIndex((obj => obj.BookedDate == this.today));
        if (this.selectedDateDetails[objIndex].IsFullDay == undefined || this.selectedDateDetails[objIndex].IsFullDay == false) {
          this.selectedDateDetails[objIndex].BookingEndTime = this.booking_end_time;
          this.selectedDateDetails[objIndex].IsFullDay = false;
        }
      }
      else {
        this.selectedDateDetails.forEach(element => {
          element.IsFullDay = false;
          element.bookingEndTime = this.booking_end_time;
          element.bookingStartTime = this.booking_start_time;
        })
      }
    }
    this.validateButton = this.ButtonValidation();
  }
  changeStartTime(event) {
    this.booking_start_time = event.target.value;

    this.ngzone.run(() => { this.EndTimeSlots = this.TimingSlots.filter(x => x.value > this.booking_start_time) });
    //this.booking_end_time = undefined;
    if (this.selectedDateDetails.length > 0) {
      if (!this.bookMeeting) {
        var objIndex = this.selectedDateDetails.findIndex((obj => obj.BookedDate == this.today));
        if (this.selectedDateDetails[objIndex].IsFullDay == undefined || this.selectedDateDetails[objIndex].IsFullDay == false) {
          //     this.Date = new Date()
          // this.currentTime = this.datepipe.transform(this.Date, 'HH:mm');
          // var todayDate = this.datepipe.transform(localStorage.getItem('localdate'), 'yyyy-MM-dd');
          // var selectedDate= this.datepipe.transform(this.today, 'yyyy-MM-dd');
          // if (selectedDate == todayDate) {
          //   this.ngzone.run(() => { this.TimingSlots = this.TimingSlotsNew.filter(x => x.value > this.currentTime) });
          //   this.booking_start_time=undefined;
          // }
          // else
          // {
          this.selectedDateDetails[objIndex].BookingStartTime = this.booking_start_time;
          this.selectedDateDetails[objIndex].IsFullDay = false;
          // }
        }
      }
      else {
        this.selectedDateDetails.forEach(element => {
          element.IsFullDay = false;
          element.bookingEndTime = this.booking_end_time;
          element.bookingStartTime = this.booking_start_time;
        })
      }
    }
    this.validateButton = this.ButtonValidation();
  }
  ButtonValidation() {
    if (this.bookMeeting) {
      return (this.selectedDateDetails.filter(d => d.DeskID != undefined).length > 0
        && this.selectedPurpose != undefined && this.Available && this.selectedPurpose != "" && ((this.isFullDay) ? true : (this.booking_start_time != undefined && this.booking_end_time != undefined) ? true : false)) ? true : false;
    }
    else {
      return (this.selectedDateModel.length == this.selectedDateDetails.filter(d => d.DeskID != undefined).length
        && this.selectedPurpose != undefined && this.selected_desk != undefined && ((this.isFullDay) ? true : (this.booking_start_time != undefined && this.booking_end_time != undefined) ? true : false)) ? true : false;
    }
  }
  ValidateDeskMultipleDays() {

    this.ngzone.run(() => { this.showLoader = true; });
    let details = {
      DeskId: this.selected_desk_ID,
      SelectedDates: this.selectedDateModel,
      IsFullDay: this.isFullDay,
      StartTime: this.booking_start_time,
      EndTime: this.booking_end_time
    };

    this.ServiceService.CheckSameDeskMultipleDays(details).subscribe((data: any[]) => {

      let _multiDayAutoDatesSelected = 0;
      let _multiDayAutoDates = [];
      let _multiDayAutoDatesNotSelected = 0;
      this._selectedDateDetails = [];
      data.forEach(element => {
        let _selDeskNumber: string;
        let _selDeskID: number;
        if (element.isAvailable == true) {
          _multiDayAutoDatesSelected = _multiDayAutoDatesSelected + 1;
          _multiDayAutoDates.push(element.selectedDate);
          _selDeskID = this.selected_desk_ID;
          _selDeskNumber = this.selected_desk;
        } else {
          _multiDayAutoDatesNotSelected = _multiDayAutoDatesNotSelected + 1;
          _selDeskID = undefined;
          _selDeskNumber = undefined;
        }

        var deskdetails = {
          DeskID: _selDeskID,
          BookedDate: element.selectedDate,
          DeskNumber: _selDeskNumber,
          BookingStartTime: this.booking_start_time,
          BookingEndTime: this.booking_end_time,
          IsFullDay: this.isFullDay,
        };
        this._selectedDateDetails.push(deskdetails);
      });

      let _msgBodyTitle: string;
      let _msgBody: string;
      if (_multiDayAutoDatesSelected == data.length) {
        _msgBodyTitle = 'MultiDayAutoAllDatesAvailable';
        _msgBody = '';
      } else {
        _msgBodyTitle = 'MultiDayAutoPartialDatesAvailable';
        _msgBody = "\n" + _multiDayAutoDates.join(", ");
      }

      this.ngzone.run(() => {
        this.showLoader = false;
        this.translate.get("MultiDayAutoSelectedDeskTitle").subscribe((data: string) => { this.msgTitle = (data + this.selected_desk); });
        this.translate.get(_msgBodyTitle).subscribe((data: string) => { this.msgBody = (data + _msgBody); });
        this.showMulitdayMsgPopUp = true;
      });
    }, error => {
      alert("SOMETHING WENT WRONG!!!")
    })
  }
  multiBookConfirmation() {
    this.selectedDateDetails = this._selectedDateDetails;
    this.validateButton = this.ButtonValidation();
    this.showMulitdayMsgPopUp = false;
  }
  noMultiBookConfirmation() {
    this.ValidateDeskSingleDay("");
    this.showMulitdayMsgPopUp = false;
  }
  LoadPurposesBasedonLang() {
    var getPurposesDTO = {
      officeId: parseInt(localStorage.getItem('OfficeID')),
      Lang: localStorage.getItem('locale')
    };
    this.ServiceService.getSelectingSeatPurposes(getPurposesDTO).subscribe((data: any[]) => {
      if (this.BookingPurposes != undefined)
        this.ngzone.run(() => { data = data.filter(x => x.forGym == false); this.BookingPurposes = data; });
    });
  }
  EnableAdminButton() {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if (userRole != 'user') {
      this.AllowAdmin = true;
      this.AdminUserRole = userRole;
    }
  }
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    if (this.router.url == '/booking') {
      let bookingMethod = new OfficeSelectorComponent(this.ServiceService, this.ngzone, this.router, this.translate);
      bookingMethod.LoadOfficesBasedonLang();
    }
    else if (this.router.url == '/deskConfiguration') {
      let bookingMethod = new OfficeSelectorComponent(this.ServiceService, this.ngzone, this.router, this.translate);
      bookingMethod.LoadOfficesBasedonLang();
    }
    else if (this.router.url == '/selectseat') {
      this.LoadPurposesBasedonLang();
      this.getOfficeDeskTypeList();
    }
  }
  logout() {

    this.ServiceService.logout().subscribe((data: any[]) => {
      window.location.reload();
      var a = localStorage.myusername;
      var b = localStorage.mypassword;
      localStorage.clear();
      localStorage.setItem('myusername', a);
      localStorage.setItem('mypassword', b);
    }, error => {

    })


    window.location.reload();
    var a = localStorage.myusername;
    var b = localStorage.mypassword;
    localStorage.clear();
    localStorage.setItem('myusername', a);
    localStorage.setItem('mypassword', b);
  }
  showMobilePopup() {
    this.dataService.setShowMobilePopup(true);
    document.getElementById('leftNavigation').classList.add('addshowmobilemenu');
  }
  search(text, deskNumber) {
    const index = this.selectedDateDetails.findIndex(x => x.DeskNumber === deskNumber);
    if (index != -1)
      this.selectedDateDetails[index].AttendeeName = text.value;
    if (text.value.length > 3) {
      this.showLoader = true;
      this.ServiceService.getUserDetails(text.value).subscribe((data: any) => {
        this.showLoader = false;
        this.AttendeeDetails = data.filter(x => x.mail != null);
      })
    }
  }

  addOnlineAttendees(onlineAttendeeText) {
    var index = this.selectedOnlineAttendees.findIndex(x => x.AttendeeName.toLowerCase() == onlineAttendeeText.value.toLowerCase())
    if (onlineAttendeeText.value != "" && index == -1) {
      this.givenText = '';
      var deskdetails = {
        BookedDate: this.today,
        MeetingName: this.selectedDateDetails[0].MeetingName,
        AttendeeName: onlineAttendeeText.value,
        BookingStartTime: this.booking_start_time,
        BookingEndTime: this.booking_end_time
      }
      this.selectedOnlineAttendees.push(deskdetails);

    }
    else
      this.givenText = '';
  }

  removeAttendee(remove) {
    this.selectedOnlineAttendees = this.selectedOnlineAttendees.filter(x => x.AttendeeName != remove);
  }

  checkSameAttendee() {
    this.duplicateValues = false;
    this.selectedDateDetails.filter(x => x.AttendeeName != '').forEach(attendee => {
      if (!this.duplicateValues) {
        var count = this.selectedDateDetails.filter(x => x.AttendeeName.toLowerCase() == attendee.AttendeeName.toLowerCase()).concat(this.selectedOnlineAttendees.filter(x => x.AttendeeName.toLowerCase() == attendee.AttendeeName.toLowerCase()));
        if (count.length > 1) {
          this.duplicateValues = true;
        }
        else {
          this.duplicateValues = false;
        }
      }
    });
    return this.duplicateValues;
  }


  //------- DeskType Implement 11Aug2022 --------
  getOfficeDeskTypeList() {
    localStorage.setItem('SelectedDeskTypes', '');
    var _offID = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
    this.ServiceService.getDeskTypes(parseInt(_offID)).subscribe((data: any[]) => {
      this.showLoader = false;
      // this.OfficeDeskTypeList = data;
      data = data.filter(o => !o.translationKey.includes('GYM_'));
      this.OfficeDeskTypeList = data.filter(x => x.langKey == localStorage.getItem('locale'));
      //Add a new parameter isChecked in DeskType list
      this.OfficeDeskTypeList = this.OfficeDeskTypeList.map((obj) => {
        obj.isChecked = false;
        return obj;
      })
    });
    this.showLoader = false;
  }

  OnDeskTypeChange() {
    this.DeskTypescheckedIDs = [];
    this.OfficeDeskTypeList.forEach((value, index) => {
      if (value.isChecked) {
        this.DeskTypescheckedIDs.push(value.id);
      }
    });
    const selectedDeskType_ids = this.DeskTypescheckedIDs.toString();
    this.selectedDeskTypesIds = selectedDeskType_ids;
  }

  //Outlook Room Availability

  isAvailable(available) {
    if (available == "true") {
      this.Available = true;
      this.validateButton = this.ButtonValidation();
    }
    else {
      this.Available = false;
      this.validateButton = this.ButtonValidation();
    }
  }

  StatusValue(status) {
    this.showStatus = status;
  }
  BtnSeatUnavailablity(status) {
    this.showUnavailablity = status;
  }

  TimeZoneChange(event) {
    this.ZoneSelected = event.target.value;
    this.TimeZoneSelected = this.OutlookTimeZones.filter(x => x.text == this.ZoneSelected)[0].value;
  }

  ShowMeetingData(id) {
    this.meetingActive = id;
    if (this.meetingActive && localStorage.getItem('IsExternalUser') != 'true') {
      if (localStorage.getItem('CurrentBookingType') != "single") {
        this.showMeeting = false;
      }
      else {
        if (this.radioDesk == 'No') {
          this.deskDisabled = true;
        }
        if (localStorage.getItem("View") == "Meeting") {
          this.bookMeeting = true;
        }
        else {
          this.bookMeeting = false;
        }
        this.showMeeting = true;
      }
    }
  }
  UpdateAdmin() {
    var params = {
      EmployeeMail: localStorage.getItem('CurrentUserEmail'),
      Zoneid: this.getCorrectID('ZoneId'),
      Floorid: this.getCorrectID('FloorID'),
      Officeid: parseInt(localStorage.getItem('OfficeID')),
      BUid: this.getCorrectID('BUId'),
      SearchedDate: new Date(this.datepipe.transform(localStorage.getItem('SelectedDateToBook'), 'yyyy-MM-dd')),
      EmployeeNo: null
    };
    this.ServiceService.updateAdminSeatUnavailablity(params).subscribe((data: any[]) => {
      //Code to Popup
      this.showLoader = false;
      this.showMsgPopUp = true;
      // this.redirectTo = '/reservation';
      this.redirectTo = '/home';
      this.translate.get('UpdateAdmin').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('UpdateAdminMsg').subscribe((data: string) => { this.PopUpMessage = data; });
    });
  }

}
