import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../../app/services/data-share';
import { LogService } from '../services/log.service';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-global-errorscreen',
  templateUrl: './global-errorscreen.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class GlobalErrorscreenComponent implements OnInit {
  logoClass: string;
  constructor(public translate: TranslateService,public ServiceService: ServiceService) { 
    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(ServiceService.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {
    if (localStorage.getItem('locale') == 'en') {
      this.logoClass = 'fl logoSaveseat marTop0'
    }
    else {
      this.logoClass = 'fl logoSaveseatfr marTop0'
    }
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    if (language == 'en') {
      this.logoClass = 'fl logoSaveseat marTop0'
    } else {
      this.logoClass = 'fl logoSaveseatfr marTop0'
    }
  }

}
