import { Component, OnInit, ViewEncapsulation, EventEmitter, NgZone } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ParkingItemApproved } from '../Model/CarParkingDTO';

@Component({
  selector: 'app-admin-swap-parking',
  templateUrl: './admin-swap-parking.component.html',
  styleUrls: ['../../assets/css/style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminSwapParkingComponent implements OnInit {
  readonly bookingTypeMap = new Map<string, number>();
  offices: any;
  parkSpaces: ParkingItemApproved[];
  filteredParkSpaces: ParkingItemApproved[];
  parkingTypes: any[];
  selParkSpace: any;
  selectedOffice: any;
  selParkType: any;
  today: Date;
  minDate: string;
  maxDate: string;
  currentDate: any;
  showMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  validateButton: boolean = false;
  showLoader: boolean = false;
  licenseText: string = "";
  selSpaceId: number;
  selBkngType: number;
  selSpacePermitReq: boolean = false;
  isSpaceSelected: boolean = false;
  parkingReservedFor: any;
  isSouthAmerica: boolean;
  showMsgPopUpError: boolean;
  redirectTo: string = 'undefined';
  showPopupCancelBtn: boolean = false;
  cancelBtnText: string = "Cancel";
  okBtnText: string = "OK";
  public selectedDateModel = [];
  sendToBooking: boolean = true;
  SwapParkingData: any;
  RedirectFromAdminSwap: boolean = true;
  displayUserEMail:string;

  constructor(private ServiceService: ServiceService, private ngzone: NgZone, private router: Router
    , public translate: TranslateService, private titleService: Title,
    private dataService: userDataService,
    public datepipe: DatePipe) {

    this.bookingTypeMap.set('ALL', 1);
    this.bookingTypeMap.set('AM', 2);
    this.bookingTypeMap.set('PM', 3);
  }

  ngOnInit() {
    this.isSouthAmerica = localStorage.getItem('SelectedCountry') != "South America" ? false : true;
    this.showLoader = true;
    this.SwapParkingData = this.dataService.getParkingDetailsAdmin();
    if (localStorage.getItem('RedirctFromAdminSwap') == 'true') {
      localStorage.setItem('Date', this.SwapParkingData.bookingDate);
      localStorage.setItem('licenseText', this.SwapParkingData.licensePlate);
      localStorage.setItem('OfficeName', this.SwapParkingData.officeName);
      localStorage.setItem('ParkingID', this.SwapParkingData.reservedId);
      localStorage.setItem('ParkingMailID', this.SwapParkingData.reservedFor);
    }
    this.licenseText = localStorage.getItem('licenseText');    
    this.displayUserEMail=localStorage.getItem('ParkingMailID');



    this.dataService.setParkingDetailsAdmin(null);


    this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), true).subscribe((data: any[]) => {
      this.offices = data.filter(o => o.hasParking == true);

      var officeSelectForSwap = this.offices.filter(o => o.name == localStorage.getItem('OfficeName'));
      // this.selectedOffice = officeSelectForSwap[0].id;

      if (this.offices.length > 0 && (localStorage.getItem('OfficeID') != undefined)) {
        this.selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('SelectedOfficeIdParking');
        // let _officeDetail = this.offices.find(x => x.id == this.selectedOffice);
        this.selectedOffice = officeSelectForSwap[0].id;
        //var selectedOfficeIDForParking = parseInt(localStorage.getItem('RedirectFromSelectDesk'));
        // if (selectedOfficeIDForParking != 0) {
        //   this.offices = data.filter(o => o.id == selectedOfficeIDForParking);
        //   this.selectedOffice = localStorage.getItem('RedirectFromSelectDesk');
        //   localStorage.setItem('RedirectFromSelectDesk', "0");
        //   this.sendToBooking = false;
        // }

        this.officeLoader();
      }
    });

    this.currentDate = new Date()
    this.currentDate = this.datepipe.transform(localStorage.getItem('Date'), 'yyyy-MM-dd');
    //this.today = localStorage.getItem('SelectedDateToBook') == undefined ? this.currentDate : localStorage.getItem('SelectedDateToBook');
    this.today = this.currentDate;
    this.minDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.maxDate = this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');
    //this.titleService.setTitle("Make a Parking Reservation");
    // localStorage.setItem('PageName', 'Park');
    localStorage.setItem('RedirctFromAdminSwap', 'false');
  }

  parkSpacesLoader() {
    if (this.selectedOffice == undefined || this.selectedOffice == "undefined" || this.selectedOffice == "null") return;
    if (this.today.toString() != "") {
      // this.showLoader = true;
    }
    this.ServiceService.getParkingSpacesForSwap(this.selectedOffice, this.today, parseInt(localStorage.getItem('ParkingID'))).subscribe((data: any) => {
      let allSpaces = data.allSpaces;
      let reservedSpaces = data.reservedSpaces;

      this.parkSpaces = [];
      this.filteredParkSpaces = [];
      allSpaces.filter(x => x.langKey == localStorage.getItem('locale')).forEach(e => {
        let _space = {} as ParkingItemApproved;
        _space.id = e.id;
        _space.name = e.spaceNumber;
        _space.AMvalue = "AM_" + e.id;
        _space.PMvalue = "PM_" + e.id;
        _space.ALLvalue = "ALL_" + e.id;
        _space.type = e.spaceType;
        _space.typeId = e.spaceTypeId;
        _space.permitRequired = e.permitRequired;
        _space.location = e.location;

        var spaceBooked = reservedSpaces.filter(value => value.id === e.id);
        if (spaceBooked.length > 0) {
          let bkngType = spaceBooked[spaceBooked.length - 1].bookingType;
          let bkngStatus = spaceBooked[spaceBooked.length - 1].isBooked;
          let Approved = spaceBooked[spaceBooked.length - 1].approved;
          if (bkngStatus) {
            if (bkngType == 1) {
              _space.amBooked = false; _space.pmBooked = false; _space.allBooked = true;
              if (Approved == true) { _space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = true; }
            }
            if (bkngType == 2) {
              _space.amBooked = true; _space.pmBooked = false; _space.allBooked = false;
              if (Approved == true) {
                _space.amBookedApproved = true; _space.pmBookedApproved = false; _space.allBookedApproved = false;
              }
              _space.reservedFor = spaceBooked[spaceBooked.length - 1].reservedFor;
            }
            if (bkngType == 3) {
              _space.amBooked = false; _space.pmBooked = true; _space.allBooked = false;
              if (Approved == true) {
                _space.amBookedApproved = false; _space.pmBookedApproved = true; _space.allBookedApproved = false;
              }
              _space.reservedFor1 = spaceBooked[spaceBooked.length - 1].reservedFor;
            }
            _space.reservedFor = spaceBooked[spaceBooked.length - 1].reservedFor;
          }
          else {
            _space.amBooked = false; _space.pmBooked = false; _space.allBooked = false;
            //_space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = false;
          }
          if (spaceBooked.length > 4) {
            let bkngType = spaceBooked[spaceBooked.length - 2].bookingType;
            let bkngStatus = spaceBooked[spaceBooked.length - 2].isBooked;
            let Approved = spaceBooked[spaceBooked.length - 2].approved;
            if (bkngStatus) {
              if (bkngType == 2) {
                _space.amBooked = true; _space.allBooked = false;
                if (Approved == true) {
                  _space.amBookedApproved = true; _space.allBookedApproved = false;
                }
                _space.reservedFor = spaceBooked[spaceBooked.length - 2].reservedFor;
              }
              if (bkngType == 3) {
                _space.pmBooked = true; _space.allBooked = false;
                if (Approved == true) {
                  _space.pmBookedApproved = true; _space.allBookedApproved = false;
                }
                _space.reservedFor1 = spaceBooked[spaceBooked.length - 2].reservedFor;
              }
            }
          }
        } else {
          _space.amBooked = false; _space.pmBooked = false; _space.allBooked = false;
          _space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = false;
        }
        this.parkSpaces.push(_space);
        this.filteredParkSpaces.push(_space);
      });

      this.showLoader = false;
      this.validateButton = false;
    });
  }

  officeLoader() {
    this.selParkType = undefined;
    if (this.selectedOffice == undefined) return;
    this.parkSpacesLoader();
    this.ServiceService.getParkingTypes(this.selectedOffice).subscribe((data: any[]) => {
      this.parkingTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));;
    })
  }

  LoadSpacedBasedonLang() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  changeOffice(e) {
    var _offID = e.target.value;
    localStorage.setItem('SelectedOfficeIdParking', this.selectedOffice);
    this.officeLoader();
  }

  changeParkType(e) {
    this.filteredParkSpaces = this.parkSpaces.filter(v => v.typeId == e.target.value);
    //this.validateButton = false;
  }

  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }

  changeDate() {
    this.selParkType = undefined;
    this.parkSpacesLoader();

    var res_details = {
      SpaceId: -1,
      DeskReservationId: 0,
      ReservedFor: localStorage.getItem('ParkingMailID'),
      ReservedDate: this.today,
      CreatedBy: localStorage.getItem('CurrentUserEmail'),
      CreatedDate: new Date(),
      TypeId: -1,
      LicenseDetail: ''
    };

    this.ServiceService.CheckUserCarParking(res_details).subscribe((data: any) => {
      if (data == true) {
        this.showMsgPopUp = true;
        this.translate.get('MultiBkngWarning').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('UserAlreadyBkdSpaceTitle').subscribe((data: string) => { this.PopUpMessage = data; });
      }
    }, error => { })
  }

  changeParkSpace(item, timeSlot: string) {
    this.selSpaceId = item.id;
    this.selSpacePermitReq = item.permitRequired;
    this.selBkngType = this.bookingTypeMap.get(timeSlot);
    this.isSpaceSelected = true;
    this.validateBtnCheck();
  }

  validateBtnCheck() {
    if (this.licenseText != "" && this.isSpaceSelected == true) {
      this.validateButton = true;
    } else {
      this.validateButton = false;
    }
  }

  search() {
    this.validateBtnCheck();
  }

  BookParking() {

    if (this.selSpacePermitReq) {
      if (localStorage.getItem('ParkingMailID') != null && localStorage.getItem('ParkingMailID') != "" && localStorage.getItem('ParkingMailID') != undefined) {
        this.parkingReservedFor = localStorage.getItem('ParkingMailID');
      }
      else {
        this.parkingReservedFor = localStorage.getItem('ParkingMailID');
      }
      var user_details = {
        OfficeId: Number(this.selectedOffice),
        ReservedFor: this.ServiceService.encrypt(this.parkingReservedFor)
      }
      this.ServiceService.CheckUserPermit(user_details).subscribe((data: any) => {
        if (data == false) {
          this.showMsgPopUp = true;
          this.translate.get('PermitReqTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('PermitReqMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          return;
        } else {
          this.BookParking2();
        }
      });
    } else {
      this.BookParking2();
    }
  }

  BookParking2() {
    this.showLoader = true;
    if (localStorage.getItem('ParkingMailID') != null && localStorage.getItem('ParkingMailID') != "null" && localStorage.getItem('ParkingMailID') != "") {
      this.parkingReservedFor = localStorage.getItem('ParkingMailID');
    }
    else {
      this.parkingReservedFor = localStorage.getItem('ParkingMailID');
    }
    var res_details = {
      SpaceId: this.selSpaceId,
      DeskReservationId: parseInt(localStorage.getItem('ParkingID')),
      ReservedFor: this.ServiceService.encrypt(this.parkingReservedFor == null ? localStorage.getItem('ParkingMailID') : this.parkingReservedFor),
      ReservedDate: this.today,
      // CreatedBy: localStorage.getItem('CurrentUserEmail'),
      CreatedBy: localStorage.getItem('ParkingMailID'),
      CreatedDate: new Date(),
      TypeId: this.selBkngType,
      LicenseDetail: this.licenseText
    };

    this.ServiceService.swapParkingByAdmin(res_details).subscribe((data: any) => {

      //this.showMsgPopUp = true;
      if (data == 100) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('ParkRestrictTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('ParkRestrictBody').subscribe((data: string) => { this.PopUpMessage = data; });
        // this.errorCode = '';
      }
      else if (data == 200 || data == 402) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('SpaceSwapTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SpaceSwapBody').subscribe((data: string) => { this.PopUpMessage = data; }); 
      }
      else if (data == 301) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('MultiBkngWarning').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('UserAlreadyBkdSpaceTitle').subscribe((data: string) => { this.PopUpMessage = data; });        
      }
      else if (data == 302) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('SpaceAlreadyBookedMsg').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SpaceAlreadyBookedTitle').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      else if (data == 303) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('BookingTimeRestrictedTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('BookingAlreadyExistSameSlotTitle').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      else if (data == 304) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('ElectricFullDayErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('ElectricFullDayErrorBody').subscribe((data: string) => { this.PopUpMessage = data; });        
      }
      else if (data == 305 || data == 405) {        
        
            this.showLoader = false;
            this.showMsgPopUpError = true;
            this.translate.get('SpaceSwapTitle').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('SpaceSwapBody').subscribe((data: string) => { this.PopUpMessage = data; });
            //this.redirecToSwap = '/listParkings';
          
      }
      else if (data == 400) {
        this.showLoader = false;
        this.showMsgPopUpError = true;
        this.translate.get('SpaceBkngErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('SpaceBkngErrorBody').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      this.showLoader = false;
    }, error => {
      this.showMsgPopUpError = true;
      this.showLoader = false;
      this.translate.get('SpaceBkngErrorTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('SpaceBkngError').subscribe((data: string) => { this.PopUpMessage = data; });
    })
  }

}