import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ComplianceTrackingDTO } from '../Model/ComplianceTrackingDTO';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-compliance-tracking',
  templateUrl: './compliance-tracking.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class ComplianceTrackingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private ServiceService: ServiceService, private router: Router
    , public translate: TranslateService, public datepipe: DatePipe) { }
  offices: any;
  formDetails: FormData;
  showLoader = false;
  PopUpMessage: string;
  msgTitle: string;
  showMsgPopUp: boolean = false;
  showErrPopUp: boolean = false;
  dataSource = new MatTableDataSource<ComplianceTrackingDTO>();
  ComplianceList: ComplianceTrackingDTO[];
  displayedColumns: string[] = ['id', 'officeName', 'noOfUsers', 'timeStamp', 'comments', 'dataSource', 'Edit', 'UpdateDesk'];//, 'UpdateDesk'
  Areyousuretoremove: string;
  edit: boolean = false;

  ComplianceTracking = new FormGroup({
    Office: new FormControl('ChooseyourLocation', Validators.required),
    DataSource: new FormControl('Manual Count', Validators.required),
    Users: new FormControl('', Validators.required),
    Comments: new FormControl(''),
    Date: new FormControl(this.datepipe.transform(new Date, 'yyyy-MM-dd'))
  });
  FilterOffice = new FormGroup({
    Office: new FormControl('ChooseyourLocation')
  });
  get Office() { return this.ComplianceTracking.get('Office'); }
  get DataSource() { return this.ComplianceTracking.get('DataSource'); }
  get Users() { return this.ComplianceTracking.get('Users'); }
  get Comments() { return this.ComplianceTracking.get('Comments'); }
  get Date() { return this.ComplianceTracking.get('Date'); }
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    // get list of offcies for country
    let checkAdminOfficeIds: boolean = (this.router.url == '/deskConfiguration' || this.router.url == '/complianceTracking' ? true : false);
    this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), checkAdminOfficeIds).subscribe((data: any[]) => {
      this.offices = data;
      if (data.length > 0) {
        this.ServiceService.getAllCompliance(this.FilterOffice.get('Office').value).subscribe((data: ComplianceTrackingDTO[]) => {
          this.ComplianceList = data;
          this.dataSource.data = this.ComplianceList;
          this.showLoader = false;
        }, err => {
          this.showLoader = false;
        });
      }
    })
  }

  submit() {
    if (this.ComplianceTracking.status === 'VALID' && this.ComplianceTracking.get('Office').value != 'ChooseyourLocation') {
      const formData = new FormData();
      formData.append('Office', (this.ComplianceTracking.value.Office).toString());
      formData.append('DataSource', (this.ComplianceTracking.value.DataSource).toString());
      formData.append('Users', (this.ComplianceTracking.value.Users).toString());
      formData.append('Comments', (this.ComplianceTracking.value.Comments).toString());
      formData.append('Date', (this.ComplianceTracking.value.Date).toString());
      this.formDetails = formData;

      this.ServiceService.addComplianceTracking(this.formDetails).subscribe((result: any) => {

        if (result == 200) {
          this.showMsgPopUp = true;
          this.translate.get('DataSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('DataSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          this.showLoader = false;
        }
        else {
          this.showMsgPopUp = true;
          this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
          this.showLoader = false;
        }
      })

    }
    else {
      this.showErrPopUp = true;
      this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('Mandetory').subscribe((data: string) => { this.PopUpMessage = data; });
    }
  }
  DeleteRecord(selectedUser) {
    this.translate.get('DeskTypeAreYouSureWantToDelete').subscribe((data: string) => { this.Areyousuretoremove = data; });
    if (confirm(this.Areyousuretoremove)) {
      this.showLoader = true;
      this.ServiceService.deleteTracking(selectedUser.id).subscribe((result: any) => {
        if (result == 200) {
          this.showLoader = false;
          this.showMsgPopUp = true;
          this.translate.get('TrackingDeletedSuccessfully').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('TrackingDeletedSuccessfully').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else {
          this.showLoader = false;
          this.showMsgPopUp = true;
          this.translate.get('TrackingDeletionFailed').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('TrackingDeletionFailed').subscribe((data: string) => { this.PopUpMessage = selectedUser.userId + data; });
        }
      }, err => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('TrackingDeletionFailed').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TrackingDeletionFailed').subscribe((data: string) => { this.PopUpMessage = data; });
      });
    }
  }

  GetRecordToUpdate(e) {
    this.edit = true;
    this.ComplianceTracking.get('Office').setValue(e.officeName);
    this.ComplianceTracking.get('DataSource').setValue(e.dataSource);
    this.ComplianceTracking.get('Users').setValue(e.noOfUsers);
    this.ComplianceTracking.get('Comments').setValue(e.comments);
    this.ComplianceTracking.get('Date').setValue(this.datepipe.transform(e.timeStamp, 'yyyy-MM-dd'))
  }
  changeOffice(e) {
    this.ServiceService.getAllCompliance(this.FilterOffice.get('Office').value).subscribe((data: ComplianceTrackingDTO[]) => {
      this.ComplianceList = data;
      this.dataSource.data = this.ComplianceList;
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });
  }
}
