import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ExternalUserCredentialsDTO } from '../Model/ExternalUserCredentialsDTO';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-parking-permit',
  templateUrl: './parking-permit.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class ParkingPermitComponent implements OnInit {

  versionTitle: string;
  constructor(private service: ServiceService, public translate: TranslateService) {
    let _langs = [];
    service.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(service.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }
  showLoader: boolean = false;
  data: any;
  givenText: string;
  offices: any;
  showMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  dataSource: ExternalUserCredentialsDTO[];
  displayedColumns: string[] = ['emailAddress', 'fullName', 'allowedOffice', 'UpdateDesk'];//, 'UpdateDesk'
  ErrorMsg: string;
  FormisnotValid: string;
  AreyousuretoremovetheUser: string;
  selectedOffice: any;
  UserDetails: any;
  displayUserName: string;
  displayUserEMail: string;
  displayPersonalDetails: boolean = false;

  ngOnInit() {
    this.service.getOffices(localStorage.getItem('SelectedCountry'), true).subscribe((data: any[]) => {
      this.offices = data.filter(o => o.hasParking == true);

      if (this.offices.length > 0 && (localStorage.getItem('OfficeID') != undefined)) {
        this.selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
        this.GetAllPermitHolders();
      }
    });
  }

  GetAllPermitHolders() {
    if (this.selectedOffice == undefined) return;
    this.showLoader = true;
    this.service.getParkingPermits(this.selectedOffice).subscribe((result: any) => {
      this.dataSource = result;
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });
  }

  changeOffice(e) {
    var _offID = e.target.value;
    localStorage.setItem('OfficeID', _offID);
    this.GetAllPermitHolders();
  }

  submit() {
    if (this.selectedOffice != undefined && this.displayPersonalDetails == true) {
      this.showLoader = true;
      var userData = {
        PermitId: -1,
        ReservedFor: this.displayUserEMail,
        CreatedBy: localStorage.getItem('CurrentUserEmail'),
        OfficeId: Number(this.selectedOffice)
      }

      this.service.PostUserPermit(userData).subscribe((result: any) => {
        this.showMsgPopUp = true;
        if (result == 301) {
          this.translate.get('ExternalUserExistTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ExternalUserExistMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else {
          this.translate.get('ExternalUserSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ExternalUserSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
      })
    }
    else {
      this.translate.get('FormisnotValid').subscribe((data: string) => { this.FormisnotValid = data; });
      alert(this.FormisnotValid);
    }
  }

  DeleteRecord(selectedUser) {
    let _confirmTitle: string;
    let _successMsgTitle : string
    let _successMsgBody : string
    let _errorMsgTitle : string
    let _errorMsgBody : string
    if (selectedUser.isValid == true) {
      _confirmTitle = "AreyousuretoremovetheUser"
      _successMsgTitle = "UserDeletedSuccessfully"
      _successMsgBody = "UserDeletedSuccessfully"
      _errorMsgTitle = "UserDeletionFailed"
      _errorMsgBody = "UserDeletionFailed"
    } else {
      _confirmTitle = "AreyousuretoaddtheUser"
      _successMsgTitle = "ExternalUserSavedTitle"
      _successMsgBody = "ExternalUserSavedMsg"
      _errorMsgTitle = "SaveDetailsErrTitle"
      _errorMsgBody = "TechErr"
    }

    this.translate.get(_confirmTitle).subscribe((data: string) => { this.AreyousuretoremovetheUser = data; });

    if (confirm(this.AreyousuretoremovetheUser)) {
      this.showLoader = true;
      selectedUser.isValid = !selectedUser.isValid;
      this.service.cancelUserPermit(selectedUser).subscribe((result: any) => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get(_successMsgTitle).subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get(_successMsgBody).subscribe((data: string) => { this.PopUpMessage = data; });
      }, err => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get(_errorMsgTitle).subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get(_errorMsgBody).subscribe((data: string) => { this.PopUpMessage = data; });
      });
    }
  }

  search() {
    this.showLoader = true;
    this.service.getUserDetails(this.givenText).subscribe((data: any) => {
      this.showLoader = false;
      this.UserDetails = data.filter(x => x.mail != null && x.mail != localStorage.getItem('CurrentUserEmail'));
    })
  }

  getUserDetail(value) {
    var selectedOtherUser = this.UserDetails.find(x => x.mail == value);
    this.displayUserName = selectedOtherUser.displayName;
    this.displayUserEMail = selectedOtherUser.mail;
    this.displayPersonalDetails = true;
  }

}
