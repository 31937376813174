import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core'
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { tr } from 'date-fns/locale';
import * as $ from 'jquery';
import { Console } from '@angular/core/src/console';
import * as powerbiClient from 'powerbi-client';
import * as models from 'powerbi-models';


let loadedResolve: any, reportLoaded = new Promise((res) => { loadedResolve = res; });
let renderedResolve: any, reportRendered = new Promise((res) => { renderedResolve = res; });
const powerbi: powerbiClient.service.Service = window["powerbi"];
let report:powerbiClient.Report=null;
var IsReportLoaded:boolean=false;

@Component({
  selector: 'app-customerfacing-dashboard',
  templateUrl: './customerfacing-dashboard.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class CustomerfacingDashboardComponent implements OnInit {
  constructor(private titleService: Title, public translate: TranslateService,
    private ServiceService: ServiceService, private dataService: userDataService,
    private router: Router,
    public sanitizer: DomSanitizer) {
  }
  userRole: string = ""
  showLoader = false;
  reportType: string = "CustomerFacing";
  selectedCountry: string = "";
  reportValue;
  floorName: string = "";
  officeName: string = "";

  offices: any;
  selectedOfficeId:string;
  selectedOfficeName: string;
  floors: any;
  FloorsInAllLanguage: any;
  selectedFloorId:string;
  selectedFloorName: string;
  reportDisplayName: string = "";
  IsOfficeChanged:boolean=true;

  ngOnInit() {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    this.userRole = payLoad.role;
    this.titleService.setTitle("Save My Seat - Customerfacing Dashboard");

    this.selectedCountry = localStorage.getItem('SelectedCountry');
    this.bindOffices();
    //this.embedPowerBIReport();
  }

  bindOffices() {
    // get list of offcies for country
    let checkAdminOfficeIds: boolean = (this.router.url == '/customerfacingdashboard' ? true : false);
    this.ServiceService.getOffices(this.selectedCountry, checkAdminOfficeIds).subscribe((data: any[]) => {
      if (data.length == 0) {
        //  var msgTitle = 'Permission Restricted';
        //  var PopUpMessage = 'Sorry, you dont have permission to access any of the offices in ' + localStorage.getItem('SelectedCountry') + '. Please select other country';
        var OfficePermissionIssueTitle = "";
        var OfficePermissionIssuePart1 = "";
        var OfficePermissionIssuePart2 = "";
        this.translate.get('OfficePermissionIssueTitle').subscribe((data: string) => { OfficePermissionIssueTitle = data; });
        this.translate.get('OfficePermissionIssuePart1').subscribe((data: string) => { OfficePermissionIssuePart1 = data; });
        this.translate.get('OfficePermissionIssuePart2').subscribe((data: string) => { OfficePermissionIssuePart2 = data; });
        var errorMsg = OfficePermissionIssuePart1 + ' ' + this.selectedCountry + ' ' + OfficePermissionIssuePart2;
        Swal.fire({
          title: "",
          text: errorMsg,
          confirmButtonColor: '#3f32f1',
          confirmButtonText: "Ok",
          icon: "warning"
        }).then((result) => {
          if (result.value) {
            this.router.navigateByUrl('/admin');
          }
        })
      }
      this.offices = data;
    });
  }

  changeOffice(e) {
    this.IsOfficeChanged=true;
    this.selectedOfficeId = (e.target == undefined ? e : e.target.value);
    this.selectedOfficeName = this.offices.find(x => x.id == this.selectedOfficeId).name;
    this.ServiceService.getFloors(parseInt(this.selectedOfficeId),'').subscribe((data: any[]) => {
      this.FloorsInAllLanguage=data;
      this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
      this.selectedFloorId= this.floors[0].id;
      this.floorSetter(this.selectedFloorId);
    });
  }

  floorSetter(floorid) {
    if (floorid != undefined) {
      if((localStorage.getItem('locale')!='en' || localStorage.getItem('locale')!="en") && this.FloorsInAllLanguage!= undefined){
        var selfloorsInEnglishObj = this.FloorsInAllLanguage.filter(x => x.langKey =='en' && x.id===parseInt(floorid));
        if (selfloorsInEnglishObj != undefined && selfloorsInEnglishObj[0] != undefined) {
          this.selectedFloorName = selfloorsInEnglishObj[0].name;
         }
      }
      else{
        var selFloorObj = this.floors.find(value => value.id === parseInt(floorid));
        if (selFloorObj != undefined) {
          this.selectedFloorName=selFloorObj.name;
        }
      }
    }
  }

  changefloor(e) {
    this.selectedFloorId = (e.target == undefined ? e : e.target.value);
    if (this.selectedFloorId != undefined)
    {
      if((localStorage.getItem('locale')!='en' || localStorage.getItem('locale')!="en") && this.FloorsInAllLanguage!= undefined){
        var selfloorsInEnglishObj = this.FloorsInAllLanguage.filter(x => x.langKey =='en' && x.id===parseInt(this.selectedFloorId ));
        if (selfloorsInEnglishObj != undefined && selfloorsInEnglishObj[0] != undefined) {
          this.selectedFloorName = selfloorsInEnglishObj[0].name;
        }
      }
      else{
        this.selectedFloorName = this.floors.find(x => x.id == this.selectedFloorId).name;
      }
    }
  }


//---------- 12-Sep-2023 approach has been changed firstly load whole report take time only once after we can change only report page quickly
  swalCall(errorMsg,iconType,title)
  {
    Swal.fire({
      title: title,
      text: errorMsg,
      confirmButtonColor: '#3f32f1',
      confirmButtonText: "Ok",
      icon: iconType
    })
  }

  async OnLoadReportClick()
  {
    if((this.selectedOfficeName!=null && this.selectedOfficeName!=undefined && this.selectedOfficeName!="undefined" && this.selectedOfficeName!="") && (this.selectedFloorName!=null && this.selectedFloorName!=undefined && this.selectedFloorName!="undefined" && this.selectedFloorName!=""))
    {
      if(IsReportLoaded && !this.IsOfficeChanged)
      {
        var CountryKey=this.selectedCountry.toLowerCase();
        switch (CountryKey) {
          case "united kingdom":
            CountryKey="UK"
            break;
        case "europe":
            CountryKey="europe"
            break;
        }
        this.reportDisplayName= CountryKey+" " + this.selectedOfficeName+"-"+this.selectedFloorName;

        await reportLoaded;
        try {
            const pages: powerbiClient.Page[] = await report.getPages();
            var index =  pages.findIndex(a => a.displayName.toLowerCase() === this.reportDisplayName.toLowerCase());
            if(index && index>-1)
            {
              await pages[index].setActive();
              console.log("Active page was set to: \"" + index+"-" + pages[index].name + " - " + pages[index].displayName + "\"");
            }
            else{
              pages[0].setActive();
              console.log("Active page was set to: \"" + index+"-" + pages[0].name + " - " + pages[0].displayName + "\"");
            }
        }
        catch (errors) {
          console.log(errors);
        }
        await reportRendered;
      }
      else
      {
        let reportContainer = <HTMLElement>document.getElementById('reportContainer');
        let powerbiService = new powerbiClient.service.Service(powerbiClient.factories.hpmFactory, powerbiClient.factories.wpmpFactory, powerbiClient.factories.routerFactory); //window["powerbi"];
        powerbiService.reset(reportContainer);
        this.embedPowerBIReport();
      }
    }
    else
    {
      var errorMsg = "";
      this.translate.get('ReportButtonError').subscribe((data: string) => { errorMsg = data;
        this.swalCall(errorMsg,'warning','');
      });
    }
  }

  async embedPowerBIReport() {
    var CountryKey = this.selectedCountry.toLowerCase();
    switch (CountryKey) {
        case "united kingdom":
          CountryKey="UK"
          break;
      case "europe":
          CountryKey="europe"
          break;
    }
    var reportForSelectedOfficeFloor = CountryKey +" "+ this.selectedOfficeName+"-"+this.selectedFloorName;
    $('#div_reportShowLoader').get(0).style.display='block';

    this.ServiceService.getPowerbiEmbedReportData(this.selectedCountry, this.selectedOfficeName,this.reportType).subscribe((res: any) => {
      $('#div_reportShowLoader').get(0).style.display='none';
      if (res && res != 400 && res != 406) {
        var responseData = res as any;
        this.LoadCustomerEmbedReport(responseData, reportForSelectedOfficeFloor);
      }
      else if(res && res == 406)
      {
        var errorMsg = "";
        this.translate.get('PbiReportNotExist').subscribe((data: string) => {
          errorMsg = data;
          this.swalCall(errorMsg,'warning','');
        });
      }
      else
      {
        var errorMsg = "";
        this.translate.get('ErrorMsg').subscribe((data: string) => {
          errorMsg = data;
          this.swalCall(errorMsg,'warning','');
        });
      }
    });
  }

  async LoadCustomerEmbedReport(embedReportData,reportForSelectedOfficeFloor) {
    try {
        //var reportForSelectedOfficeFloor=  this.officeName+"-"+this.floorNameForReport;
        let accessToken: string = embedReportData.embedToken;
        let embedUrl: string = embedReportData.embedUrl;
        let embedReportId: string = embedReportData.reportId;
        let tokenType = models.TokenType.Embed;
        let permissions: models.Permissions = models.Permissions.All;

        let config: models.IReportEmbedConfiguration = {
          type: 'report',
          tokenType: tokenType,
          accessToken: accessToken,
          embedUrl: embedUrl,
          id: embedReportId,
          permissions: permissions,
          settings: {
              panes: {
                  filters: {
                      visible: false
                  },
                  pageNavigation: {
                      visible: false
                  }
              }
          }


        }
        // Get a reference to the embedded report HTML element
        var $reportContainer = $('#reportContainer');
        let embedContainer = $reportContainer.get(0);//: HTMLElement = document.getElementById('embedContainer');

        // Embed the report and display it within the div container.
        report =   <powerbiClient.Report>(powerbi.embed(embedContainer,config));
        this.reportValue = report as powerbiClient.Report;
        //powerbi.embed(embedContainer, config) as powerbiClient.Report;

        report.off("loaded");
        report.on("loaded", async (event) => {
          console.log("report load successfully.");
          IsReportLoaded=true;
          this.IsOfficeChanged=false;
          Promise.all([report.getPages()]).then(response => {
            var pages=response[0];
            var index =  pages.findIndex(a => a.displayName.toLowerCase() === reportForSelectedOfficeFloor.toLowerCase());
            if(index && index>-1)
            {
              pages[index].setActive();
              console.log("Active page was set to: \"" + index+"-" + pages[index].name + " - " + pages[index].displayName + "\"");
            }
            else{
              pages[0].setActive();
              console.log("Active page was set to: \"" + index+"-" + pages[0].name + " - " + pages[0].displayName + "\"");
            }
          })
          $('#div_reportShowLoader').get(0).style.display='none';
          loadedResolve();
          report.off("loaded");
        });

        report.off("error");
        report.on("error", function (event: powerbiClient.service.ICustomEvent<any>) {
          $('#div_reportShowLoader').get(0).style.display='none';
          console.log(event.detail);
        });

        report.off("rendered");
        report.on("rendered", function () {
          $('#div_reportShowLoader').get(0).style.display='none';
          renderedResolve();
          report.off("rendered");
        });
    }
    catch (errors) {
      console.log(errors);
      $('#div_reportShowLoader').get(0).style.display='none';
    }

  }

}
