import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { userDataService } from '../services/data-share';
import { Observable } from 'rxjs';
import { DeskReservationsToBookDTO } from '../Model/DeskReservationsToBookDTO';
import { CheckValidDateSelectedToBook, CheckBookingLimit, MultiDateSelectedToBookDTO } from '../Model/CheckValidDateSelectedToBook';
import { ExternalUserCredentialsDTO } from '../Model/ExternalUserCredentialsDTO';
import { GetReservationParamsDTO } from '../Model/getReservationParamsDTO';
import { TranslateService } from '@ngx-translate/core'
import { DesksDTO } from '../Model/Desks';

import { ReturnStatement } from '@angular/compiler';
import { OfficeDTO } from '../Model/OfficeDTO';
import { FloorDTO } from '../Model/FloorDTO';
import { ZoneDTO } from '../Model/ZoneDTO';
import { SpacesDTO } from '../Model/CarParkingDTO';
import * as CryptoJS from 'crypto-js';
import keys from '../key.json';
import { id } from 'date-fns/locale';
import { SeatAvailablityDTO } from '../Model/SeatAvailablityDTO';

@Injectable({
  providedIn: 'root'
})

export class ServiceService {
  //***** TO BE MODIFIED BEFORE EVERY RELEASE!!!
  // LastAppRelease: string = "Last Update On: 08-Mar-2023";
  LastAppRelease: string = "Last Update On: " + localStorage.getItem('PublishTime');
  HostUrl: string = ""//"https://as-sms-dev-backend.azurewebsites.net/";//"https://localhost:44380/"; //
  //*****
  SupportedLanguages = [
    { 'languageCode': 'en', 'languageName': 'English' },
    { 'languageCode': 'fr', 'languageName': 'French' },
    { 'languageCode': 'ptBR', 'languageName': 'Portuguese (Brazil)' },
    { 'languageCode': 'es', 'languageName': 'Spanish' }
  ]
  SupportedLangRegex = /en|fr|ptBR|es/

  constructor(private http: HttpClient, private dataService: userDataService, public translate: TranslateService) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  getData() {
    return this.http.get('/api/Employee');
  }

  // getOption() {
  //   return this.http.get(this.HostUrl + 'api/Home/GetCountryList');
  //   // return this.http.get('api/Home/GetCountryList');
  // }
  getOption() {
    //return this.http.get(this.HostUrl + 'api/Home/GetCountryList');
    return this.http.get(this.HostUrl + 'api/Home/GetCountryList?currentUser=' + this.encrypt(localStorage.getItem('CurrentUserEmail')));
  }

  getWithoutParams(partialUrl) {
    return this.http.get(this.HostUrl + partialUrl);
  }

  getOfficeData(id: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetOfficeData?id=' + id);
  }
  getOfficeDataForOffice(id: string, lang: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetOfficeDataForOffice?id=' + id + '&lang=' + lang);
  }

  getReservations(params: GetReservationParamsDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/GetReservations', params);
  }
  getReservationsForGym(params: GetReservationParamsDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/GetReservationsForGym', params);
  }
  getParkingReservations(params: GetReservationParamsDTO) {
    return this.http.post(this.HostUrl + 'api/Parking/GetParkingReservations', params);
  }
  getTodayReservations(params: GetReservationParamsDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/GetTodaysBookings', params);
  }
  getSelfReservations(params: GetReservationParamsDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/GetSelfReservations', params);
  }
  getSelfReservationsForSwap(params: GetReservationParamsDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/GetSelfReservationsForSwap', params);
  }
  getOffices(country_name: string, checkAdminOffices: Boolean) {
    // return this.http.get(this.HostUrl + 'api/Home/' + country_name);
    return this.http.get(this.HostUrl + 'api/Home?country=' + country_name + '&currentUser=' + this.encrypt(localStorage.getItem('CurrentUserEmail')) + '&AdminOffices=' + checkAdminOffices);
  }
  getFloors(office_id: number, url: string) {
    return this.http.get(this.HostUrl + 'api/Floors/GetFloors?officeid=' + office_id + '&url=' + url);
  }
  getZones(floor_id: number) {
    return this.http.get(this.HostUrl + 'api/Floors/GetZones/' + floor_id);
  }
  getAllOfficesForGroupedBU() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllOfficesForGroupedBU');
  }
  GetAllBUOffices() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllBUOffices');
  }
  GetEditBUOffices(id: number) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllEditBUOffices?id=' + id);
  }
  GetEditBUFloors(id: number) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllEditBUFloors?id=' + id);
  }
  getAllBUs() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllBUs');
  }
  GetAllGroupBUs() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllGroupBUs');
  }
  GetAllEditBUGroup(id: number) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllEditBUGroup?id=' + id);
  }
  getAllFloorsForBU(OfficeList: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllFloorsForBU?OfficeList=' + OfficeList);
  }
  public uploadBUPlan(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/UploadBUPLan', formData);
  }
  public UploadGroupBUPLan(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/UploadGroupBUPLan', formData);
  }
  getBUsearchdata(giventext: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getBUsearchdata?giventext=' + giventext);
  }
  getGroupedBUsearchdata(giventext: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getGroupedBUsearchdata?giventext=' + giventext);
  }
  getzoneFloorPlanPath(zoneId: number) {
    return this.http.get(this.HostUrl + 'api/Floors/getzoneFloorPlanPath/' + zoneId);
  }
  getfloorFloorPlanPath(floorid: number) {
    return this.http.get(this.HostUrl + 'api/Floors/getfloorFloorPlanPath/' + floorid);
  }

  getDesksFromFloorID(floorid: number, booking_start_timing: string, booking_end_timing: string) {
    // return this.http.get(this.HostUrl + 'api/deskf/GetDeskFAvailablity?floorid=' + floorid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook'));
    return this.http.get(this.HostUrl + 'api/deskf/GetDeskFAvailablity?floorid=' + floorid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing);
  }

  getDesksFromFloorIDWithBU(floorid: number, buID: number, booking_start_timing: string, booking_end_timing: string, businessUnit: number) {
    return this.http.get(this.HostUrl + 'api/deskf/GetDeskFAvailablity?floorid=' + floorid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing + '&buID=' + buID + '&businessUnit=' + businessUnit);
  }
  // getAllDesksFromFloorID(floorid: number) {
  //   return this.http.get(this.HostUrl + 'api/deskf/GetAllDesksF?floorid=' + floorid);
  // }
  getDesksFromZoneID(zoneid: number, booking_start_timing: string, booking_end_timing: string) {
    return this.http.get(this.HostUrl + 'api/deskz/GetDeskZAvailablity?zoneid=' + zoneid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing);
  }
  // getAllDesksFromZoneID(zoneid: number) {
  //   return this.http.get(this.HostUrl + 'api/deskz/GetAllDesksZ?zoneid=' + zoneid);
  // }

  postReservation(DeskReservationsToBook) {//formData
    return this.http.post(this.HostUrl + 'api/reservation/', DeskReservationsToBook);
    // return this.http.post('/api/reservation/', formData);
  }
  postReservationForGym(DeskReservationsToBook) {//formData
    return this.http.post(this.HostUrl + 'api/reservation/ReservationForGym', DeskReservationsToBook);
    // return this.http.post('/api/reservation/', formData);
  }

  sendOTPtoSNCL(snclUserData) {
    return this.http.post(this.HostUrl + 'api/Authentication/SendOTPtoSNCL', snclUserData);
  }

  authoriseSNCLUser(user, pwd) {
    return this.http.get(this.HostUrl + 'api/Authentication/' + user + "/" + pwd);
  }

  authoriseExternalUser(externalUserData) {
    return this.http.post(this.HostUrl + 'api/Authentication/AuthoriseExternalUser', externalUserData);
  }

  changeExtPwd(ChangePasswordModel) {
    return this.http.post(this.HostUrl + 'api/Authentication/ChangeExternalUserPwd', ChangePasswordModel);
  }

  postUserData(snclUserData) {
    return this.http.post(this.HostUrl + 'api/Authentication/VerifySNCLOTP', snclUserData);
  }
  getSelectingSeatPurposes(getPurposesDTO) {
    // return this.http.get(this.HostUrl + 'api/Home/getSelectingSeatPurposes?officeId=' + officeId + '&selectedCountry=' + localStorage.getItem('SelectedCountry'));
    return this.http.post(this.HostUrl + 'api/Home/getSelectingSeatPurposes', getPurposesDTO);
  }

  cancelReservation(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Reservation/CancelReservation', reservDetails);
  }
  cancelReservationForGym(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Reservation/CancelReservationForGym', reservDetails);
  }

  getDetailReservation(reservID) {
    return this.http.get(this.HostUrl + 'api/Reservation/GetDetailReservation?reservID=' + reservID);
  }
  getUserDetails(searchText) {
    return this.http.get(this.HostUrl + 'api/UserDetails/' + searchText);
  }

  checkInReservation(reservDetails) {
    return this.http.post(this.HostUrl + 'api/ReservationTracker/CheckIn', reservDetails);
  }

  checkOutReservation(reservDetails) {
    return this.http.post(this.HostUrl + 'api/ReservationTracker/CheckOut', reservDetails);
  }

  getCheckedInStatus(reservID) {
    return this.http.get(this.HostUrl + 'api/ReservationTracker/GetCheckInStatus?reservID=' + reservID);
  }

  getCheckedOutStatus(reservID) {
    return this.http.get(this.HostUrl + 'api/ReservationTracker/GetCheckOutStatus?reservID=' + reservID);
  }

  postFeedback(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/feedback/PostFeedback', formData);
  }

  // getBUs(office_id: number) {
  //   return this.http.get(this.HostUrl + 'api/BusinessUnit/GetBUs?officeID=' + office_id);
  // }
  getBUs(office_id: number) {
    //return this.http.get(this.HostUrl + 'api/BusinessUnit/GetBUs?officeID=' + office_id);
    return this.http.get(this.HostUrl + 'api/BusinessUnit/GetBUs?officeID=' + office_id + '&currentUser=' + this.encrypt(localStorage.getItem('CurrentUserEmail')));
  }

  getDeskBUs(_officeid: number) {
    //return this.http.get(this.HostUrl + 'api/BusinessUnit/GetBUs?officeID=' + office_id);
    return this.http.get(this.HostUrl + 'api/Admin/GetAllOfficeBU?Officeid=' + _officeid);
  }

  getZonesFromBU(office_id: number, buID: number) {
    return this.http.get(this.HostUrl + 'api/BusinessUnit/GetZonesFromBU?officeID=' + office_id + '&buID=' + buID);
  }
  //getFloorsFromBU(office_id: number, buID: number) { return this.http.get(this.HostUrl + 'api/BusinessUnit/getFloorsFromBU?officeID=' + office_id + '&buID=' + buID); }
  getFloorsFromBU(office_id: number, buID: number, url: string) {
    //return this.http.get(this.HostUrl + 'api/BusinessUnit/getFloorsFromBU?officeID=' + office_id + '&buID=' + buID);
    return this.http.get(this.HostUrl + 'api/BusinessUnit/getFloorsFromBU?officeID=' + office_id + '&buID=' + buID + '&currentUser=' + this.encrypt(localStorage.getItem('CurrentUserEmail')) + '&url=' + url);
  }

  getGroupedBUID(office_id: number, buID: number) {
    return this.http.get(this.HostUrl + 'api/BusinessUnit/GetGroupedBU?officeID=' + office_id + '&buID=' + buID);
  }
  getCreateExcel(office_id: number, BUId: number, FloorID: number, ZoneID: number) {
    return this.http.get(this.HostUrl + 'api/BusinessUnit/CreateExcel?officeID=' + office_id + '&BUId=' + BUId + '&FloorID=' + FloorID + '&ZoneID=' + ZoneID);
  }

  getDesksFromZoneIDWithBU(zoneid: number, buID: number, booking_start_timing: string, booking_end_timing: string) {
    return this.http.get(this.HostUrl + 'api/deskz/GetDeskZAvailablity?zoneid=' + zoneid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing + '&buID=' + buID);
  }
  // getAllDesksFromZoneIDWithBU(zoneid: number, buID: number) {
  //   return this.http.get(this.HostUrl + 'api/deskz/GetAllDesksZ?zoneid=' + zoneid + '&buID=' + buID);
  // }

  CheckReservationforSelectedDate(CheckValidDateSelectedToBook: CheckValidDateSelectedToBook) {
    return this.http.post(this.HostUrl + 'api/Reservation/CheckReservationforSelectedDate', CheckValidDateSelectedToBook);
  }
  CheckReservationforSelectedDateForGym(CheckValidDateSelectedToBook: CheckValidDateSelectedToBook) {
    return this.http.post(this.HostUrl + 'api/Reservation/CheckReservationforSelectedDateForGym', CheckValidDateSelectedToBook);
  }
  getDeskDetails(deskID: number) {
    return this.http.get(this.HostUrl + 'api/deskf/GetDeskDetails?deskID=' + deskID);
  }

  swapReservation(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Reservation/SwapReservation', reservDetails);
  }


  DecryptEmail(encryptedEmailUrlEncoded) {
    return this.http.get(this.HostUrl + 'api/Authentication/DecryptEmail?encryptedEmailUrlEncoded=' + encryptedEmailUrlEncoded);
  }

  postFailHealthAssmnt(userFeedback) {
    return this.http.post(this.HostUrl + 'api/feedback/PostFailHEA', userFeedback);
  }

  createUserPreference(userPreference) {
    return this.http.post(this.HostUrl + 'api/UserDetails/CreateUserPreference', userPreference);
  }

  getUserPreference() {
    return this.http.get(this.HostUrl + 'api/UserDetails/GetUserPreference?userID=' + this.encrypt(localStorage.getItem('CurrentUserEmail')));
  }
  getSAofficeAccess() {
    return this.http.get(this.HostUrl + 'api/Parking/CheckAccessSAOffice');
    // ?userID=' + localStorage.getItem('CurrentUserEmail')
  }

  CheckBookingLimit(checkBookingLimit: CheckBookingLimit) {
    return this.http.post(this.HostUrl + 'api/reservation/CheckBookingLimit', checkBookingLimit);
  }
  CheckBookingLimitForGym(checkBookingLimit: CheckBookingLimit) {
    return this.http.post(this.HostUrl + 'api/reservation/CheckBookingLimitForGym', checkBookingLimit);
  }
  GetParkingLimits(checkBookingLimit: CheckBookingLimit) {
    return this.http.post(this.HostUrl + 'api/reservation/GetParkingLimits', checkBookingLimit);
  }
  PostCarParking(carParkingPostDTO) {
    return this.http.post(this.HostUrl + 'api/Parking/CreateParkingReservation', carParkingPostDTO);
  }
  CheckUserCarParking(carParkingPostDTO) {
    return this.http.post(this.HostUrl + 'api/Parking/CheckUserHasParkingReservation', carParkingPostDTO);
  }
  GenerateToken() {
    return this.http.get(this.HostUrl + "api/Auth/Authenticate");
  }
  logout() {
    return this.http.get(this.HostUrl + "api/Authentication/Logout");
  }
  GenerateTokenwithRole(email: string) {
    return this.http.get(this.HostUrl + 'api/Auth/GenerateTokenwithRole?email=' + email);
  }
  getExternalUserList() {
    return this.http.get(this.HostUrl + "api/Admin/GetDetails");
  }
  SaveUserDetails(externalUserDetails: ExternalUserCredentialsDTO) {
    return this.http.post(this.HostUrl + 'api/Admin/SaveUserDetails', externalUserDetails);
  }
  getUserData(userId: number) {
    return this.http.get(this.HostUrl + 'api/Admin/getUserDetail?UserId=' + userId);
  }
  deleteExternalUser(fullName: string) {
    return this.http.delete(this.HostUrl + 'api/Admin/' + fullName);
  }
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }
  AddOffice(OfficeData: OfficeDTO) {
    return this.http.post(this.HostUrl + 'api/Admin/SaveNewOffice', OfficeData);
  }
  getAllOffices() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllOffices');
  }
  AddFloor(FloorDetails: any) {
    return this.http.post(this.HostUrl + 'api/Admin/SaveNewFloor', FloorDetails);
  }
  getAllFloors() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllFloors');
  }
  getAllFloorsForZones() {
    return this.http.get(this.HostUrl + 'api/Admin/getAllFloorsForZones');
  }
  getAllZones() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllZones');
  }
  AddZone(ZoneDetails: any) {
    return this.http.post(this.HostUrl + 'api/Admin/SaveNewFloor', ZoneDetails);
  }
  public uploadSpreadSheet(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/uploaddata', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
  public uploadFloorPlan(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/uploadFloorPlan', formData);
  }
  public uploadZonePlan(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/uploadZonePlan', formData);
  }
  getAllDesks() {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesks');
  }
  getAllDesksFromZoneId(zoneid: number, lang: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesksFromZoneId?zoneid=' + zoneid + '&lang=' + lang);
    // return this.http.get(this.HostUrl + 'api/Authentication/' + user + "/" + pwd);
  }
  getAllDesksFromZoneIdToExport(zoneid: number, OfficeID: number) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesksFromZoneIdToExport?zoneid=' + zoneid + '&OfficeID=' + OfficeID);
    // return this.http.get(this.HostUrl + 'api/Authentication/' + user + "/" + pwd);
  }
  getAllDesksFromFloorId(floorid: number, lang: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesksFromFloorId?floorid=' + floorid + '&lang=' + lang);
  }
  getAllDesksFromFloorIdToExport(floorid: number, OfficeID: number) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesksFromFloorIdToExport?floorid=' + floorid + '&OfficeID=' + OfficeID);
  }
  getsearchdata(giventext: string, mappingId: number, type: string, buId: number, lang: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getsearchdata?giventext=' + giventext + '&mappingId=' + mappingId + '&type=' + type + '&buId=' + buId + '&lang=' + lang);
  }
  getFloorsearchdata(giventext: string, givenTextOffice: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getFloorsearchdata?giventext=' + giventext + '&Office=' + givenTextOffice);
  }
  getZonesSearchData(giventext: string, givenTextOffice: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getZonesSearchData?giventext=' + giventext + '&Office=' + givenTextOffice);
  }
  GetOfficeSearchData(giventext: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetOfficeSearchData?giventext=' + giventext);
  }
  UpdateDeskConfiguration(DataToSave: Array<DesksDTO>) {
    return this.http.post(this.HostUrl + 'api/Admin/UpdateDeskConfiguration', DataToSave);
  }
  UpdateParkingConfiguration(DataToSave: Array<SpacesDTO>) {
    return this.http.post(this.HostUrl + 'api/Admin/UpdateParkingConfiguration', DataToSave);
  }
  CheckIfAdminUser(userEmailId: string) {
    return this.http.get(this.HostUrl + 'api/Admin/CheckIfAdminUser?userEmailId=' + this.encrypt(userEmailId));
  }
  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    allowedRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }
  AddExternalUser(DeskConfigDTO: ExternalUserCredentialsDTO) {
    return this.http.post(this.HostUrl + 'api/Admin/SaveExternalUser', DeskConfigDTO)
  }
  getAllExternalUsers() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllExternalUser');
  }
  getAllDesksFromGroupedBUId(floorid: number, buID: number, lang: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesksFromGroupedBUId?floorid=' + floorid + '&buID=' + buID + '&lang=' + lang);
  }
  getAllDesksFromGroupedBUIdToExport(floorid: number, buID: number, OfficeID: number) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesksFromGroupedBUIdToExport?floorid=' + floorid + '&buID=' + buID + '&OfficeID=' + OfficeID);
  }
  getParkingSpaces(office_id: number, selectDate: any) {
    return this.http.get(this.HostUrl + 'api/Parking/GetParkingSpaces?officeId=' + office_id + '&SelectedDateToBook=' + selectDate);
  }
  getParkingSpacesSA(office_id: number, selectDate: any) {
    return this.http.get(this.HostUrl + 'api/Parking/GetParkingSpacesSA?officeId=' + office_id + '&SelectedDateToBook=' + selectDate);
  }
  getAllParkingSpaces(office_id: number) {
    return this.http.get(this.HostUrl + 'api/Parking/GetAllParkingSpaces?officeId=' + office_id);
  }
  CheckOfficeBU(office_id: number) {
    return this.http.get(this.HostUrl + 'api/Admin/CheckOfficeBU?officeId=' + office_id);
  }
  getParkingTypes(office_id: number) {
    return this.http.get(this.HostUrl + 'api/Parking/GetParkingTypes?officeId=' + office_id);
  }
  getAllParkingTypes() {
    return this.http.get(this.HostUrl + 'api/Parking/GetAllParkingTypes');
  }
  cancelParkReservation(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Parking/CancelReservation', reservDetails);
  }
  approveParkReservation(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Parking/ApproveReservation', reservDetails);
  }
  cancelParkReservationSA(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Parking/CancelReservationSA', reservDetails);
  }
  getParkingPermits(office_id: number) {
    return this.http.get(this.HostUrl + 'api/Parking/GetPermits?officeId=' + office_id);
  }
  CheckUserPermit(carParkingPostDTO) {
    return this.http.post(this.HostUrl + 'api/Parking/CheckUserHasPermit', carParkingPostDTO);
  }
  PostUserPermit(carParkingPostDTO) {
    return this.http.post(this.HostUrl + 'api/Parking/CreateUserPermit', carParkingPostDTO);
  }
  PostWaitingList(carParkingPostDTO) {
    return this.http.post(this.HostUrl + 'api/Parking/CreateWaitingList', carParkingPostDTO);
  }
  cancelUserPermit(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Parking/CancelUserPermit', reservDetails);
  }
  getDeskReservations(office_id: number, selectDate: any) {
    return this.http.get(this.HostUrl + 'api/reservation/GetDayReservations?officeId=' + office_id + '&SelectedDateToBook=' + selectDate);
  }
  checkAssignedorDeskCatBasedonZoneId(zoneid: number) {
    return this.http.get(this.HostUrl + 'api/Admin/checkAssignedorDeskCatBasedonZoneId?zoneid=' + zoneid);
  }
  checkAssignedorDeskCatBasedonFloorId(floorid: number) {
    return this.http.get(this.HostUrl + 'api/Admin/checkAssignedorDeskCatBasedonFloorId?floorid=' + floorid);
  }

  CheckSameDeskMultipleDays(MultiDateSelectedToBookDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/CheckSameDeskMultipleDays', MultiDateSelectedToBookDTO);
  }
  CheckSameDeskMultipleDaysForGym(MultiDateSelectedToBookDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/CheckSameDeskMultipleDaysForGym', MultiDateSelectedToBookDTO);
  }
  GetSelectedCountryName(selectedCountry: string) {
    return this.http.get(this.HostUrl + 'api/Home/GetSelectedCountryName?selectedCountry=' + selectedCountry, { responseType: 'text' });
  }

  //--- DeskType Implement 11Aug2022
  getDeskTypes(office_id: number) {
    return this.http.get(this.HostUrl + 'api/DeskType/GetDeskTypes/' + office_id);
  }

  GetAllOfficeBU(office_id: number) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllOfficeBU/?Officeid=' + office_id);
  }

  getAllDeskTypes() {
    return this.http.get(this.HostUrl + 'api/DeskType/GetAllDeskTypes');
  }
  postDeskTypeData(DeskTypeDTO) {
    return this.http.post(this.HostUrl + 'api/DeskType/SaveDeskType', DeskTypeDTO);
  }

  getIsIncludeDeskType(DeskTypeId: number, OfficeId: number) {
    return this.http.get(this.HostUrl + 'api/DeskType/IsIncludeDeskType?DeskTypeId=' + DeskTypeId + '&OfficeId=' + OfficeId);
  }

  deleteDeskType(DeskTypeId: number, OfficeId: number) {
    return this.http.delete(this.HostUrl + 'api/DeskType/DeleteDeskType?DeskTypeId=' + DeskTypeId + '&OfficeId=' + OfficeId);
  }

  getAllTranslationKeyType() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllTranslationsKeyType');
  }

  getAllTranslation(keyType: string, language: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllTranslations?keyType=' + keyType + '&language=' + language);
  }

  postIsTranslationVerify(TranslationsDTO) {
    return this.http.post(this.HostUrl + 'api/Admin/UpdateIsTranslationVerify', TranslationsDTO);
  }
  postTranslation(TranslationsDTO) {
    return this.http.post(this.HostUrl + 'api/Admin/UpdateTranslation', TranslationsDTO);
  }


  //----------- User Allowed Region 18Nov2022
  getUserAllowedRegion() {
    return this.http.get(this.HostUrl + 'api/Admin/GetUserAllowedRegion');
  }
  getAllOfficesOnRegion(region: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllOfficesOnRegion?region=' + region);
  }
  getUserAllRegion() {
    return this.http.get(this.HostUrl + 'api/Admin/GetUserAllRegion');
  }
  addDashBoardAccess(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/SaveDashboardAccess', formData);
  }
  getAllAdmins() {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllAdmins');
  }
  deleteAdmin(userID: string, currentEmail: string) {
    return this.http.delete(this.HostUrl + 'api/Admin/DeleteAdmin?userID=' + userID + "&currentUserEmail=" + currentEmail);
  }
  getAdminInfo(userID: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAdminInfo?userID=' + userID);
  }
  getEditAdminInfo(officeIDs: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetEditAdminInfo?officeIDs=' + officeIDs);
  }
  getEditAdminInfoRegion(Region: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetEditAdminInfoRegion?Region=' + Region);
  }
  getAdminSearchData(giventext: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAdminSearchData?giventext=' + giventext);
  }
  getAdminSearchDataBasedOnOffice(giventext: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAdminSearchDataBasedOnOffice?giventext=' + giventext);
  }

  ForgetExtPwd(ChangePasswordModel) {
    return this.http.post(this.HostUrl + 'api/Admin/ForgetExternalUserPwd', ChangePasswordModel);
  }

  //----------- PowerBIEmbedded
  getPBIEmbedReportDataV1(region: string, ReportType: string, mode: string, reportId: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetEmbedReportDataV1?Region=' + region + "&ReportType=" + ReportType + "&mode=" + mode + "&PBIreportId=" + reportId);
  }
  getPBIRefreshReportOnDemandV1(region: string, ReportType: string) {
    return this.http.get(this.HostUrl + 'api/Admin/PBIRefreshReportOnDemandV1?Region=' + region + "&ReportType=" + ReportType);
  }
  //----------- PowerBIEmbedded customer
  getPowerbiEmbedReportData(region: string, officeName: string, reportType: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetPbiEmbedReportData?region=' + region + "&officeName=" + officeName + "&reportType=" + reportType);
  }
  getPBIRefreshCustomerReport(region: string, officeName: string, reportType: string) {
    return this.http.get(this.HostUrl + 'api/Admin/PBIRefreshCustomerReport?region=' + region + "&officeName=" + officeName + "&reportType=" + reportType);
  }

  //----------- Parking Configuration Upload

  getCreateExcelForParking(office_id: number) {
    return this.http.get(this.HostUrl + 'api/Admin/getCreateExcelForParking?officeID=' + office_id);
  }

  public uploadParkingSheet(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/uploadDataParking', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
  //Parking End

  getPublishTime() {
    return this.http.get(this.HostUrl + 'api/Home/GetPublishTime');
  }
  //Swap Only in Reserved Country and Office
  getCountryBasedOnOffice(officeID: string) {
    return this.http.get(this.HostUrl + 'api/Home/GetCountryBasedOnOffice?officeID=' + officeID);
  }
  getOfficesForSwap(country_name: string, checkAdminOffices: Boolean, OfficeID: string) {
    return this.http.get(this.HostUrl + 'api/Home/GetOfficesForSwap?country=' + country_name + '&currentUser=' + this.encrypt(localStorage.getItem('CurrentUserEmail')) + '&AdminOffices=' + checkAdminOffices + '&OfficeID=' + OfficeID);
  }
  //End
  searchForBookOthers(searchText: string) {
    return this.http.get(this.HostUrl + 'api/UserDetails/SearchForBookOthers?searchText=' + searchText);
  }
  // getExternalSearchData(giventext: string) {
  //   return this.http.get(this.HostUrl + 'api/Admin/getExternalSearchData?giventext=' + giventext);
  // }

  // TranslateLanguage() {
  //   this.translate.addLangs(['en', 'fr']);
  //   if (localStorage.getItem('locale')) {
  //     const browserLang = localStorage.getItem('locale');
  //     this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  //   } else {
  //     localStorage.setItem('locale', 'en');
  //     this.translate.setDefaultLang('en');
  //   }
  // }
  //Encrypt and Decrypt
  decryptData(encryptedText: any) {
    try {
      let cipherPhrase = keys[0]['Encrypt'];
      var key = CryptoJS.enc.Utf8.parse(cipherPhrase);
      var iv = CryptoJS.enc.Utf8.parse('');
      var decrypted = CryptoJS.AES.decrypt(encryptedText.trim(), key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (e) { }
  }

  encrypt(plainText: any) {
    try {
      let cipherPhrase = keys[0]['Encrypt'];
      var key = CryptoJS.enc.Utf8.parse(cipherPhrase);
      var iv = CryptoJS.enc.Utf8.parse('');
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      let s = encrypted.toString();
      return btoa(s);
    } catch (e) { }
  }

  rescheduleMeeting(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Reservation/RescheduleMeeting', reservDetails);
  }

  getAccessToken() {
    return this.http.get(this.HostUrl + 'api/UserDetails/GetAccessToken?code=' + (localStorage.getItem('code')),
      { responseType: 'text' });
  }
  //Outlook Meeting Room Availability
  checkRoomAvailability(meetingDetails) {
    return this.http.post(this.HostUrl + 'api/Reservation/MeetingRoomAvailability', meetingDetails);

  }
  getAllDesksFromOfficeId(officeid: number, floorid: number, lang: string) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllDesksFromOfficeId?officeid=' + officeid + '&floorid=' + floorid + '&lang=' + lang);
  }
  // To Accept/Decline Outlook Invite
  sendResponse(response) {
    return this.http.post(this.HostUrl + 'api/Reservation/SendResponse', response);
  }

  //Check Meeting Response
  checkResponse(response) {
    return this.http.post(this.HostUrl + 'api/Reservation/CheckResponse', response, { responseType: 'text' })
  }
  //End

  //Get Meeting Name from Desk
  getMeetingName(deskId) {
    return this.http.get(this.HostUrl + 'api/Admin/getMeetingName?DeskId=' + deskId);
  }
  //Get Allowed Office Floor for Dashboard
  getAllowedOfficeFloor(allowedofficeList, selectedCountry) {
    return this.http.get(this.HostUrl + 'api/Admin/getAllowedOfficeFloor?allowedofficeList=' + allowedofficeList + "&country=" + selectedCountry);
  }
  getParkingSpacesForSwap(office_id: number, selectDate: any, revNumber: number) {
    return this.http.get(this.HostUrl + 'api/Parking/GetParkingSpacesForSwap?officeId=' + office_id + '&SelectedDateToBook=' + selectDate + '&revNumber=' + revNumber);
  }
  swapParkingByAdmin(carParkingPostDTO) {
    return this.http.post(this.HostUrl + 'api/Parking/SwapParkingByAdmin', carParkingPostDTO);
  }
  getParkingListForAdmin(office_id: number, selectDate: any) {
    return this.http.get(this.HostUrl + 'api/Parking/GetParkingListForAdmin?officeId=' + office_id + '&SelectedDateToBook=' + selectDate);
  }
  cancelParkReservationViaBooking(email: string, SelectedDateToCancel: string) {
    return this.http.get(this.HostUrl + 'api/Reservation/CancelParkingViaBooking?email=' + email + '&SelectedDateToCancel=' + SelectedDateToCancel);
  }
  getOfficesNotForSwap() {
    return this.http.get(this.HostUrl + 'api/Parking/GetOfficesNotForSwap');
  }
  getAdminRoles() {
    return this.http.get(this.HostUrl + 'api/Admin/GetRoles');
  }
  updateAdminSeatUnavailablity(params: SeatAvailablityDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/updateAdminSeatUnavailablity', params);
  }
  public uploadParkingAttachement(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/uploadParkingAttachement', formData);
  }
  public getLastBookings(email: string) {
    return this.http.get(this.HostUrl + 'api/Reservation/QuickBook?email=' + email);
  }
  public bookLastBookings(data: any) {
    return this.http.get(this.HostUrl + 'api/Reservation/ReserveLast?attachmentName=' + data.attachmentName + '&attachmentPath=' + data.attachmentPath + '&deskNumber=' + data.deskNumber + '&floorName=' + data.floorName + '&id=' + data.id + '&officeName=' + data.officeName + '&country=' + data.country + '&dateFormat=' + data.dateFormat + '&commonLanguage=' + data.commonLanguage);
  }
  //Get Quick Check In
  public getTodayCheckIn(email: string) {
    return this.http.get(this.HostUrl + 'api/Reservation/TodayCheckIn?email=' + email);
  }
  //Quick Check In
  public checkin(data: any) {
    return this.http.get(this.HostUrl + 'api/Reservation/CheckInCurrent?id=' + data.id);
  }
  //Get Quick Check Out
  public getTodayCheckOut(email: string) {
    return this.http.get(this.HostUrl + 'api/Reservation/TodayCheckOut?email=' + email);
  }
  //Quick Check Out
  public checkout(data: any) {
    return this.http.get(this.HostUrl + 'api/Reservation/CheckOutCurrent?id=' + data.id);
  }
  //Compliance Tracking
  addComplianceTracking(formData: FormData) {
    return this.http.post(this.HostUrl + 'api/Admin/SaveComplianceTracking', formData);
  }
  getAllCompliance(office: string) {
    return this.http.get(this.HostUrl + 'api/Admin/GetAllCompliance?office=' + office);
  }
  deleteTracking(id: number) {
    return this.http.delete(this.HostUrl + 'api/Admin/DeleteTracking?id=' + id);
  }
  getAccessibleCountry() {
    return this.http.get(this.HostUrl + 'api/Home/GetAccessibleCountryList?currentUser=' + this.encrypt(localStorage.getItem('CurrentUserEmail')));
  }
  getDailyReport(country: string, office: string) {
    return this.http.get(this.HostUrl + 'api/Home/GetDailyReport?country=' + country + '&office=' + office);
  }
  getDesksFromFloorIDForGym(floorid: number, booking_start_timing: string, booking_end_timing: string) {
    // return this.http.get(this.HostUrl + 'api/deskf/GetDeskFAvailablity?floorid=' + floorid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook'));
    return this.http.get(this.HostUrl + 'api/deskf/GetDeskFAvailablityForGym?floorid=' + floorid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing);
  }

  getDesksFromFloorIDWithBUForGym(floorid: number, buID: number, booking_start_timing: string, booking_end_timing: string, businessUnit: number) {
    return this.http.get(this.HostUrl + 'api/deskf/GetDeskFAvailablityForGym?floorid=' + floorid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing + '&buid=' + buID + '&businessUnit=' + businessUnit);
  }

  getDesksFromZoneIDForGym(zoneid: number, booking_start_timing: string, booking_end_timing: string) {
    return this.http.get(this.HostUrl + 'api/deskz/GetDeskZAvailablityForGym?zoneid=' + zoneid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing);
  }

  getDesksFromZoneIDWithBUForGym(zoneid: number, buID: number, booking_start_timing: string, booking_end_timing: string) {
    return this.http.get(this.HostUrl + 'api/deskz/GetDeskZAvailablityForGym?zoneid=' + zoneid + '&SelectedDateToBook=' + localStorage.getItem('SelectedDateToBook') + '&BookingStartTime=' + booking_start_timing + '&BookingEndTime=' + booking_end_timing + '&buID=' + buID);
  }
  MoreThan1HourForGym(data: any) {
    return this.http.post(this.HostUrl + 'api/Reservation/MoreThan1HourForGym', data);
  }
  getSelfReservationsGymForSwap(params: GetReservationParamsDTO) {
    return this.http.post(this.HostUrl + 'api/Reservation/GetSelfGymReservationsForSwap', params);
  }
  swapEquipment(reservDetails) {
    return this.http.post(this.HostUrl + 'api/Reservation/SwapEquipment', reservDetails);
  }
}
