import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/home/home.component';
import { ReservationComponent } from '../app/reservation/reservation.component';
import { BookingComponent } from '../app/booking/booking.component';
import { SelectseatComponent } from '../app/selectseat/selectseat.component';
import { LoginComponent } from '../app/login/login.component';
import { UsersettingsComponent } from '../app/usersettings/usersettings.component';
import { CancelResComponent } from '../app/cancel-res/cancel-res.component';
import { CheckinResComponent } from '../app/checkin-res/checkin-res.component';
import { CheckoutResComponent } from '../app/checkout-res/checkout-res.component';
import { SwapdeskComponent } from '../app/swapdesk/swapdesk.component';
import { TestComponent } from '../app/test/test.component';
import { SwapseatComponent } from '../app/swapseat/swapseat.component';
import { SupportFormComponent } from '../app/support-form/support-form.component';
import { AuthGuardService as AuthGuard } from './AuthGuard/auth-guard.service';
import { AdminComponent } from '../app/admin/admin.component';
import { FaqComponent } from './faq/faq.component';
import { OfficeDetailsComponent } from '../app/office-details/office-details.component';
import { AddOfficeComponent } from '../app/add-office/add-office.component';
import { AddFloorComponent } from '../app/add-floor/add-floor.component';
import { AddZoneComponent } from '../app/add-zone/add-zone.component';
import { DeskConfigurationComponent } from '../app/desk-configuration/desk-configuration.component';
import { ErrorScreenComponent } from '../app/error-screen/error-screen.component';
import { GenerateTokenComponent } from '../app/generate-token/generate-token.component';
import { ExternalUserConfigurationComponent } from './external-user-configuration/external-user-configuration.component';
import { ParkingComponent } from './parking/parking.component';
import { NewParkingComponent } from './new-parking/new-parking.component';
import { ParkingConfigurationComponent } from './parking-configuration/parking-configuration.component';
import { GlobalErrorscreenComponent } from './global-errorscreen/global-errorscreen.component';
import { OfficeDashboardComponent } from './office-dashboard/office-dashboard.component';
import { RegionalDashboardComponent } from './regional-dashboard/regional-dashboard.component';
import { ParkingListComponent } from './parking-list/parking-list.component';
import { ParkingPermitComponent } from './parking-permit/parking-permit.component';
import { BookingCancelComponent } from './booking-cancel/booking-cancel.component';
import { AddBUComponent } from '../app/add-BU/add-BU.component';
import { ParkingListSAComponent } from './parking-list-sa/parking-list-sa.component';
import { TranslationsComponent } from '../app/Translations/Translations.component';
import { DashboardAccessComponent } from './dashboard-access/dashboard-access.component';
import { AdminSwapParkingComponent } from './admin-swap-parking/admin-swap-parking.component';
import { CustomerfacingDashboardComponent } from './customerfacing-dashboard/customerfacing-dashboard.component';
import { ComplianceTrackingComponent } from './compliance-tracking/compliance-tracking.component';
import { GymReservationComponent } from './gym-reservation/gym-reservation.component';
import { GymBookingComponent } from './gym-booking/gym-booking.component';
import { GymSelectSeatComponent } from './gym-select-seat/gym-select-seat.component';
import { GymSwapSeatComponent } from './gym-swap-seat/gym-swap-seat.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'reservation', component: ReservationComponent, canActivate: [AuthGuard] },
  { path: 'booking', component: BookingComponent, canActivate: [AuthGuard] },
  { path: 'selectseat', component: SelectseatComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: UsersettingsComponent, canActivate: [AuthGuard] },
  { path: 'cancellation', component: CancelResComponent, canActivate: [AuthGuard] },
  { path: 'checkin', component: CheckinResComponent, canActivate: [AuthGuard] },
  { path: 'checkout', component: CheckoutResComponent, canActivate: [AuthGuard] },
  { path: 'swap', component: SwapdeskComponent, canActivate: [AuthGuard] },
  { path: 'swapseat', component: SwapseatComponent, canActivate: [AuthGuard] },
  { path: 'test', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'feedback', component: SupportFormComponent, canActivate: [AuthGuard] },
  { path: 'FAQ', component: FaqComponent, canActivate: [AuthGuard] },
  { path: 'parking', component: ParkingComponent, canActivate: [AuthGuard] },
  { path: 'reserveparking', component: NewParkingComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin', 'RegionalLeader', 'OfficeLeader', 'Manager'] } },
  { path: 'officedetails', component: OfficeDetailsComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperAdmin'] } },
  { path: 'office', component: AddOfficeComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperAdmin'] } },
  { path: 'floor', component: AddFloorComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperAdmin'] } },
  { path: 'BUConfiguration', component: AddBUComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperAdmin'] } },
  { path: 'zone', component: AddZoneComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperAdmin'] } },
  { path: 'deskConfiguration', component: DeskConfigurationComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin'] } },
  { path: 'parkingConfiguration', component: ParkingConfigurationComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin'] } },
  { path: 'listParkings', component: ParkingListComponent, canActivate: [AuthGuard], data: { permittedRoles: ['RegionalAdmin', 'RegionalLeader', 'SuperAdmin', 'Admin'] } },
  { path: 'parkingPermits', component: ParkingPermitComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin'] } },
  { path: 'forbidden', component: ErrorScreenComponent },
  { path: 'generatetoken', component: GenerateTokenComponent },
  { path: 'externalUserConfiguration', component: ExternalUserConfigurationComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin'] } },
  { path: 'globalerror', component: GlobalErrorscreenComponent },
  { path: 'officedashboard', component: OfficeDashboardComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin', 'RegionalLeader', 'OfficeLeader'] } },
  { path: 'regionaldashboard', component: RegionalDashboardComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin', 'RegionalLeader', 'OfficeLeader'] } },
  { path: 'bookingCancellation', component: BookingCancelComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin', 'RegionalAdmin'] } },
  { path: 'listParkingsSA', component: ParkingListSAComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperAdmin'] } },
  { path: 'Translations', component: TranslationsComponent, canActivate: [AuthGuard] },
  { path: 'dashboardAccess', component: DashboardAccessComponent, canActivate: [AuthGuard] },
  { path: 'adminSwapParking', component: AdminSwapParkingComponent, canActivate: [AuthGuard], data: { permittedRoles: ['RegionalAdmin', 'RegionalLeader', 'SuperAdmin', 'Admin'] } },
  { path: 'customerfacingdashboard', component: CustomerfacingDashboardComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Manager', 'SuperAdmin'] } },
  { path: 'complianceTracking', component: ComplianceTrackingComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperAdmin', 'Admin'] } },
  { path: 'gymReservation', component: GymReservationComponent, canActivate: [AuthGuard] },
  { path: 'gymBooking', component: GymBookingComponent, canActivate: [AuthGuard] },
  { path: 'gymSelectSeat', component: GymSelectSeatComponent, canActivate: [AuthGuard] },
  { path: 'gymSwapSeat', component: GymSwapSeatComponent, canActivate: [AuthGuard] },
];


@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
