import { Component, OnInit, NgZone } from '@angular/core';
import { userDataService } from '../services/data-share';
import { TranslateService } from '@ngx-translate/core';
import { ServiceService } from '../services/service.service';
import { Router } from '@angular/router';
import { OfficeSelectorComponent } from '../office-selector/office-selector.component';
import { SelectseatComponent } from '../selectseat/selectseat.component';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { NewParkingComponent } from '../new-parking/new-parking.component';
import { DatePipe } from '@angular/common';

import { FaqComponent } from '../faq/faq.component';
import { AddBUComponent } from '../add-BU/add-BU.component';
import { FormBuilder } from '@angular/forms';
import { ParkingListSAComponent } from '../parking-list-sa/parking-list-sa.component';
//import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class HeaderComponent implements OnInit {
  CurrentUserName: string;
  showPopupWrap = false;
  showPopupWraphelp = false;
  AllowAdmin: boolean = false;
  AdminUserRole: string = null;
  constructor(private dataService: userDataService, public translate: TranslateService, public Service: ServiceService,
    private router: Router, private ngzone: NgZone, private title: Title, private dom: DomSanitizer, public datepipe: DatePipe,
    private fb: FormBuilder) {
    let _langs = [];
    Service.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(Service.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {
    this.CurrentUserName = localStorage.getItem('CurrentUser');
    // if (localStorage.getItem('CurrentUserEmail') != undefined && localStorage.getItem('CurrentUserEmail') != 'null' && localStorage.getItem('CurrentUserEmail') != 'undefined') {
    //   this.Service.CheckIfAdminUser(localStorage.getItem('CurrentUserEmail')).subscribe((result: boolean) => {
    //     this.AllowAdmin = result;
    //   }, err => {
    //     this.AllowAdmin = false;
    //   });
    // }
    if (localStorage.getItem('token') != null) {
      this.EnableAdminButton();
    }
  }
  EnableAdminButton() {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if (userRole != 'user') {
      this.AllowAdmin = true;
      this.AdminUserRole = userRole;
    }
  }
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    if (this.router.url == '/booking') {
      let bookingMethod = new OfficeSelectorComponent(this.Service, this.ngzone, this.router, this.translate);
      bookingMethod.LoadOfficesBasedonLang();
    }
    else if (this.router.url == '/gymBooking') {
      let bookingMethod = new OfficeSelectorComponent(this.Service, this.ngzone, this.router, this.translate);
      bookingMethod.LoadOfficesBasedonLang();
    }
    else if (this.router.url == '/deskConfiguration') {
      let bookingMethod = new OfficeSelectorComponent(this.Service, this.ngzone, this.router, this.translate);
      bookingMethod.LoadOfficesBasedonLang();
    }
    else if (this.router.url == '/listParkings') {
      let bookingMethod = new NewParkingComponent(this.Service, this.ngzone, this.router, this.translate, this.title, this.dataService, this.datepipe);
      bookingMethod.LoadSpacedBasedonLang();
    }
    else if (this.router.url == '/listParkingsSA') {
      let bookingMethod = new NewParkingComponent(this.Service, this.ngzone, this.router, this.translate, this.title, this.dataService, this.datepipe);
      bookingMethod.LoadSpacedBasedonLang();
    }
    else if (this.router.url == '/reserveparking') {
      let bookingMethod = new NewParkingComponent(this.Service, this.ngzone, this.router, this.translate, this.title, this.dataService, this.datepipe);
      bookingMethod.LoadSpacedBasedonLang();
    }
    else if (this.router.url == '/settings') {
      let bookingMethod = new OfficeSelectorComponent(this.Service, this.ngzone, this.router, this.translate);
      bookingMethod.LoadOfficesBasedonLang();
    }
    else if (this.router.url == '/parkingConfiguration') {
      let bookingMethod = new NewParkingComponent(this.Service, this.ngzone, this.router, this.translate, this.title, this.dataService, this.datepipe);
      bookingMethod.LoadSpacedBasedonLang();
    }
    else if (this.router.url == '/FAQ') {
      let faqMethod = new FaqComponent(this.title, this.translate, this.Service, this.dataService, this.router,);
      faqMethod.LoadFAQBasedonLang();
    }
    else if (this.router.url == '/BUConfiguration') {
      let faqMethod = new AddBUComponent(this.Service, this.translate, this.fb, this.router);
      faqMethod.LoadAddBUBasedonLang();
    }
    else if (this.router.url == '/office') {
      let faqMethod = new AddBUComponent(this.Service, this.translate, this.fb, this.router);
      faqMethod.LoadAddBUBasedonLang();
    }
  }
  logout() {

    this.Service.logout().subscribe((data: any[]) => {
      window.location.reload();
      var a = localStorage.myusername;
      var b = localStorage.mypassword;
      localStorage.clear();
      localStorage.setItem('myusername', a);
      localStorage.setItem('mypassword', b);
    }, error => {

    })


    window.location.reload();
    var a = localStorage.myusername;
    var b = localStorage.mypassword;
    localStorage.clear();
    localStorage.setItem('myusername', a);
    localStorage.setItem('mypassword', b);
  }
  showMobilePopup() {
    this.dataService.setShowMobilePopup(true);
    document.getElementById('leftNavigation').classList.add('addshowmobilemenu');
  }

  onAdminClick() {
    this.router.navigateByUrl('/admin');

  }

  showHidePopupDropDown(type, isCheck) {
    if (type == 'userClick' && isCheck == false) {
      this.showPopupWrap = true;
      this.showPopupWraphelp = false;
    }
    else if (type == 'helpClick' && isCheck == false) {
      this.showPopupWrap = false;
      this.showPopupWraphelp = true;
    }
    else if ((type == 'helpClick' && isCheck == true) || (type == 'userClick' && isCheck == true) || (type == 'langClick')) {
      this.showPopupWrap = false;
      this.showPopupWraphelp = false;
    }
  }
}
