import { Injectable } from '@angular/core';
import { constructDependencies } from '@angular/core/src/di/reflective_provider';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ServiceService } from '../services/service.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  showMsgPopUp: boolean = false;
  popMsgTitle: string;
  popMsgDetail: string;
  constructor(private router: Router, private jwlHelper: JwtHelperService, private service: ServiceService) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    const token = localStorage.getItem('token');
    if (token && !this.jwlHelper.isTokenExpired(token)) {
      let roles = next.data['permittedRoles'] as Array<string>;
      if (roles) {
        if (this.service.roleMatch(roles)) return true;
        else {
          this.router.navigate(['/forbidden']);
          return false;
        }
      }
      return true;
    }
    // else if (localStorage.getItem('CurrentUserEmail') != null) {
    //   return true;
    // }
    else {
      this.router.navigate(['']);
      return false;
    }

  }



  //   if(token && !this.jwlHelper.isTokenExpired(token)) {
  //   if (localStorage.getItem('CurrentUserEmail') != null) {
  //     return true;
  //   }
  //   else {
  //     //this.showPopupMsg('Login to Access', 'You have to login to access this page!!');
  //     if (localStorage.getItem('currentDomain') == 'atkins') {
  //       return true;
  //     }
  //     alert("You have to login to access this page!!")
  //     this.router.navigate(['']);
  //     //this.router.navigateByUrl('/login');
  //     return false
  //   }
  // } else {
  //   this.router.navigate(['']);
  //   return false;
  // }

  //   }

  showPopupMsg(msgTitle: string, msgDetail: string) {
    this.showMsgPopUp = true;
    this.popMsgTitle = msgTitle;
    this.popMsgDetail = msgDetail;
  }
}
