import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import { tab } from '../utils';
import * as moment from 'moment';
@Component({
  selector: 'app-checkout-res',
  templateUrl: './checkout-res.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class CheckoutResComponent implements OnInit {
  showLoader: boolean = false;
  showMsgPopUp: boolean = false;
  selectedReservation: any;
  isCheckedIn: boolean = false;
  isCheckedOut: boolean = false;
  data: tab[];
  showPopUp: boolean = false;
  constructor(private ServiceService: ServiceService, private titleService: Title, private dataService: userDataService) { }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle("Check-out");
    localStorage.setItem('PageName', 'Checkout');
    var params = {
      CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
    };
    this.ServiceService.getTodayReservations(params).subscribe((data: any[]) => {
      this.showLoader = false;
      this.data = data;
    });
  }

  ResSelected(selRes) {
    this.showPopUp = true;
    this.showLoader = true;
    this.selectedReservation = selRes;
    this.ServiceService.getCheckedOutStatus(this.selectedReservation.reservationID).subscribe((data: any) => {
      this.isCheckedIn = data.isCheckedIn;
      this.isCheckedOut = data.isCheckedOut;
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });
  }

  closeBooking() {
    this.selectedReservation = undefined
    this.showPopUp = false;
    this.isCheckedIn = false;
    this.isCheckedOut = false;
  }

  checkOutBooking() {
    this.showLoader = true;
    var res_details = {
      ID: this.selectedReservation.reservationID
    }

    this.ServiceService.checkOutReservation(res_details).subscribe((data: any) => {
      // TO REFRESH THE DATA SOURCE
      var params = {
        CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
        Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
      }
      this.ServiceService.getTodayReservations(params).subscribe((data: any[]) => {
        this.showLoader = false;
        this.data = data;
        this.showMsgPopUp = true;
      });
      this.closeBooking();
      //alert('You have checked-out');
    });
  }

}
