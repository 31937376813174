import { BrowserModule, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common'
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceService } from './services/service.service';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReservationComponent } from './reservation/reservation.component';
import { BookingComponent } from './booking/booking.component';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { LoginComponent } from './login/login.component';
import { SelectseatComponent } from './selectseat/selectseat.component';
import { FloorplanComponent } from './floorplan/floorplan.component';
import { TestComponent } from './test/test.component';
import { HeaderComponent } from './header/header.component';
import { UsersettingsComponent } from './usersettings/usersettings.component';
import { CheckinResComponent } from './checkin-res/checkin-res.component';
import { CheckoutResComponent } from './checkout-res/checkout-res.component';
import { SwapdeskComponent } from './swapdesk/swapdesk.component';
import { CancelResComponent } from './cancel-res/cancel-res.component';
import { ResDetailsComponent } from './res-details/res-details.component';
import { SupportFormComponent } from './support-form/support-form.component';
import { HealthformComponent } from './healthform/healthform.component';
import { SwapseatComponent } from './swapseat/swapseat.component';
import { AlertMsgComponent } from './alert-msg/alert-msg.component';
import { OfficeSelectorComponent } from './office-selector/office-selector.component';
import { AuthGuardService } from './AuthGuard/auth-guard.service';
import { BookParkingComponent } from './book-parking/book-parking.component';
import { LogService } from './services/log.service';
import { JwtModule } from '@auth0/angular-jwt';
import { AdminComponent } from './admin/admin.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConvertDate } from './convert-date.pipe';
import { AddOfficeComponent } from './add-office/add-office.component';
import { FaqComponent } from './faq/faq.component';
import { AddFloorComponent } from './add-floor/add-floor.component';
import { AddZoneComponent } from './add-zone/add-zone.component';
import { DeskConfigurationComponent } from './desk-configuration/desk-configuration.component';
import { ErrorScreenComponent } from './error-screen/error-screen.component';
import { AddBUComponent } from './add-BU/add-BU.component';
import { AuthInterceptor } from './AuthGuard/auth.interceptor';
import { GenerateTokenComponent } from './generate-token/generate-token.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ExternalUserConfigurationComponent } from './external-user-configuration/external-user-configuration.component';
import { GlobalErrorscreenComponent } from './global-errorscreen/global-errorscreen.component';
import { ParkingComponent } from './parking/parking.component';
import { NewParkingComponent } from './new-parking/new-parking.component';
import { ParkingConfigurationComponent } from './parking-configuration/parking-configuration.component';
import { OfficeDashboardComponent } from './office-dashboard/office-dashboard.component';
import { RegionalDashboardComponent } from './regional-dashboard/regional-dashboard.component';
import { FloorplanMiniComponent } from './floorplan-mini/floorplan-mini.component';
import { LegendFloorplanComponent } from './legend-floorplan/legend-floorplan.component';
import { ParkingListComponent } from './parking-list/parking-list.component';
import { OfficeDetailsComponent } from './office-details/office-details.component';
import { ParkingPermitComponent } from './parking-permit/parking-permit.component';
import { YesNoPipe } from './yes-no.pipe';
import { BookingCancelComponent } from './booking-cancel/booking-cancel.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ParkingListSAComponent } from './parking-list-sa/parking-list-sa.component';
import { AddDesktypeComponent } from './add-desktype/add-desktype.component';
import { TranslationsComponent } from '../app/Translations/Translations.component';
import { DashboardAccessComponent } from './dashboard-access/dashboard-access.component';
import { AlertMsgParkingComponent } from './alert-msg-parking/alert-msg-parking.component';
import { HealthformBookingComponent } from './healthform-booking/healthform-booking.component';
import { RegionOfficeSelectorComponent } from './region-office-selector/region-office-selector.component';
import { environment } from './../environments/environment';
import { AdminSwapParkingComponent } from './admin-swap-parking/admin-swap-parking.component';
import { AlertDeleteParkingComponent } from './alert-delete-parking/alert-delete-parking.component';
import { CustomerfacingDashboardComponent } from './customerfacing-dashboard/customerfacing-dashboard.component';
import { ComplianceTrackingComponent } from './compliance-tracking/compliance-tracking.component';
import { GymReservationComponent } from './gym-reservation/gym-reservation.component';
import { GymBookingComponent } from './gym-booking/gym-booking.component';
import { GymSelectSeatComponent } from './gym-select-seat/gym-select-seat.component';
import { GymFloorplanComponent } from './gym-floorplan/gym-floorplan.component';
import { GymSwapSeatComponent } from './gym-swap-seat/gym-swap-seat.component';


export const createtransalteloader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
};

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ReservationComponent,
    BookingComponent,
    LeftNavComponent,
    LoginComponent,
    SelectseatComponent,
    FloorplanComponent,
    HeaderComponent,
    UsersettingsComponent,
    TestComponent,
    CheckinResComponent,
    CheckoutResComponent,
    SwapdeskComponent,
    CancelResComponent,
    ResDetailsComponent,
    SupportFormComponent,
    HealthformComponent,
    SwapseatComponent,
    AlertMsgComponent,
    OfficeSelectorComponent,
    BookParkingComponent,
    AdminComponent,
    ConvertDate,
    AddOfficeComponent,
    FaqComponent,
    AddFloorComponent,
    AddZoneComponent,
    DeskConfigurationComponent,
    ErrorScreenComponent,
    GenerateTokenComponent,
    ParkingComponent,
    NewParkingComponent,
    ParkingConfigurationComponent,
    ExternalUserConfigurationComponent,
    GlobalErrorscreenComponent,
    OfficeDashboardComponent,
    RegionalDashboardComponent,
    FloorplanMiniComponent,
    LegendFloorplanComponent,
    ParkingListComponent,
    OfficeDetailsComponent,
    ParkingPermitComponent,
    YesNoPipe,
    BookingCancelComponent,
    AddBUComponent,
    ParkingListSAComponent,
    AddDesktypeComponent,
    TranslationsComponent,
    DashboardAccessComponent,
    AlertMsgParkingComponent,
    HealthformBookingComponent,
    RegionOfficeSelectorComponent,
    AdminSwapParkingComponent,
    AlertDeleteParkingComponent,
    CustomerfacingDashboardComponent,
    ComplianceTrackingComponent,
    GymReservationComponent,
    GymBookingComponent,
    GymSelectSeatComponent,
    GymFloorplanComponent,
    GymSwapSeatComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, BrowserAnimationsModule,
    CdkStepperModule,
    CdkTableModule,
    RecaptchaModule,
    CommonModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.apiModuleURL],//["https://edpmauthforsms.azurewebsites.net"],//["https://localhost:44380/"], //
        disallowedRoutes: []
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createtransalteloader,
        deps: [HttpClient]
      }
    }),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    })
  ],
  providers: [Title, DatePipe, AuthGuardService, LogService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
