import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { userDataService } from '../services/data-share';
import { tab } from '../utils'
import { GetReservationParamsDTO } from '../Model/getReservationParamsDTO';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  // styleUrls: ['./reservation.component.sass']
  styleUrls: ['../../assets/css/style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservationComponent implements OnInit {
  showLoader: boolean = false;
  data: tab[];
  data_api: tab[];
  selectedReservation: any;
  showPopUp: boolean = false;
  showReservationPopUp: boolean = false;
  showMsgPopUp: boolean = false;
  bookingFor: string;
  selfvalue: string = 'self';
  currentDomain: string;
  passingParams: GetReservationParamsDTO;
  selDeskNumber: string;
  selFloorPlanPath;
  showFloorplanPopUp: boolean = false;

  constructor(private ServiceService: ServiceService, private titleService: Title, private dataService: userDataService, private router: Router, public datepipe: DatePipe, private dom: DomSanitizer) {
  }

  ngOnInit() {
    localStorage.removeItem('BackfromSelectSeatPage');
    localStorage.removeItem('SelectedBookingTypefromBooking');
    localStorage.removeItem('SelectedSingleDatefromBooking');
    localStorage.removeItem('SelectedMultiDatesfromBooking');
    localStorage.removeItem('SelectedOfficeIdfromBooking');
    localStorage.setItem('RedirectFromParking', "0");
    localStorage.setItem('OfficeID', "0");
    localStorage.setItem('SelectedDateToBookForParking', 'undefined');
    localStorage.setItem('NotShowParkingPopup',"1");
    localStorage.setItem('HealthForm', '');
    this.showLoader = true;
    this.titleService.setTitle("All Bookings");
    localStorage.setItem('PageName', 'Res');
    this.bookingFor = "self";
    localStorage.setItem('bookingFor', 'self');
    localStorage.setItem('pastdaybooking', 'false');
    var params = {
      CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      Date: moment.utc(new Date()).local().format('YYYY-MM-DD'),
      Code: localStorage.getItem('AccessToken')
    };
    // this.passingParams = params;
    this.ServiceService.getReservations(params).subscribe((data: any[]) => {
      this.data_api = data;
      if (this.bookingFor = "self") {
        this.data = this.data_api.filter(x => x.bookedFor == 'Self');
      }
      this.showLoader = false;
    });
    // this.data = "[{"title": "Upcoming","bookedFor": "Self","reservationDetails": [{"reservationID": 0,"emailId": "govendan.am@snclavalin.com","date": "2020 - 12 - 31T00: 00: 00","location": "Norman","status": 0,"deskNumber": null,"floorName": null,"reservedFor": null}]},{"title": "History","bookedFor": "Self","reservationDetails": [{"reservationID": 0,"emailId":"govendan.am@snclavalin.com","date": "2020 - 12 - 13T00: 00: 00","location": "Chicago","status": 0,"deskNumber": null,"floorName": null,"reservedFor": null}]},{"title": "Upcoming","bookedFor": "Others","reservationDetails": [{"reservationID": 0,"emailId": "govendan.am@snclavalin.com","date": "2020 - 12 - 30T00: 00: 00","location": "New York","status": 0,"deskNumber": null,"floorName": null,"reservedFor": null}]},{"title": "History","bookedFor": "Others","reservationDetails": []}]";
    this.currentDomain = localStorage.getItem('currentDomain');
  }
  PageTab(evt, tabName) {
    var i, x, tablinks;
    x = document.getElementsByClassName("tabContent");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("pagelink");
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";

    if (tabName == 'History') {
      document.getElementById('Historys').classList.add('active');
      document.getElementById('Upcomings').classList.remove('active');
    }
    else {
      document.getElementById('Upcomings').classList.add('active');
      document.getElementById('Historys').classList.remove('active');
    }

  }
  Booking() {
    //When coming from reservation screen, set Today's date and tab to Self booking
    //localStorage.setItem('bookingFor', 'self');

    var currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    localStorage.setItem('SelectedDateToBook', currentDate);
    localStorage.setItem('SelectedDateToBookWithUTC', new Date().toString());
    ((localStorage.getItem('SelectedCountry') == 'United States') && this.bookingFor == 'self') ? this.showPopUp = true : this.router.navigateByUrl('/booking');
  }
  closeBooking() {
    this.showPopUp = false;
    this.showReservationPopUp = false;
  }
  onItemChange(value) {
    this.bookingFor = value;
    localStorage.setItem('bookingFor', this.bookingFor);
    this.data = (this.bookingFor == "self") ? this.data_api.filter(x => x.bookedFor == 'Self') : this.data_api.filter(x => x.bookedFor == 'Others')
  }

  resHealthFormCheck(showMsgPopup: boolean) {
    this.showPopUp = false
    if (showMsgPopup == true) {
      this.showMsgPopUp = true;
    }
  }
  ResSelected(detail) {
    this.selectedReservation = detail;
    this.showReservationPopUp = true;
    // this.ServiceService.getCheckedInStatus(this.selectedReservation.reservationID).subscribe((data: any) => {
    //   // this.isCheckedIn = data.isCheckedIn;
    // });
  }
}
