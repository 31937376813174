import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import * as moment from 'moment';
import { bypassSanitizationTrustStyle } from '@angular/core/src/sanitization/bypass';
import { TranslateService } from '@ngx-translate/core'
import { tr } from 'date-fns/locale';

@Component({
  selector: 'app-healthform',
  templateUrl: './healthform.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class HealthformComponent implements OnInit {
  @Input() redirectTo: string;
  @Input() fromReservationId: number;
  @Output() showPopUp = new EventEmitter<boolean>();

  // symptomsChk: boolean = false;
  // testingChk: boolean = false;
  // exposureChk: boolean = false;
  completeChkYes: boolean = false;
  completeChkNo: boolean = true;
  firstToggle: boolean = false;
  showMsgPopUp: boolean = false;
  showLoader: boolean = false;
  selectedCountry: string;
  browserLang1:string;

  constructor(private ServiceService: ServiceService,
    private titleService: Title,
    private dataService: userDataService, private router: Router, public translate: TranslateService) {
    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(ServiceService.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  toggleBtn(yesClicked: boolean) {
    if (!this.firstToggle) {
      this.firstToggle = true;
      if (yesClicked) {
        this.completeChkYes = true;
        this.completeChkNo = false;
      } else {
        this.completeChkYes = false;
        this.completeChkNo = true;
      }
    } else {
      if (yesClicked) {
        this.completeChkNo = false;
        this.completeChkYes = true;
      } else {
        this.completeChkYes = false;
        this.completeChkNo = true;
      }
    }
  }

  currentSelection(value) {
    if (value == 'yes') {
      this.firstToggle = true;
      this.completeChkYes = false;
      this.completeChkNo = true;
    }
    else {
      this.firstToggle = false;
      this.completeChkYes = true;
      this.completeChkNo = false;
    }
  }



  ngOnInit() {
    this.titleService.setTitle("Help desk");
    this.browserLang1=localStorage.getItem('locale');
    if(this.router.url=='/checkin')
    {
      if(this.dataService.selectedCountryForCheckin.getValue()!='No')
      {
        this.selectedCountry=this.dataService.selectedCountryForCheckin.getValue();
        this.dataService.selectedCountryForCheckin.next('No');
      }
      else{this.selectedCountry = localStorage.getItem('SelectedCountry');}
    }
    else{
    this.selectedCountry = localStorage.getItem('SelectedCountry');
    }
    // if(this.selectedCountry=='South America')
    // {
    //   this.completeChkYes= true;
    //   this.completeChkNo= false;
    // }
  }

  formSubmit() {
    this.showLoader = true;
    if(this.completeChkNo == true)
    {
      localStorage.setItem('HealthForm', 'Submit');
    }
    if (this.completeChkYes == true && this.completeChkNo == false) {
      var userDetail = {
        Name: localStorage.getItem('CurrentUser'),
        SenderMail: localStorage.getItem('CurrentUserEmail'),
        Title: '',
        Description: ''
      };
      this.ServiceService.postFailHealthAssmnt(userDetail).subscribe((data: any) => {
        //alert('You have failed health assessment!');
        //this.showMsgPopUp = false;
        //this.closeBooking();
        this.showLoader = false;
        this.showPopUp.emit(true);
        //document.getElementById('newHealthform').classList.add('removeshowmobilemenu');
      });

    }
    else {
      if (this.redirectTo == 'BOOKING') {
        this.router.navigateByUrl('/booking');
      }
      else if (this.redirectTo == 'PARKING') {
        this.router.navigateByUrl('/reserveparking');
      }
      else { //'CHECKIN'
        this.showLoader = true;
        var res_details = {
          ID: this.fromReservationId
        }

        this.ServiceService.checkInReservation(res_details).subscribe((data: any) => {
          // TO REFRESH THE DATA SOURCE
          // var params = {
          //   CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
          //   Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
          // }
          // this.ServiceService.getTodayReservations(params).subscribe((data: any[]) => {
          //   this.showLoader = false;
          //   this.data = data;
          //   this.showMsgPopUp = true;
          // });
          this.showMsgPopUp = true;
          this.showLoader = false;
          document.getElementById('newHealthform').classList.add('removeshowmobilemenu');
          //this.showMsgPopUp = true;

          //alert('You have checked-in');
        });
      }
    }
  }

  closeBooking() {
    this.showPopUp.emit(false);
    document.getElementById('newHealthform').classList.add('removeshowmobilemenu');
  }

}
