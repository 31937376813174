import { Component, OnInit, ViewEncapsulation, EventEmitter, NgZone } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ParkingItemApproved } from '../Model/CarParkingDTO';

@Component({
  selector: 'app-parking-list',
  templateUrl: './parking-list.component.html',
  styleUrls: ['../../assets/css/style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParkingListComponent implements OnInit {
  readonly bookingTypeMap = new Map<string, number>();
  offices: any;
  parkSpaces: ParkingItemApproved[];
  filteredParkSpaces: ParkingItemApproved[];
  parkingTypes: any[];
  selParkSpace: any;
  selectedOffice: any;
  selParkType: any;
  today: Date;
  minDate: string;
  maxDate: string;
  currentDate: any;
  showMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  validateButton: boolean = false;
  showLoader: boolean = false;
  licenseText: string;
  selSpaceId: number;
  selBkngType: number;
  selSpacePermitReq: boolean = false;
  ConfirmMsg: string;
  ParkingCancelled: string;
  isSouthAmerica: boolean;
  selectedReservation: any;
  showReservationPopUp: boolean = false;
  ParkingType: string;
  ParkingTime: string;
  OfficeName: string;
  parkingInfoForFilter: any;
  //OfficeNameNotToShowSwapPopup: string[] =["Safina","test","Test Manchester","Floor Testing"];
  showErrSwapNotPossible: boolean;
  okBtnText: string = "OK";
  OfficeNameNotToShowSwapPopup:any;

  constructor(private ServiceService: ServiceService, private ngzone: NgZone, private router: Router
    , public translate: TranslateService, private titleService: Title,
    private dataService: userDataService,
    public datepipe: DatePipe) {

    this.bookingTypeMap.set('ALL', 1);
    this.bookingTypeMap.set('AM', 2);
    this.bookingTypeMap.set('PM', 3);
  }

  ngOnInit() {
    this.isSouthAmerica = localStorage.getItem('SelectedCountry') != "South America" ? false : true;
    this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), true).subscribe((data: any[]) => {
      this.offices = data.filter(o => o.hasParking == true);

      if (this.offices.length > 0 && (localStorage.getItem('OfficeID') != undefined)) {
        this.selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
        // let _officeDetail = this.offices.find(x => x.id == this.selectedOffice);
        this.officeLoader();
      }
    });
    this.ServiceService.getOfficesNotForSwap().subscribe((data: any[]) => {
      this.OfficeNameNotToShowSwapPopup=data;      
    }); 

    this.currentDate = new Date()
    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.today = localStorage.getItem('SelectedDateToBook') == undefined ? this.currentDate : localStorage.getItem('SelectedDateToBook');
    this.minDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.maxDate = this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');

    this.titleService.setTitle("View Parking Reservations");
    localStorage.removeItem('Date');
    localStorage.removeItem('licenseText');
    localStorage.removeItem('OfficeName');
    localStorage.removeItem('ParkingID');
    localStorage.removeItem('ParkingMailID');

    localStorage.setItem('RedirctFromAdminSwap', 'false');
  }

  changeOffice(e) {
    var _offID = e.target.value;
    this.officeLoader();
  }
  onKeyDownEvent(event: any) {
    event.preventDefault();
  }

  officeLoader() {
    this.selParkType = undefined;
    //this.selectedOffice=this.offices[0].id;
    if (this.selectedOffice == undefined || this.selectedOffice == 'undefined' || this.selectedOffice == "null") return;




    this.ServiceService.getParkingListForAdmin(this.selectedOffice, this.today).subscribe((data: any) => {
      this.parkingInfoForFilter = data;
    });







    this.parkSpacesLoader();
    this.OfficeName = this.offices.filter(o => o.id == this.selectedOffice)[0].name;
    this.ServiceService.getParkingTypes(this.selectedOffice).subscribe((data: any[]) => {
      this.parkingTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));;
    })

  }


  parkSpacesLoader() {
    if (this.selectedOffice == undefined || this.selectedOffice == "undefined" || this.selectedOffice == "null") return;
    if (this.today.toString() != "") {
      this.showLoader = true;
    }
    this.ServiceService.getParkingSpaces(this.selectedOffice, this.today).subscribe((data: any) => {
      let allSpaces = data.allSpaces;
      let reservedSpaces = data.reservedSpaces;
      this.parkSpaces = [];
      this.filteredParkSpaces = [];
      allSpaces.filter(x => x.langKey == localStorage.getItem('locale')).forEach(e => {
        let _space = {} as ParkingItemApproved;
        _space.id = e.id;
        _space.name = e.spaceNumber;
        _space.AMvalue = "AM_" + e.id;
        _space.PMvalue = "PM_" + e.id;
        _space.ALLvalue = "ALL_" + e.id;
        _space.type = e.spaceType;
        _space.typeId = e.spaceTypeId;
        _space.permitRequired = e.permitRequired;
        _space.location = e.location;

        var spaceBooked = reservedSpaces.filter(value => value.id === e.id);
        if (spaceBooked.length > 0) {
          let bkngType = spaceBooked[spaceBooked.length - 1].bookingType;
          let bkngStatus = spaceBooked[spaceBooked.length - 1].isBooked;
          let Approved = spaceBooked[spaceBooked.length - 1].approved;
          if (bkngStatus) {
            if (bkngType == 1) {
              _space.amBooked = false; _space.pmBooked = false; _space.allBooked = true;
              if (Approved == true) { _space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = true; }
              _space.licensePlate = spaceBooked[spaceBooked.length - 1].licensePlate;
            }
            if (bkngType == 2) {
              _space.amBooked = true; _space.pmBooked = false; _space.allBooked = false;
              if (Approved == true) {
                _space.amBookedApproved = true; _space.pmBookedApproved = false; _space.allBookedApproved = false;
              }
              _space.licensePlate = ('AM:' + spaceBooked[spaceBooked.length - 1].licensePlate);
              _space.reservedFor = spaceBooked[spaceBooked.length - 1].reservedFor;
              _space.reservedId = spaceBooked[spaceBooked.length - 1].reservedId;
            }
            if (bkngType == 3) {
              _space.amBooked = false; _space.pmBooked = true; _space.allBooked = false;
              if (Approved == true) {
                _space.amBookedApproved = false; _space.pmBookedApproved = true; _space.allBookedApproved = false;
              }
              _space.licensePlate = ('PM:' + spaceBooked[spaceBooked.length - 1].licensePlate);
              _space.reservedFor1 = spaceBooked[spaceBooked.length - 1].reservedFor;
              _space.reservedId1 = spaceBooked[spaceBooked.length - 1].reservedId;
            }

            _space.reservedFor = spaceBooked[spaceBooked.length - 1].reservedFor;
            _space.reservedId = spaceBooked[spaceBooked.length - 1].reservedId;
          }
          else {
            _space.amBooked = false; _space.pmBooked = false; _space.allBooked = false;
          }
          if (spaceBooked.length > 4) {
            let bkngType = spaceBooked[spaceBooked.length - 2].bookingType;
            let bkngStatus = spaceBooked[spaceBooked.length - 2].isBooked;
            let Approved = spaceBooked[spaceBooked.length - 2].approved;
            if (bkngStatus) {
              if (bkngType == 2) {
                _space.amBooked = true; _space.allBooked = false;
                if (Approved == true) {
                  _space.amBookedApproved = true; _space.allBookedApproved = false;
                }
                if (_space.licensePlate != null || _space.licensePlate != undefined) {
                  _space.licensePlate += (' / AM: ' + spaceBooked[spaceBooked.length - 2].licensePlate);
                }
                _space.reservedFor = spaceBooked[spaceBooked.length - 2].reservedFor;
                _space.reservedId = spaceBooked[spaceBooked.length - 2].reservedId;
              }
              if (bkngType == 3) {
                _space.pmBooked = true; _space.allBooked = false;
                if (Approved == true) {
                  _space.pmBookedApproved = true; _space.allBookedApproved = false;
                }
                if (_space.licensePlate != null || _space.licensePlate != undefined) {
                  _space.licensePlate += (' / PM: ' + spaceBooked[spaceBooked.length - 2].licensePlate);
                }
                _space.reservedFor1 = spaceBooked[spaceBooked.length - 2].reservedFor;
                _space.reservedId1 = spaceBooked[spaceBooked.length - 2].reservedId;
              }

            }
          }
        } else {
          _space.amBooked = false; _space.pmBooked = false; _space.allBooked = false;
          _space.amBookedApproved = false; _space.pmBookedApproved = false; _space.allBookedApproved = false;
          _space.licensePlate = null;
        }
        this.parkSpaces.push(_space);
        this.filteredParkSpaces.push(_space);
      });
      this.showLoader = false;
      this.validateButton = false;
    });
  }

  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }

  changeDate() {
    this.selParkType = undefined;
    this.parkSpacesLoader();
    this.officeLoader();
  }

  cancelBooking(reservedId: number, typeId: number) {
    var res_details = {
      ID: reservedId,
      TypeId: typeId
    };

    this.translate.get('Areyousuretocanceltheparkings').subscribe((data: string) => { this.ConfirmMsg = data; });
    if (confirm(this.ConfirmMsg)) {
      this.showLoader = true;
      this.ServiceService.cancelParkReservation(res_details).subscribe((data: any) => {
        this.showLoader = false;
        this.translate.get('ParkingCancelled').subscribe((data: string) => { this.ParkingCancelled = data; })
        alert(this.ParkingCancelled);
        this.officeLoader();
      });
    }
  }

  SwapBooking(reservedId: number, typeId: number) {

    var ParkingDetails = this.parkingInfoForFilter.filter(x => x.reservedId == reservedId && x.langKey == localStorage.getItem('locale'));

    this.selectedReservation = ParkingDetails[0];

    this.selectedReservation.bookingDate = this.today;
    this.selectedReservation.officeName = this.OfficeName;
    if (this.selectedReservation.bookingType != null || this.selectedReservation.bookingType == "") {
      if (this.selectedReservation.bookingType == 2) {
        this.ParkingTime = "ParkingTabHead2";
      }
      else if (this.selectedReservation.bookingType == 1) {
        this.ParkingTime = "ParkingTabHead4";
      }
      else {
        this.ParkingTime = "ParkingTabHead3";
      }
    }
    if(this.OfficeNameNotToShowSwapPopup.indexOf(this.selectedReservation.officeName) > -1) {
      this.showErrSwapNotPossible=true;
      this.translate.get('Warning').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('ParkingSwapNotShow').subscribe((data: string) => { this.PopUpMessage = data; });
    }
    else{   
    this.showReservationPopUp = true;
    }
  }
  closeBooking() {
    this.showReservationPopUp = false;
  }
  SwapParking() {
    localStorage.setItem('RedirctFromAdminSwap', 'true');
    this.dataService.setParkingDetailsAdmin(this.selectedReservation);
    this.router.navigateByUrl('/adminSwapParking');
  }
  formSubmit()
  {
    this.showLoader=false;
    this.showErrSwapNotPossible=false;
  }

}
