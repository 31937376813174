import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { sortDataByDate } from '../utils'

@Injectable({ providedIn: 'root' })
export class userDataService {
    readonly STYLE_PATH = '../../assets/css/style.scss';

    selectedDeskNumber: string;
    getSelectedDeskNumber() {
        return this.selectedDeskNumber;
    }
    setSelectedDeskNumber(data: string) {
        this.selectedDeskNumber = data;
    }

    selectedDateDetails = [];
    getSelectedDateList() {
        return this.selectedDateDetails;
    }
    setSelectedDateList(data: any) {
        this.selectedDateDetails = data;
    }

    dateSelection: string;
    getSingleOrMultipleDateSelection() {
        return this.dateSelection;
    }
    setSingleOrMultipleDateSelection(data: string) {
        this.dateSelection = data;
    }

    SelectedDatesToBook: any;
    setSelectedDatesToBook(selectedDate: any) {
        this.SelectedDatesToBook = selectedDate;
    }
    getSelectedDatesToBook() {
        if (this.SelectedDatesToBook == undefined) {
            return this.SelectedDatesToBook
        } else {
            return sortDataByDate(this.SelectedDatesToBook);
        }
    }


    swapReservation: any;
    getSwapResDetails() {
        return this.swapReservation;
    }
    setSwapResDetails(data: any) {
        this.swapReservation = data;
    }

    showMobilePopup: boolean = false;
    getShowMobilePopup() {
        return this.showMobilePopup;
    }
    setShowMobilePopup(data: boolean) {
        this.showMobilePopup = data;
    }

    bookedDeskDetails: any;
    getBookedDeskDetails() {
        return this.bookedDeskDetails;
    }
    setBookedDeskDetails(data: any) {
        this.bookedDeskDetails = data;
    }
    // For Meeting Room, to send by default value as Yes
    showDesk = new BehaviorSubject('Yes');

    //For Checkin Selected Country for Popup
    selectedCountryForCheckin = new BehaviorSubject('No');

    //For Swap to Swap Parking
    redirectToParking = new BehaviorSubject('No');
    countryName = new BehaviorSubject('No');

    //Parking Reservation Details
    bookedParkingDetails: any;
    getParkingDetails() {
        return this.bookedParkingDetails;
    }
    setParkingDetails(data: any) {
        this.bookedParkingDetails = data;
    }

    //Parking Reservation Details For Admin Swap
    bookedParkingDetailsAdmin: any;
    getParkingDetailsAdmin() {
        return this.bookedParkingDetailsAdmin;
    }
    setParkingDetailsAdmin(data: any) {
        this.bookedParkingDetailsAdmin = data;
    }
    showGYMIcon: boolean = false;
    getShowGymIcon() {
        return this.showGYMIcon;
    }
    setShowGymIcon(data: boolean) {
        this.showGYMIcon = data;
    }
}
