import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { DesksDTO } from '../Model/Desks';
import { TranslateService } from '@ngx-translate/core';
import { SpacesDTO } from '../Model/CarParkingDTO';
import { HttpEventType, HttpRequest } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { FormControl, FormGroup } from '@angular/forms';
import { FloorDTO } from '../Model/FloorDTO';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-parking-configuration',
  templateUrl: './parking-configuration.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class ParkingConfigurationComponent implements OnInit {
  versionTitle: string;
  offices: any;
  selectedOffice: any;
  showLoader: boolean = false;
  parkSpaces: SpacesDTO[];
  DataToHold:any;
  ListtoSave: Array<SpacesDTO> = [];
  ConfirmMsg: string;
  bayUpdated: string;
  pleaseupdateatleastonedesk: string;
  SpaceDTO: SpacesDTO;
  parkingTypes: any[];
  displayedColumns: string[] = ['spaceNumber', 'isAvailable', 'permitRequired', 'spaceTypeId'];
  showUploadMsgPopUp: boolean = false;
  msgUploadTitle: string;
  PopUpUploadMessage: string;
  public progress: number;
  public message: string;
  givenText: string;
  public floorDTO: FloorDTO;
  files: string[] = [];
  formDetails: FormData;
  PopUpMessage: string;
  msgTitle: string;
  @ViewChild('file') myInputFile: ElementRef;
  okBtnText: string = "OK";

  FloorForm = new FormGroup({
    OfficeId: new FormControl(''),
    FloorplanAttachmentPath: new FormControl('')
  });

  public ReadmeForExcel:Array<Object> = [
    {
        'space_id': '123/A123',
        'Type': 'Electric Vehicle',
        'office_id': 123,
        'permit_required': 'FALSE',
        'is_available': 'TRUE',
        'location': 'P1'
    },
    {
      'space_id': ''
    },
    {
        'space_id': 'INSTRUCTION'
    },
    {
      'space_id': ''
    },
    {
        'space_id': 'Instructions to fill the \'ParkingData\' Sheet '
    },
    {
        'space_id': 'Column A:-\'space_id\' Enter all the Parking Space IDs belonging to the office in this column as per the data sheet '
    },
    {
        'space_id': 'Column B:-\'Type\' Enter the Type of the Parking Space. Ex.- Electric Vehicle, Car, Bike, Cycle etc'
    },
    {
        'space_id': 'Column C:-\'office_id\' will auto-populate in this column ,the same should be applied/populated to all the parking space ID\'\s.  Note that this column should have only the autopopulated ID. '
    },
    {
        'space_id': 'Column D:-\'permit_required\' Enter TRUE/FALSE based on the permit needed/permit not needed for given parking Space numbers. If TRUE, people with permits will need to be entered through the Parking Permit Holders Admin Tool. '
    },
    {
        'space_id': 'Column E:-\'is_available\' Enter TRUE/FALSE based on parking space to be displayed/not displayed to the user. Parking number not displayed would be used in scenarios where you are only concerned about the count and not which space they park in. '
    },
    {
        'space_id': 'Column F:-\'location\' Kindly Enter the location of the Parking i.e parking garage level 1, basement, west lot, etc'
    }
];

  @Output() public onUploadFinished = new EventEmitter();
  showErrPopUpWarning: boolean;
  constructor(private service: ServiceService, public translate: TranslateService,
    private ServiceService: ServiceService) {
    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(service.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {
    localStorage.removeItem('ParkingReservLocation');
    this.selectedOffice = localStorage.getItem('ParkingReservLocation') == undefined ? 'Choose your Location' : localStorage.getItem('ParkingReservLocation');
    if (this.selectedOffice != null || this.selectedOffice != "") {
      this.ServiceService.getAllParkingSpaces(this.selectedOffice).subscribe((data: any[]) => {
        this.parkSpaces = data as SpacesDTO[];
        this.DataToHold=data;
        this.showLoader = false;

        this.ServiceService.getAllParkingTypes().subscribe((data: any[]) => {
          this.parkingTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));;
        })

      });
    }
    this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), true).subscribe((data: any[]) => {
      this.offices = data.filter(o => o.hasParking == true);
    });
  }

  changeOffice(e) {
    var _offID = e.target.value;
    localStorage.setItem('ParkingReservLocation', e.target.value);
    this.showLoader = true;
    this.ServiceService.getAllParkingSpaces(this.selectedOffice).subscribe((data: any[]) => {
      this.parkSpaces = data as SpacesDTO[];
      this.DataToHold=data;
      this.showLoader = false;

      this.ServiceService.getAllParkingTypes().subscribe((data: any[]) => {
        this.parkingTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));;
      })

    });
  }

  getRecord() {
    if (this.ListtoSave.length > 0) {
      this.translate.get('Areyousuretoupdatetheparkings').subscribe((data: string) => { this.ConfirmMsg = data; })
      if (confirm(this.ConfirmMsg)) {
        this.showLoader = true;
        this.service.UpdateParkingConfiguration(this.ListtoSave).subscribe((result: any) => {
          this.showLoader = false;
          this.translate.get('ParkingBayUpdated').subscribe((data: string) => { this.bayUpdated = data; })
          alert(this.bayUpdated);
        });
      }
    }
    else {
      if (this.selectedOffice == null || this.selectedOffice == "Choose your Location" || this.selectedOffice == "undefined") {
        this.translate.get('ChooseyourLocation').subscribe((data: string) => { this.selectedOffice = data; })
        alert(this.selectedOffice);
      }
      else {
        this.translate.get('pleaseupdateatleastoneparking').subscribe((data: string) => { this.pleaseupdateatleastonedesk = data; })
        alert(this.pleaseupdateatleastonedesk);
      }
    }
  }

  AddSpacesToList(isChecked, bay, param) {
    this.showLoader = true;
    this.SpaceDTO = bay;

    switch (param) {
      case 'checkboxPermit':
        this.SpaceDTO.PermitRequired = isChecked.target.checked;
        break;
      case 'checkboxInUse':
        this.SpaceDTO.IsAvailable = isChecked.target.checked;
        break;
      case 'selectType':
        this.SpaceDTO.TypeId = Number(isChecked.target.value);
        break;
    }

    this.SpaceDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    let elementId = param + bay.id;
    document.getElementById(elementId).classList.add('ChangeSelectedCheckBoxColor');
    const index: number = this.ListtoSave.indexOf(bay);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.SpaceDTO);
    this.showLoader = false;
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }
  downloadExcelTemplate() {
    this.showLoader = true;
    this.service.getCreateExcelForParking(parseInt(this.selectedOffice))
      .subscribe((data: any) => {
        this.downloadExcelFile1(data);
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
      });
  }

  downloadExcelFile1(data: any) {
    this.showLoader = true;
    var date = new Date();
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(data);
    var ws1 = XLSX.utils.json_to_sheet(this.ReadmeForExcel);
    XLSX.utils.book_append_sheet(wb, ws, 'ParkingData');
    XLSX.utils.book_append_sheet(wb, ws1, 'Readme');
    XLSX.writeFile(wb, 'ParkingSampleDownload_' + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() + '.xlsx');
    this.showLoader = false;
  }
  
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('CurrentUserEmailId', localStorage.getItem('CurrentUserEmail'));
    formData.append('OfficeID', localStorage.getItem('OfficeID'));

    this.showUploadMsgPopUp = true;
    this.translate.get('Deskupload').subscribe((data: string) => { this.msgUploadTitle = data; });
    this.translate.get('UploadStatusMsg').subscribe((data: string) => { this.PopUpUploadMessage = data; });
    //files[0] = '';
    this.service.uploadParkingSheet(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Upload success.';
        this.onUploadFinished.emit(event.body);
      }
      // this.showPopUp = false;

    }, err => {
      this.showLoader = false;
      this.showUploadMsgPopUp = true;
      this.translate.get('DeskuploadErr').subscribe((data: string) => { this.msgUploadTitle = data; });
      this.translate.get('UploadStatusErrMsg').subscribe((data: string) => { this.PopUpUploadMessage = data; });
      return
    });
  }
  search() {
    this.showLoader = true;
    console.log(this.DataToHold);
    //if (this.givenText.length > 0) {
  
      if(this.givenText==undefined )
      {
        this.givenText='';
      }
      if (this.givenText!='') {
        //this.parkSpaces = this.DataToHold.filter(x => x.SpaceNumber.includes(this.givenText));
this.parkSpaces=this.DataToHold.filter(s => s.spaceNumber.includes(this.givenText));
        this.showLoader = false;
    }
    else {
      this.parkSpaces = this.DataToHold;
        this.showLoader = false;
    }
  }
  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  getFloorplanDetails() {
    console.log(this.selectedOffice);
    this.floorDTO = this.FloorForm.value;
    const formData = new FormData();    
    for (var i = 0; i < this.files.length; i++) {
      formData.append("file[]", this.files[i]);
    }
    formData.append('OfficeId', (this.selectedOffice).toString());
    formData.append('FloorplanAttachmentPath', this.floorDTO.FloorplanAttachmentPath == null ? '' : (this.floorDTO.FloorplanAttachmentPath).toString().replace(/^.*[\\\/]/, ''));
    this.formDetails = formData;
  }
  onFileChange(event, UploadFolder: string) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);      
    }
  }
  submit() {
    
      var floorplanAttachmentPath = /[^\\]*$/.exec(this.FloorForm.value.FloorplanAttachmentPath)[0];
      var floorplanAttachmentPathWhiteSpaceCheck=this.hasWhiteSpace(floorplanAttachmentPath);

      if (this.FloorForm.value.FloorplanAttachmentPath.trim() == '') {
        this.showErrPopUpWarning=true;
        this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('Mandetory').subscribe((data: string) => { this.PopUpMessage = data; });
        return;
      }

      if(floorplanAttachmentPathWhiteSpaceCheck)
      {
        this.showErrPopUpWarning=true;
        this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('FileWhiteSpaceErrorMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        return;
      }
      if(this.selectedOffice==undefined || this.selectedOffice=='undefined' || this.selectedOffice==null ||this.selectedOffice=='0' || this.selectedOffice=='Choose your Location')
      {
        this.showErrPopUpWarning=true;
        this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('OfficeSelect').subscribe((data: string) => { this.PopUpMessage = data; }); 
        return;
      }

    this.getFloorplanDetails();   

      this.service.uploadParkingAttachement(this.formDetails).subscribe((result: any) => {
        this.showErrPopUpWarning=true;
        if (result == 400) {
          this.myInputFile.nativeElement.value=null;this.FloorForm.value.FloorplanAttachmentPath=null;this.files=[];
          this.translate.get('UploadFile').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('FileUploadFail').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else {          
          this.myInputFile.nativeElement.value=null;this.FloorForm.value.FloorplanAttachmentPath=null;this.files=[];
          this.translate.get('ParkingSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ParkingSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
        // this.showMsgPopUp = true;
        // this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        // this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
      });
  }
  formSubmit()
  {
    this.myInputFile.nativeElement.value=null;this.FloorForm.value.FloorplanAttachmentPath=null;this.files=[];
    this.showLoader=false;
    this.showErrPopUpWarning=false;
  }

}
