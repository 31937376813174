import { Component, OnInit, NgZone } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { ServiceService } from '../services/service.service'
import { userDataService } from '../services/data-share';
import { DeskReservationsToBookDTO } from '../Model/DeskReservationsToBookDTO';
import { Router } from '@angular/router';
import { AzureBlobStorageService } from './../azure-blob-storage.service';
import { TranslateService } from '@ngx-translate/core';
import keys from '../key.json';

@Component({
  selector: 'app-swapseat',
  templateUrl: './swapseat.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class SwapseatComponent implements OnInit {

  constructor(private ngzone: NgZone,
    private titleService: Title,
    private dataService: userDataService,
    public translate: TranslateService,
    private ServiceService: ServiceService, private router: Router, private dom: DomSanitizer
    , private blobService: AzureBlobStorageService) { }

  showLoader = false;
  prev_selected_desk: string;
  selected_desk: string;
  selected_desk_ID: number;
  src_fp_path: any;
  SelectTypeofSeat: boolean;
  ShowReservableType: boolean = false;
  BookingPurposes: any;
  selectedPurpose: string;
  validateButton: boolean = false;
  showMsgPopUp: boolean = false;
  showFPpopup: boolean = false;
  showFullPlan: boolean = false;
  fullPlanPath;
  showLegent: string;
  BookingType: string;
  bookMeeting: boolean;
  selectedDateDetails = [];
  AttendeeDetails: any;
  bookedDate;
  duplicateValues;
  Available:boolean;
  showStatus:boolean;
  //sas ="?sp=rwl&st=2023-01-02T05:03:02Z&se=2024-12-31T18:29:59Z&spr=https&sv=2021-06-08&sr=c&sig=qMmEdTPN1cxNg6QmqpFIPYQjtZAvRgPQaxSSFwA1rz8%3D";
  sas=keys[0]['sas'];
  OfficeDeskTypeList = [];
  DeskTypescheckedIDs = [];
  selectedDeskTypesIds = '';


  ngOnInit() {
    this.titleService.setTitle("Swap a desk");
    this.SelectTypeofSeat = (localStorage.getItem('SelectTypeofSeat') == "true");
    this.getFloorPlanimage();
    this.getOfficeDeskTypeList();
  }

  getFloorPlanimage() {
    this.blobService.downloadImage(this.sas, localStorage.getItem('FloorPlanPath'), blob => {
      let url = window.URL.createObjectURL(blob);
      this.ngzone.run(() => { this.src_fp_path = this.dom.bypassSecurityTrustResourceUrl(url); });

      //this.src_fp_path = '/assets/floorplans/'.concat(localStorage.getItem('FloorPlanPath'));
      if (localStorage.getItem("ZoneId") != 'undefined' && localStorage.getItem("ZoneId") != null && localStorage.getItem("ZoneId") != 'null') {
        this.ServiceService.checkAssignedorDeskCatBasedonZoneId(parseInt(localStorage.getItem("ZoneId"))).subscribe((result: boolean) => {
          this.ShowReservableType = result;
        });
      }
      else if (localStorage.getItem("FloorID") != 'undefined' && localStorage.getItem("FloorID") != null && localStorage.getItem("FloorID") != 'null') {
        this.ServiceService.checkAssignedorDeskCatBasedonFloorId(parseInt(localStorage.getItem("FloorID"))).subscribe((result: boolean) => {
          this.ShowReservableType = result;
        });
      }
      else {
        this.ShowReservableType = false;
      }

      var details = this.dataService.getSwapResDetails();
      var reservationType=details.reservationType;
      this.bookedDate=details.date;
      if(reservationType=="Meeting")
      {
        this.bookMeeting=true
        localStorage.setItem("View", "Meeting");
      }
      else
      {
        this.bookMeeting=false;
        localStorage.setItem("View", "Desk");
      }
      this.prev_selected_desk = details.deskNumber;
      this.selectedPurpose = details.bkngPurpose;
      this.showFullPlan = (localStorage.getItem('ShowFullFloorplan') == "true");
      if (this.showFullPlan == true) {
        this.blobService.downloadImage(this.sas, localStorage.getItem('FullFloorPlanPath'), blob => {
          let url = window.URL.createObjectURL(blob);
          this.ngzone.run(() => { this.fullPlanPath = this.dom.bypassSecurityTrustResourceUrl(url); });
        });
      }

      this.BookingType = localStorage.getItem('CurrentBookingType');
      this.showLegent = localStorage.getItem('SelectedCountry');
    });
  }

  showDeskNumber(deskDetail) {

    if (this.bookMeeting) {
      this.selectedDateDetails = [];
      deskDetail.forEach(element => {
        var deskdetails = {
          DeskID: element.deskID,
          DeskNumber: element.deskNumber,
          MeetingName: element.meetingName,
          AttendeeName: '',
          BookedDate:this.bookedDate
        }
        this.selectedDateDetails.push(deskdetails);
      });
      if(localStorage.getItem("View")=="Meeting")
      {

      this.roomAvailability();
      }
      if (this.selectedPurpose != undefined) {
        this.ngzone.run(() => {
           this.validateButton = true;
          this.showReservedData = false;
        });
      }
    }
    else
    {
    this.selected_desk_ID = deskDetail.DID;
    this.ngzone.run(() => {
      this.selected_desk = deskDetail.DNUM
      this.showReservedData = false;
    });
    if (this.selectedPurpose != undefined && this.selected_desk != undefined) {
      this.ngzone.run(() => { this.validateButton = true; });
    }
  }
  }

  resDeskNum;
  resUserName;
  resMeetingName;
  resBy;
  showReservedData: boolean = false;
  showBookedData(bookedDetail) {
    this.ngzone.run(() => {
      this.showReservedData = true;
      this.resDeskNum = bookedDetail.DNUM;
      this.resUserName = bookedDetail.REV;
      this.resMeetingName = bookedDetail.DeskMeetingName;
      this.resBy = bookedDetail.REVBy;
      // if (this.resBy.toLowerCase() == localStorage.getItem('CurrentUserEmail').toLowerCase()) {
      //   this.organiser = true;
      // }
    });
  }

  desk_quiet_chk: boolean = false;
  desk_dualmon_chk: boolean = false;
  desk_sitstand_chk: boolean = false;
  desk_sncl_chk: boolean = false;
  desk_assigned_chk: boolean = false;
  desk_touchdown_chk: boolean = false;

  selectPurpose(option) {
    this.selectedPurpose = option.target.value;
    this.validateButton = (this.selectedPurpose != undefined && this.selected_desk != undefined) ? true : false;
  }

  SwapDesk() {
    this.showLoader = true;
    if(this.bookMeeting && this.selectedDateDetails.findIndex(x => x.AttendeeName.toLowerCase() === localStorage.getItem('CurrentUserEmail').toLowerCase())==-1)
    {
        this.translate.get('SelfMeetingDesk').subscribe((data: string) => { alert(data); })
        this.showLoader = false;
    }
    else if(this.bookMeeting && this.checkSameAttendee())
    {
      this.translate.get('SameAttendee').subscribe((data: string) => { alert(data); })
      this.showLoader = false;
    }
    else
    {
      this.selectedDateDetails=this.selectedDateDetails.filter(d => d.AttendeeName != '')
    var res_details = {
      ID: this.dataService.getSwapResDetails().reservationID,
      newDeskID: this.selected_desk_ID,
      DeskDetails: this.selectedDateDetails,
      OfficeId: parseInt(localStorage.getItem('OfficeID')),
      Reservation_Type:this.dataService.getSwapResDetails().reservationType,
      Code: localStorage.getItem("AccessToken"),
      OutlookMeetingId: this.dataService.getSwapResDetails().outlookId,
      ICalUID: this.dataService.getSwapResDetails().iCalUId,
      Buinfo:this.getCorrectID('BUId')
    };

    this.ServiceService.swapReservation(res_details).subscribe((data: any) => {
      this.showLoader = false;
      this.showMsgPopUp = true;
      // alert('Your desk has been swapped');
      // this.router.navigateByUrl('/swap');
    });
  }
  }

  search(text,deskNumber) {
    const index = this.selectedDateDetails.findIndex(x => x.DeskNumber === deskNumber);
    if(index!=-1)
    this.selectedDateDetails[index].AttendeeName=text.value;
    if(text.value.length > 3)
      {
    this.showLoader = true;
    this.ServiceService.getUserDetails(text.value).subscribe((data: any) => {
      this.showLoader = false;
      this.AttendeeDetails = data.filter(x => x.mail != null);
    })
     }
  }

  checkSameAttendee()
  {
    this.selectedDateDetails.filter(x=>x.AttendeeName!='').forEach(attendee=>
      {
        var count=this.selectedDateDetails.filter(x=>x.AttendeeName.toLowerCase()==attendee.AttendeeName.toLowerCase());
        if(count.length>1)
        {
          this.duplicateValues=true;
        }
        else{
          this.duplicateValues=false;
        }
      });
      return this.duplicateValues;
  }

    //Outlook Meeting Room Availability
    roomAvailability()
    {
      this.showLoader = true;

    var timeZone=new Date().toString().split('GMT')[1].split(' ')[0];
    var token=localStorage.getItem('AccessToken');
    var details = this.dataService.getSwapResDetails();
    var meetingDetails = {
      MeetingName: this.selectedDateDetails[0].MeetingName,
      StartTime: details.startTime,
      EndTime: details.endTime,
      TimeZone: timeZone,
      Code: token,
      BookedDate: details.date.split('T')[0],
      IsFullday:details.isFullday
    };
    this.ServiceService.checkRoomAvailability(meetingDetails).subscribe((data: any) => {

      if(data==200)
      {
        this.ngzone.run(() => {
          this.Available=false;
          this.validateButton = false;
          this.showStatus=true;
          this.showLoader = false; });
      }
      else
      {
        this.ngzone.run(() => {
        this.Available=true;
        this.validateButton = true;
        this.showStatus=true;
        this.showLoader = false;
      });
      }
    })
    }

  getOfficeDeskTypeList()
  {
      localStorage.setItem('SelectedDeskTypes', '');
      var _offID = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
      this.ServiceService.getDeskTypes(parseInt(_offID)).subscribe((data: any[]) => {
        this.showLoader = false;
        data=data.filter(o => !o.translationKey.includes('GYM_'));
        // this.OfficeDeskTypeList = data;
        this.OfficeDeskTypeList  = data.filter(x => x.langKey == localStorage.getItem('locale'));
       //Add a new parameter isChecked in DeskType list
        this.OfficeDeskTypeList=  this.OfficeDeskTypeList.map((obj) => {
            obj.isChecked = false;
            return obj;
        })
      });
      this.showLoader = false;
  }

  OnDeskTypeChange() {
    this.DeskTypescheckedIDs = [];
    this.OfficeDeskTypeList.forEach((value, index) => {
      if (value.isChecked) {
        this.DeskTypescheckedIDs.push(value.id);
      }
    });
    const selectedDeskType_ids =  this.DeskTypescheckedIDs.toString();
    this.selectedDeskTypesIds=selectedDeskType_ids;
  }
  getCorrectID(paramName: string) {
    let correctID = '0';
    if (localStorage.getItem(paramName) != "undefined") {
      if (localStorage.getItem(paramName) != "null") {
        if (localStorage.getItem(paramName) != null) {
          correctID = localStorage.getItem(paramName);
        }
      }
    }
    return parseInt(correctID);
  }

}
