import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { NgForm, FormBuilder, Validators } from '@angular/forms';
import { ExternalUserCredentialsDTO } from '../Model/ExternalUserCredentialsDTO';
import { ServiceService } from '../services/service.service';
import { Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Console } from '@angular/core/src/console';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['../../assets/css/style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit {
  public progress: number;
  public message: string;
  AllowAdmin: boolean = false;
  ExternalUserList: any;
  showPopUp: boolean = false;
  showMsgPopUp: boolean = false;
  showUploadMsgPopUp: boolean = false;
  SelectCountryMsgPopUp: boolean = false;
  usertype: boolean = false;
  PopUpMessage: string;
  PopUpUploadMessage: string;
  msgUploadTitle: string;
  msgTitle: string;
  showUserId: boolean = false;
  showAdminFeatures: boolean = false;
  showSuperAdminFeatures: boolean = false;
  showDashBoardAdminFeatures: boolean = false;
  showTranslationFeatures: boolean = false;
  externalUserDetails: ExternalUserCredentialsDTO;
  countries: [];
  allowedOffice: [];
  addedOnDate: any = new Date();
  showLoader = false;
  UserForm = this.forms.group(
    {
      emailAddress: ['', Validators.email],
      fullName: [''],
      // addedOn: [this.addedOnDate],
      allowedOffice: [''],
      userId: [0]
    });
  utcDateTime: any;
  CurrentUserName;
  showPopupWrap;
  showPopupWraphelp = false;
  versionTitle: string;
  ShowParkingSA: boolean;
  AdminAccessForSA: boolean;
  OfficeLeaderRole: Boolean;
  RegionalLeaderRole: Boolean;
  CustomerfacingRole: Boolean;
  ComplianceTracking: Boolean = true;
  //logout;

  @Output() public onUploadFinished = new EventEmitter();
  constructor(public service: ServiceService, private forms: FormBuilder, private router: Router, public translate: TranslateService, private activatedRoute: ActivatedRoute) {
    let _langs = [];
    service.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    this.versionTitle = service.LastAppRelease;

    //Commented to stop Refresh
    // localStorage.setItem("PBIAuthCode",'');
    // localStorage.setItem("PBIAccessToken",'')

    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(service.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {
    var selectedRegion = localStorage.getItem('SelectedCountry');
    this.service.getUserAllowedRegion().subscribe((data: any[]) => {
      if (data.length > 0) {
        var allowRegion = data.find(x => x.langKey == localStorage.getItem('locale') && x.country.toLowerCase() === selectedRegion.toLowerCase());
        if (allowRegion == undefined || allowRegion == null) {
          this.swalRetrictedRegionMsgPopup();
        }
      }
    });
    if (localStorage.getItem('SelectedCountry') != null && localStorage.getItem('SelectedCountry') != undefined) {
      localStorage.setItem('PageName', 'Admin');
      this.showLoader = true;
      // this.service.getExternalUserList().subscribe(response => {
      //   this.ExternalUserList = response;
      //   this.showLoader = false;
      // });
      this.CurrentUserName = localStorage.getItem('CurrentUser');
      // if (localStorage.getItem('CurrentUserEmail') != undefined && localStorage.getItem('CurrentUserEmail') != 'null' && localStorage.getItem('CurrentUserEmail') != 'undefined') {
      //   this.service.CheckIfAdminUser(localStorage.getItem('CurrentUserEmail')).subscribe((result: boolean) => {
      //     this.AllowAdmin = result;
      //   }, err => {
      //     this.AllowAdmin = false;
      //   });
      // }

      this.ExternalUserDatas();

      this.service.getOption().subscribe((data: any) => {
        this.countries = data;
        // this.showLoader = false;
      });

      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      var userRole = payLoad.role;
      // if(userRole == 'SuperAdmin' ||  userRole == 'RegionalLeader')
      if (userRole == 'SuperAdmin') {
        this.showTranslationFeatures = true;
      }
      if (userRole == 'OfficeLeader' || userRole == 'SuperAdmin' || userRole == 'RegionalAdmin' || userRole == 'Admin' || userRole == 'RegionalLeader') {
        this.OfficeLeaderRole = true;
      }
      if (userRole == 'SuperAdmin' || userRole == 'RegionalAdmin' || userRole == 'RegionalLeader') {
        this.RegionalLeaderRole = true;
      }

      if (userRole == 'Admin' || userRole == 'RegionalAdmin')
        this.showAdminFeatures = true;
      else if (userRole == 'SuperAdmin')
        this.showSuperAdminFeatures = true
      else if (userRole == 'RegionalLeader' || userRole == 'OfficeLeader') {
        this.showAdminFeatures = false;
        this.showSuperAdminFeatures = false;
        this.showDashBoardAdminFeatures = true;
      }
      else {
        this.showAdminFeatures = false;
        this.showSuperAdminFeatures = false;
        this.showDashBoardAdminFeatures = false;
      }
      if (localStorage.getItem('token') != null) {
        this.EnableAdminButton();
      }
    }
    else {
      this.SelectCountryMsgPopUp = true;
      //this.msgUploadTitle = 'Desks Upload!';
      //this.PopUpUploadMessage = 'We will update you the Upload Status Shortly via eMail.';
      // this.translate.get('ExternalUserExistTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('CountrySelectionAlert').subscribe((data: string) => { this.PopUpUploadMessage = data; });
    }
    // this.service.getSAofficeAccess().subscribe((data: any) => {
    //   this.AdminAccessForSA=data=='200'?true:false;
    // });
    this.ShowParkingSA = localStorage.getItem('SelectedCountry') != "South America" ? false : true;
    this.AdminAccessForSA = localStorage.getItem('AdminAccessForSA') != "200" ? false : true;
    if (userRole == 'RegionalAdmin' || userRole == 'OfficeLeader') { this.AdminAccessForSA = false; }
    if (userRole == 'RegionalAdmin' || userRole == 'OfficeLeader' || userRole == 'RegionalLeader' || userRole == 'Manager') { this.ComplianceTracking = false; }
    if ((userRole == 'Manager' || userRole == 'SuperAdmin') && (localStorage.getItem('SelectedCountry') == 'United Kingdom' || localStorage.getItem('SelectedCountry') == 'Europe')) {
      this.CustomerfacingRole = true;
    }
  }


  swalRetrictedRegionMsgPopup() {
    var AdminPermissionIssueTitle = "";
    var AdminPermissionIssuePart1 = "";
    var AdminPermissionIssuePart2 = "";
    this.translate.get('AdminPermissionIssueTitle').subscribe((data: string) => { AdminPermissionIssueTitle = data; });
    this.translate.get('AdminPermissionIssuePart1').subscribe((data: string) => { AdminPermissionIssuePart1 = data; });
    this.translate.get('AdminPermissionIssuePart2').subscribe((data: string) => { AdminPermissionIssuePart2 = data; });
    var errorMsg = AdminPermissionIssuePart1 + ' ' + localStorage.getItem('SelectedCountry') + '. ' + AdminPermissionIssuePart2;

    Swal.fire({
      title: "",
      text: errorMsg,
      confirmButtonColor: '#3f32f1',
      confirmButtonText: "Ok",
      icon: "warning",
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.router.navigateByUrl('/home');
      }
    })
  }

  EnableAdminButton() {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if (userRole != 'user')
      this.AllowAdmin = true;
  }
  ExternalUserDatas() {
    this.service.getExternalUserList().subscribe(response => {
      this.ExternalUserList = response;
      this.showLoader = false;
    });
  }
  UploadDesks() {
    //this.UserForm.get('emailAddress').enable();

    this.usertype = false;
    this.showPopUp = true;
  }
  editUser(UserData: any) {
    this.UserForm.get('emailAddress').disable();
    this.showLoader = true;
    this.service.getUserData(UserData.userId).subscribe(userdetail => {
      this.UserForm.controls['emailAddress'].setValue(userdetail[0].emailAddress);
      this.UserForm.controls['fullName'].setValue(userdetail[0].fullName);
      this.UserForm.controls['allowedOffice'].setValue(userdetail[0].allowedOffice);
      this.UserForm.controls['userId'].setValue(userdetail[0].userId);
      this.ExternalUserDatas();
      this.showLoader = false;
    });
    this.showPopUp = true;
  }
  deleteUser(UserData: any) {
    this.showLoader = true;
    this.service.deleteExternalUser(UserData.userId).subscribe(response => {
      this.ExternalUserDatas();
      this.showLoader = false;
      this.showMsgPopUp = true;
      this.msgTitle = 'User Deleted Successfully!';
      this.PopUpMessage = 'User Deleted Successfully.';
    }, err => {
      this.showLoader = false;
      this.showMsgPopUp = true;
      this.msgTitle = 'User Deletion Failed!';
      this.PopUpMessage = 'User Deletion Failed.';
    });
  }
  closeBooking() {
    this.showPopUp = false;
    this.UserForm.controls['emailAddress'].setValue('');
    this.UserForm.controls['fullName'].setValue('');
    this.UserForm.controls['allowedOffice'].setValue('');
    this.UserForm.controls['userId'].setValue(0);
  }
  onSubmit() {
    this.showLoader = true;
    var userlist = {
      EmailAddress: this.UserForm.value.emailAddress,
      FullName: this.UserForm.value.fullName,
      AllowedOffice: this.UserForm.value.allowedOffice,
      UserId: parseInt(this.UserForm.value.userId),
      PasswordType: false,
      captchaResponse: null,
    };
    this.externalUserDetails = userlist;
    this.service.SaveUserDetails(this.externalUserDetails).subscribe(response => {
      this.ExternalUserDatas();
      this.showLoader = false;
      this.showPopUp = false;
      this.showMsgPopUp = true;
      this.msgTitle = 'User Added Successfully!';
      this.PopUpMessage = 'User Added Successfully and He/She will receive an email shortly.';
      this.UserForm.controls['emailAddress'].setValue('');
      this.UserForm.controls['fullName'].setValue('');
      this.UserForm.controls['allowedOffice'].setValue('');
      this.UserForm.controls['userId'].setValue(0);
    }, err => {
      this.showLoader = false;
      this.showMsgPopUp = true;
      this.msgTitle = 'User Added Failed!';
      this.PopUpMessage = 'Please check with Admin';
    });
    // this.showPopUp = false;
  }
  // changeCountry(option) {
  //   this.service.getOffices(option.target.value).subscribe((data: any) => {
  //     this.allowedOffice = data;
  //   });
  // }
  CreateOffices() {
    //this.router.navigateByUrl('/office');
    this.router.navigateByUrl('/officedetails');
  }
  CreateFloors() {
    this.router.navigateByUrl('/floor');
  }
  CreateZone() {
    this.router.navigateByUrl('/zone');
  }
  CreateBU() {
    this.router.navigateByUrl('/BUConfiguration');
  }
  deskConfiguration() {
    this.router.navigateByUrl('/deskConfiguration');
  }
  externalUserConfiguration() {
    this.router.navigateByUrl('/externalUserConfiguration');
  }
  parkingConfiguration() {
    this.router.navigateByUrl('/parkingConfiguration');
  }
  listParkings() {
    this.router.navigateByUrl('/listParkings');
  }
  listParkingsSA() {
    this.router.navigateByUrl('/listParkingsSA');
  }
  listParkingPermits() {
    this.router.navigateByUrl('/parkingPermits');
  }
  PastDayBookings() {
    localStorage.setItem('pastdaybooking', 'true');
    this.router.navigateByUrl('/booking');
  }

  gotoTranslationPage() {
    this.router.navigateByUrl('/Translations');
  }
  giveDashboardAccess() {
    this.router.navigateByUrl('/dashboardAccess');
  }

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('CurrentUserEmailId', localStorage.getItem('CurrentUserEmail'));
    formData.append('OfficeID', localStorage.getItem('OfficeID'));

    this.service.uploadSpreadSheet(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Upload success.';
        this.onUploadFinished.emit(event.body);
      }
      this.showPopUp = false;
      this.showUploadMsgPopUp = true;
      this.msgUploadTitle = 'Desks Upload!';
      this.PopUpUploadMessage = 'We will update you the Upload Status Shortly via eMail.';
    });
  };
  logout() {

    this.service.logout().subscribe((data: any[]) => {
      window.location.reload();
      var a = localStorage.myusername;
      var b = localStorage.mypassword;
      localStorage.clear();
      localStorage.setItem('myusername', a);
      localStorage.setItem('mypassword', b);
    }, error => {

    })


    window.location.reload();
    var a = localStorage.myusername;
    var b = localStorage.mypassword;
    localStorage.clear();
    localStorage.setItem('myusername', a);
    localStorage.setItem('mypassword', b);
  }
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    // this.translate.get('logoClass').subscribe((data: string) => { this.logoClass = data; });
    // this.data = this.FullCountryList.filter(x => x.langKey == localStorage.getItem('locale'));
  }
}
