import { Component, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';

@Component({
  selector: 'app-office-selector',
  templateUrl: './office-selector.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class OfficeSelectorComponent implements OnInit {
  @Output() validateButton = new EventEmitter<boolean>();
  @Output() selectedCarParking = new EventEmitter<string>();
  @Output() ShowFullData = new EventEmitter();
  @Output() officeChange = new EventEmitter<boolean>();
  offices: any;
  floors: any;
  zones: any;
  businessUnits: any;
  AllbusinessUnits: any;
  selectedFloor: string;
  selectedZone: string;
  selectedBU: string;
  selectedOffice: any;
  hasParking: boolean;
  IsGroupedBU: any;
  hasBusinessUnits: boolean;
  showFloors: boolean = true;
  showMsgPopUp: boolean = false;
  msgTitle: string = null;
  PopUpMessage: string = null;
  //--- DeskType Implement 11Aug2022
  deskTypes: any;
  selectedDeskType: string;
  showPopUp: boolean = false;
  IsAdminDeskConfiguration = false;
  FullData = false;
  userPrefData;
  setPreference = false;
  FloorsInAllLanguage: any;
  NavigatingURL: string;
  constructor(private ServiceService: ServiceService, private ngzone: NgZone, private router: Router
    , public translate: TranslateService) {
    this.selectedOffice = null;
    this.selectedFloor = null;

    //--- DeskType Implement 11Aug2022
    this.selectedDeskType = null;
  }
  //,private dataService: userDataService
  ngOnInit() {
    this.NgOnIntFunctionality();
  }
  NgOnIntFunctionality() {
    let url: string = this.router.url;
    this.NavigatingURL = url;
    if (url == "/settings" || url == "/booking") {
      if (url == "/booking" && localStorage.getItem('BackfromSelectSeatPage') == 'selectseat')
        this.setPreference = false;
      else
        this.setPreference = true;
    }
    if (url == "/settings") {
      localStorage.setItem("RedirectFromParking", '0');
    }
    this.ServiceService.getUserPreference().subscribe((userPrefs: any) => {
      if (userPrefs != undefined) {
        this.userPrefData = userPrefs;
      }
      localStorage.setItem('GroupedBU', 'false');
      // get list of offcies for country
      let checkAdminOfficeIds: boolean = (this.router.url == '/deskConfiguration' ? true : false);
      this.IsAdminDeskConfiguration = checkAdminOfficeIds;
      this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), checkAdminOfficeIds).subscribe((data: any[]) => {
        if (data.length == 0) {
          this.msgTitle = 'Permission Restricted';
          this.PopUpMessage = 'Sorry, you dont have permission to access any of the offices in ' + localStorage.getItem('SelectedCountry') + '. Please select other country';
          this.showMsgPopUp = true;
          // alert("You dont have permission to update the offices in country, please select other counrty");
          // this.router.navigate(["/"]);
        }
        if (url == "/gymBooking") {
          data = data.filter(o => o.gymMaxCalender != 0);
        }
        else if (url == "/deskConfiguration") {
          data = data
        }
        //From Parking To Reservation
        var selectedOfficeIDFormParking = parseInt(localStorage.getItem('RedirectFromParking'));
        if (isNaN(selectedOfficeIDFormParking)) {
          selectedOfficeIDFormParking = 0;
        }
        localStorage.setItem('OfficeID', this.selectedOffice);
        if (selectedOfficeIDFormParking != 0) {
          data = data.filter(o => o.id == selectedOfficeIDFormParking);
          this.selectedOffice = localStorage.getItem('RedirectFromParking');
          localStorage.setItem('OfficeID', this.selectedOffice);
          //localStorage.setItem('RedirectFromParking', "0");
          localStorage.setItem('NotShowParkingPopup', "0");
        }
        //End
        this.offices = data;

        if (data.length > 0) {//&& localStorage.getItem('OfficeID') != undefined
          let _offID;
          if (this.userPrefData != undefined && this.userPrefData.selCountry == localStorage.getItem('SelectedCountry') && this.setPreference && (selectedOfficeIDFormParking == 0 || selectedOfficeIDFormParking == undefined)) {
            _offID = (parseInt(this.userPrefData.selOfficeId));
          }
          else {
            _offID = (parseInt(localStorage.getItem('OfficeID')) != 0 && parseInt(localStorage.getItem('OfficeID')) != undefined && (localStorage.getItem('OfficeID')) != 'undefined') ? parseInt(localStorage.getItem('OfficeID')) : 0;//parseInt(localStorage.getItem('OfficeID'));
          }
          let _officeDetail = this.offices.find(x => x.id == _offID);
          if (_officeDetail != undefined) {
            this.hasParking = _officeDetail.hasParking;
            this.hasBusinessUnits = _officeDetail.hasBUs;
            this.IsGroupedBU = _officeDetail.isGroupBu.toString();
            localStorage.setItem('GroupedBU', this.IsGroupedBU);
            localStorage.setItem('OfficeName', _officeDetail.name);
            localStorage.setItem('CommsLang', _officeDetail.commLanguage);
            localStorage.setItem('DateFormat', _officeDetail.dateFormat);
            localStorage.setItem('HasParking', _officeDetail.hasParking);
            localStorage.setItem('IsMultipleDayParking', _officeDetail.isParkingMultipleDay);


            localStorage.setItem('ParkingDurationStart', _officeDetail.resParkingDurationStart);
            localStorage.setItem('ParkingDurationEnd', _officeDetail.resParkingDurationEnd);
          } else {
            let _officeDetail = this.offices.find(x => x.id == data[0].id);

            this.hasParking = _officeDetail.hasParking;
            this.hasBusinessUnits = _officeDetail.hasBUs;
            this.IsGroupedBU = _officeDetail.isGroupBu.toString();
            localStorage.setItem('GroupedBU', this.IsGroupedBU);
            localStorage.setItem('OfficeName', _officeDetail.name);
            localStorage.setItem('CommsLang', _officeDetail.commLanguage);
            localStorage.setItem('DateFormat', _officeDetail.dateFormat);
            localStorage.setItem('HasParking', _officeDetail.hasParking);
            localStorage.setItem('IsMultipleDayParking', _officeDetail.isParkingMultipleDay);

            localStorage.setItem('ParkingDurationStart', _officeDetail.resParkingDurationStart);
            localStorage.setItem('ParkingDurationEnd', _officeDetail.resParkingDurationEnd);
          }



          (this.hasParking) ? localStorage.setItem("CarParkingSelection", 'true') : localStorage.setItem("CarParkingSelection", 'false');
          //get BU list
          if (this.hasBusinessUnits == true) {
            this.ServiceService.getBUs(parseInt(this.selectedOffice)).subscribe((data: any[]) => {

              if (url == "/gymBooking") {
                data = data.filter(o => o.hasGym == true);
              }
              else if (url == "/deskConfiguration") {
                data = data
              }
              else {
                data = data.filter(o => o.hasGym == false);
              }

              this.AllbusinessUnits = data;
              this.businessUnits = data.filter(x => x.langKey == localStorage.getItem('locale'));
              if (this.userPrefData != undefined && this.userPrefData.selCountry == localStorage.getItem('SelectedCountry') && this.setPreference && (selectedOfficeIDFormParking == 0 || selectedOfficeIDFormParking == undefined)) {
                this.selectedZone = this.userPrefData.selZoneId;
                this.selectedBU = this.userPrefData.selBuid;
              }
              else {
                this.selectedZone = localStorage.getItem('ZoneId');
                this.selectedBU = localStorage.getItem('BUId');
              }
              this.buSetter(this.selectedBU, false);
            });
          }
          else {
            localStorage.setItem('BUId', undefined);
          }

        }
        this.officeChange.emit(false);
      });
      var selectedOfficeIDFormParking = parseInt(localStorage.getItem('RedirectFromParking'));
      if (isNaN(selectedOfficeIDFormParking)) {
        selectedOfficeIDFormParking = 0;
      }
      if (this.userPrefData != undefined && this.userPrefData.selCountry == localStorage.getItem('SelectedCountry') && this.setPreference && (selectedOfficeIDFormParking == 0 || selectedOfficeIDFormParking == undefined)) {
        this.selectedOffice = parseInt(this.userPrefData.selOfficeId);
      }
      else {
        this.selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
      }
      // this.IsGroupedBU = this.offices.find(x => x.id == this.selectedOffice).isGroupBu;
      if (this.selectedOffice != undefined) {
        //get floors list
        this.ServiceService.getFloors(parseInt(this.selectedOffice), this.router.url).subscribe((data: any[]) => {
          if (url == "/gymBooking") {
            data = data.filter(o => o.hasGym == true);
          }
          else if (url == "/deskConfiguration") {
            data = data
          }
          else {
            data = data.filter(o => o.hasGym == false);
          }
          this.FloorsInAllLanguage = data;
          this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
          this.floorSetter(this.selectedFloor);

          var selFloorObj = this.floors.find(value => value.id === parseInt(this.selectedFloor));
          //get zones list
          if (selFloorObj.hasZones) {
            this.ServiceService.getZones(parseInt(this.selectedFloor)).subscribe((data: any[]) => {
              if (url == "/gymBooking") {
                data = data.filter(o => o.hasGym == true);
              }
              else if (url == "/deskConfiguration") {
                data = data
              }
              else {
                data = data.filter(o => o.hasGym == false);
              }
              this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
              if (this.userPrefData != undefined && this.userPrefData.selCountry == localStorage.getItem('SelectedCountry') && this.setPreference && (selectedOfficeIDFormParking == 0 || selectedOfficeIDFormParking == undefined)) {
                this.selectedZone = this.userPrefData.selZoneId;
              }
              else {
                this.selectedZone = localStorage.getItem('ZoneId');
              }
              this.zoneSetter(this.selectedZone);
            });
          }
          else {
            localStorage.setItem('ZoneId', undefined);
          }
        });
        if (this.userPrefData != undefined && this.userPrefData.selCountry == localStorage.getItem('SelectedCountry') && this.setPreference && (selectedOfficeIDFormParking == 0 || selectedOfficeIDFormParking == undefined)) {
          this.selectedFloor = this.userPrefData.selFloorId;
        }
        else {
          this.selectedFloor = localStorage.getItem('FloorID');
        }
      };
      this.IsGroupedBU = (localStorage.getItem('GroupedBU') == undefined) ? false : localStorage.getItem('GroupedBU');

      //--- DeskType Implement 11Aug2022 Get DeskTypes by Office Id

      if (this.selectedOffice != undefined) {
        this.ServiceService.getDeskTypes(parseInt(this.selectedOffice)).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.deskTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));
            // this.deskTypes = data;
            this.deskTypeSetter(this.selectedDeskType);

            var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(this.selectedDeskType));
          }
          else {
            this.deskTypes = data;
            this.deskTypeSetter(this.selectedDeskType);
          }
        });
        this.selectedDeskType = localStorage.getItem('DeskTypeID');
      }

      this.CheckValidateButton();
    });
  }
  CheckValidateButton() {
    if (this.selectedOffice != undefined && this.selectedFloor != undefined)// && (this.selectedZone != undefined || this.selectedBU != undefined))
    {
      this.validateButton.emit(true);
      this.checkIfUserSelected();
    }
    else {
      this.validateButton.emit(false);
    }
  }
  changeOffice(e) {
    var _offID = (e.target == undefined ? e : e.target.value);
    this.hasParking = this.offices.find(x => x.id == _offID).hasParking;

    //Set hasParking while changing Office
    localStorage.setItem('HasParking', this.offices.find(x => x.id == _offID).hasParking);
    localStorage.setItem('IsMultipleDayParking', this.offices.find(x => x.id == _offID).isParkingMultipleDay);

    localStorage.setItem('ParkingDurationStart', this.offices.find(x => x.id == _offID).resParkingDurationStart);
    localStorage.setItem('ParkingDurationEnd', this.offices.find(x => x.id == _offID).resParkingDurationEnd);
    //
    //set Language
    localStorage.setItem('CommsLang', this.offices.find(x => x.id == _offID).commLanguage);
    if (localStorage.getItem('CommsLang') == undefined || localStorage.getItem('CommsLang') == null) {
      localStorage.setItem('CommsLang', 'en-US');
    }
    //
    // this.IsGroupedBU = this.offices.find(x => x.id == _offID).isGroupBu;
    localStorage.setItem('OfficeName', this.offices.find(x => x.id == this.selectedOffice).name);
    localStorage.setItem('GroupedBU', this.offices.find(x => x.id == this.selectedOffice).isGroupBu);
    localStorage.setItem('OfficeChangedOnBooking', 'true');
    this.IsGroupedBU = localStorage.getItem('GroupedBU');
    this.ServiceService.getFloors(parseInt(_offID), this.router.url).subscribe((data: any[]) => {
      if (this.NavigatingURL == "/gymBooking") {
        data = data.filter(o => o.hasGym == true);
      }
      else if (this.NavigatingURL == "/deskConfiguration") {
        data = data
      }
      else {
        data = data.filter(o => o.hasGym == false);
      }
      this.FloorsInAllLanguage = data;
      this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
      this.selectedFloor = this.floors[0].id;
      this.floorSetter(this.selectedFloor);

      var selFloorObj = this.floors.find(value => value.id === parseInt(this.selectedFloor));
      //get zones list
      if (selFloorObj.hasZones) {
        this.ServiceService.getZones(parseInt(this.selectedFloor)).subscribe((data: any[]) => {
          if (this.NavigatingURL == "/gymBooking") {
            data = data.filter(o => o.hasGym == true);
          }
          else if (this.NavigatingURL == "/deskConfiguration") {
            data = data
          }
          else {
            data = data.filter(o => o.hasGym == false);
          }
          this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
          this.selectedZone = localStorage.getItem('ZoneId');
          this.zoneSetter(this.selectedZone);
        });
      }
      else {
        localStorage.setItem('ZoneId', undefined);
        this.zones = null;
      }

    });
    (this.hasParking) ? this.selectedCarParking.emit('true') : this.selectedCarParking.emit('false');
    (this.hasParking) ? this.ngzone.run(() => { localStorage.setItem("CarParkingSelection", 'true') }) : this.ngzone.run(() => { localStorage.setItem("CarParkingSelection", 'false') });
    this.hasBusinessUnits = this.offices.find(x => x.id == _offID).hasBUs;

    if (this.hasBusinessUnits == true) {
      this.ServiceService.getBUs(_offID).subscribe((data: any[]) => {
        this.selectedFloor = undefined;
        if (this.NavigatingURL == "/gymBooking") {
          data = data.filter(o => o.hasGym == true);
        }
        else if (this.NavigatingURL == "/deskConfiguration") {
          data = data
        }
        else {
          data = data.filter(o => o.hasGym == false);
        }
        this.AllbusinessUnits = data;
        this.businessUnits = this.AllbusinessUnits.filter(x => x.langKey == localStorage.getItem('locale'));
        this.selectedBU = this.businessUnits[0].id;
        this.changeBU(this.businessUnits[0].id);
        this.checkIfUserSelected();
      });
    }
    else {
      localStorage.setItem('BUId', undefined);
      this.ServiceService.getFloors(_offID, this.router.url).subscribe((data: any[]) => {
        if (data.length > 0) {
          if (this.NavigatingURL == "/gymBooking") {
            data = data.filter(o => o.hasGym == true);
          }
          else if (this.NavigatingURL == "/deskConfiguration") {
            data = data
          }
          else {
            data = data.filter(o => o.hasGym == false);
          }
          this.showFloors = true;
          this.FloorsInAllLanguage = data;
          this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
          localStorage.setItem('FloorID', this.floors[0].id);
          this.selectedFloor = localStorage.getItem('FloorID');
          localStorage.setItem("CarParkingSelection", 'false');
          this.changeFloors(this.floors[0].id);
          this.checkIfUserSelected();
        }
        else {
          this.showFloors = false;
          localStorage.setItem('ZoneId', undefined);
          this.zones = null;
        }
      });
    };
    localStorage.setItem('OfficeID', _offID);
    localStorage.setItem('ZoneId', undefined);
    this.officeChange.emit(true);
    //--- DeskType Implement 11Aug2022 Get DeskTypes by Office Id


    this.ServiceService.getDeskTypes(parseInt(_offID)).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.selectedDeskType = data[0].id;
        this.deskTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));
        // this.deskTypes = data;
        this.deskTypeSetter(this.selectedDeskType);

        var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(this.selectedDeskType));
      }
      else {
        this.deskTypes = data;
        this.deskTypeSetter(this.selectedDeskType);
      }
    });

  }

  changeFloors(e) {
    var floorid = (e.target == undefined ? e : e.target.value);
    this.floorSetter(floorid);

    this.ServiceService.getZones(floorid).subscribe((data: any[]) => {
      if (data.length > 0) {
        if (this.NavigatingURL == "/gymBooking") {
          data = data.filter(o => o.hasGym == true);
        }
        else if (this.NavigatingURL == "/deskConfiguration") {
          data = data
        }
        else {
          data = data.filter(o => o.hasGym == false);
        }
        this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
        this.zoneSetter(this.zones[0].id);

      } else { this.zones = ''; }
    });
    // if (this.zones == undefined)
    localStorage.setItem('ZoneId', undefined);
  }

  floorSetter(floorid) {
    if (floorid != undefined) {
      this.selectedFloor = floorid;
      localStorage.setItem('FloorID', floorid);

      var selFloorObj = this.floors.find(value => value.id === parseInt(floorid));
      if (selFloorObj != undefined) {
        localStorage.setItem('SelectTypeofSeat', selFloorObj.hasDeskCategory);
        // this.dataService.setFloorPlanPath(selFloorObj.floorPlanPath);
        localStorage.setItem('FloorPlanPath', selFloorObj.floorPlanPath);
        localStorage.setItem('FullFloorPlanPath', selFloorObj.fullFloorplanPath);
        localStorage.setItem('ShowFullFloorplan', selFloorObj.showFullFloorplan);

        //--------For Customer Powerbi Report-------------
        localStorage.setItem('FloorNameInEnglish', selFloorObj.name);
        if ((localStorage.getItem('locale') != 'en' || localStorage.getItem('locale') != "en") && this.FloorsInAllLanguage != undefined) {
          var selfloorsInEnglishObj = this.FloorsInAllLanguage.filter(x => x.langKey == 'en' && x.id === parseInt(floorid));
          if (selfloorsInEnglishObj != undefined && selfloorsInEnglishObj[0] != undefined) {
            localStorage.setItem('FloorNameInEnglish', selfloorsInEnglishObj[0].name);
          }
        }
      }
    }
  }

  zoneSetter(zoneid) {
    if (zoneid != undefined) {
      this.selectedZone = zoneid;
      localStorage.setItem('ZoneId', zoneid);

      var selZoneObj = this.zones.find(value => value.id === parseInt(zoneid));
      if (selZoneObj != undefined) {
        localStorage.setItem('FloorID', selZoneObj.floorId);
        localStorage.setItem('SelectTypeofSeat', selZoneObj.hasDeskCategory);
        localStorage.setItem('FloorPlanPath', selZoneObj.floorPlanPath);
        localStorage.setItem('FullFloorPlanPath', selZoneObj.fullFloorplanPath);
        localStorage.setItem('ShowFullFloorplan', selZoneObj.showFullFloorplan);
      }

      // var fpp = this.zones.filter(value => value.id === parseInt(zoneid));

      // if (this.offices.find(x => x.id == this.selectedOffice)) {
      //   this.ServiceService.getGroupedBUID(parseInt(localStorage.getItem('OfficeID')), parseInt(localStorage.getItem('BUId'))).subscribe((data: any) => {
      //     var groupedbuid = data;
      //     localStorage.setItem('BUId', groupedbuid);
      //   });
      // }

    }
  }

  changeZones(e) {
    var zoneid = e.target.value;
    this.zoneSetter(zoneid);
  }

  changeBU(e) {
    var buid = (e.target == undefined ? e : e.target.value);
    this.setPreference = false;
    this.buSetter(buid, true);
  }

  buSetter(buid, onchange: boolean) {
    // if (this.IsGroupedBU) {
    //   this.ServiceService.getGroupedBUID(parseInt(localStorage.getItem('OfficeID')), buid).subscribe((data: any) => {
    //     var groupedbuid = data;
    //     localStorage.setItem('BUId', groupedbuid);
    //   });
    // }
    // else {
    var selectedOfficeIDFormParking = parseInt(localStorage.getItem('RedirectFromParking'));
    if (isNaN(selectedOfficeIDFormParking)) {
      selectedOfficeIDFormParking = 0;
    }
    localStorage.setItem('BUId', buid);
    // }
    if (localStorage.getItem('GroupedBU') == 'true' && !this.FullData) {
      this.ServiceService.getFloorsFromBU(parseInt(localStorage.getItem('OfficeID')), buid, this.router.url).subscribe((data: any[]) => {
        if (data.length > 0) {
          if (this.NavigatingURL == "/gymBooking") {
            data = data.filter(o => o.hasGym == true);
          }
          else if (this.NavigatingURL == "/deskConfiguration") {
            data = data
          }
          else {
            data = data.filter(o => o.hasGym == false);
          }
          this.FloorsInAllLanguage = data;
          this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
          if (this.userPrefData != undefined && this.userPrefData.selCountry == localStorage.getItem('SelectedCountry') && this.setPreference && (selectedOfficeIDFormParking == 0 || selectedOfficeIDFormParking == undefined)) {
            this.floorSetter(this.userPrefData.selFloorId);
          }
          else {
            // if (onchange) {
            this.floorSetter(this.floors[0].id);
          }
          // }
          // else {
          //   this.floorSetter(this.selectedFloor);
          // }
        }
        else {
          this.FloorsInAllLanguage = data;
          this.floors = data;
        }
      });
    }
    else {
      this.ServiceService.getZonesFromBU(parseInt(localStorage.getItem('OfficeID')), buid).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
          if (onchange) {
            this.zoneSetter(this.zones[0].id);
          }
          else {
            this.zoneSetter(this.selectedZone);
          }
        } else { this.zones = ''; }
      });
    }
  }
  ParkingSelection(value) {
    this.selectedCarParking.emit(value);
    localStorage.setItem("CarParkingSelection", value);
    this.ngzone.run(() => { localStorage.setItem("CarParkingSelection", value) });
  }

  LoadOfficesBasedonLang() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    //this.ngzone.run(() => { localStorage.setItem("CarParkingSelection", value) });
  }

  checkIfUserSelected() {
    let bookingFor = localStorage.getItem('bookingFor') == undefined ? 'self' : localStorage.getItem('bookingFor');
    if (bookingFor == 'self') {
      this.validateButton.emit(true);
    } else {
      var selectedOfficeIDFormParking = parseInt(localStorage.getItem('RedirectFromParking'));
      if (localStorage.getItem('selectedOtherUserEmail') != null || selectedOfficeIDFormParking != 0) {
        this.validateButton.emit(true);
      } else {
        this.validateButton.emit(false);
      }
    }
  }

  //--- DeskType Implement 11Aug2022
  deskTypeSetter(deskTypeid) {
    if (deskTypeid != undefined) {
      this.selectedDeskType = deskTypeid;
      localStorage.setItem('DeskTypeID', deskTypeid);

      var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(deskTypeid));
      if (selDeskTypeObj != undefined) {
        localStorage.setItem('SelectDeskType', selDeskTypeObj.Name);
      }
    }
  }

  AddDeskType() {
    this.showPopUp = true;
  }

  desktypeFormCheck(showMsgPopup: boolean) {
    this.showPopUp = false;
    //--- DeskType Implement 11Aug2022 Get DeskTypes by Office Id
    if (showMsgPopup == true) {
      // alert("DeskType saved successfully!!");

      var _offID = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
      if (_offID != undefined) {
        this.ServiceService.getDeskTypes(parseInt(_offID)).subscribe((data: any[]) => {
          if (data.length > 0) {

            // if(this.NavigatingURL=="/gymBooking")
            // {
            //   data=data.filter(o => o.translationKey.includes('GYM_'));
            //   console.log(data);
            // }
            this.selectedDeskType = data[0].id;
            this.deskTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));
            //this.deskTypes = data;
            this.deskTypeSetter(this.selectedDeskType);

            var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(this.selectedDeskType));
          }
          else {
            this.deskTypes = data;
            this.deskTypeSetter(this.selectedDeskType);
          }
        });
      }
    }
    // if (showMsgPopup == true) {
    //   this.showMsgPopUp = true;
    // }
  }
  fetchCompleteData(fetchData) {
    if (fetchData) {
      this.FullData = true;
      this.ShowFullData.emit(true);
      this.changeOffice(parseInt(localStorage.getItem('OfficeID')));
    }
    else {
      this.FullData = false;
      this.ShowFullData.emit(false);
      this.changeOffice(parseInt(localStorage.getItem('OfficeID')));
    }
  }

}
