export class SupportForm {

    constructor(
      public UserName: string,
      public SenderEmail: string,
      public Title: string,
      public Comments: string,
      public Category: string,
      public Functionality: string,
      public UserLanguage: string,
    ) {  }

  }