import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { stringify } from 'querystring';
import { AdminDTO } from '../Model/AdminDTO';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import * as $ from 'jquery';

@Component({
  selector: 'app-dashboard-access',
  templateUrl: './dashboard-access.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class DashboardAccessComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  RoleValue: any[];
  constructor(private service: ServiceService, public translate: TranslateService,
    private router: Router) {
  }

  blockPaste(event: ClipboardEvent) {
    this.validateFields(event);
  }
  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(/[^a-zA-Z0-9-_]/g, '');
    document.execCommand('insertHTML', false, pasteData);
  }

  showMsgPopUp: boolean = false;
  showErrPopUp: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownListCountry = [];
  selectedItemsCountry=[];
  dropdownSettings = {};
  OfficeList: string='';
  CountryList: string;
  formDetails: FormData;
  showLoader = false;
  PopUpMessage: string;
  msgTitle: string;
  displayedColumns: string[] = ['id', 'userId', 'userRole','Edit','UpdateDesk'];//, 'UpdateDesk'
  dataSource = new MatTableDataSource<AdminDTO>();
  //dataSource : any;
  AdminList : AdminDTO[];
  AreyousuretoremovetheUser: string;
  givenText:string;
  givenTextOffice:string;




  OfficeForm = new FormGroup({
    Id: new FormControl(0),
    Country: new FormControl('', Validators.required),
    Admin: new FormControl('', Validators.required),
    BusinessRole: new FormControl(''),
    SMSRole: new FormControl('', [Validators.required, Validators.minLength(3)]),
    OfficeId: new FormControl('', Validators.required)
  });
  get OfficeId() { return this.OfficeForm.get('OfficeId'); }
  get Country() { return this.OfficeForm.get('Country'); }
  get Admin() { return this.OfficeForm.get('Admin'); }
  get BusinessRole() { return this.OfficeForm.get('BusinessRole'); }
  get SMSRole() { return this.OfficeForm.get('SMSRole') }

  addEditOrViewOffice: string;
  viewOrEditOfficeId: string;
  IsEditView: boolean;
  IsOnlyNumberName:boolean;
  IsOnlyNumberCity:boolean;
  UserRegionList:any;
  givenTextAdmin: string='';
  UserDetails: any;
  displayUserName: string;
  displayUserEMail: string;
  displayPersonalDetails: boolean = false;
  selectedUkEuropeIds:any=[];

  ngOnInit() {
    this.showLoader = true;
    this.formDetails = new FormData();
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      enableCheckAll: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true
    };
    // this.service.GetAllBUOffices().subscribe((result: any) => {
    //   this.dropdownList = result;
    // });

    this.service.getUserAllRegion().subscribe((data: any[]) => {
      this.dropdownListCountry=data;
      $("#SMSRole option[value='Manager']").hide();
      this.selectedUkEuropeIds =  this.dropdownListCountry.filter(item=>item.name.toLowerCase()=='europe' || item.name.toLowerCase()=='united kingdom').map(({ id }) => id);
    });

    this.service.getAdminRoles().subscribe((data: any[]) => {
      this.RoleValue=data;
    });

    if (this.addEditOrViewOffice == 'edit' || this.addEditOrViewOffice == 'add') {
      this.IsEditView = true;
    }
    else {
      this.IsEditView = true;
    }

    if (this.addEditOrViewOffice == 'edit' || this.addEditOrViewOffice == 'view') {
      this.GetOffice();
    }

    this.service.getAllAdmins().subscribe((data: AdminDTO[]) => {
      this.AdminList = data;
      this.dataSource.data = this.AdminList;
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });



  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  GetOffice() {
    this.service.getOfficeData(this.viewOrEditOfficeId).subscribe((data: any) => {

      this.Country.setValue(data.country);
      this.Admin.setValue(this.doLowercase(data.admin));

      this.BusinessRole.setValue(data.BusinessRole);
      this.SMSRole.setValue(data.SMSRole);
    });
  }

  submit() {

    let isemailvalid=/\b[a-z0-9-_.]+@[a-z0-9-_.]+(\.[a-z0-9]+)+/i.test(this.Admin.value.trim());
    //this.officeDTO = this.OfficeForm.value;
    this.OfficeForm.value.OfficeID=this.OfficeList;
    this.OfficeForm.value.Country=this.CountryList;

    // if(this.BusinessRole.value.trim()== '')
    // {
    //   this.BusinessRole.markAsTouched();
    //   document.getElementById('BusinessRole').focus();
    //   return;
    // }
    if(this.SMSRole.value.trim()== '')
    {
      this.SMSRole.markAsTouched();
      document.getElementById('SMSRole').focus();
      return;
    }
    if(!isemailvalid)
    {
      document.getElementById('Admin').focus();
      this.Admin.markAsTouched();
    //  alert('Name can not be left blank');
      return;
    }
    if(this.Admin.value.trim()== '')
    {
      document.getElementById('Admin').focus();
      this.Admin.markAsTouched();
      return;
    }
    // if(this.Country.value.trim()=='')
    // {
    //   this.Country.markAsTouched();
    //   document.getElementById('Country').focus();
    //   return;
    // }
    // if(this.OfficeId.value.trim()=='')
    // {
    //   this.OfficeId.markAsTouched();
    //   document.getElementById('OfficeId').focus();
    //   return;
    // }
    if (this.OfficeForm.status === 'VALID') {
      this.showLoader = true;

      const formData = new FormData();
      formData.append('Admin', (this.OfficeForm.value.Admin).toString());
      //formData.append('BusinessRole', (this.OfficeForm.value.BusinessRole).toString());
      formData.append('SMSRole', (this.OfficeForm.value.SMSRole).toString());
      formData.append('OfficeID', (this.OfficeForm.value.OfficeID).toString());
      formData.append('Country', (this.OfficeForm.value.Country).toString());
      this.formDetails = formData;
      // alert(this.OfficeForm.value.Country);
      // alert(this.OfficeForm.value.OfficeID);
      // alert(this.OfficeForm.value.SMSRole);
      // alert(this.OfficeForm.value.BusinessRole);
      // alert(this.OfficeForm.value.Admin);

      this.service.addDashBoardAccess(this.formDetails).subscribe((result: any) => {

      if(result==200)
      {
        this.showMsgPopUp = true;
        this.translate.get('AdminSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('AdminSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        this.showLoader = false;
      }
      else
      {
        this.showMsgPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
        this.showLoader = false;
      }
      }, error => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
      })
    }

    else {
        this.showErrPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('FormError').subscribe((data: string) => { this.PopUpMessage = data; });
    }


  }

   doLowercase(string) {
    return (typeof string === 'string') ? string.toLowerCase() : string;
  }

  GoToOfficeDetailsView() {
    this.router.navigateByUrl('/Admin');
  }

  get f() {
    return this.OfficeForm.controls;
  }

  changeCountry(e) {
    this.service.getAllOfficesOnRegion(this.CountryList).subscribe((result: any) => {
      this.dropdownList = result;
    });

  }


  searchTest(val) {
    this.showLoader = true;
    this.service.getUserDetails(val).subscribe((data: any) => {
      this.showLoader = false;
      //this.UserDetails = data.filter(x => x.mail != null && x.mail != localStorage.getItem('CurrentUserEmail'));
      this.UserDetails = data.filter(x => x.mail != null);
    })
  }

  getUserDetail(value) {
    var selectedOtherUser = this.UserDetails.find(x => x.mail == value);
    this.displayUserName = selectedOtherUser.displayName;
    this.displayUserEMail = selectedOtherUser.mail;
    this.displayPersonalDetails = true;
  }

  CheckMail(event: Event): void {
    this.service.getUserDetails((event.target as HTMLInputElement).value).subscribe((data: any) => {

      this.UserDetails = data.filter(x => x.mail == (event.target as HTMLInputElement).value);
      if(this.UserDetails.length==0)
      {
        if((event.target as HTMLInputElement).id=='Admin')
        {
          this.givenTextAdmin="";
        }
      }
    })
  }


  onItemSelect(item: any) {
    if (this.OfficeList != null) {
      this.OfficeList = this.OfficeList + ',' + item.id.toString();
    }
    else {
      this.OfficeList = item.id.toString();
    }
  }

  onSelectAll(items: any): void {
    this.OfficeList ='';
    items.forEach(item => {
      this.OfficeList = this.OfficeList + ',' + item.id.toString();
    });
    this.OfficeList=this.OfficeList.substring(1);
  }
  onDeSelectAll(items: any){
    this.OfficeList='';
}


  onItemDeSelect(item: any) {
    this.OfficeList = this.OfficeList.toString().replace(item.id.toString(), "0");
  }

  onItemSelectCountry(item: any) {
    if (this.CountryList != null) {
      this.CountryList = this.CountryList + ',' + item.id.toString();
    }
    else {
      this.CountryList = item.id.toString();
    }
    this.service.getAllOfficesOnRegion(this.CountryList).subscribe((result: any) => {
      this.dropdownList = result;
    });
    if(item.name.toLowerCase()=='europe' || item.name.toLowerCase()=='united kingdom')
    {
      $("#SMSRole option[value='Manager']").show();
    }
    else
    {
      $("#SMSRole option[value='Manager']").hide();
    }
  }
  onItemDeSelectCountry(item: any) {
    this.CountryList = this.CountryList.toString().replace(item.id.toString(), "0");
    this.service.getAllOfficesOnRegion(this.CountryList).subscribe((result: any) => {
      this.dropdownList = result;
    });
    let countryStringArray=this.CountryList.split(",");
    if(countryStringArray.includes(this.selectedUkEuropeIds[0].toString()) || countryStringArray.includes(this.selectedUkEuropeIds[1].toString())){
      $("#SMSRole option[value='Manager']").show();
    }
    else
    {
      $("#SMSRole option[value='Manager']").hide();
    }
  }

  onSelectAllCountry(items: any): void {
    this.CountryList ='';
    items.forEach(item => {
      this.CountryList = this.CountryList + ',' + item.id.toString();
    });
    this.CountryList=this.CountryList.substring(1);

    this.service.getAllOfficesOnRegion(this.CountryList).subscribe((result: any) => {
      this.dropdownList = result;
    });
    $("#SMSRole option[value='Manager']").show();
  }
  onDeSelectAllCountry(items: any){
    this.CountryList='';
    this.dropdownList =[];
    $("#SMSRole option[value='Manager']").hide();
}
DeleteRecord(selectedUser) {
  this.translate.get('AreyousuretoremovetheUser').subscribe((data: string) => { this.AreyousuretoremovetheUser = data; });
  if (confirm(this.AreyousuretoremovetheUser)) {
    this.showLoader = true;
    this.service.deleteAdmin(selectedUser.userId,localStorage.getItem('CurrentUserEmail')).subscribe((result: any) => {
      if(result==301) {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('HeaderAdminNotDelete').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('AdminNotDelete').subscribe((data: string) => { this.PopUpMessage = selectedUser.userId + data; });
      }
      else{
      this.showLoader = false;
      this.showMsgPopUp = true;
      this.translate.get('UserDeletedSuccessfully').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('UserDeletedSuccessfully').subscribe((data: string) => { this.PopUpMessage = data; });
      }
    }, err => {
      this.showLoader = false;
      this.showMsgPopUp = true;
      this.translate.get('UserDeletionFailed').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('UserDeletionFailed').subscribe((data: string) => { this.PopUpMessage = data; });
    });
  }
}

GetRecordToUpdate(e) {
  this.OfficeForm.get('Admin').setValue(e.userId);
  this.OfficeForm.get('SMSRole').setValue(e.SMSRole);

  this.service.getAdminInfo(e.userId).subscribe((result: any) => {

    this.OfficeForm.get('SMSRole').setValue(result[0].userRole);

    this.service.getEditAdminInfoRegion(result[0].allowedRegion).subscribe((result: any) => {
      this.selectedItemsCountry = result;
      this.CountryList=result.map(x => x.id).join(",");

      var index = this.selectedItemsCountry.findIndex(a => a.name.toLowerCase() == 'europe' ||  a.name.toLowerCase() == 'united kingdom');
      if(index > -1){ $("#SMSRole option[value='Manager']").show(); }
      else{ $("#SMSRole option[value='Manager']").hide(); }
    });

    this.service.getAllOfficesOnRegion(result[0].allowedRegion).subscribe((result: any) => {
      this.dropdownList = result;
    });
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      enableCheckAll: true,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: true
    };
    this.service.getEditAdminInfo(result[0].allowedOffice).subscribe((result: any) => {
      this.selectedItems = result;
      this.OfficeList = result.map(x => x.id).join(",");
    });
  });

  window.scroll(0, 0);
}
search() {
  this.givenTextOffice=''
  this.showLoader = true;
  //if (this.givenText.length > 0) {

    if(this.givenText==undefined )
    {
      this.givenText='';
    }
    if (this.givenText!='') {
      this.service.getAdminSearchData(this.givenText).subscribe((data: AdminDTO[]) => {
        this.AdminList = data;
        this.dataSource.data = this.AdminList;
        this.showLoader = false;
    });
  }
  else {
    this.service.getAllAdmins().subscribe((data: AdminDTO[]) => {
      this.AdminList = data;
      this.dataSource.data = this.AdminList;
      this.showLoader = false;
    });
  }
}
searchWithOffice() {
  this.givenText='';
  this.showLoader = true;
  //if (this.givenText.length > 0) {

    if(this.givenTextOffice==undefined )
    {
      this.givenTextOffice='';
    }
    if (this.givenTextOffice!='') {
      this.service.getAdminSearchDataBasedOnOffice(this.givenTextOffice).subscribe((data: AdminDTO[]) => {
        this.AdminList = data;
        this.dataSource.data = this.AdminList;
        this.showLoader = false;
    });
  }
  else {
    this.service.getAllAdmins().subscribe((data: AdminDTO[]) => {
      this.AdminList = data;
      this.dataSource.data = this.AdminList;
      this.showLoader = false;
    });
  }
}

}

