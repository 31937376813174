import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { OfficeDTO } from '../Model/OfficeDTO';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-office-details',
  templateUrl: './office-details.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class OfficeDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private service: ServiceService, public translate: TranslateService,
    private router: Router) { }

  offices: OfficeDTO[];
  dataSource = new MatTableDataSource<OfficeDTO>();
  showMsgPopUp: boolean = false;
  showLoader = false;
  PopUpMessage: string;
  msgTitle: string;
  givenText: string;

  columnsToDisplay = ['id', 'name', 'country', 'city', 'admin', 'action'];

  ngOnInit() {
    this.GetAllOffices();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  //Get all Offices Data
  GetAllOffices() {
    let partialUrl = 'api/Admin/GetAllOfficeDetails';
    this.service.getWithoutParams(partialUrl).subscribe((data: OfficeDTO[]) => {
      this.offices = data;
      this.dataSource.data = this.offices;
    });
  }

  UpdateOfficeDetails(element) {
    localStorage.setItem('addEditOrViewOffice', "edit");
    localStorage.setItem('viewOrEditOfficeId', element.id);
    this.router.navigateByUrl('/office');
  }

  ViewOfficeDetails(element) {
    localStorage.setItem('addEditOrViewOffice', "view");
    localStorage.setItem('viewOrEditOfficeId', element.id);
    this.router.navigateByUrl('/office');
  }

  AddOfficeDetails() {
    localStorage.setItem('addEditOrViewOffice', "add");
    this.router.navigateByUrl('/office');
  }

  search() {
    this.showLoader = true;
    if (this.givenText.length > 0) {
      this.service.GetOfficeSearchData(this.givenText).subscribe((data: any) => {
        this.offices = data;
        this.dataSource.data = this.offices;
        this.showLoader = false;
      });
    }
    else {
      let partialUrl = 'api/Admin/GetAllOfficeDetails';
      this.service.getWithoutParams(partialUrl).subscribe((data: OfficeDTO[]) => {
        this.offices = data;
        this.dataSource.data = this.offices;
        this.showLoader = false;
      });
    }
  }
}
