import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import { tab } from '../utils'
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-checkin-res',
  templateUrl: './checkin-res.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class CheckinResComponent implements OnInit {
  showLoader: boolean = false;
  showMsgSuccessPopUp: boolean = false;
  showMsgPopUp: boolean = false;
  selectedReservation: any;
  isCheckedIn: boolean = false;
  data: tab[];
  showPopUp: boolean = false;
  showHealthform: boolean = false;
  country: string;
  checkinReservationId: number;
  constructor(private ServiceService: ServiceService,
    private titleService: Title,
    private dataService: userDataService,
    private router: Router) { }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle("Check-in");
    localStorage.setItem('PageName', 'Checkin');
    var params = {
      CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
    }
    this.ServiceService.getTodayReservations(params).subscribe((data: any[]) => {
      this.showLoader = false;
      this.data = data;
      this.country = data[0].reservationDetails[0].country;
    });
  }

  ResSelected(detail) {
    this.showPopUp = true;
    this.showLoader = true;
    this.selectedReservation = detail;
    this.country=this.selectedReservation.country;
    this.ServiceService.getCheckedInStatus(this.selectedReservation.reservationID).subscribe((data: any) => {
      this.isCheckedIn = data.isCheckedIn;
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
    });
  }

  Booking() {
    (localStorage.getItem('SelectedCountry') == 'United States') ? this.showHealthform = true : this.router.navigateByUrl('/checkin');
  }

  closeBooking() {
    this.selectedReservation = undefined
    this.showPopUp = false;
    this.isCheckedIn = false;
  }
  resHealthFormCheck(showMsgPopup: boolean) {
    this.showLoader = true;
    this.showPopUp = false
    if (showMsgPopup == true) {
      this.showMsgPopUp = true;
    }
    var params = {
      CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
    }
    this.ServiceService.getTodayReservations(params).subscribe((data: any[]) => {
      this.showLoader = false;
      this.data = data;
      // this.showMsgSuccessPopUp = true;
    });
  }
  checkInBooking() {
    if (this.country == 'United States') {
      this.dataService.selectedCountryForCheckin.next(this.country);
      this.checkinReservationId = this.selectedReservation.reservationID;
      this.showPopUp = false;
      this.showHealthform = true;
      // document.getElementById('newHealthform').classList.add('addshowmobilemenu');
    }
    else {
      this.confirmCheckin();
    }
  }
  confirmCheckin() {
    this.showLoader = true;
    var res_details = {
      ID: this.selectedReservation.reservationID
    }

    this.ServiceService.checkInReservation(res_details).subscribe((data: any) => {
      // TO REFRESH THE DATA SOURCE
      var params = {
        CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
        Date: moment.utc(new Date()).local().format('YYYY-MM-DD')
      }
      this.ServiceService.getTodayReservations(params).subscribe((data: any[]) => {
        this.showLoader = false;
        this.data = data;
        this.showMsgSuccessPopUp = true;
      });
      this.closeBooking();
      //alert('You have checked-in');
    });
  }
}
