import { Component, OnInit, ElementRef, Renderer2, Input, ViewChild, NgZone } from '@angular/core';
import { userDataService } from '../services/data-share';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class LeftNavComponent implements OnInit {
  selectedPageNav: string;
  isMobilePopupViz: boolean;
  ShowGymIcon: boolean = false;
  //NonGTCEmployees: boolean = false;
  @Input() selected_nav: string;
  @ViewChild("homenav", { read: ElementRef }) homenav: ElementRef;
  @ViewChild("resnav", { read: ElementRef }) resnav: ElementRef;
  @ViewChild("checkinnav", { read: ElementRef }) checkinnav: ElementRef;
  @ViewChild("checkoutnav", { read: ElementRef }) checkoutnav: ElementRef;
  @ViewChild("cancnav", { read: ElementRef }) cancnav: ElementRef;
  @ViewChild("swapnav", { read: ElementRef }) swapnav: ElementRef;
  @ViewChild("gymnav", { read: ElementRef }) gymnav: ElementRef;

  map = new Map<string, ElementRef>();
  constructor(private renderer: Renderer2, private el: ElementRef, private dataService: userDataService, private ngzone: NgZone) { }

  ngOnInit() {
    this.selectedPageNav = localStorage.getItem('PageName');
    this.isMobilePopupViz = this.dataService.getShowMobilePopup();
    var GymRegion = localStorage.getItem("GymRegion");
    GymRegion == "Yes" ? this.ShowGymIcon = true : false;
    // this.map.set('HOME', this.homenav); 
    // this.map.set('RES', this.resnav);
    // this.map.set('CIN', this.checkinnav); 
    // this.map.set('COUT', this.checkoutnav);
    // this.map.set('CANC', this.cancnav); 
    // this.map.set('SWAP', this.swapnav);

    // this.renderer.addClass(
    //   this.map.get(this.selected_nav).nativeElement, 'active')
    if (localStorage.getItem('token') != null) {
      this.EnableAdminButton();
    }
  }

  hideMobilePopup() {
    this.dataService.setShowMobilePopup(false);
    document.getElementById('leftNavigation').classList.remove('addshowmobilemenu');
  }
  EnableAdminButton() {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var location = payLoad.Location;
    // if (location != 'GTC') {
    //   this.NonGTCEmployees = true;
    // }
  }
}
