import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import { tab } from '../utils';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cancel-res',
  templateUrl: './cancel-res.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class CancelResComponent implements OnInit {
  selectedReservation: any;
  data: tab[];
  currentUserName:string=localStorage.getItem("CurrentUserEmail").toLowerCase();
  showPopUp: boolean = false;
  // canSwap:boolean = false;
  showMsgPopUp: boolean = false;
  showLoader = false;
  showPopupCancelBtn: boolean;
  redirectTo: string;
  cancelBtnText: string = "Cancel";
  okBtnText: string = "OK";
  PopUpMessage: string;
  msgTitle: string;
  showMsgPopUperror: boolean;
  constructor(private ServiceService: ServiceService, private titleService: Title, private dataService: userDataService,
    public translate: TranslateService,public datepipe: DatePipe) { }

  ngOnInit() {
    this.showLoader = true;
    this.titleService.setTitle("Cancel Bookings");
    localStorage.setItem('PageName', 'Cancel');
    var params = {
      CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      Date: moment.utc(new Date()).local().format('YYYY-MM-DD'),
      Code: localStorage.getItem('AccessToken')
    };
    this.ServiceService.getSelfReservations(params).subscribe((data: any[]) => {
      this.showLoader = false;
      this.data = data;
    });
  }

  ResSelected(selRes) {
    this.showPopUp = true;
    // this.canSwap=false;
    // if(localStorage.getItem('CurrentUserEmail').toLowerCase()==selRes.emailId.toLowerCase())
    // {
    // this.canSwap=true;
    // }
    this.selectedReservation = selRes
  }

  cancelBooking() {
    this.showLoader = true;
    var res_details = {
      ID: this.selectedReservation.reservationID,
      ReservationType: this.selectedReservation.reservationType,
      Code: localStorage.getItem('AccessToken'),
      OutlookMeetingId:this.selectedReservation.outlookId
    }

    this.ServiceService.cancelReservation(res_details).subscribe((data: any) => {
      if (data["hasParking"] == 'False') {
        var params = {
          CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
          Date: moment.utc(new Date()).local().format('YYYY-MM-DD'),
          Code: localStorage.getItem('AccessToken')
        };
        // TO REFRESH THE DATA SOURCE
        this.ServiceService.getSelfReservations(params).subscribe((data: any[]) => {
          this.showLoader = false;
          this.data = data;
          this.showMsgPopUp = true;
        });
      }
      else {
        localStorage.setItem('parkingMail',this.selectedReservation.emailId);

        var dat=this.datepipe.transform(this.selectedReservation.date, 'yyyy-MM-dd');
        localStorage.setItem('parkingDate',dat);

        this.showLoader = false;
        this.showMsgPopUp = false;
        this.showMsgPopUperror = true;
        this.showPopupCancelBtn = true;
        this.translate.get('CancelParking').subscribe((data: string) => { this.msgTitle = data; });
        this.redirectTo = '/cancellation';
        this.translate.get('DontCancel').subscribe((data: string) => { this.cancelBtnText = data; });
        this.translate.get('Cancel').subscribe((data: string) => { this.okBtnText = data; });
        this.translate.get('DeskCancelWithParkingCancelMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      // var params = {
      //   CurrentUserEmail: localStorage.getItem('CurrentUserEmail'),
      //   Date: moment.utc(new Date()).local().format('YYYY-MM-DD'),
      //   Code: localStorage.getItem('AccessToken')
      // };
      // // TO REFRESH THE DATA SOURCE
      // this.ServiceService.getSelfReservations(params).subscribe((data: any[]) => {
      //   this.showLoader = false;
      //   this.data = data;
      //   this.showMsgPopUp = true;
      // });
      this.closeBooking();
    }, err => {
      this.showLoader = false;
    });
  }

  closeBooking() {
    this.selectedReservation = undefined
    this.showPopUp = false;
  }

}
