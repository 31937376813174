import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import * as moment from 'moment';
import { bypassSanitizationTrustStyle } from '@angular/core/src/sanitization/bypass';
import { TranslateService } from '@ngx-translate/core'
import { tr } from 'date-fns/locale';

@Component({
  selector: 'app-healthform-booking',
  templateUrl: './healthform-booking.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class HealthformBookingComponent implements OnInit {
  @Input() redirectTo: string;
  @Input() fromReservationId: number;
  @Output() showPopUp = new EventEmitter<boolean>();

  // symptomsChk: boolean = false;
  // testingChk: boolean = false;
  // exposureChk: boolean = false;
  completeChkYes: boolean = false;
  completeChkNo: boolean = true;
  firstToggle: boolean = false;
  showMsgPopUp: boolean = false;
  showLoader: boolean = false;
  selectedCountry: string;
  browserLang1: string;

  constructor(private ServiceService: ServiceService,
    private titleService: Title,
    private dataService: userDataService, private router: Router, public translate: TranslateService) {
    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(ServiceService.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  toggleBtn(yesClicked: boolean) {
    if (!this.firstToggle) {
      this.firstToggle = true;
      if (yesClicked) {
        this.completeChkYes = true;
        this.completeChkNo = false;
      } else {
        this.completeChkYes = false;
        this.completeChkNo = true;
      }
    } else {
      if (yesClicked) {
        this.completeChkNo = false;
        this.completeChkYes = true;
      } else {
        this.completeChkYes = false;
        this.completeChkNo = true;
      }
    }
  }

  currentSelection(value) {
    if (value == 'yes') {
      this.firstToggle = true;
      this.completeChkYes = false;
      this.completeChkNo = true;
    }
    else {
      this.firstToggle = false;
      this.completeChkYes = true;
      this.completeChkNo = false;
    }
  }



  ngOnInit() {
    this.titleService.setTitle("Help desk");
    this.browserLang1 = localStorage.getItem('locale');
    this.selectedCountry = localStorage.getItem('SelectedCountry');
    // if(this.selectedCountry=='South America')
    // {
    //   this.completeChkYes= true;
    //   this.completeChkNo= false;
    // }
  }

  formSubmit() {
    this.showLoader = true;
    if (this.completeChkNo == true) {
      localStorage.setItem('HealthForm', 'Submit');
    }
    if (this.completeChkYes == true && this.completeChkNo == false) {
      var userDetail = {
        Name: localStorage.getItem('CurrentUser'),
        SenderMail: localStorage.getItem('CurrentUserEmail'),
        Title: '',
        Description: ''
      };
      this.ServiceService.postFailHealthAssmnt(userDetail).subscribe((data: any) => {
        //alert('You have failed health assessment!');
        //this.showMsgPopUp = false;
        //this.closeBooking();
        this.showLoader = false;
        this.showPopUp.emit(true);
        //document.getElementById('newHealthform').classList.add('removeshowmobilemenu');
      });

    }
    else {
      if (localStorage.getItem('RedirectFromParking') != '0') {
        if (localStorage.getItem('NotShowParkingPopup') != '0') {
          localStorage.setItem('NotShowParkingPopup', undefined)
        }
      };
      this.closeBooking();
    }
  }

  closeBooking() {
    this.showPopUp.emit(false);
    document.getElementById('newHealthform').classList.add('removeshowmobilemenu');
  }

}
