import { Component, OnInit, ViewEncapsulation, EventEmitter, NgZone } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ParkingItem } from '../Model/CarParkingDTO';
@Component({
  selector: 'app-booking-cancel',
  templateUrl: './booking-cancel.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class BookingCancelComponent implements OnInit {
  offices: any;
  reservations: any[];
  UseForFilter: any[];
  selParkSpace: any;
  selectedOffice: any;
  selParkType: any;
  today: Date;
  minDate: string;
  maxDate: string;
  currentDate: any;
  showMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  validateButton: boolean = false;
  showLoader: boolean = false;
  licenseText: string;
  selSpaceId: number;
  selBkngType: number;
  selSpacePermitReq: boolean = false;
  ConfirmMsg: string;
  tempReservedId: number;
  givenText: string;
  showCancelBookingMsgPopUp: boolean;
  showCancelParkingMsgPopUp: boolean;
  showPopupCancelBtn: boolean;
  redirectTo: string;
  cancelBtnText: string = "Cancel";
  okBtnText: string = "OK";
  msgBody: string;

  constructor(private ServiceService: ServiceService, private ngzone: NgZone, private router: Router
    , public translate: TranslateService, private titleService: Title,
    private dataService: userDataService,
    public datepipe: DatePipe) {
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  ngOnInit() {

    this.ServiceService.getOffices(localStorage.getItem('SelectedCountry'), true).subscribe((data: any[]) => {
      this.offices = data;

      if (this.offices.length > 0 && (localStorage.getItem('OfficeID') != undefined)) {
        this.selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
        // let _officeDetail = this.offices.find(x => x.id == this.selectedOffice);
        this.officeLoader();
      }
    });

    this.currentDate = new Date()
    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.today = localStorage.getItem('SelectedDateToBook') == undefined ? this.currentDate : localStorage.getItem('SelectedDateToBook');
    this.minDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.maxDate = this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');

    this.titleService.setTitle("Cancel a Desk Reservation");

  }

  changeOffice(e) {
    var _offID = e.target.value;
    this.officeLoader();
  }

  officeLoader() {
    this.selParkType = undefined;
    if (this.selectedOffice == 'undefined' || this.selectedOffice == undefined || this.selectedOffice == "null") return;
    this.reservationsLoader();

  }


  reservationsLoader() {
    if (this.selectedOffice == 'undefined' || this.selectedOffice == undefined || this.selectedOffice == "null") return;
    this.showLoader = true;
    this.ServiceService.getDeskReservations(this.selectedOffice, this.today).subscribe((data: any) => {
      this.reservations = data;
      this.UseForFilter = this.reservations;
      this.showLoader = false;
      this.validateButton = false;
    });
  }

  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }

  changeDate() {
    this.selParkType = undefined;
    this.reservationsLoader();
  }

  cancelBooking(reservedId: number, isGym: boolean) {
    this.tempReservedId = reservedId;
    if (isGym == true) {
      this.translate.get("GymCancel").subscribe((data: string) => { this.msgTitle = data; });
    }
    else {
      this.translate.get("DeskCancel").subscribe((data: string) => { this.msgTitle = data; });
    }
    this.translate.get("Areyousuretcancelthebookings").subscribe((data: string) => { this.msgBody = data; });

    this.showCancelBookingMsgPopUp = true;
    var selectedReservation = this.UseForFilter.filter(x => x.reservationID == reservedId);

    localStorage.setItem('parkingMail', selectedReservation[0].emailId);

    var dat = this.datepipe.transform(selectedReservation[0].date, 'yyyy-MM-dd');
    localStorage.setItem('parkingDate', dat);

    // var res_details = {
    //   ID: reservedId
    // };

    // this.translate.get('Areyousuretcancelthebookings').subscribe((data: string) => { this.ConfirmMsg = data; });
    // if (confirm(this.ConfirmMsg)) {
    //   this.showLoader = true;
    //   this.ServiceService.cancelReservation(res_details).subscribe((data: any) => {
    //     this.showLoader = false;
    //     this.translate.get('BookingCancelled').subscribe((data: string) => { alert(data); })

    //     this.officeLoader();
    //   });
    // }
  }
  noShowCancelBookingMsgPopUp() {
    this.showCancelBookingMsgPopUp = false;
  }
  yesShowCancelBookingMsgPopUp() {
    this.showCancelBookingMsgPopUp = false;
    var res_details = {
      ID: this.tempReservedId
    };
    //Verify if its Gym or Normal Seat
    var selectedReservation = this.UseForFilter.filter(x => x.reservationID == this.tempReservedId);
    var isForGym = selectedReservation[0].isForGym;
    //End


    this.showLoader = true;
    if (isForGym == false) {
      this.ServiceService.cancelReservation(res_details).subscribe((data: any) => {
        this.showLoader = false;

        if (data["hasParking"] == 'False') {
          //this.translate.get('BookingCancelled').subscribe((data: string) => { alert(data); })
        }
        else {
          this.showLoader = false;
          this.showMsgPopUp = false;
          this.showCancelParkingMsgPopUp = true;
          this.showPopupCancelBtn = true;
          this.translate.get('CancelParking').subscribe((data: string) => { this.msgTitle = data; });
          this.redirectTo = '/bookingCancellation';
          this.translate.get('DontCancel').subscribe((data: string) => { this.cancelBtnText = data; });
          this.translate.get('Cancel').subscribe((data: string) => { this.okBtnText = data; });
          this.translate.get('DeskCancelWithParkingCancelMsgOther').subscribe((data: string) => { this.PopUpMessage = data; });
        }

        this.officeLoader();
      });
    }
    else {
      this.ServiceService.cancelReservationForGym(res_details).subscribe((data: any) => {
        this.showLoader = false;

        if (data["hasParking"] == 'False') {
          //this.translate.get('BookingCancelled').subscribe((data: string) => { alert(data); })
        }
        else {
          this.showLoader = false;
          this.showMsgPopUp = false;
          this.showCancelParkingMsgPopUp = true;
          this.showPopupCancelBtn = true;
          this.translate.get('CancelParking').subscribe((data: string) => { this.msgTitle = data; });
          this.redirectTo = '/bookingCancellation';
          this.translate.get('DontCancel').subscribe((data: string) => { this.cancelBtnText = data; });
          this.translate.get('Cancel').subscribe((data: string) => { this.okBtnText = data; });
          this.translate.get('DeskCancelWithParkingCancelMsgOther').subscribe((data: string) => { this.PopUpMessage = data; });
        }

        this.officeLoader();
      });
    }
  }
  search() {
    this.showLoader = true;
    //if (this.givenText.length > 0) {

    if (this.givenText == undefined) {
      this.givenText = '';
    }
    if (this.givenText != '') {
      this.reservations = this.UseForFilter.filter(x => x.reservedFor.toLowerCase().includes(this.givenText.toLowerCase()));
      this.showLoader = false;
    }
    else {
      this.reservations = this.UseForFilter;
      this.showLoader = false;
    }
  }
  onKeyDownEvent(event: any) {
    event.preventDefault();
  }

}
