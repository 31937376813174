import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { ZoneDTO } from '../Model/ZoneDTO';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface zones {
  id: number;
  name: string;
  FloorId: number;
  FloorName: string;
  OfficeName: string;
  FloorplanBlobPath: string;
  HasDeskCategory: boolean;
  SequenceId: number
  showFullFloorplan: boolean;
  FullFloorplanPath: string;
  FloorplanAttachmentPath: string;
  IsAvailable: boolean;
}
@Component({
  selector: 'app-add-zone',
  templateUrl: './add-zone.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class AddZoneComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: zones[];
  dataSource1 = new MatTableDataSource<zones>();
  //displayedColumns: string[] = ['id', 'name', 'floorName', 'officeName', 'sequenceId', 'hasDeskCategory', 'floorPlanPath', 'showFullFloorplan', 'UpdateDesk'];//, 'sequence_id', 'office_id'
  displayedColumns: string[] = ['id', 'name', 'floorName', 'officeName', 'hasDeskCategory', 'floorPlanPath', 'showFullFloorplan', 'IsAvailable' , 'UpdateDesk'];//, 'sequence_id', 'office_id'
  public zoneDTO: ZoneDTO;
  formDetails: FormData;
  showErrPopUp: boolean = false;  
  givenText: string;
  givenTextOffice:string;
  EditRedirect:boolean;
  regexStr = '^[a-zA-Z0-9 -]+$';
  showRemainingOption: boolean;
  showErrPopUpWarning: boolean = false;
  okBtnText: string = "OK";

  onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }
  blockPaste(event: ClipboardEvent) {
    this.validateFields(event);
  }
  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(/[^a-zA-Z0-9-_]/g, '');
    document.execCommand('insertHTML', false, pasteData);
  }
  constructor(private Service: ServiceService, public translate: TranslateService) { }
  FloorList: any;
  showMsgPopUp: boolean = false;
  showLoader = false;
  PopUpMessage: string;
  msgTitle: string;
  files: string[] = [];
  FloorPlan: boolean = false;
  FullFloorPlan: boolean = false;
  EmailAttachment: boolean = false;
  showFullFloorPlanFlag: boolean = false;
  IsOnlyNumber:boolean;

  ZoneForm = new FormGroup({
    Id: new FormControl(0),
    Name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    FloorId: new FormControl('', Validators.required),
    // FloorplanBlobPath: new FormControl('', Validators.required),
    HasDeskCategory: new FormControl(false, Validators.required),
    // SequenceId: new FormControl('', [Validators.required, Validators.max(50)]),
    SequenceId: new FormControl(''),
    DeskFloorPlan: new FormControl('', Validators.required),
    ShowFullFloorplan: new FormControl(false),
    FullFloorplanPath: new FormControl(''),
    FloorplanAttachmentPath: new FormControl(''),
    IsAvailable: new FormControl(true, Validators.required),
  });

  get Name() { return this.ZoneForm.get('Name'); }
  get FloorId() { return this.ZoneForm.get('FloorId'); }
  get SequenceId() { return this.ZoneForm.get('SequenceId'); }
  get HasDeskCategory() { return this.ZoneForm.get('HasDeskCategory') }
  //get HasZones() { return this.ZoneForm.get('HasZones') }
  get ShowFullFloorplan() { return this.ZoneForm.get('ShowFullFloorplan') }
  get FullFloorplanPath() { return this.ZoneForm.get('FullFloorplanPath') }
  get FloorplanAttachmentPath() { return this.ZoneForm.get('FloorplanAttachmentPath') }
  get DeskFloorPlan() { return this.ZoneForm.get('DeskFloorPlan') }
  get IsAvailable() { return this.ZoneForm.get('IsAvailable') }

  ngOnInit() {
    this.showLoader = true;
    this.Service.getAllFloorsForZones().subscribe((result: any) => {
      this.FloorList = result;
    });
    this.Service.getAllZones().subscribe((res: any) => {
      this.dataSource = res as zones[];
      this.dataSource1.data = res;
      this.showLoader = false;
    });
  }
  ngAfterViewInit() {
    this.dataSource1.paginator = this.paginator;
  }

  setradio(e) {
    this.showFullFloorPlanFlag = (e=='true')?true:false;
  }
 
  onFileChange(event, UploadFolder: string) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);
      if (UploadFolder == 'floorplan') { this.FloorPlan = true; }
      else if (UploadFolder == 'fullfloorplan') { this.FullFloorPlan = true; }
      else if (UploadFolder == 'emailattach') { this.EmailAttachment = true; }
    }
  }
  submit() {

    let isnum = /^\d+$/.test(this.ZoneForm.value.Name.trim());
    if(isnum)
    {
      this.IsOnlyNumber=true;
      document.getElementById('Name').focus();
      this.Name.markAsTouched();
      return;
    }
    if(this.ZoneForm.value.IsAvailable==false)
    {
      if(this.ZoneForm.value.FloorId === ''){
        document.getElementById('FloorId').focus();
        this.FloorId.markAsTouched();
        return;
      }
      if(this.ZoneForm.value.Name === ''){
        document.getElementById('Name').focus();
        this.Name.markAsTouched();
        return;
      }
    }
    else{
      var deskFloorPlan = /[^\\]*$/.exec(this.ZoneForm.value.DeskFloorPlan)[0];
      var floorplanAttachmentPath = /[^\\]*$/.exec(this.ZoneForm.value.FloorplanAttachmentPath)[0];      

      var deskFloorPlanWhiteSpaceCheck=this.hasWhiteSpace(deskFloorPlan);
      var floorplanAttachmentPathWhiteSpaceCheck=this.hasWhiteSpace(floorplanAttachmentPath);

      if(this.ZoneForm.value.ShowFullFloorplan==true) {
            var fullFloorplanPath = /[^\\]*$/.exec(this.ZoneForm.value.FullFloorplanPath)[0];
            var FullFloorplanPathWhiteSpaceCheck=this.hasWhiteSpace(fullFloorplanPath);

            if(FullFloorplanPathWhiteSpaceCheck) {
              this.showErrPopUpWarning = true;
              this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
              this.translate.get('FileWhiteSpaceErrorMsg').subscribe((data: string) => { this.PopUpMessage = data; });
              return;
            }
      }
      if(deskFloorPlanWhiteSpaceCheck || floorplanAttachmentPathWhiteSpaceCheck) {
        this.showErrPopUpWarning = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('FileWhiteSpaceErrorMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        return;
      }
    }
    if ( this.ZoneForm.value.Name != '' && (this.ZoneForm.value.IsAvailable==true? this.ZoneForm.status === 'VALID':this.ZoneForm.value.Name != '')) {
      //this.ZoneForm.status === 'VALID' &&
      this.getZoneplanDetails();
      this.showLoader = true;
      this.Service.uploadZonePlan(this.formDetails).subscribe((result: any) => {
        this.showMsgPopUp = true;
        if (result == 200) {
          this.translate.get('ZoneSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('ZoneSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else if (result == 300) {
          this.translate.get('SaveZoneExistTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('SaveZoneExistData').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else if (result == 401) {
          this.translate.get('CkeckedInTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('CkeckedInBodyZone').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        else if (result == 400) {
          this.translate.get('UploadFile').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('FileUploadFail').subscribe((data: string) => { this.PopUpMessage = data; });
        }
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
      })

    }
    else {
      this.showErrPopUp = true;
      this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('FormError').subscribe((data: string) => { this.PopUpMessage = data; });
    }
  }
  getZoneplanDetails() {
    this.zoneDTO = this.ZoneForm.value;
    const formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      formData.append("file[]", this.files[i]);
    }
    formData.append('Id', this.zoneDTO.Id.toString());
    formData.append('Name', this.zoneDTO.Name);
    formData.append('FloorId', (this.zoneDTO.FloorId).toString());
    formData.append('SequenceId', (this.zoneDTO.SequenceId).toString());
    formData.append('HasDeskCategory', (this.zoneDTO.HasDeskCategory).toString());
    formData.append('IsAvailable', (this.zoneDTO.IsAvailable).toString());
    formData.append('ShowFullFloorplan', this.zoneDTO.ShowFullFloorplan == null ? '' : (this.zoneDTO.ShowFullFloorplan).toString());
    formData.append('FullFloorplanPath', this.zoneDTO.FullFloorplanPath == null ? '' : (this.zoneDTO.FullFloorplanPath).toString().replace(/^.*[\\\/]/, ''));
    formData.append('FloorplanAttachmentPath', this.zoneDTO.FloorplanAttachmentPath == null ? '' : (this.zoneDTO.FloorplanAttachmentPath).toString().replace(/^.*[\\\/]/, ''));
    formData.append('DeskFloorPlan', this.zoneDTO.DeskFloorPlan == null ? '' : (this.zoneDTO.DeskFloorPlan).toString().replace(/^.*[\\\/]/, ''));
    this.formDetails = formData;
  }
  GetRecordToUpdate(e) {
    this.ZoneForm.get('Id').setValue(e.id);
    this.ZoneForm.get('Name').setValue(e.name);
    this.ZoneForm.get('FloorId').setValue(e.floorId);
    // this.ZoneForm.get('DeskFloorPlan').setValue(e.floorPlanPath);
    this.ZoneForm.get('SequenceId').setValue(e.sequenceId);
    this.ZoneForm.get('HasDeskCategory').setValue(e.hasDeskCategory);
    this.ZoneForm.get('IsAvailable').setValue(e.isAvailable);
    // this.ZoneForm.get('FullFloorplanPath').setValue(e.fullFloorplanPath);
    this.ZoneForm.get('ShowFullFloorplan').setValue(e.showFullFloorplan);
    if(e.showFullFloorplan==true)
    {this.showFullFloorPlanFlag=true;}
    else{this.showFullFloorPlanFlag=false;}
    this.EditRedirect=true;
    window.scroll(0, 0);
  }

  search() {
    this.showLoader = true;
    //if (this.givenText.length > 0) {
      if (this.givenText!=undefined || this.givenTextOffice!=undefined) {
        if(this.givenText==undefined )
      {
        this.givenText='';
      }
      if(this.givenTextOffice==undefined )
      {
        this.givenTextOffice='';
      }
      this.Service.getZonesSearchData(this.givenText,this.givenTextOffice).subscribe((res: any) => {
        this.dataSource = res as zones[];
        this.dataSource1.data = res;
        this.showLoader = false;
      });
    }
    else {
      this.Service.getAllZones().subscribe((res: any) => {
        this.dataSource = res as zones[];
        this.dataSource1.data = res;
        this.showLoader = false;
      });
    }
  }

  isAvailableEvent(Option) {
    console.log(Option);
    this.showRemainingOption = (Option.value) ? true : false;   
  }
  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  formSubmit()
  {
    this.showLoader=false;
    this.showErrPopUpWarning=false;
  }

}
