import { Component } from '@angular/core';
import { ServiceService } from './services/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


import { MatTooltip } from '@angular/material/tooltip';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SaveMySeatFrontEnd'; router: string;

  constructor(private ServiceService: ServiceService){}
  data: any;
  EmpForm: FormGroup;
  submitted = false;
  EventValue: any = "Save";

  ngOnInit(): void {

  }
}
