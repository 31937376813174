import { Component, OnInit, ElementRef, ViewChild, Input, NgZone, Output, EventEmitter, HostListener, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { Time } from '@angular/common';
import { startTimeRange } from '@angular/core/src/profile/wtf_impl';
import * as SvgPanZoom from 'svg-pan-zoom';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AzureBlobStorageService } from './../azure-blob-storage.service';
import keys from '../key.json';

const BASE_DESK_COLOR: string = "rgb(213,255,241)";
const SELECTED_DESK_COLOR: string = "rgb(0,0,0)";

const BOOKED_DESK_COLOR: string = "rgb(255,10,10)";
const AVAILABLE_DESK_COLOR: string = "rgb(60,226,60)";
const SNC_AVAILABLE_DESK_COLOR: string = "rgb(250,130,0)";
const PENDING_DESK_COLOR: string = "rgb(8,0,255)";
const NOT_AVAILABLE_DESK_COLOR: string = "rgb(24,6,223)";

// const DESK_OPACITY = "1.0";
// const NOT_DESK_OPACITY = "1.0";
// const INV_DESK_OPACITY = "0";


@Component({
  selector: 'app-gym-floorplan',
  templateUrl: './gym-floorplan.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class GymFloorplanComponent implements OnInit {
  fp_svg_data: any;
  readonly statusColorMap = new Map<number, string>();
  readonly opacityMap = new Map<boolean, string>();
  readonly deskAvailableStatus: number[] = [2, 3, 4];
  readonly abc: number = 0;
  loopcount: number = 0;
  public getScreenWidth: any;
  public getScreenHeight: any;

  showLoader = false;
  chkQuietDesks: boolean = false;
  chkSitStandDesks: boolean = false;
  chkDualMonDesks: boolean = false;
  chkSNCLDesks: boolean = false;
  chkAssignedDesks: boolean = false;
  chkTouchdownDesks: boolean = false;
  bookingStartTime: string;
  bookingEndTime: string;
  lastSelectedDeskNode;
  QuickBookingDeskNumber: string;
  _buid: number = parseInt(localStorage.getItem('BUId'));
  allMeetingDesks: any;
  isFullDay: boolean = true;
  meetingRoomCheck = '';
  meetingActive = false;
  // sub!: Subscription;
  // imgsrc!: any;

  //------- DeskType Implement 11Aug2022 --------
  selectedDeskType: string = '';
  day_Selection: string;
  //Quiet Room
  quietRoomCheck = true;
  @Input()
  set deskTypes(deskTypeId: string) {
    this.selectedDeskType = deskTypeId;
    this.filterDesksByCategory();
  }


  @Input() fp_path: string;

  @Input()
  set desks_quiet(is_checked: boolean) {
    this.chkQuietDesks = is_checked;
    this.filterDesksByCategory();
  }

  @Input()
  set date_change(date: string) {

    localStorage.setItem('SelectedDateToBook', date);
    this.lastSelectedDeskNode = undefined;


    //when select seat below method executed to load map again.
    this.showLoader = true;
    document.getElementById('overlay-loading').classList.remove('showLoader');
    this.applyEvents(true);

  }

  @Input()
  set desks_sitstand(is_checked: boolean) {
    this.chkSitStandDesks = is_checked;
    this.filterDesksByCategory();
  }

  @Input()
  set desks_dualmonitor(is_checked: boolean) {
    this.chkDualMonDesks = is_checked;
    this.filterDesksByCategory();
  }

  @Input()
  set desks_sncl(is_checked: boolean) {
    this.chkSNCLDesks = is_checked;
    this.filterDesksByCategory();
  }

  @Input()
  set desks_assigned(is_checked: boolean) {
    this.chkAssignedDesks = is_checked;
    this.filterDesksByCategory();
  }

  @Input()
  set desks_touchdown(is_checked: boolean) {
    this.chkTouchdownDesks = is_checked;
    this.filterDesksByCategory();
  }

  @Input()
  set booking_start_timing(starttime: string) {
    this.bookingStartTime = starttime;
    if (starttime != undefined) {
      this.applyEvents(true);
      this.roomAvailability();
    }
  }

  @Input()
  set partialfull(isFullDay) {
    this.isFullDay = isFullDay;
    this.roomAvailability();
  }

  @Input()
  set roomChanged(selectedDateDetails) {
    if (selectedDateDetails.length > 0) {
      this.meetingRoomCheck = selectedDateDetails[0].MeetingName;
      this.roomAvailability();
    }
  }

  @Input()
  set booking_end_timing(endtime: string) {
    this.bookingEndTime = endtime;
    if (endtime != undefined) {
      this.applyEvents(true);
    }
    this.roomAvailability();
  }

  @Input()
  set single_multiple(day_Selection: string) {
    this.day_Selection = day_Selection;
  }

  @Input()
  set meeting_desk_changed(meetingOrDesk) {
    this.lastSelectedDeskNode = undefined;
    this.filterDesksByCategory();
  }

  filterDesksByCategory_Old() {
    if (this.allDesksOnPlan == undefined) {

      return;
    }
    let filtereddesksCount = 0;

    if (this.chkQuietDesks == false &&
      this.chkSitStandDesks == false &&
      this.chkDualMonDesks == false &&
      this.chkSNCLDesks == false &&
      this.chkAssignedDesks == false &&
      this.chkTouchdownDesks == false) {
      this.filtered_desks = this.allDesksOnPlan;
      this.filtered_sncdesks = this.sncDesks;
    }
    else {

      // var filtered_desks = this.allDesksOnPlan.filter(value =>
      //   value.isQuiet == this.chkQuietDesks ||
      //   value.isSitStand == this.chkSitStandDesks ||
      //   value.isDualMonitor == this.chkDualMonDesks ||
      //   value.isSNCL == this.chkSNCLDesks);
      if (this.chkQuietDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkSitStandDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkDualMonDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkSNCLDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkAssignedDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkTouchdownDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (filtereddesksCount < 2) {
        if (this.chkQuietDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isQuiet == this.chkQuietDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isQuiet == this.chkQuietDesks);
        }
        if (this.chkSitStandDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isSitStand == this.chkSitStandDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isSitStand == this.chkSitStandDesks);
        }
        if (this.chkDualMonDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDualMonitor == this.chkDualMonDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDualMonitor == this.chkDualMonDesks);
        }
        if (this.chkSNCLDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isSNCL == this.chkSNCLDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isSNCL == this.chkSNCLDesks);
        }
        if (this.chkAssignedDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false).concat(this.allDesksOnPlan.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false).concat(this.sncDesks.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
        }
        if (this.chkTouchdownDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false).concat(this.allDesksOnPlan.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false).concat(this.sncDesks.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
        }
        if (this.chkTouchdownDesks && this.chkAssignedDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false);
        }
      }
      else {
        this.filtered_desks = this.allDesksOnPlan.filter(value => value.isQuiet == this.chkQuietDesks && value.isSitStand == this.chkSitStandDesks && value.isDualMonitor == this.chkDualMonDesks);
        if (this._buid == 201)
          this.filtered_sncdesks = this.sncDesks.filter(value => value.isQuiet == this.chkQuietDesks && value.isSitStand == this.chkSitStandDesks && value.isDualMonitor == this.chkDualMonDesks);
        if (this.chkAssignedDesks) {
          this.filtered_desks = this.filtered_desks.filter(value => value.isDesk == false).concat(this.filtered_desks.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.filtered_sncdesks.filter(value => value.isDesk == false).concat(this.filtered_sncdesks.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
        }
        if (this.chkTouchdownDesks) {
          this.filtered_desks = this.filtered_desks.filter(value => value.isDesk == false).concat(this.filtered_desks.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.filtered_sncdesks.filter(value => value.isDesk == false).concat(this.filtered_sncdesks.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
        }
        if (this.chkTouchdownDesks && this.chkAssignedDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false);
        }
      }
    }


    let desk_marks;
    if (this.newSVGFormat) {
      if (this.checkIfLayersExist()) return;
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.getElementById("Bubbles").querySelectorAll('path') as NodeList);

    } else {
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.querySelectorAll('ellipse') as NodeList)
    }


    var i: number;
    // var opacity_value = this.opacityMap.get(is_checked);
    //FILTER BUBBLES
    for (i = 0; i < desk_marks.length; i++) {
      let desk_ell = desk_marks[i] as SVGPathElement;
      var desk_number = desk_ell.id.split('_')[1];
      var desk_Visible = this.allDesksOnPlan.filter(value => value.deskNumber === desk_number);
      if (parseInt(localStorage.getItem('OfficeID')) == 20 && (desk_Visible.length > 0)) {
        var workspace = desk_Visible[0].workSpaceType;
        if (this.MeetingCheck(workspace)) {
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          continue;
        }
      }
      if (this.sncDesks != undefined)
        var sncdesk_Visible = this.sncDesks.filter(value => value.deskNumber === desk_number);
      if (desk_Visible.length > 0) {
        var desk_is_available: boolean = desk_Visible[desk_Visible.length - 1].isAvailable;

        if (desk_is_available == true) {
          var desk_Res = this.filtered_desks.filter(value => value.deskNumber === desk_number);
          if (desk_Res.length > 0) {
            // desk_ell.style.setProperty("opacity", this.DESK_OPACITY);
            var desk_Res2 = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
            var meetingName = desk_Visible[0].meetingName;
            var workspace = desk_Visible[0].workSpaceType;
            if (desk_Res2.length > 0) {
              var desk_status = desk_Res2[desk_Res2.length - 1].status;
              desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            } else if (parseInt(localStorage.getItem('OfficeID')) == 20 && this.completeRoomBooked(workspace) && this.desksOnPlan.filter(value => value.meetingName === meetingName).length > 0) {
              var hasMeetingBooked = this.desksOnPlan.filter(value => value.meetingName === meetingName);
              var desk_status = hasMeetingBooked[hasMeetingBooked.length - 1].status;
              desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            } else {
              if (this._buid != 204)
                desk_ell.style.setProperty("fill", AVAILABLE_DESK_COLOR);
              else
                desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
            }
          }
          else {
            //desk_ell.style.setProperty("opacity", this.INV_DESK_OPACITY);
            desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          }
        } else {
          desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
        }
      }
      else if (this._buid == 201 && sncdesk_Visible.length > 0) {

        var desk_is_available: boolean = sncdesk_Visible[sncdesk_Visible.length - 1].isAvailable;

        if (desk_is_available == true) {
          var desk_Res = this.filtered_sncdesks.filter(value => value.deskNumber === desk_number);
          if (desk_Res.length > 0) {
            // desk_ell.style.setProperty("opacity", this.DESK_OPACITY);
            var desk_Res2 = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
            if (desk_Res2.length > 0) {
              var desk_status = desk_Res2[desk_Res2.length - 1].status;
              if (desk_status == 2 || desk_status == 3 || desk_status == 4)
                desk_status = 5;
              desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            } else {
              desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
            }
          } else {
            //desk_ell.style.setProperty("opacity", this.INV_DESK_OPACITY);
            desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          }
        } else {
          desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
        }
      }
      else {
        desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
        desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
      }
    };


    //Apply style to selected desk
    if (this.chkQuietDesks == false &&
      this.chkSitStandDesks == false &&
      this.chkDualMonDesks == false &&
      this.chkSNCLDesks == false && this.chkAssignedDesks == false && this.chkTouchdownDesks == false) {
      if (this.lastSelectedDeskNode != undefined) {
        var selectedDeskNum = this.lastSelectedDeskNode.id.split('_')[1];
        let selBubble = this.lastSelectedDeskNode.ownerSVGElement.getElementById("DN_" + selectedDeskNum) as SVGPathElement;
        selBubble.style.setProperty("fill", SELECTED_DESK_COLOR);
      }
    } else {
      if (this.lastSelectedDeskNode != undefined) {
        var selectedDeskNum = this.lastSelectedDeskNode.id.split('_')[1];
        var desk_Res = this.filtered_desks.filter(value => value.deskNumber === selectedDeskNum);
        if (this.filtered_sncdesks != undefined)
          var sncdesk_Res = this.filtered_sncdesks.filter(value => value.deskNumber === selectedDeskNum);
        if (desk_Res.length > 0 || (this._buid == 201 && sncdesk_Res.length > 0)) {
          let selBubble = this.lastSelectedDeskNode.ownerSVGElement.getElementById("DN_" + selectedDeskNum) as SVGPathElement;
          selBubble.style.setProperty("fill", SELECTED_DESK_COLOR);
        }
      }
    }


  }

  filterDesksByCategory() {
    if (this.allDesksOnPlan == undefined) {

      return;
    }
    let filtereddesksCount = 0;

    if (this.chkQuietDesks == false &&
      this.chkSitStandDesks == false &&
      this.chkDualMonDesks == false &&
      this.chkSNCLDesks == false &&
      this.chkAssignedDesks == false &&
      this.chkTouchdownDesks == false &&
      this.selectedDeskType == '') {
      this.filtered_desks = this.allDesksOnPlan;
      this.filtered_sncdesks = this.sncDesks;
    }
    else {

      // var filtered_desks = this.allDesksOnPlan.filter(value =>
      //   value.isQuiet == this.chkQuietDesks ||
      //   value.isSitStand == this.chkSitStandDesks ||
      //   value.isDualMonitor == this.chkDualMonDesks ||
      //   value.isSNCL == this.chkSNCLDesks);
      if (this.selectedDeskType) {
        filtereddesksCount = filtereddesksCount + 1;
      }

      if (this.chkQuietDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkSitStandDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkDualMonDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkSNCLDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkAssignedDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (this.chkTouchdownDesks) {
        filtereddesksCount = filtereddesksCount + 1;
      }
      if (filtereddesksCount < 2) {
        //-------- DeskType Implementation
        if (this.selectedDeskType) {
          var selectedDeskTypeArray = this.selectedDeskType.split(',');
          if (selectedDeskTypeArray.length > 1) {
            var allDeskPlanData = this.allDesksOnPlan.filter(value => selectedDeskTypeArray.every(function (item) {
              return value.deskTypeIds.split(',').includes(item);
            }));
            this.filtered_desks = allDeskPlanData;

            if (this._buid == 201) {
              var sncDeskData = this.sncDesks.filter(value => value.deskTypeIds.split(',').every(function (item) {
                return selectedDeskTypeArray.includes(item);
              }));
              this.filtered_sncdesks = sncDeskData;
            }
          }
          else {
            var deskTypeId = selectedDeskTypeArray[0];

            var allDeskPlanData = this.allDesksOnPlan.filter(value => value.deskTypeIds.split(',').includes(deskTypeId));
            this.filtered_desks = allDeskPlanData;

            if (this._buid == 201) {
              var sncDeskData = this.sncDesks.filter(value => value.deskTypeIds.split(',').includes(deskTypeId));
              this.filtered_sncdesks = sncDeskData;
            }
          }
        }

        if (this.chkQuietDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isQuiet == this.chkQuietDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isQuiet == this.chkQuietDesks);
        }
        if (this.chkSitStandDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isSitStand == this.chkSitStandDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isSitStand == this.chkSitStandDesks);
        }
        if (this.chkDualMonDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDualMonitor == this.chkDualMonDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDualMonitor == this.chkDualMonDesks);
        }
        if (this.chkSNCLDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isSNCL == this.chkSNCLDesks);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isSNCL == this.chkSNCLDesks);
        }
        if (this.chkAssignedDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false).concat(this.allDesksOnPlan.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false).concat(this.sncDesks.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
        }
        if (this.chkTouchdownDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false).concat(this.allDesksOnPlan.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false).concat(this.sncDesks.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
        }
        if (this.chkTouchdownDesks && this.chkAssignedDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false);
        }
      }
      else {
        this.filtered_desks = this.allDesksOnPlan.filter(value => value.isQuiet == this.chkQuietDesks && value.isSitStand == this.chkSitStandDesks && value.isDualMonitor == this.chkDualMonDesks);
        if (this._buid == 201)
          this.filtered_sncdesks = this.sncDesks.filter(value => value.isQuiet == this.chkQuietDesks && value.isSitStand == this.chkSitStandDesks && value.isDualMonitor == this.chkDualMonDesks);
        if (this.chkAssignedDesks) {
          this.filtered_desks = this.filtered_desks.filter(value => value.isDesk == false).concat(this.filtered_desks.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.filtered_sncdesks.filter(value => value.isDesk == false).concat(this.filtered_sncdesks.filter(value => value.isDesk == true && value.reservable == (this.chkAssignedDesks ? 'Assigned' : '')));
        }
        if (this.chkTouchdownDesks) {
          this.filtered_desks = this.filtered_desks.filter(value => value.isDesk == false).concat(this.filtered_desks.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
          if (this._buid == 201)
            this.filtered_sncdesks = this.filtered_sncdesks.filter(value => value.isDesk == false).concat(this.filtered_sncdesks.filter(value => value.isDesk == true && value.reservable == (this.chkTouchdownDesks ? 'Touchdown' : '')));
        }
        if (this.chkTouchdownDesks && this.chkAssignedDesks) {
          this.filtered_desks = this.allDesksOnPlan.filter(value => value.isDesk == false);
          if (this._buid == 201)
            this.filtered_sncdesks = this.sncDesks.filter(value => value.isDesk == false);
        }


        //-------- DeskType Implementation
        if (this.selectedDeskType) {
          var selectedDeskTypeArray = this.selectedDeskType.split(',');
          if (selectedDeskTypeArray.length > 1) {
            var allDeskPlanData = this.filtered_desks.filter(value => selectedDeskTypeArray.every(function (item) {
              return value.deskTypeIds.split(',').includes(item);
            }));
            this.filtered_desks = allDeskPlanData;

            if (this._buid == 201) {
              var sncDeskData = this.filtered_sncdesks.filter(value => value.deskTypeIds.split(',').every(function (item) {
                return selectedDeskTypeArray.includes(item);
              }));
              this.filtered_sncdesks = sncDeskData;
            }
          }
          else {
            var deskTypeId = selectedDeskTypeArray[0];

            var allDeskPlanData = this.filtered_desks.filter(value => value.deskTypeIds.split(',').includes(deskTypeId));
            this.filtered_desks = allDeskPlanData;

            if (this._buid == 201) {
              var sncDeskData = this.filtered_sncdesks.filter(value => value.deskTypeIds.split(',').includes(deskTypeId));
              this.filtered_sncdesks = sncDeskData;
            }
          }
        }

      }
    }


    let desk_marks;
    if (this.newSVGFormat) {
      if (this.checkIfLayersExist()) return;
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.getElementById("Bubbles").querySelectorAll('path') as NodeList);

    } else {
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.querySelectorAll('ellipse') as NodeList)
    }


    var i: number;
    // var opacity_value = this.opacityMap.get(is_checked);
    //FILTER BUBBLES
    for (i = 0; i < desk_marks.length; i++) {
      let desk_ell = desk_marks[i] as SVGPathElement;
      var desk_number = desk_ell.id.split('_')[1];
      var desk_Visible = this.allDesksOnPlan.filter(value => value.deskNumber === desk_number);
      if (this.meetingActive && (desk_Visible.length > 0)) {
        var workspace = desk_Visible[0].workSpaceType;
        if (this.MeetingCheck(workspace)) {
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          continue;
        }
      }
      if (this.sncDesks != undefined)
        var sncdesk_Visible = this.sncDesks.filter(value => value.deskNumber === desk_number);
      if (desk_Visible.length > 0) {
        var desk_is_available: boolean = desk_Visible[desk_Visible.length - 1].isAvailable;

        if (desk_is_available == true) {
          var desk_Res = this.filtered_desks.filter(value => value.deskNumber === desk_number);
          if (desk_Res.length > 0) {
            // desk_ell.style.setProperty("opacity", this.DESK_OPACITY);
            var desk_Res2 = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
            var meetingName = desk_Visible[0].meetingName;
            var workspace = desk_Visible[0].workSpaceType;
            if (desk_Res2.length > 0 && localStorage.getItem("View") != "Meeting") {
              var desk_status = desk_Res2[desk_Res2.length - 1].status;
              desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            }
            else if (localStorage.getItem("View") == "Meeting" && this.desksOnPlan.filter(value => value.meetingName === meetingName).length > 0) {
              var hasMeetingBooked = this.desksOnPlan.filter(value => value.meetingName === meetingName);
              var desk_status = hasMeetingBooked[hasMeetingBooked.length - 1].status;
              desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            } else {
              if (this._buid != 204)
                desk_ell.style.setProperty("fill", AVAILABLE_DESK_COLOR);
              else
                desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
            }
          } else {
            //desk_ell.style.setProperty("opacity", this.INV_DESK_OPACITY);
            desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          }
        } else {
          desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
        }
      }
      else if (this._buid == 201 && sncdesk_Visible.length > 0) {

        var desk_is_available: boolean = sncdesk_Visible[sncdesk_Visible.length - 1].isAvailable;

        if (desk_is_available == true) {
          var desk_Res = this.filtered_sncdesks.filter(value => value.deskNumber === desk_number);
          if (desk_Res.length > 0) {
            // desk_ell.style.setProperty("opacity", this.DESK_OPACITY);
            var desk_Res2 = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
            if (desk_Res2.length > 0) {
              var desk_status = desk_Res2[desk_Res2.length - 1].status;
              if (desk_status == 2 || desk_status == 3 || desk_status == 4)
                desk_status = 5;
              desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            } else {
              desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
            }
          } else {
            //desk_ell.style.setProperty("opacity", this.INV_DESK_OPACITY);
            desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          }
        } else {
          desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
        }
      }
      else {
        desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
        desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
      }
    };


    //Apply style to selected desk
    if (this.chkQuietDesks == false &&
      this.chkSitStandDesks == false &&
      this.chkDualMonDesks == false &&
      this.chkSNCLDesks == false &&
      this.chkAssignedDesks == false &&
      this.chkTouchdownDesks == false &&
      this.selectedDeskType == '') {
      if (this.lastSelectedDeskNode != undefined) {
        var selectedDeskNum = this.lastSelectedDeskNode.id.split('_')[1];
        let selBubble = this.lastSelectedDeskNode.ownerSVGElement.getElementById("DN_" + selectedDeskNum) as SVGPathElement;
        selBubble.style.setProperty("fill", SELECTED_DESK_COLOR);
      }
    } else {
      if (this.lastSelectedDeskNode != undefined) {
        var selectedDeskNum = this.lastSelectedDeskNode.id.split('_')[1];
        var desk_Res = this.filtered_desks.filter(value => value.deskNumber === selectedDeskNum);
        if (this.filtered_sncdesks != undefined)
          var sncdesk_Res = this.filtered_sncdesks.filter(value => value.deskNumber === selectedDeskNum);
        if (desk_Res.length > 0 || (this._buid == 201 && sncdesk_Res.length > 0)) {
          let selBubble = this.lastSelectedDeskNode.ownerSVGElement.getElementById("DN_" + selectedDeskNum) as SVGPathElement;
          selBubble.style.setProperty("fill", SELECTED_DESK_COLOR);
        }
      }
    }
  }



  @ViewChild("svgfloorplan", { read: ElementRef }) svgfloorplan: ElementRef;

  //For pan and zoom
  public scheme: SvgPanZoom.Instance;
  public options = {
    zoomEnabled: true,
    controlIconsEnabled: true,
    fit: true,
    center: true
  };

  @Output() selectedDeskNum = new EventEmitter();
  @Output() bookedDeskData = new EventEmitter();

  //To Send Room Availability Values to SelectSeat
  @Output() Available = new EventEmitter();
  @Output() showStatus = new EventEmitter();
  @Output() showSeatUnavailablity = new EventEmitter();
  desksOnPlan: any;
  allDesksOnPlan: any;
  filtered_desks: any;
  filtered_sncdesks: any;
  sncDesks: any;
  newSVGFormat: boolean = false;//set true for SVG made for color blindness

  DESK_OPACITY = "1.0";
  NOT_DESK_OPACITY = "1.0";
  INV_DESK_OPACITY = "0";
  //sas ="?sp=rwl&st=2023-01-02T05:03:02Z&se=2024-12-31T18:29:59Z&spr=https&sv=2021-06-08&sr=c&sig=qMmEdTPN1cxNg6QmqpFIPYQjtZAvRgPQaxSSFwA1rz8%3D";
  sas = keys[0]['sas'];
  @Output() showMeeting = new EventEmitter();
  //For Guyanabo Quiet Room
  @Output() QuietRoom = new EventEmitter();
  constructor(private dom: DomSanitizer,
    private ServiceService: ServiceService,
    private dataService: userDataService, private http: HttpClient, private blobService: AzureBlobStorageService, private ngzone: NgZone) { }



  ngOnInit() {
    this.ServiceService.getOfficeData(localStorage.getItem('OfficeID')).subscribe((data: any) => {
      if (data.meetingActive) {
        this.meetingActive = true;
        this.showMeeting.emit(true);
      }
      localStorage.setItem("MeetingName", "");
      this.Available.emit("false");
      this.showStatus.emit(false);
      localStorage.setItem('PageName', 'Res');
      this.getFloorPlanimage();
    });
  }

  getFloorPlanimage() {

    this.blobService.downloadImage(this.sas, localStorage.getItem('FloorPlanPath'), blob => {
      let url = window.URL.createObjectURL(blob);
      this.ngzone.run(() => { this.fp_svg_data = this.dom.bypassSecurityTrustResourceUrl(url); });//this.dom.sanitize(SecurityContext.HTML, this.dom.bypassSecurityTrustResourceUrl(url)) });
      this.newSVGFormat = localStorage.getItem('FloorPlanPath').includes('COLOR');

      this.statusColorMap.set(0, PENDING_DESK_COLOR);
      this.statusColorMap.set(1, BOOKED_DESK_COLOR);
      if (this._buid != 204) {
        this.statusColorMap.set(2, AVAILABLE_DESK_COLOR);
        this.statusColorMap.set(3, AVAILABLE_DESK_COLOR);
        this.statusColorMap.set(4, AVAILABLE_DESK_COLOR);
      }
      else {
        this.statusColorMap.set(2, SNC_AVAILABLE_DESK_COLOR);
        this.statusColorMap.set(3, SNC_AVAILABLE_DESK_COLOR);
        this.statusColorMap.set(4, SNC_AVAILABLE_DESK_COLOR);
      }
      this.statusColorMap.set(5, SNC_AVAILABLE_DESK_COLOR); //SNC Color
      this.opacityMap.set(true, this.INV_DESK_OPACITY);
      this.opacityMap.set(false, this.DESK_OPACITY);

      if (this.newSVGFormat) {
        this.DESK_OPACITY = "1.0";
        this.NOT_DESK_OPACITY = "1.0";
      } else {
        this.DESK_OPACITY = "0.377049";
        this.NOT_DESK_OPACITY = "0.50";
      }

      this.getScreenWidth = window.innerWidth;
      this.getScreenHeight = window.innerHeight;
    })
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  checkIfLayersExist() {
    if (this.svgfloorplan.nativeElement.contentDocument.getElementById("Bubbles") == null ||
      this.svgfloorplan.nativeElement.contentDocument.getElementById("Desks") == null) {
      return true;
    }
    else {
      return false;
    }
  }



  applyEvents(doFilterDesks: boolean = false) {
    if (this.newSVGFormat) {
      if (this.checkIfLayersExist()) return;
    }
    let desk_marks;
    let text_marks;
    if (this.newSVGFormat) {
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.getElementById("Bubbles").querySelectorAll('path') as NodeList);
      text_marks = (this.svgfloorplan.nativeElement.contentDocument.getElementById("Desks").querySelectorAll('text') as NodeList);
      (this.svgfloorplan.nativeElement.contentDocument.getElementById("Desks").style.cursor = 'context-menu');
    } else {
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.querySelectorAll('ellipse') as NodeList);
    }
    this.showLoader = true;
    if (desk_marks.length > 0) {
      if (localStorage.getItem('GroupedBU') == "true") {
        this.ServiceService.getGroupedBUID(parseInt(localStorage.getItem('OfficeID')), parseInt(localStorage.getItem('BUId'))).subscribe((data: any) => {
          var groupedbuid = data;
          this.ServiceService.getDesksFromFloorIDWithBUForGym(parseInt(localStorage.getItem('FloorID')), groupedbuid, this.bookingStartTime, this.bookingEndTime, parseInt(localStorage.getItem('BUId')))
            .subscribe((data: any) => {
              this.desksOnPlan = data.reservedDesks;
              this.allDesksOnPlan = data.allDesks;
              this.sncDesks = data.sncAllDesks;

              //Seat Unavailablity
              var allAvailableDesk = data.allDesks.filter(value => value.isAvailable === true && value.workSpaceType != 'Meeting Room');
              var DeskCount = allAvailableDesk.length;
              var reserved = data.reservedDesks.filter(x => x.workSpaceType != 'Meeting Room');
              var ReservedSeat = reserved.map(item => item.deskNumber)
                .filter((value, index, self) => self.indexOf(value) === index).length;
              if (DeskCount == ReservedSeat && DeskCount != 0 && this.day_Selection != 'multiple') {
                this.showSeatUnavailablity.emit(false);
              }
              else {
                this.showSeatUnavailablity.emit(true);
              }
              //Seat Unavailablity End

              this.filtered_sncdesks = this.sncDesks;
              this.filtered_desks = this.allDesksOnPlan;
              this.applyDeskStatusColors(desk_marks);
              this.applyDeskTextEvent(text_marks);
              //to apply any filters checked before date change
              if (doFilterDesks) { this.filterDesksByCategory(); }
              //higlight the selected desk
              this.showSelectedSeat(this.dataService.getSelectedDeskNumber());

              this.loopcount = this.loopcount + 1;
              //alert('case1 hi-'+this.loopcount);
              if (desk_marks.length != 0) {
                document.getElementById('overlay-loading').classList.remove('showLoader');
                document.getElementById('overlay-loading').classList.add('showLoader');
                //alert('case1 hi-'+this.loopcount);
              }
            });
        });
      }
      else if (localStorage.getItem('ZoneId') == 'undefined') {
        this.showLoader = true;
        if (document.getElementById('overlay-loading') != null)
          //  document.getElementById('overlay-loading').classList.remove('showLoader');
          // var _floorid = parseInt(this.dataService.getFloorID())
          var _floorid = parseInt(localStorage.getItem('FloorID'));
        this.ServiceService.getDesksFromFloorIDForGym(_floorid, this.bookingStartTime, this.bookingEndTime).subscribe((data: any) => {
          this.desksOnPlan = data.reservedDesks;
          this.allDesksOnPlan = data.allDesks;
          this.filtered_desks = this.allDesksOnPlan;

          //Seat Unavailablity
          var allAvailableDesk = data.allDesks.filter(value => value.isAvailable === true && value.workSpaceType != 'Meeting Room');
          var DeskCount = allAvailableDesk.length;
          var reserved = data.reservedDesks.filter(x => x.workSpaceType != 'Meeting Room');
          var ReservedSeat = reserved.map(item => item.deskNumber)
            .filter((value, index, self) => self.indexOf(value) === index).length;
          if (DeskCount == ReservedSeat && DeskCount != 0 && this.day_Selection != 'multiple') {
            this.showSeatUnavailablity.emit(false);
          }
          else {
            this.showSeatUnavailablity.emit(true);
          }
          //Seat Unavailablity End

          if (document.getElementById('overlay-loading') != null)
            this.applyDeskStatusColors(desk_marks);
          this.applyDeskTextEvent(text_marks);
          if (doFilterDesks) { this.filterDesksByCategory(); }
          this.showSelectedSeat(this.dataService.getSelectedDeskNumber());
          //document.getElementById('overlay-loading').classList.add('showLoader');
          this.loopcount = this.loopcount + 1;
          if (desk_marks.length != 0) {
            document.getElementById('overlay-loading').classList.remove('showLoader');
            document.getElementById('overlay-loading').classList.add('showLoader');
          }
        });
      }
      else {
        this.showLoader = true;
        if (localStorage.getItem('ZoneId') == 'null') {
          var _floorid = parseInt(localStorage.getItem('FloorID'));

          this.ServiceService.getDesksFromFloorIDForGym(_floorid, this.bookingStartTime, this.bookingEndTime).subscribe((data: any) => {
            this.desksOnPlan = data.reservedDesks;
            this.allDesksOnPlan = data.allDesks;

            //Seat Unavailablity
            var allAvailableDesk = data.allDesks.filter(value => value.isAvailable === true && value.workSpaceType != 'Meeting Room');
            var DeskCount = allAvailableDesk.length;
            var reserved = data.reservedDesks.filter(x => x.workSpaceType != 'Meeting Room');
            var ReservedSeat = reserved.map(item => item.deskNumber)
              .filter((value, index, self) => self.indexOf(value) === index).length;
            if (DeskCount == ReservedSeat && DeskCount != 0 && this.day_Selection != 'multiple') {
              this.showSeatUnavailablity.emit(false);
            }
            else {
              this.showSeatUnavailablity.emit(true);
            }
            //Seat Unavailablity End

            this.filtered_desks = this.allDesksOnPlan;
            this.applyDeskStatusColors(desk_marks);
            this.applyDeskTextEvent(text_marks);
            if (doFilterDesks) { this.filterDesksByCategory(); }
            this.showSelectedSeat(this.dataService.getSelectedDeskNumber());
            this.loopcount = this.loopcount + 1;
            if (desk_marks.length != 0) {
              document.getElementById('overlay-loading').classList.remove('showLoader');
              document.getElementById('overlay-loading').classList.add('showLoader');
            }

          });
        } else {
          var _zoneid = parseInt(localStorage.getItem('ZoneId'))
          if (localStorage.getItem('BUId') == 'undefined') {

            this.ServiceService.getDesksFromZoneIDForGym(_zoneid, this.bookingStartTime, this.bookingEndTime).subscribe((data: any) => {
              this.desksOnPlan = data.reservedDesks;
              this.allDesksOnPlan = data.allDesks;

              //Seat Unavailablity
              var allAvailableDesk = data.allDesks.filter(value => value.isAvailable === true && value.workSpaceType != 'Meeting Room');
              var DeskCount = allAvailableDesk.length;
              var reserved = data.reservedDesks.filter(x => x.workSpaceType != 'Meeting Room');
              var ReservedSeat = reserved.map(item => item.deskNumber)
                .filter((value, index, self) => self.indexOf(value) === index).length;
              if (DeskCount == ReservedSeat && DeskCount != 0 && this.day_Selection != 'multiple') {
                this.showSeatUnavailablity.emit(false);
              }
              else {
                this.showSeatUnavailablity.emit(true);
              }
              //Seat Unavailablity End

              this.filtered_desks = this.allDesksOnPlan;
              this.applyDeskStatusColors(desk_marks);
              this.applyDeskTextEvent(text_marks);
              if (doFilterDesks) { this.filterDesksByCategory(); }
              this.showSelectedSeat(this.dataService.getSelectedDeskNumber());

              this.loopcount = this.loopcount + 1;
              if (desk_marks.length != 0) {
                document.getElementById('overlay-loading').classList.remove('showLoader');
                document.getElementById('overlay-loading').classList.add('showLoader');
              }
            });
          } else {
            if (localStorage.getItem('BUId') == 'null') {
              this.ServiceService.getDesksFromZoneIDForGym(_zoneid, this.bookingStartTime, this.bookingEndTime).subscribe((data: any) => {
                this.desksOnPlan = data.reservedDesks;
                this.allDesksOnPlan = data.allDesks;

                //Seat Unavailablity
                var allAvailableDesk = data.allDesks.filter(value => value.isAvailable === true && value.workSpaceType != 'Meeting Room');
                var DeskCount = allAvailableDesk.length;
                var reserved = data.reservedDesks.filter(x => x.workSpaceType != 'Meeting Room');
                var ReservedSeat = reserved.map(item => item.deskNumber)
                  .filter((value, index, self) => self.indexOf(value) === index).length;
                if (DeskCount == ReservedSeat && DeskCount != 0 && this.day_Selection != 'multiple') {
                  this.showSeatUnavailablity.emit(false);
                }
                else {
                  this.showSeatUnavailablity.emit(true);
                }
                //Seat Unavailablity End

                this.filtered_desks = this.allDesksOnPlan;
                this.applyDeskStatusColors(desk_marks);
                this.applyDeskTextEvent(text_marks);
                if (doFilterDesks) { this.filterDesksByCategory(); }
                this.showSelectedSeat(this.dataService.getSelectedDeskNumber());
                this.loopcount = this.loopcount + 1;
                if (desk_marks.length != 0) {
                  document.getElementById('overlay-loading').classList.remove('showLoader');
                  document.getElementById('overlay-loading').classList.add('showLoader');
                }

              });
            }
            else {
              var _buid = parseInt(localStorage.getItem('BUId'));
              //document.getElementById('overlay-loading').classList.remove('showLoader');
              this.ServiceService.getDesksFromZoneIDWithBUForGym(_zoneid, _buid, this.bookingStartTime, this.bookingEndTime).subscribe((data: any) => {
                this.desksOnPlan = data.reservedDesks;
                this.allDesksOnPlan = data.allDesks;
                this.filtered_desks = this.allDesksOnPlan;
                this.applyDeskStatusColors(desk_marks);
                this.applyDeskTextEvent(text_marks);
                if (doFilterDesks) { this.filterDesksByCategory(); }
                this.showSelectedSeat(this.dataService.getSelectedDeskNumber());

                // document.getElementById('overlay-loading').classList.add('showLoader');
                this.loopcount = this.loopcount + 1;
                if (desk_marks.length != 0) {
                  document.getElementById('overlay-loading').classList.remove('showLoader');
                  document.getElementById('overlay-loading').classList.add('showLoader');
                }

              });
              // }
            }
          };
        }
      }
    }
  }
  applyDeskStatusColors(desk_marks: any) {
    // Define the arrow function
    const clickHandler = (event) => {
      var clkSuccess = this.nodeClicked(event.target);
    };
    const clickHandlerShowData = (event) => {
      var clkSuccess = this.showReservationData(event.target)
    }
    var i: number;
    for (i = 0; i < desk_marks.length; i++) {
      let desk_ell = desk_marks[i] as SVGPathElement;
      var desk_number = desk_ell.id.split('_')[1];
      // Applicable for BU wise desk filtering
      var desk_Visible = this.allDesksOnPlan.filter(value => value.deskNumber === desk_number);
      if (this.sncDesks != undefined)
        var sncdesk_Visible = this.sncDesks.filter(value => value.deskNumber === desk_number);
      if (desk_Visible.length > 0) {
        var desk_is_available: boolean = desk_Visible[desk_Visible.length - 1].isAvailable;
        if (desk_is_available == true) {
          desk_ell.style.setProperty("opacity", this.DESK_OPACITY)
          var desk_Res = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
          var meetingName = desk_Visible[0].meetingName;
          var workspace = desk_Visible[0].workSpaceType;
          if (desk_Res.length > 0) {
            // get last reservation detail
            var desk_status = desk_Res[desk_Res.length - 1].status;
            var meetingBooking = desk_Res.filter(value => value.meetingName == meetingName);
            if (meetingBooking.length == 0 && this.desksOnPlan.filter(value => value.meetingName === meetingName).length == 0 && localStorage.getItem("View") == "Meeting") {
              desk_status = 3;
            }
            desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            // do not add click event if desk is pending/booked
            if (this.deskAvailableStatus.includes(desk_status)) {
              desk_marks[i].addEventListener('click', clickHandler, false);
            }
            else {
              desk_marks[i].addEventListener('click', clickHandlerShowData, false);
            }
          }
          else if (this.completeRoomBooked(workspace) && this.desksOnPlan.filter(value => value.meetingName === meetingName).length > 0 && this.meetingActive) {
            var hasMeetingBooked = this.desksOnPlan.filter(value => value.meetingName === meetingName);
            var desk_status = hasMeetingBooked[hasMeetingBooked.length - 1].status;
            desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            desk_marks[i].addEventListener('click', clickHandlerShowData, false);
          }
          else {
            if (this._buid != 204)
              desk_ell.style.setProperty("fill", AVAILABLE_DESK_COLOR);
            else
              desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
            desk_marks[i].addEventListener('click', clickHandler, false);
          }
        } else {
          desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
        }
      }
      else if (this._buid == 201 && sncdesk_Visible.length > 0) {
        var desk_is_available: boolean = sncdesk_Visible[sncdesk_Visible.length - 1].isAvailable;
        if (desk_is_available == true) {
          desk_ell.style.setProperty("opacity", this.DESK_OPACITY)
          var desk_Res = this.desksOnPlan.filter(value => value.deskNumber === desk_number);

          if (desk_Res.length > 0) {
            // get last reservation detail
            var desk_status = desk_Res[desk_Res.length - 1].status;
            if (desk_status == 2 || desk_status == 3 || desk_status == 4)
              desk_status = 5;
            desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
            // do not add click event if desk is pending/booked
            if (this.deskAvailableStatus.includes(desk_status)) {
              desk_marks[i].addEventListener('click', clickHandler, false);
            }
            else {
              desk_marks[i].addEventListener('click', clickHandlerShowData, false);
            }
          }
          else {
            desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
            desk_marks[i].addEventListener('click', clickHandler, false);
          }
        }
        else {
          desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
        }
      }
      else {
        desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
        desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
      }
      if (this.meetingActive && (desk_Visible.length > 0)) {
        var workspace = desk_Visible[0].workSpaceType;
        if (this.MeetingCheck(workspace)) {
          desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          continue;
        }
      }
      // else {
      //   alert(this.INV_DESK_OPACITY)
      //   desk_ell.style.setProperty("opacity", this.INV_DESK_OPACITY)
      // }
      //For pan and zoom
      // && this.getScreenHeight > 00
      if (this.getScreenWidth > 1100)
        this.scheme = SvgPanZoom(this.svgfloorplan.nativeElement.contentDocument.getElementById('svgfloorplan'), this.options);
    }
  }
  MeetingCheck(workspace) {
    if (localStorage.getItem("View") == "Meeting" && (workspace == "Workstation" || workspace == "Office" || workspace == "Reception" || workspace == "Quiet Room") || localStorage.getItem("View") == "Desk" && (workspace != "Workstation" && workspace != "Office" && workspace != "Reception" && workspace != "Quiet Room")) {
      return true;
    }
    else
      return false;
  }

  completeRoomBooked(workspace) {
    if (workspace != "Workstation" && workspace != "Office" && workspace != "Reception" && workspace != "Quiet Room" && workspace != null) {
      return true;
    }
  }

  applyDeskTextEvent(desk_marks: any) {
    if (desk_marks == undefined) { return; }
    const clickHandler = (event) => {
      var clkSuccess = this.nodeClicked(event.target);
    };
    const clickHandlerShowData = (event) => {
      var clkSuccess = this.showReservationData(event.target)
    }
    if (this.newSVGFormat) {
      var i: number;

      for (i = 0; i < desk_marks.length; i++) {
        let desk_ell = desk_marks[i] as SVGTextElement;
        var desk_number = desk_ell.id.split('_')[1];
        // Applicable for BU wise desk filtering
        var desk_Visible = this.allDesksOnPlan.filter(value => value.deskNumber === desk_number);
        if (this.sncDesks != undefined)
          var sncdesk_Visible = this.sncDesks.filter(value => value.deskNumber === desk_number);
        if (desk_Visible.length > 0) {
          var desk_is_available: boolean = desk_Visible[desk_Visible.length - 1].isAvailable;
          if (desk_is_available == true) {
            desk_ell.style.setProperty("opacity", this.DESK_OPACITY)
            var desk_Res = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
            var meetingName = desk_Visible[0].meetingName;
            var workspace = desk_Visible[0].workSpaceType;
            if (desk_Res.length > 0) {
              // get last reservation detail
              var desk_status = desk_Res[desk_Res.length - 1].status;
              var meetingBooking = desk_Res.filter(value => value.meetingName == meetingName);
              // do not add click event if desk is pending/booked
              if (this.deskAvailableStatus.includes(desk_status) || (meetingBooking.length == 0 && localStorage.getItem("View") == "Meeting")) {
                desk_marks[i].addEventListener('click', clickHandler, false);
              }
              else {
                desk_marks[i].addEventListener('click', clickHandlerShowData, false);
              }
            }
            else if (this.completeRoomBooked(workspace) && this.desksOnPlan.filter(value => value.meetingName === meetingName).length > 0 && this.meetingActive) {
              var hasMeetingBooked = this.desksOnPlan.filter(value => value.meetingName === meetingName);
              var desk_status = hasMeetingBooked[hasMeetingBooked.length - 1].status;
              desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
              desk_marks[i].addEventListener('click', clickHandlerShowData, false);
            }
            else {
              desk_marks[i].addEventListener('click', clickHandler, false);
            }
          } else {
            desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          }
        }
        else if (this._buid == 201 && sncdesk_Visible.length > 0) {
          var desk_is_available: boolean = sncdesk_Visible[sncdesk_Visible.length - 1].isAvailable;
          if (desk_is_available == true) {
            desk_ell.style.setProperty("opacity", this.DESK_OPACITY)
            var desk_Res = this.desksOnPlan.filter(value => value.deskNumber === desk_number);

            if (desk_Res.length > 0) {
              // get last reservation detail
              var desk_status = desk_Res[desk_Res.length - 1].status;
              // do not add click event if desk is pending/booked
              if (this.deskAvailableStatus.includes(desk_status)) {
                desk_marks[i].addEventListener('click', clickHandler, false);
              }
              else {
                desk_marks[i].addEventListener('click', clickHandlerShowData, false);
              }
            } else {
              desk_marks[i].addEventListener('click', clickHandler, false);
            }
          } else {
            desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
          }
        }
        // if (this.meetingActive && (desk_Visible.length > 0)) {
        //   var workspace = desk_Visible[0].workSpaceType;
        //   if (this.MeetingCheck(workspace)) {
        //     debugger;
        //     desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
        //     desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
        //     continue;
        //   }
        // }
        // else {
        //   desk_ell.style.setProperty("opacity", this.INV_DESK_OPACITY)
        // }
      }
    }
  }

  showSelectedSeat(selectedDeskNum) {
    if (selectedDeskNum == undefined) return;
    let desk_marks;
    if (this.newSVGFormat) {
      if (this.checkIfLayersExist()) return;
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.getElementById("Bubbles").querySelectorAll('path') as NodeList);
    } else {
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.querySelectorAll('ellipse') as NodeList);
    }
    var i: number;
    for (i = 0; i < desk_marks.length; i++) {
      let desk_ell = desk_marks[i] as SVGPathElement;
      var desk_number = desk_ell.id.split('_')[1];
      if (desk_number == selectedDeskNum) {
        //Apply style to selected desk
        desk_ell.style.setProperty("fill", SELECTED_DESK_COLOR);
        return;
      }
    }
  }

  showReservationData(desk_selected) {
    let isVisitor = (localStorage.getItem('IsExternalUser') == "true");
    if (isVisitor == true) return;
    var selectedDeskNum = desk_selected.id.split('_')[1];
    //check to see if selected desk is already booked
    var selected_desk_Res = this.desksOnPlan.filter(value => value.deskNumber === selectedDeskNum && value.status == 1);
    var meeting_Res = this.allDesksOnPlan.filter(value => value.deskNumber === selectedDeskNum)
    if (selected_desk_Res.length > 0 && selected_desk_Res[0].reservationType != "Meeting") {
      var bkdDesk = { REV: selected_desk_Res[0].reservedFor, DNUM: selectedDeskNum, DeskMeetingName: selected_desk_Res[0].meetingName, REVBy: selected_desk_Res[0].createdBy }
      this.bookedDeskData.emit(bkdDesk);
    }
    else {
      var bookedMeeting = this.desksOnPlan.filter(value => value.meetingName == meeting_Res[0].meetingName)
      var bkdMeeting = { REV: '', DNUM: selectedDeskNum, DeskMeetingName: bookedMeeting[0].meetingName, REVBy: bookedMeeting[0].createdBy }
      this.bookedDeskData.emit(bkdMeeting);
    }
  }

  getDeskNumber(deskNum) {
    try {
      return deskNum.id.split('_')[1];
    } catch (e) {
      //let result = (e as Error).message;
      return "NA";
    }
  }

  nodeClicked(desk_selected) {
    let desk_marks;
    this.lastSelectedDeskNode = desk_selected;
    if (this.newSVGFormat) {
      if (this.checkIfLayersExist()) return;
      desk_marks = (desk_selected.ownerSVGElement.getElementById("Bubbles").querySelectorAll('path') as NodeList);
    } else {
      desk_marks = (desk_selected.ownerSVGElement.querySelectorAll('ellipse') as NodeList);
    }
    var selectedDeskNum = desk_selected.id.split('_')[1];
    var canEmit: boolean = false;

    //check to see if selected desk is in filtered desk
    var desk_In_Filtered = this.filtered_desks.filter(value => value.deskNumber === selectedDeskNum);
    if (this.filtered_sncdesks != undefined)
      var sncdesk_In_Filtered = this.filtered_sncdesks.filter(value => value.deskNumber === selectedDeskNum);
    if (desk_In_Filtered.length > 0 || (this._buid == 201 && sncdesk_In_Filtered.length > 0)) { canEmit = true };
    //check to see if clicked is Quiet Room or Not for Guyanabo
    if (desk_In_Filtered[0].workSpaceType == "Quiet Room" && localStorage.getItem("OfficeID") == "144") {
      canEmit = true;
      this.quietRoomCheck = true;
    }
    else {
      this.quietRoomCheck = false;
    }
    //check to see if selected desk is already booked

    var selected_desk_Res = this.desksOnPlan.filter(value => value.deskNumber === selectedDeskNum && value.status == 1);
    if ((selected_desk_Res.length > 0 && selected_desk_Res[0].reservationType != "Meeting") || (this.desksOnPlan.filter(value => value.meetingName == desk_In_Filtered[0].meetingName).length > 0 && desk_In_Filtered[0].meetingName != null && desk_In_Filtered[0].meetingName != "")) { canEmit = false };

    if (this.meetingActive && desk_In_Filtered.length > 0) {
      var workspace = desk_In_Filtered[0].workSpaceType;
      if (this.MeetingCheck(workspace)) { canEmit = false };
    }

    if (canEmit) {
      var i: number;
      for (i = 0; i < desk_marks.length; i++) {
        let desk_ell = desk_marks[i] as SVGPathElement;
        var desk_number = desk_ell.id.split('_')[1];
        var desk_Visible = this.allDesksOnPlan.filter(value => value.deskNumber === desk_number);
        if (this.sncDesks != undefined)
          var sncdesk_Visible = this.sncDesks.filter(value => value.deskNumber === desk_number);
        if (desk_Visible.length > 0) {
          var desk_is_available: boolean = desk_Visible[desk_Visible.length - 1].isAvailable;
          if (desk_is_available == true) {
            var desk_Res2 = this.filtered_desks.filter(value => value.deskNumber === desk_number);
            var meetingName = desk_Visible[0].meetingName;
            var workspace = desk_Visible[0].workSpaceType;
            if (desk_Res2.length > 0) {
              var desk_Res = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
              if (desk_Res.length > 0 && localStorage.getItem("View") != "Meeting") {
                // get last reservation detail
                var desk_fill_color = this.statusColorMap.get(desk_Res[desk_Res.length - 1].status)
                desk_ell.style.setProperty("fill", desk_fill_color);
              } else if (localStorage.getItem("View") == "Meeting" && this.completeRoomBooked(workspace) && this.desksOnPlan.filter(value => value.meetingName === meetingName).length > 0) {
                var hasMeetingBooked = this.desksOnPlan.filter(value => value.meetingName === meetingName);
                var desk_status = hasMeetingBooked[hasMeetingBooked.length - 1].status;
                desk_ell.style.setProperty("fill", this.statusColorMap.get(desk_status));
              } else {
                if (this._buid != 204)
                  desk_ell.style.setProperty("fill", AVAILABLE_DESK_COLOR);
                else
                  desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
              }
            }
            else {
              desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
            }
          } else {
            desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
            desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          }
        }
        else if (this._buid == 201 && sncdesk_Visible.length > 0) {
          var desk_is_available: boolean = sncdesk_Visible[sncdesk_Visible.length - 1].isAvailable;
          if (desk_is_available == true) {
            var desk_Res2 = this.filtered_sncdesks.filter(value => value.deskNumber === desk_number);

            if (desk_Res2.length > 0) {
              var desk_Res = this.desksOnPlan.filter(value => value.deskNumber === desk_number);
              if (desk_Res.length > 0) {
                // get last reservation detail
                if (desk_Res[desk_Res.length - 1].status == 2 || desk_Res[desk_Res.length - 1].status == 3 || desk_Res[desk_Res.length - 1].status == 4)
                  desk_Res[desk_Res.length - 1].status = 5;
                var desk_fill_color = this.statusColorMap.get(desk_Res[desk_Res.length - 1].status)
                desk_ell.style.setProperty("fill", desk_fill_color);
              } else {
                desk_ell.style.setProperty("fill", SNC_AVAILABLE_DESK_COLOR);
              }
            } else {
              desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
            }
          } else {
            desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
            desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
          }
        }
        if (this.meetingActive && (desk_Visible.length > 0)) {
          var workspace = desk_Visible[0].workSpaceType;
          if (this.MeetingCheck(workspace)) {
            desk_ell.style.setProperty("fill", NOT_AVAILABLE_DESK_COLOR);
            desk_ell.style.setProperty("opacity", this.NOT_DESK_OPACITY)
            continue;
          }
        }
      };
      //Apply style to selected desk
      let selBubble = desk_selected.ownerSVGElement.getElementById("DN_" + selectedDeskNum) as SVGPathElement;
      selBubble.style.setProperty("fill", SELECTED_DESK_COLOR);//desk_selected.
      var seldesk_Res = this.allDesksOnPlan.find(value => value.deskNumber === selectedDeskNum);
      if (this._buid == 201) {
        var sncseldesk_Res = this.sncDesks.find(value => value.deskNumber === selectedDeskNum);
      }
      // For Quiet Room
      if (this.quietRoomCheck) {
        this.QuietRoom.emit(true);
      }
      else {
        this.QuietRoom.emit(false);
      }
      if (this._buid == 201 && sncseldesk_Res != undefined) {
        var sncselDesk = { DID: sncseldesk_Res.deskID, DNUM: selectedDeskNum }
        this.selectedDeskNum.emit(sncselDesk);
      }
      else if (this.meetingActive && (localStorage.getItem("View") == "Meeting")) {
        this.allMeetingDesks = this.filtered_desks.filter(value => value.meetingName === seldesk_Res.meetingName && value.isAvailable == true);
        this.selectedDeskNum.emit(this.allMeetingDesks);
      }
      else {
        var selDesk = { DID: seldesk_Res.deskID, DNUM: selectedDeskNum }
        this.selectedDeskNum.emit(selDesk);
      }
    }
  }

  //Outlook Meeting Room Availability
  roomAvailability() {
    this.Available.emit("false");
    this.showStatus.emit(false);
    var checkRoom = ((this.isFullDay && this.meetingRoomCheck != "" && localStorage.getItem("View") == "Meeting") ||
      (!this.isFullDay && this.bookingEndTime != undefined && this.bookingStartTime != undefined && this.meetingRoomCheck != "" && localStorage.getItem("View") == "Meeting")) ? "true" : "false"
    if (checkRoom == "true") {
      this.showLoader = true;
      var timeZone = new Date().toString().split('GMT')[1].split(' ')[0];
      var token = localStorage.getItem('AccessToken');
      var meetingDetails = {
        MeetingName: this.meetingRoomCheck,
        StartTime: this.bookingStartTime,
        EndTime: this.bookingEndTime,
        TimeZone: timeZone,
        Code: token,
        BookedDate: localStorage.getItem('SelectedDateToBook'),
        IsFullday: this.isFullDay
      };
      this.ServiceService.checkRoomAvailability(meetingDetails).subscribe((data: any) => {
        if (data == 200) {
          this.Available.emit("false");
          this.showStatus.emit(true);
          this.showLoader = false;
        }
        else {
          this.Available.emit("true");
          this.showStatus.emit(true);
          this.showLoader = false;
        }

      })
    }
  }

}
