import { Component, OnInit, Injectable, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { BUDTO } from '../Model/BUDTO';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FloorDTO } from '../Model/FloorDTO';
import { id } from 'date-fns/locale';
import { Router } from '@angular/router';

export interface BU {
  id: number;
  // office_id: number;
  // sequence_id: number;
  name: string;

}

export interface GroupBU {
  id: number;
  OfficeId: number;
  OfficeName:string;
  BUId:number;
  BUName:string;
}
@Component({
  selector: 'app-add-BU',
  templateUrl: './add-BU.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class AddBUComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPaginator',{read: MatPaginator}) allPaginator: MatPaginator;
  dataSource: BU[];
  dataSource1 = new MatTableDataSource<BU>();
  displayedColumns: string[] = ['id', 'BUName','OfficeName','UpdateDesk'];
  dataSourceBU: GroupBU[];
  dataSourceBU1 = new MatTableDataSource<GroupBU>();
  displayedColumnsBU: string[] = ['id', 'BUName','OfficeName', 'UpdateDesk'];//, 'sequence_id', 'office_id'
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownListFloor=[];
  selectedItemsFloor=[];
  dropdownSettingsFloor = {};
  dropdownListBU=[];
  selectedItemsBU=[];
  dropdownSettingsBU = {};

  public buDTO: BUDTO;
  public floorDTO: FloorDTO;
  // ExistingFloors: FloorDTO;

  constructor(private Service: ServiceService, public translate: TranslateService
    ,private fb: FormBuilder,private router: Router) { }

    regexStr = '^[a-zA-Z0-9 -]+$';
  onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }
  
  OfficeList: string;
  FloorList:string;
  BUList:string;
  BUOfficeList:string;
  showMsgPopUp: boolean = false;
  showLoader = false;
  PopUpMessage: string;
  msgTitle: string;
  formDetails: FormData;
  givenText: string;
  givenTextGroupBU: string;
  FormTest: FormData;


  FloorForm = new FormGroup({
    Id: new FormControl(0),
    Name: new FormControl('', Validators.required),
    OfficeId: new FormControl('', Validators.required),
    FloorId:new FormControl('',Validators.required),
    BUId:new FormControl(''),
  });

  BUForm = new FormGroup({
    groupedBUId:new FormControl('',),
    BUId:new FormControl('', Validators.required),
    BUOfficeId:new FormControl('', Validators.required),
  });

  get Name() { return this.FloorForm.get('Name'); }
  get OfficeId() { return this.FloorForm.get('OfficeId') }
  get FloorId() { return this.FloorForm.get('FloorId') }
  get BUId() { return this.FloorForm.get('BUId') }
  get BUOfficeId() { return this.BUForm.get('BUOfficeId') }

  ngOnInit() {
    this.showLoader = true;
    this.formDetails = new FormData();
    this.Service.GetAllBUOffices().subscribe((result: any) => {
      this.dropdownList = result;
    });
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name'
    };   
    this.Service.getAllBUs().subscribe((res: any) => {
      this.dataSource = res as BU[];
      this.dataSource1.data = res;
      this.showLoader = false;
      this.dropdownListBU=res;
    });
    this.dropdownSettingsBU = {
      idField: 'id',
      textField: 'name'
    };
    // this.Service.getAllOffices().subscribe((res: any) => {
    //   this.BUOfficeList=res;
    // });
    this.Service.getAllOfficesForGroupedBU().subscribe((res: any) => {
      this.BUOfficeList=res;
    });    
    this.Service.GetAllGroupBUs().subscribe((res: any) => {
      this.dataSourceBU = res as GroupBU[];
      this.dataSourceBU1.data = res;
      this.showLoader = false;
    });
  }
  ngAfterViewInit() {
     this.dataSource1.paginator = this.paginator;
     this.dataSourceBU1.paginator = this.allPaginator;
    }

  onItemSelect(item: any) {
    if (this.OfficeList != null) {
      this.OfficeList = this.OfficeList + ',' + item.id.toString();
    }
    else {
      this.OfficeList = item.id.toString();
    }
    this.Service.getAllFloorsForBU(this.OfficeList).subscribe((result: any) => {
      this.dropdownListFloor = result;
      this.dropdownListFloor = result.filter(x => x.langKey == localStorage.getItem('locale'));
    });
    this.dropdownSettingsFloor = {
      idField: 'id',
      textField: 'name'
    };
  }
  onItemDeSelect(item: any) {
    this.OfficeList = this.OfficeList.toString().replace(item.id.toString(), "0");
    this.Service.getAllFloorsForBU(this.OfficeList).subscribe((result: any) => {
    this.dropdownListFloor = result.filter(x => x.langKey == localStorage.getItem('locale'));
    });
    this.dropdownSettingsFloor = {
      idField: 'id',
      textField: 'name'
    };
  }
  onItemSelectFloor(item: any) {
    if (this.FloorList != null) {
      this.FloorList = this.FloorList + ',' + item.id.toString();
    }
    else {
      this.FloorList = item.id.toString();
    }
  }
  onItemDeSelectFloor(item: any) {
    this.FloorList = this.FloorList.toString().replace(item.id.toString(), "0");
  }

  onItemSelectBU(item: any) {
    if (this.BUList != null) {
      this.BUList = this.BUList + ',' + item.id.toString();
    }
    else {
      this.BUList = item.id.toString();
    }
  }
  onItemDeSelectBU(item: any) {
    this.BUList = this.BUList.toString().replace(item.id.toString(), "0");
  }

  onItemSelectBUOffice(item: any) {
    if (this.BUOfficeList != null) {
      this.BUOfficeList = this.BUOfficeList + ',' + item.id.toString();
    }
    else {
      this.BUOfficeList = item.id.toString();
    }
  }
  onItemDeSelectBUOffice(item: any) {
    this.BUOfficeList = this.BUOfficeList.toString().replace(item.id.toString(), "0");
  }

  submitBU() {
    if (this.BUForm.status === 'VALID' && this.BUForm.value.Name != '') {
    //this.getFloorplanDetails();
    this.buDTO = this.BUForm.value;
    this.buDTO.BUId = this.BUList;
    const formData = new FormData();
    formData.append("groupedBUId",this.buDTO.groupedBUId.toString());
    formData.append("BUId", this.buDTO.BUId);
    formData.append("BUOfficeId", this.buDTO.BUOfficeId);
    this.formDetails = formData;
    // if (this.FloorForm.status === 'VALID' && this.FloorForm.value.Name != '') {
    //   this.showLoader = true;

    // if (!this.showFloorPlanUpload)
    //   this.getFloorplanDetails(undefined);

    this.Service.UploadGroupBUPLan(this.formDetails).subscribe((result: any) => {
      this.showMsgPopUp = true;
      if (result == 400) {
        // this.translate.get('UploadFile').subscribe((data: string) => { this.msgTitle = data; });
        // this.translate.get('FileUploadFail').subscribe((data: string) => { this.PopUpMessage = data; });
        this.translate.get('GroupBUNotSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('GroupBUNotSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      else if(result==100){
        this.translate.get('GroupedBU').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('GroupedBUExistsError').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      else {
        this.translate.get('BUSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('BUSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      this.showMsgPopUp = true;
      this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
    });
  }
  
else {
  alert('Please fill all the mandatory fields.')
}
  }

  submit() {
    
    if (this.FloorForm.status === 'VALID' && this.FloorForm.value.Name != '') {
    //this.getFloorplanDetails();
    this.buDTO = this.FloorForm.value;
    this.buDTO.OfficeId = this.OfficeList;
    this.buDTO.FloorId=this.FloorList;
    const formData = new FormData();
    formData.append('Id', this.buDTO.Id.toString());
    formData.append('Name', this.buDTO.Name);
    formData.append("OfficeId", this.buDTO.OfficeId);
    formData.append("FloorId", this.buDTO.FloorId);
    this.formDetails = formData;
    // if (this.FloorForm.status === 'VALID' && this.FloorForm.value.Name != '') {
    //   this.showLoader = true;

    // if (!this.showFloorPlanUpload)
    //   this.getFloorplanDetails(undefined);

    this.Service.uploadBUPlan(this.formDetails).subscribe((result: any) => {
      this.showMsgPopUp = true;
      if (result == 400) {
        // this.translate.get('UploadFile').subscribe((data: string) => { this.msgTitle = data; });
        // this.translate.get('FileUploadFail').subscribe((data: string) => { this.PopUpMessage = data; });
        this.translate.get('BUNotSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('BUNotSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      else if(result==100){
        this.translate.get('BUName').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('NameExistsError').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      else if(result==401){
        this.translate.get('Floor').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('FloorRemove').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      else {
        this.translate.get('BUSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('BUSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
      }
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      this.showMsgPopUp = true;
      this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
    });
  }

else {
  alert('Please fill all the mandatory fields.')
}
  }
 
  GetRecordToUpdate(e) {    
    this.FloorForm.get('Id').setValue(e.id);
    this.FloorForm.get('Name').setValue(e.name);
    this.Service.GetEditBUOffices(e.id).subscribe((result: any) => {
      //this.OfficeList = result;
      this.selectedItems = result;     
      this.OfficeList = result.map(x => x.id).join(",");
      this.Service.getAllFloorsForBU(this.OfficeList).subscribe((result: any) => {
        this.dropdownListFloor = result.filter(x => x.langKey == localStorage.getItem('locale'));
      });
      this.dropdownSettingsFloor = {
        idField: 'id',
        textField: 'name'
      };
      this.Service.GetEditBUFloors(e.id).subscribe((result: any) => {
        this.selectedItemsFloor = result;     
        this.FloorList = result.map(x => x.id).join(",");
      });
    });
    
    window.scroll(0, 0);
  }

GetGroupRecordToUpdate(e) {
    this.BUForm.get('groupedBUId').setValue(e.id);
    //this.BUForm.get('Name').setValue(e.name);
    this.BUForm.get('BUOfficeId').setValue(e.officeID);
    this.dropdownListBU=this.dropdownListBU.filter(x => x.officeID == e.officeID);
    this.Service.GetAllEditBUGroup(e.id).subscribe((result: any) => {
      //this.OfficeList = result;
      this.selectedItemsBU = result;     
      this.BUList = result.map(x => x.id).join(",");     
    });
    
    window.scroll(0, 0);
  }
  search() {
    this.showLoader = true;
    if (this.givenText.length > 0) {
      this.Service.getBUsearchdata(this.givenText).subscribe((res: any) => {
        this.dataSource = res as BU[];
        this.dataSource1.data = res;
        this.showLoader = false;
      });
    }
    else {
      this.Service.getAllBUs().subscribe((res: any) => {
        this.dataSource = res as BU[];
        this.dataSource1.data = res;
        this.showLoader = false;
      });
    }
  }
  searchGroup() {
    this.showLoader = true;
    if (this.givenTextGroupBU.length > 0) {
      this.Service.getGroupedBUsearchdata(this.givenTextGroupBU).subscribe((res: any) => {
        this.dataSourceBU = res as GroupBU[];
        this.dataSourceBU1.data = res;
        this.showLoader = false;
      });
    }
    else {
      this.Service.GetAllGroupBUs().subscribe((res: any) => {
        this.dataSourceBU = res as GroupBU[];
        this.dataSourceBU1.data = res;
        this.showLoader = false;
      });
    }
  }

  LoadAddBUBasedonLang() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  changeOffice(e) {
    var _offID = e.target.value;

    this.Service.getAllBUs().subscribe((res: any) => {
      this.dataSource = res as BU[];
      this.dataSource1.data = res;
      this.showLoader = false;
      this.dropdownListBU=res.filter(x => x.officeID == _offID);
    });

  }
}
