import { Component, OnInit } from '@angular/core';
import { SupportForm } from '../Model/SupportForm';
import { ServiceService } from '../services/service.service'
import { Title } from '@angular/platform-browser';
import { userDataService } from '../services/data-share';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core'
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class SupportFormComponent implements OnInit {
  showMsgPopUp: boolean = false;
  popMsgTitle: string;
  popMsgDetail: string;
  supportFormModel = new SupportForm(localStorage.getItem('CurrentUser'), localStorage.getItem('CurrentUserEmail'), '','','','','');
  submitted = false;
  fileList: FileList;
  showLoader = false;
  checkformat:boolean=false;

  constructor(private titleService: Title,
    private dataService: userDataService,
    private ServiceService: ServiceService, public translate: TranslateService) { }

  ngOnInit() {
    this.titleService.setTitle("Save My Seat - Feedback");
    this.fileList = null;
  }

  IsInArray(value, array) {return array.indexOf(value) > -1;}

  onSubmit() {
    var selectedLang = this.translate.store.currentLang;
    this.submitted = true;
    this.supportFormModel.UserLanguage = selectedLang;
    const formData = new FormData();
    this.showLoader = true;
    if(this.fileList != null){
      if(this.fileList.length > 3){
        this.showPopupMsg('File Upload Warning!!', 'Number of file uploads cannot exceed 3.');
        this.showLoader = false;
        return;
      }
      var totFilesSize = 0;
      Array.prototype.forEach.call(this.fileList,(file) => {
        if(!this.IsInArray(file.type,["image/png","image/jpeg","application/pdf"])){
          this.checkformat=true;
          this.showPopupMsg('File Upload Warning!!', 'The file must be of either pdf, png or jpeg.');
          this.showLoader = false;
          return;
        }
        totFilesSize = totFilesSize + file.size;
        formData.append('files[]', file);
      });
      if(this.checkformat)
      {
        return;
      }
      if(totFilesSize > 20000000){
        this.showPopupMsg('File Upload Warning!!', 'Total size of the files cannot exceed 20 MB.');
        this.showLoader = false;
        return;
      }
      //formData.append('files', this.fileList[0]);
    }

    formData.append('UserName', this.supportFormModel.UserName);
    formData.append('SenderEmail', this.supportFormModel.SenderEmail);
    formData.append('Title', this.supportFormModel.Title);
    formData.append('Comments', this.supportFormModel.Comments);
    formData.append('Category', this.supportFormModel.Category);
    formData.append('Functionality', this.supportFormModel.Functionality);
    formData.append('UserLanguage', this.supportFormModel.UserLanguage);

    this.ServiceService.postFeedback(formData).subscribe((data: any) => {
      this.showLoader = false;
      if(data['response'].toLowerCase() == "success"){
        this.translate.get('SMSSupportTitle').subscribe((data: string) => {this.popMsgTitle = data;});
        this.translate.get('SMSSupportResponse').subscribe((data: string) => {this.popMsgDetail = data;});
        this.showPopupMsg(this.popMsgTitle, this.popMsgDetail);
      }
      else if(data['response'] == "data_not_saved"){
        this.showPopupMsg('Error: Feedback could not be saved', 'Oops!!\nFeedback could not be saved. Please try again.');
      }
      else if(data['response'] == "cat_fun_db_not_accessed" || data['response'] == "mail_not_sent"){
        this.showPopupMsg('Error: Mail not sent', 'Oops!!\nFeedback is saved successfully. However, mail is not sent due to some technical problem.');
      }
      else if(data['response'] == "failed"){
        this.showPopupMsg('Error: Feedback could not be saved', 'Oops!!\nSomething went went wrong due to technical problem. Please try again.');
      }
      else{
        this.showPopupMsg('Error: Feedback could not be saved', 'Oops!!\nSomething went went wrong due to technical problem. Please try again.');
      }
    });
  }

  SetFileName(files){
    if (files[0]) {
      this.fileList = files;
    }
  }

  showPopupMsg(msgTitle: string, msgDetail: string) {
    this.showMsgPopUp = true;
    this.popMsgTitle = msgTitle;
    this.popMsgDetail = msgDetail;
  }
}
