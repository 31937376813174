import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ServiceService } from '../services/service.service'
import { userDataService } from '../services/data-share';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-msg-parking',
  templateUrl: './alert-msg-parking.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class AlertMsgParkingComponent implements OnInit {
  @Input() redirectTo: string;
  @Input() msgTitle: string;
  @Input() msgBody: string;
  @Output() showPopUp = new EventEmitter();
  @Input() showCancelBtn: boolean = false;
  @Input() cancelRedirect: string;
  @Input() cancelBtnText: string = 'Cancel';
  @Input() okBtnText: string = 'OK';
  constructor(private dataService: userDataService,
    private ServiceService: ServiceService, private router: Router) { }

  ngOnInit() {
  }
  formSubmit() {
    if (this.redirectTo != undefined) {
      this.router.navigateByUrl(this.redirectTo);
      localStorage.setItem("ShowHealthForm","true");
    } else {
      this.showPopUp.emit(false);
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });

    }
  }
}