import { Component, OnInit, Output, EventEmitter, NgZone, AfterViewInit, ViewChild, Input } from '@angular/core';
// import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { ServiceService } from '../services/service.service';
import { DesksDTO } from '../Model/Desks';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpEventType, HttpRequest } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import * as excel from 'exceljs'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Console } from 'console';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-desk-configuration',
  templateUrl: './desk-configuration.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class DeskConfigurationComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  dataSource: DesksDTO[];
  // displayedColumns: string[] = ['deskNumber', 'isAvailable', 'catSitstand', 'catDualMonitor', 'catQuietDesk'];//, 'UpdateDesk'
  displayedColumns: string[] = ['deskNumber', 'isAvailable', 'DeskType', 'workSpaceType', 'meetingName'];//, 'UpdateDesk'
  excelHeaders: string[];
  templateToExcel: string[][];
  public progress: number;
  public message: string;
  showLoader = false;
  givenText: string;
  type: string;
  mappingId: number;
  DeskDTO: DesksDTO;
  ListtoSave: Array<DesksDTO> = [];
  versionTitle: string;
  ConfirmMsg: string;
  DeskUpdated: string;
  pleaseupdateatleastonedesk: string;
  showUploadMsgPopUp: boolean = false;
  msgUploadTitle: string;
  PopUpUploadMessage: string;
  showErrorMsgPopUp: boolean = false;
  PopUpMessage: string;
  msgTitle: string;
  buId: number;
  AssignedorTouchdown: boolean = false;
  fileUrl;
  public ReadmeForExcel: Array<Object> = [
    {
      'office_name': 'ABC',
      'desk_number': 'Desk_no1',
      'floor_name': 'ABC',
      'zone_name': 'ABC',
      'business_unit': 'ABC',
      // 'amenities': '',
      'is_available': 'TRUE',
      'istouchdown_assigned': 'TRUE',
      'reservable': 'Assigned',
      'cat_desk': 'TRUE',
      // 'cat_r_ace': 'FALSE',
      // 'cat_sncl': 'FALSE',
      'work_space_type': 'workstation',
      'meeting_name': 'enter outlook example room name here',
      'Quiet Desk': 'FALSE',
      'Dual Monitor': 'FALSE',
      'Sit / Stand': 'FALSE'
    },
    {
      'desk_number': ''
    },
    {
      'desk_number': 'INSTRUCTION'
    },
    {
      'desk_number': ''
    },
    {
      'desk_number': 'Instructions to fill the \'DeskData\' Sheet '
    },
    {
      'desk_number': '\'office_name\' will auto-populate in this column ,the same should be applied/populated to all the desk numbers '
    },
    {
      'desk_number': '\'desk_number\' Enter all the desk numbers belonging to that floor,zone or BU in this column as per the data sheet '
    },
    {
      'desk_number': '\'floor_name\' will auto-populate in this column ,the same should be applied/populated to all the desk numbers.Note that this column should have only the autopopulated name. '
    },
    {
      'desk_number': '\'zone_name\' will auto-populate in this column ,the same should be applied/populated to all the desk numbers. Zone Name will be hidden if it is Floor or BU concept. '
    },
    // {
    //     'desk_number': 'Column D:-\'amenities\' Leave it blank '
    // },
    {
      'desk_number': '\'business_unit\' will auto-populate in this column ,the same should be applied/populated to all the desk numbers. This should be hidden when using Zone or Floor concepts.'
    },
    {
      'desk_number': '\'is_available\' Select value from dropdown for all the desks'
    },
    {
      'desk_number': '\'istouchdown_assigned\' For New Office Configurations: Select either TRUE for all rows or FALSE for all the rows.' +
        'For Old Office Configurations: Select True if previously configured desks were configured with istouchdown_assigned as true or vice-versa.'
    },
    {
      'desk_number': '\'reservable\'  Select "Assigned" or "Touchdown" as present in the data sheet for the given desk numbers'
    },
    {
      'desk_number': '\'cat_desk\' Select TRUE for all the desk numbers if you would like to search by different desk categories (dual monitor, sit to stand, etc.) '
    },
    // {
    //     'desk_number': 'Column J :- \'cat_r_ace\' Enter FALSE for all the desk numbers'
    // },
    // {
    //     'desk_number': 'Column K :- \'cat_sncl\' Enter FALSE for all the desk numbers'
    // },
    {
      'desk_number': '\'work_space_type\' Enter the work_space_type for the desk numbers as per data sheet(example work space types: workstation, desk, office, meeting room, collaboration area)'
    },
    {
      'desk_number': '\'meeting_name\' Enter the Meeting Room Name as described in Outlook for the desk as per data sheet. This needs to match Outlook naming in order to link to Outlook bookings. '
    },
    {
      'desk_number': '\'Quiet Desk\' Select TRUE/FALSE for all the desk numbers as per data sheet. In order for this column to populate on the data sheet, you must first add the desk type in the desk configuration admin tool. '
    },
    {
      'desk_number': '\'Dual Monitor\' Select TRUE/FALSE for all the desk numbers as per data sheet. In order for this column to populate on the data sheet, you must first add the desk type in the desk configuration admin tool. '
    },
    {
      'desk_number': '\'Sit / Stand\' Select TRUE/FALSE for all the desk numbers as per data sheet. In order for this column to populate on the data sheet, you must first add the desk type in the desk configuration admin tool. '
    },
    {
      'desk_number': '\r\n\r\n\r\n\r\n\r\n'
    }
  ];
  DeskTypeList: string;
  // deskConfigformDetails: FormData;
  selectedDeskTypes: [];
  dropdownListDeskTypeList = [];
  dropdownDeskTypeSettings = {};
  dropdownListBUList = [];
  dropdownBUSettings = {};
  showDownloadUpload = true;
  hasbu: boolean;
  //Raman Admin Desk Unavailable Changes
  showDisableDesk = false;
  showDates = false;
  minDate: Date;
  maxDate: string;
  today: Date;
  minToDate: Date;
  checkboxSelected: any;
  //END

  dataSourceNew = new MatTableDataSource<DesksDTO>();

  // DeskConfig_DeskTypeForm = new FormGroup({
  //   DeskTypeId: new FormControl('')
  // });


  // get DeskTypeId() { return this.DeskConfig_DeskTypeForm.get('DeskTypeId') }

  @Output() public onUploadFinished = new EventEmitter();
  constructor(private sanitizer: DomSanitizer, private service: ServiceService, public translate: TranslateService, private router: Router, private ngzone: NgZone, public datepipe: DatePipe) {
    let _langs = [];
    service.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    this.versionTitle = service.LastAppRelease;
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(service.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {
    localStorage.setItem('OfficeID', "0");
    this.showLoader = true;
    // this.service.getAllDesks().subscribe((res: any) => {
    //   this.dataSource = res as DesksDTO[];
    //   this.showLoader = false;
    // });
    //this.deskConfigformDetails = new FormData();
    this.FilterDesks();
    //Raman Admin Desk Unavailable Changes
    this.minDate = new Date;
    this.maxDate = this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');
    this.today = new Date;
    this.minToDate = this.minDate;
    //END
  }

  ngAfterViewInit() {
    this.dataSourceNew.paginator = this.paginator;
    this.dataSourceNew.sort = this.sort;
    this.dataSourceNew.filterPredicate = function (data: any, filterValue: string) {
      return data.deskNumber.trim().toLocaleLowerCase().indexOf(filterValue.trim().toLocaleLowerCase()) >= 0;
    };
  }

  getRecord() {
    if (this.ListtoSave.length > 0) {
      this.translate.get('Areyousuretoupdatethedesks').subscribe((data: string) => { this.ConfirmMsg = data; })
      if (confirm(this.ConfirmMsg)) {
        this.showLoader = true;
        this.service.UpdateDeskConfiguration(this.ListtoSave).subscribe((result: any) => {
          this.showLoader = false;
          this.showUploadMsgPopUp = true;
          if (result == 401) {
            this.translate.get('CkeckedInTitle').subscribe((data: string) => { this.msgUploadTitle = data; })
            this.translate.get('CkeckedInDeskBody').subscribe((data: string) => { this.PopUpUploadMessage = data; })
          }
          else if (result == 400) {
            this.translate.get('UploadFile').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('FileUploadFail').subscribe((data: string) => { this.PopUpMessage = data; });
          }
          else {
            this.translate.get('DeskUpdated').subscribe((data: string) => { this.msgUploadTitle = data; })
            this.translate.get('DeskUpdatedMsg').subscribe((data: string) => { this.PopUpUploadMessage = data; })
          }
        }, error => {
          if (error.status == 400) {
            this.router.navigate(["/globalerror"]);
            return
          }
        }
        );
      }
    }
    else {
      this.showUploadMsgPopUp = true;
      this.translate.get('pleaseupdateatleastonedesk').subscribe((data: string) => { this.PopUpUploadMessage = data; })
    }
  }
  AddQuitDeskinList(isChecked, desk) {
    this.showLoader = true;
    this.DeskDTO = desk;
    this.DeskDTO.CatQuietDesk = isChecked.target.checked;
    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    let elementId = 'checkboxQuietDesk' + desk.id;
    document.getElementById(elementId).classList.add('ChangeSelectedCheckBoxColor');
    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;
  }
  AddDualMonitorinList(isChecked, desk) {
    this.showLoader = true;
    this.DeskDTO = desk;
    this.DeskDTO.CatDualMonitor = isChecked.target.checked;
    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    let elementId = 'checkboxDualMonitor' + desk.id;
    document.getElementById(elementId).classList.add('ChangeSelectedCheckBoxColor');
    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;
  }
  AddDeskInUseinList(isChecked, desk) {
    //Raman Admin Desk Unavailable Changes
    this.checkboxSelected = isChecked;
    if (!isChecked.target.checked) {
      this.showDates = false;
      this.today = new Date;
      this.minToDate = this.minDate;
      this.showDisableDesk = true;
    }
    else {
      this.showDisableDesk = false;
    }
    //END
    this.showLoader = true;
    this.DeskDTO = desk;
    this.DeskDTO.IsAvailable = isChecked.target.checked;
    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    let elementId = 'checkboxInUse' + desk.id;
    document.getElementById(elementId).classList.add('ChangeSelectedCheckBoxColor');
    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;
  }
  AddSitStandinList(isChecked, desk) {
    this.showLoader = true;
    this.DeskDTO = desk;
    this.DeskDTO.CatSitstand = isChecked.target.checked;
    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    let elementId = 'checkboxSitstand' + desk.id;
    document.getElementById(elementId).classList.add('ChangeSelectedCheckBoxColor');
    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;
  }
  changeReservableList(event, desk) {
    this.showLoader = true;
    this.DeskDTO = desk;
    this.DeskDTO.Reservable = event.target.value;
    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    let elementId = 'dropDownReservable' + desk.id;
    document.getElementById(elementId).classList.add('ChangeSelectedDropDownColor');
    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;
  }

  changeBUList(event, desk) {
    this.showLoader = true;
    this.DeskDTO = desk;
    desk.businessUnit = parseInt(event.target.value);
    var Buinfo = this.dropdownListBUList.filter(x => x.id == desk.businessUnit);
    this.DeskDTO.BU = Buinfo[0].officeName;
    //this.DeskDTO.BusinessUnit = event.target.value;
    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    let elementId = 'dropDownDeskBU' + desk.id;
    document.getElementById(elementId).classList.add('ChangeSelectedDropDownColor');
    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;
  }
  FilterDesks() {
    this.showLoader = true;

    (document.getElementById("txt_filter") as HTMLInputElement).value = '';
    this.dataSourceNew = new MatTableDataSource<DesksDTO>();
    this.dataSourceNew.filterPredicate = function (data: any, filterValue: string) {
      return data.deskNumber.trim().toLocaleLowerCase().indexOf(filterValue.trim().toLocaleLowerCase()) >= 0;
    };
    this.ListtoSave = [];
    this.checkhasOfficeBU();
    if (localStorage.getItem('FloorID') != undefined && !this.showDownloadUpload && localStorage.getItem('FloorID') != 'null' && localStorage.getItem('FloorID') != 'undefined') {
      var _officeid = parseInt(localStorage.getItem('OfficeID'));
      var floorid = parseInt(localStorage.getItem('FloorID'));
      this.service.getAllDesksFromOfficeId(_officeid, floorid, localStorage.getItem('locale')).subscribe((result: any) => {
        if (result.length > 0) {
          this.dataSource = result as DesksDTO[];
          this.checkhasOfficeBU();
          this.AssignedorTouchdown = (result[0].reservable != null) ? true : false;
          this.setDisplayColumns(this.AssignedorTouchdown);
          this.bindDeskTypeDropDown();
          this.bindDeskBUDropDown();
          this.dataSourceNew.data = this.dataSource;
          this.dataSourceNew.sort = this.sort;
          this.dataSourceNew.paginator = this.paginator;
        }
        else {
          this.dataSource = [];
          this.dataSourceNew.data = this.dataSource;
          this.dataSourceNew.sort = this.sort;
          this.dataSourceNew.paginator = this.paginator;
        }
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        if (err.status == 400) {
          this.router.navigate(["/globalerror"]);
          return
        }
      });

    }
    else if (localStorage.getItem('GroupedBU') == "true") {
      this.service.getGroupedBUID(parseInt(localStorage.getItem('OfficeID')), parseInt(localStorage.getItem('BUId'))).subscribe((data: any) => {
        var groupedbuid = data;
        this.service.getAllDesksFromGroupedBUId(parseInt(localStorage.getItem('FloorID')), parseInt(localStorage.getItem('BUId')), localStorage.getItem('locale'))
          .subscribe((data: any) => {
            if (data.length > 0) {
              this.dataSource = data as DesksDTO[];
              this.AssignedorTouchdown = (data[0].reservable != null) ? true : false;
              this.setDisplayColumns(this.AssignedorTouchdown);
              this.bindDeskTypeDropDown();
              this.dataSourceNew.data = this.dataSource;
              this.dataSourceNew.sort = this.sort;
              this.dataSourceNew.paginator = this.paginator;
            }
            else {
              this.dataSource = [];
              this.dataSourceNew.data = this.dataSource;
              this.dataSourceNew.sort = this.sort;
              this.dataSourceNew.paginator = this.paginator;
            }

            //------ 30Nov2022 search

            this.showLoader = false;
          }, err => {
            this.showLoader = false;
            // if (err.status == 400) {
            //   this.router.navigate(["/globalerror"]);
            //   return
            // }
          });
      }, err => {
        this.showLoader = false;
        // if (err.status == 400) {
        //   this.router.navigate(["/globalerror"]);
        //   return
        // }
      });
    }
    else if (localStorage.getItem('ZoneId') != undefined && localStorage.getItem('ZoneId') != 'null' && localStorage.getItem('ZoneId') != 'undefined') {
      var _zoneid = parseInt(localStorage.getItem('ZoneId'));
      this.service.getAllDesksFromZoneId(_zoneid, localStorage.getItem('locale')).subscribe((result: any) => {
        if (result.length > 0) {
          this.dataSource = result as DesksDTO[];
          this.AssignedorTouchdown = (result[0].reservable != null) ? true : false;
          this.setDisplayColumns(this.AssignedorTouchdown);
          this.bindDeskTypeDropDown();
          this.dataSourceNew.data = this.dataSource;
          this.dataSourceNew.sort = this.sort;
          this.dataSourceNew.paginator = this.paginator;
        }
        else {
          this.dataSource = [];
          this.dataSourceNew.data = this.dataSource;
          this.dataSourceNew.sort = this.sort;
          this.dataSourceNew.paginator = this.paginator;
        }
        //------ 30Nov2022 search

        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        if (err.status == 400) {
          this.router.navigate(["/globalerror"]);
          return
        }
      });
    }
    else if (localStorage.getItem('FloorID') != undefined && localStorage.getItem('FloorID') != 'null' && localStorage.getItem('FloorID') != 'undefined') {
      var _floorid = parseInt(localStorage.getItem('FloorID'));
      this.service.getAllDesksFromFloorId(_floorid, localStorage.getItem('locale')).subscribe((result: any) => {
        if (result.length > 0) {
          this.dataSource = result as DesksDTO[];
          this.AssignedorTouchdown = (result[0].reservable != null) ? true : false;
          this.setDisplayColumns(this.AssignedorTouchdown);
          this.bindDeskTypeDropDown();
          this.dataSourceNew.data = this.dataSource;
          this.dataSourceNew.sort = this.sort;
          this.dataSourceNew.paginator = this.paginator;
        }
        else {
          this.dataSource = [];
          this.dataSourceNew.data = this.dataSource;
          this.dataSourceNew.sort = this.sort;
          this.dataSourceNew.paginator = this.paginator;
        }
        //------ 30Nov2022 search

        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        if (err.status == 400) {
          this.router.navigate(["/globalerror"]);
          return
        }
      });

    }
    else {
      this.showLoader = false;
    }
  }


  downloadExcelTemplate() {
    this.showLoader = true;
    // this.service.getCreateExcel(parseInt(localStorage.getItem('OfficeID')))
    //       .subscribe((data: any) => {
    //         this.downloadExcelFile1(data);
    //         this.showLoader = false;
    //       },err => {
    //         this.showLoader = false;
    //         if (err.status == 400) {
    //           this.router.navigate(["/globalerror"]);
    //           return
    //         }
    //       });





    var bu = isNaN(parseInt(localStorage.getItem('BUId'))) ? parseInt('0') : parseInt(localStorage.getItem('BUId'));
    var floor = isNaN(parseInt(localStorage.getItem('FloorID'))) ? parseInt('0') : parseInt(localStorage.getItem('FloorID'));
    var zone = isNaN(parseInt(localStorage.getItem('ZoneId'))) ? parseInt('0') : parseInt(localStorage.getItem('ZoneId'));
    // alert(bu);
    // alert(floor);alert(zone);
    this.showLoader = true;
    this.service.getCreateExcel(parseInt(localStorage.getItem('OfficeID')), bu, floor, zone)
      .subscribe((data: any) => {
        this.downloadExcelFile1(data);
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        // if (err.status == 400) {
        //   this.router.navigate(["/globalerror"]);
        //   return
        // }
      });
  }

  downloadExcelFile1(data: any) {
    // this.excelHeaders=data;
    // this.templateToExcel= [this.excelHeaders,[]];
    // var ws: XLSX.WorkSheet=XLSX.utils.aoa_to_sheet(this.templateToExcel);

    this.showLoader = true;
    var date = new Date();
    var wb = new excel.Workbook();
    var ws = wb.addWorksheet('DeskData');
    var ws1 = wb.addWorksheet('Readme');
    // Add headers for ReadMe
    const readMeheaders = Object.keys(this.ReadmeForExcel[0]);
    ws1.addRow(readMeheaders);

    // Add data for ReadMe
    this.ReadmeForExcel.forEach(obj => {
      const readMeData = Object.values(obj);
      ws1.addRow(readMeData);
    });

    // Add headers for Data
    const headers = Object.keys(data[0]);
    ws.addRow(headers);

    // Add data for Data
    data.forEach(obj => {
      const data = Object.values(obj);
      ws.addRow(data);
    });
    ws.columns.forEach(column => {
      column.width = 20; // Adjust width as needed
    });
    // Get the header row
    const headerRow = ws.getRow(1);
    headerRow.height = 30; // Adjust height as needed
    // Add comments to header cells
    headerRow.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.style = {
        font: {
          name: 'Calibri',
          bold: true,
          size: 11,
          color: { argb: 'FFFFFFFF' }, // White color
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF305496' } // Blue color
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center'
        }
      }
      if (cell.value == "office_name" || cell.value == "floor_name" || cell.value == "zone_name" || cell.value == "business_unit") {
        cell.note = {
          texts: [
            { text: 'This will auto-populate, no need to enter in other rows.' }
          ]
        };
      }
      else if (cell.value == "desk_number") {
        cell.note = {
          texts: [
            { text: 'Enter all the desk numbers belonging to that floor,zone or BU. Make sure desk numbers are written exactly as they are on the plans.' }
          ]
        };
      }
      else if (cell.value == "reservable") {
        cell.note = {
          texts: [
            { text: 'Select Assigned if the desk can only be used by one individual.'+ 
            'Select Touchdown if this is a shared desk.'+ 
            'Select value from dropdown for all the desks.' }
          ]
        };
      }
      else if (cell.value == "cat_desk") {
        cell.note = {
          texts: [
            { text: 'This will be True if you would like to allow user to filter desks by different attributes like sit to stand, dual monitor, or docking station type.'+ 
            'Select value from dropdown for all the desks.' }
          ]
        };
      }
      else if (cell.value == "istouchdown_assigned") {
        cell.note = {
          texts: [
            { text: 'Select TRUE(if previously configured) else FALSE in all.' }
          ],
        };
      }
      else if (cell.value == "is_available")
      {
        cell.note = {
          texts: [
            { text: 'This should be True almost always. False if you do not want the desk to be reservable but you do want it set up to potentially make it reservable later.'+ 
            'Select value from dropdown for all the desks' }
          ]
        };
      }
      else if (cell.value == "work_space_type") {
        cell.note = {
          texts: [
            { text: 'Enter workspacetype as workstation/meeting room etc.' }
          ]
        };
      }
      else if (cell.value == "meeting_name") {
        cell.note = {
          texts: [
            { text: 'Enter the Meeting Room Name as described in Outlook. This is a specific naming convention as displayed in Outlook when you book the meeting room.' }
          ]
        };
      }
    });
    // Define the range of cells
    const cellRanges = ['is_available', 'istouchdown_assigned', 'cat_desk'];
    const numRows = 1000;
    var values = ws.findRow(1).values;
    // Define the data validation settings
    // Apply data validation settings to each cell in the specified range
    cellRanges.forEach(column => {
      for (let row = 2; row <= numRows; row++) {
        if (Array.isArray(values)) {
          const index = values.indexOf(column);
          const alphabet = this.getColumnLetter(index);
          const cell = ws.getCell(`${alphabet}${row}`);
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: ['"TRUE,FALSE"'],
            showErrorMessage: true,
            error: 'Please select a value from the dropdown.',
            errorStyle: 'stop', // Display a stop error alert
          };
        }
      }
    });

    for (let row = 2; row <= numRows; row++) {
      if (Array.isArray(values)) {
        const index = values.indexOf('reservable');
        const alphabet = this.getColumnLetter(index);
        const cell = ws.getCell(`${alphabet}${row}`);
        cell.dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ['"Assigned,Touchdown"'],
          showErrorMessage: true,
          error: 'Please select a value from the dropdown.',
          errorStyle: 'stop', // Display a stop error alert
        };
      }
    }

    // Add dropdowns in extra added Desk Types
    if (ws.actualColumnCount > 9) {
      if (Array.isArray(values)) {
        const index = values.indexOf('meeting_name');
        const startingColumnIndex = index + 1;
        for (let columnIndex = startingColumnIndex; columnIndex <= ws.actualColumnCount; columnIndex++) {
          for (let rowNumber = 2; rowNumber <= numRows; rowNumber++) {
            const cell = ws.getCell(rowNumber, columnIndex);
            cell.dataValidation = {
              type: 'list',
              allowBlank: true,
              formulae: ['"TRUE,FALSE"'],
              showErrorMessage: true,
              error: 'Please select a value from the dropdown.',
              errorStyle: 'stop', // Display a stop error alert
            };
          };
        }
      }
    }

    // Save the workbook to a buffer
    wb.xlsx.writeBuffer().then((buffer) => {
      // Convert the buffer to a Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save the Blob as a file using file-saver
      saveAs(blob, 'DeskModifyingData_' + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() + '.xlsx');
    });
    // var wb = XLSX.utils.book_new();
    // var ws = XLSX.utils.json_to_sheet(data);
    // var ws1 = XLSX.utils.json_to_sheet(this.ReadmeForExcel);
    // XLSX.utils.book_append_sheet(wb, ws, 'DeskData');
    // XLSX.utils.book_append_sheet(wb, ws1, 'Readme');
    // wb.xlsx.writeFile('DeskModifyingData_' + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() + '.xlsx');
    this.showLoader = false;
  }

  // Define a function to get the letter corresponding to the given column index
  getColumnLetter(columnIndex) {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let letter = '';
    while (columnIndex > 0) {
      const remainder = (columnIndex - 1) % 26;
      letter = alphabet.charAt(remainder) + letter;
      columnIndex = Math.floor((columnIndex - 1) / 26);
    }
    return letter;
  }





  downloadRecord() {
    this.showLoader = true;
    if (localStorage.getItem('GroupedBU') == "true") {
      this.service.getGroupedBUID(parseInt(localStorage.getItem('OfficeID')), parseInt(localStorage.getItem('BUId'))).subscribe((data: any) => {
        var groupedbuid = data;
        this.service.getAllDesksFromGroupedBUIdToExport(parseInt(localStorage.getItem('FloorID')), groupedbuid, parseInt(localStorage.getItem('OfficeID')))
          .subscribe((data: any) => {
            this.downloadExcelFile(data);
            this.showLoader = false;
          }, err => {
            this.showLoader = false;
            if (err.status == 400) {
              this.router.navigate(["/globalerror"]);
              return
            }
          });
      }, err => {
        this.showLoader = false;
        if (err.status == 400) {
          this.router.navigate(["/globalerror"]);
          return
        }
      });
    }
    else if (localStorage.getItem('ZoneId') != undefined && localStorage.getItem('ZoneId') != 'null' && localStorage.getItem('ZoneId') != 'undefined') {
      var _zoneid = parseInt(localStorage.getItem('ZoneId'));
      this.service.getAllDesksFromZoneIdToExport(_zoneid, parseInt(localStorage.getItem('OfficeID'))).subscribe((result: any) => {
        this.downloadExcelFile(result);
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        if (err.status == 400) {
          this.router.navigate(["/globalerror"]);
          return
        }
      });
    }
    else if (localStorage.getItem('FloorID') != undefined && localStorage.getItem('FloorID') != 'null' && localStorage.getItem('FloorID') != 'undefined') {
      var _floorid = parseInt(localStorage.getItem('FloorID'));
      this.service.getAllDesksFromFloorIdToExport(_floorid, parseInt(localStorage.getItem('OfficeID'))).subscribe((result: any) => {
        this.downloadExcelFile(result);
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        if (err.status == 400) {
          this.router.navigate(["/globalerror"]);
          return
        }
      });
    }
    else {
      this.showLoader = false;
    }
  }

  downloadExcelFile(data: any) {
    // this.showLoader = true;
    // var date = new Date();
    // var wb = XLSX.utils.book_new();
    // var ws = XLSX.utils.json_to_sheet(data);
    // XLSX.utils.book_append_sheet(wb, ws, 'DeskData');
    // XLSX.writeFile(wb, 'DeskModifyingData_' + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() + '.xlsx');
    // this.showLoader = false;

    this.showLoader = true;
    var date = new Date();
    var wb = new excel.Workbook();
    var ws = wb.addWorksheet('DeskData');

    // Add headers for Data
    const headers = Object.keys(data[0]);
    ws.addRow(headers);

    // Add data for Data
    data.forEach(obj => {
      const data = Object.values(obj);
      ws.addRow(data);
    });
    // Define the range of cells    
    const cellRanges = ['is_available', 'istouchdown_assigned', 'cat_desk'];
    const numRows = 1000;
    var values = ws.findRow(1).values;
    // Get the header row
    ws.columns.forEach(column => {
      column.width = 20; // Adjust width as needed
    });
    // Get the header row
    const headerRow = ws.getRow(1);
    headerRow.height = 30; // Adjust height as needed

    // Add comments to header cells
    headerRow.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.style = {
        font: {
          name: 'Calibri',
          bold: true,
          size: 11,
          color: { argb: 'FFFFFFFF' }, // White color
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF305496' } // Blue color
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center'
        }
      }
      if (cell.value == "office_name" || cell.value == "floor_name" || cell.value == "zone_name" || cell.value == "business_unit") {
        cell.note = {
          texts: [
            { text: 'This will auto-populate, no need to enter in other rows.' }
          ]
        };
      }
      else if (cell.value == "desk_number") {
        cell.note = {
          texts: [
            { text: 'Enter all the desk numbers belonging to that floor,zone or BU. Make sure desk numbers are written exactly as they are on the plans.' }
          ]
        };
      }
      else if (cell.value == "reservable") {
        cell.note = {
          texts: [
            { text: 'Select Assigned if the desk can only be used by one individual.'+ 
            'Select Touchdown if this is a shared desk.'+ 
            'Select value from dropdown for all the desks.' }
          ]
        };
      }
      else if (cell.value == "is_available")
      {
        cell.note = {
          texts: [
            { text: 'This should be True almost always. False if you do not want the desk to be reservable but you do want it set up to potentially make it reservable later.'+ 
            'Select value from dropdown for all the desks' }
          ]
        };
      }
      else if (cell.value == "cat_desk") {
        cell.note = {
          texts: [
            { text: 'This will be True if you would like to allow user to filter desks by different attributes like sit to stand, dual monitor, or docking station type.'+ 
            'Select value from dropdown for all the desks.' }
          ]
        };
      }
      else if (cell.value == "istouchdown_assigned") {
        cell.note = {
          texts: [
            { text: 'Select TRUE(if previously configured) else FALSE in all.' }
          ],
        };
      }
      else if (cell.value == "work_space_type") {
        cell.note = {
          texts: [
            { text: 'Enter workspacetype as workstation/meeting room etc.' }
          ]
        };
      }
      else if (cell.value == "meeting_name") {
        cell.note = {
          texts: [
            { text: 'Enter the Meeting Room Name as described in Outlook. This is a specific naming convention as displayed in Outlook when you book the meeting room.' }
          ]
        };
      }
    });
    // Define the data validation settings
    // Apply data validation settings to each cell in the specified range
    cellRanges.forEach(column => {
      for (let row = 2; row <= numRows; row++) {
        if (Array.isArray(values)) {
          const index = values.indexOf(column);
          const alphabet = this.getColumnLetter(index);
          const cell = ws.getCell(`${alphabet}${row}`);
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: ['"TRUE,FALSE"'],
            showErrorMessage: true,
            error: 'Please select a value from the dropdown.',
            errorStyle: 'stop', // Display a stop error alert
          };
        }
      }
    });

    for (let row = 2; row <= numRows; row++) {
      if (Array.isArray(values)) {
        const index = values.indexOf('reservable');
        const alphabet = this.getColumnLetter(index);
        const cell = ws.getCell(`${alphabet}${row}`);
        cell.dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ['"Assigned,Touchdown"'],
          showErrorMessage: true,
          error: 'Please select a value from the dropdown.',
          errorStyle: 'stop', // Display a stop error alert
        };
      }
    }

    // Add dropdowns in extra added Desk Types
    if (ws.actualColumnCount > 9) {
      if (Array.isArray(values)) {
        const index = values.indexOf('meeting_name');
        const startingColumnIndex = index + 1;
        for (let columnIndex = startingColumnIndex; columnIndex <= ws.actualColumnCount; columnIndex++) {
          for (let rowNumber = 2; rowNumber <= numRows; rowNumber++) {
            const cell = ws.getCell(rowNumber, columnIndex);
            cell.dataValidation = {
              type: 'list',
              allowBlank: true,
              formulae: ['"TRUE,FALSE"'],
              showErrorMessage: true,
              error: 'Please select a value from the dropdown.',
              errorStyle: 'stop', // Display a stop error alert
            };
          };
        }
      }
    }

    // Save the workbook to a buffer
    wb.xlsx.writeBuffer().then((buffer) => {
      // Convert the buffer to a Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save the Blob as a file using file-saver
      saveAs(blob, 'DeskModifyingData_' + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() + '.xlsx');
    });
    this.showLoader = false;
  }

  search() {
    this.showLoader = true;
    if (this.givenText.length > 0) {
      if (localStorage.getItem('GroupedBU') == "true") {
        this.service.getGroupedBUID(parseInt(localStorage.getItem('OfficeID')), parseInt(localStorage.getItem('BUId'))).subscribe((data: any) => {
          this.buId = data;
          this.searchData();
        }, err => {
          this.showLoader = false;
        });
      }
      if (localStorage.getItem('GroupedBU') != "true") {
        this.searchData();
      }
    }
    else {
      this.FilterDesks();
    }
  }
  searchData() {
    if (localStorage.getItem('ZoneId') != undefined && localStorage.getItem('ZoneId') != 'null' && localStorage.getItem('ZoneId') != 'undefined') {
      this.type = 'zoneId';
      this.mappingId = parseInt(localStorage.getItem('ZoneId'));
      this.buId = (localStorage.getItem('GroupedBU') == "true") ? this.buId : 0;
    }
    else if (localStorage.getItem('FloorID') != undefined && localStorage.getItem('FloorID') != 'null' && localStorage.getItem('FloorID') != 'undefined') {
      this.type = 'floorId';
      this.mappingId = parseInt(localStorage.getItem('FloorID'));
      this.buId = (localStorage.getItem('GroupedBU') == "true") ? this.buId : 0;
    }
    this.service.getsearchdata(this.givenText, this.mappingId, this.type, this.buId, localStorage.getItem('locale')).subscribe((res: any) => {
      this.dataSource = res as DesksDTO[];
      this.showLoader = false;
    });
  }
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('CurrentUserEmailId', localStorage.getItem('CurrentUserEmail'));
    formData.append('OfficeID', localStorage.getItem('OfficeID'));

    this.showUploadMsgPopUp = true;
    this.translate.get('Deskupload').subscribe((data: string) => { this.msgUploadTitle = data; });
    this.translate.get('UploadStatusMsg').subscribe((data: string) => { this.PopUpUploadMessage = data; });
    //files[0] = '';
    this.service.uploadSpreadSheet(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Upload success.';
        this.onUploadFinished.emit(event.body);
      }
      // this.showPopUp = false;

    }, err => {
      this.showLoader = false;
      this.showUploadMsgPopUp = true;
      this.translate.get('DeskuploadErr').subscribe((data: string) => { this.msgUploadTitle = data; });
      this.translate.get('UploadStatusErrMsg').subscribe((data: string) => { this.PopUpUploadMessage = data; });
      return
    });
  };
  setDisplayColumns(assignedorDesCat: boolean) {
    if (!this.showDownloadUpload && localStorage.getItem('GroupedBU') == "true") {
      // this.displayedColumns = ['deskNumber', 'isAvailable', 'catSitstand', 'catDualMonitor', 'catQuietDesk', 'reservable'];
      this.displayedColumns = ['deskNumber', 'isAvailable', 'DeskBU', 'workSpaceType', 'meetingName'];
    }
    else if (!this.showDownloadUpload && !this.hasbu == true) {
      this.displayedColumns = ['deskNumber', 'isAvailable', 'workSpaceType', 'meetingName'];
    }
    else if (assignedorDesCat) {
      this.displayedColumns = ['deskNumber', 'isAvailable', 'DeskType', 'reservable', 'workSpaceType', 'meetingName'];
    }
    else {
      //this.displayedColumns = ['deskNumber', 'isAvailable', 'catSitstand', 'catDualMonitor', 'catQuietDesk'];
      this.displayedColumns = ['deskNumber', 'isAvailable', 'DeskType', 'workSpaceType', 'meetingName'];
    }
  }

  //------- DeskType Implement 11Aug2022 --------
  bindDeskTypeDropDown() {
    var _offID = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
    if (_offID != undefined && _offID != "undefined") {
      this.service.getDeskTypes(parseInt(_offID)).subscribe((result: any[]) => {
        // this.dropdownListDeskTypeList = result;
        this.dropdownListDeskTypeList = result.filter(x => x.langKey == localStorage.getItem('locale'));
      });
    }
    this.dropdownDeskTypeSettings = {
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      noDataAvailablePlaceholderText: "There is no item availabale to show"
      , allowSearchFilter: true
    };
  }
  bindDeskBUDropDown() {
    var _offID = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
    if (_offID != undefined && _offID != "undefined") {
      this.service.GetAllOfficeBU(parseInt(_offID)).subscribe((result: any[]) => {
        // this.dropdownListDeskTypeList = result;
        this.dropdownListBUList = result;
      });
    }
    this.dropdownDeskTypeSettings = {
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      noDataAvailablePlaceholderText: "There is no item availabale to show"
      , allowSearchFilter: true
    };
  }

  onDeskTypeItemSelect(item: any, desk) {
    let elementId = 'multiDDLDeskType_' + desk.id;
    this.showLoader = true;
    this.DeskDTO = desk;

    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    this.DeskDTO.DeskType = desk.deskType;

    const DeskType_ids = desk.deskType.map(e => e.id).join(",");
    this.DeskDTO.DeskTypeIds = DeskType_ids;

    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;

  }

  onDeskTypeDeSelect(item: any, desk) {
    let elementId = 'multiDDLDeskType_' + desk.id;
    this.showLoader = true;
    this.DeskDTO = desk;

    this.DeskDTO.CurrentUserEmail = localStorage.getItem('CurrentUserEmail');
    this.DeskDTO.DeskType = desk.deskType;

    const DeskType_ids = desk.deskType.map(e => e.id).join(",");
    this.DeskDTO.DeskTypeIds = DeskType_ids;

    const index: number = this.ListtoSave.indexOf(desk);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showLoader = false;
  }


  applyFilter(filterValue: string) {
    this.dataSourceNew.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceNew.paginator) {
      this.dataSourceNew.paginator.firstPage();
      // this.displayedColumns = ['deskNumber', 'isAvailable', 'catSitstand', 'catDualMonitor', 'catQuietDesk','workSpaceType', 'meetingName'];
    }
  }
  showHideButtons(buttonsShowHide) {
    if (buttonsShowHide) {
      this.showDownloadUpload = false;
    }
    else {
      this.showDownloadUpload = true;
    }
  }
  checkhasOfficeBU() {
    var _offID = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
    if (_offID != undefined && _offID != "undefined") {
      this.service.CheckOfficeBU(parseInt(_offID)).subscribe((result: boolean) => {
        // this.dropdownListDeskTypeList = result;
        this.hasbu = result;
      });
    }
  }
  //Raman Admin Desk Unavailable Changes
  disableDeskFor(disableDeskFor) {
    if (disableDeskFor == "Temporary") {
      this.showDates = true;
    }
    else {
      this.showDates = false;
    }
  }
  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }
  getDateFormatString(): string {
    return 'MM/DD/YYYY';
  }
  changeFromDate(event) {
    this.minToDate = event.value;
  }
  closeDeskDisable() {
    const index: number = this.ListtoSave.indexOf(this.DeskDTO);
    if (index !== -1) {
      this.checkboxSelected.target.checked = true;
      let elementId = 'checkboxInUse' + this.DeskDTO["id"];
      document.getElementById(elementId).classList.remove('ChangeSelectedCheckBoxColor')
      this.ListtoSave.splice(index, 1);

    }
    this.showDisableDesk = false;
  }
  AddStartEndDate() {
    this.showLoader = true;
    var deskData = this.DeskDTO;
    if (this.showDates) {
      this.DeskDTO.ToDate = this.datepipe.transform(this.minToDate, 'yyyy-MM-dd');
      this.DeskDTO.FromDate = this.datepipe.transform(this.today, 'yyyy-MM-dd');
      this.DeskDTO.DisableType = "Partially";
    }
    else {
      this.DeskDTO.DisableType = "Permanent";
    }
    const index: number = this.ListtoSave.indexOf(deskData);
    if (index !== -1) {
      this.ListtoSave.splice(index, 1);
    }
    this.ListtoSave.push(this.DeskDTO);
    this.showDisableDesk = false
    this.showLoader = false;
  }
  //END
}
