import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { userDataService } from '../../app/services/data-share';
import { LogService } from '../services/log.service';
import { TranslateService } from '@ngx-translate/core'
import { REACTIVE_DRIVEN_DIRECTIVES } from '@angular/forms/src/directives';
import keys from '../key.json';
import { environment } from './../../environments/environment';
import * as moment from 'moment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class LoginComponent implements OnInit {
  AllowAdmin: boolean = false;
  showLoader = false;
  isCaptchaValidated = false;
  captchaKey: string;
  CaptchaResponse: string;
  userDomain = localStorage.getItem('IsExternalUser') == 'true' ? 'external' : 'atkins';
  enterSNCLOTP = false;
  snclUserLogin = true;
  loadAPI: Promise<any>;
  snclLogInOtp = null;
  snclUser = '';
  snclPwd = '';
  externalUser = '';
  externalPwd = '';
  snclEmail = '';
  wntToChgExtPwd = false;
  pwdChgEmail = '';
  pwdChgOldPwd = '';
  pwdChgNewPwd = '';
  pwdChgNewReEnteredPwd = '';
  wntToForgetExtPwd = false;
  err_msg = {
    'sncl_email_val_msg': { 'isValid': false, 'msg': '' },
    'sncl_otp_val_msg': { 'isValid': false, 'msg': '' },
    'sncl_login_un_val_msg': { 'isValid': false, 'msg': '' },
    'sncl_login_pwd_val_msg': { 'isValid': false, 'msg': '' },
    'ext_login_un_val_msg': { 'isValid': false, 'msg': '' },
    'ext_login_pwd_val_msg': { 'isValid': false, 'msg': '' },
    'ext_pwd_chg_email_val_msg': { 'isValid': false, 'msg': '' },
    'ext_pwd_chg_oldpwd_val_msg': { 'isValid': false, 'msg': '' },
    'ext_pwd_chg_newpwd_val_msg': { 'isValid': false, 'msg': '' },
    'ext_pwd_chg_newreenteredpwd_val_msg': { 'isValid': false, 'msg': '' }
  }
  snclalloweddomains = ['snclavalin.com'];
  showMsgPopUpOld: boolean = false;
  popMsgTitle: string;
  popMsgDetail: string;
  logoClass: string;
  msgTitle: string;
  msgBody: string;
  showMsgPopUp: boolean = false;
  showQuickBook: boolean = true;
  showLastReservation: boolean = false;
  cancelBtnText: string = "Cancel";
  okBtnText: string = "OK";
  quickBookData;
  constructor(public ServiceService: ServiceService, private router: Router, private titleService: Title, private dataservice: userDataService, private logger: LogService
    , public translate: TranslateService) {
    let _langs = [];
    ServiceService.SupportedLanguages.forEach(l => _langs.push(l['languageCode']));
    translate.addLangs(_langs);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(ServiceService.SupportedLangRegex) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
    // this.loadAPI = new Promise((resolve) => {
    //   this.loadScript();
    //   resolve(true);
    //   });
  }


  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = ["https://use.fontawesome.com/releases/v5.0.13/js/fontawesome.js",
        "https://use.fontawesome.com/releases/v5.0.13/js/solid.js"];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }
  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    if (language == 'fr') {
      this.logoClass = 'fl logoSaveseatfr'
    } else {
      this.logoClass = 'fl logoSaveseat'
    }
  }
  //Check for SNCL Authentication and the navigate to Home
  snclLoginBtnClick() {
    switch (this.snclUser) {
      case '':
      case null:
        this.err_msg.sncl_login_un_val_msg.isValid = true;
        this.err_msg.sncl_login_un_val_msg.msg = 'Please enter an User Name.';
        return;
        break;
      default:
        this.err_msg.sncl_login_un_val_msg.isValid = false;
        this.err_msg.sncl_login_un_val_msg.msg = '';
        break;
    }

    switch (this.snclPwd) {
      case '':
      case null:
        this.err_msg.sncl_login_pwd_val_msg.isValid = true;
        this.err_msg.sncl_login_pwd_val_msg.msg = 'Please enter Password.';
        return;
        break;
      default:
        this.err_msg.sncl_login_un_val_msg.isValid = false;
        this.err_msg.sncl_login_un_val_msg.msg = '';
        break;
    }

    this.err_msg.sncl_login_un_val_msg.isValid = false;
    this.err_msg.sncl_login_un_val_msg.msg = '';
    this.err_msg.sncl_login_pwd_val_msg.isValid = false;
    this.err_msg.sncl_login_pwd_val_msg.msg = '';

    this.showLoader = true;
    this.ServiceService.authoriseSNCLUser(this.snclUser, this.snclPwd).subscribe((data: any[]) => {
      if (data['message'] == 'something_wrong') {
        this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      }
      else if (data['response'].toString() != '200') {
        this.showPopupMsg('Error', 'Either Username or Password is invalid.\nPlease renter your SNCL credentials.');
      }
      else {
        if (data['isUserExist']) {
          if (data['isOTPValid']) {
            localStorage.setItem('CurrentUserEmail', data['emailId']);
            localStorage.setItem('CurrentUser', this.snclUser);
            localStorage.setItem('IsExternalUser', false.toString());
            this.setUserPreferences();
          }
          else {
            this.snclUserLogin = false;
            this.enterSNCLOTP = false;
          }
        }
        else if (!data['isUserExist']) {
          if (data['message'] == 'user_not_saved') {
            this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
          }
          else {
            this.snclUserLogin = false;
            this.enterSNCLOTP = false;
          }
        }
      }

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      if (error.status == 400) {
        this.router.navigate(["/globalerror"]);
        return
      }
      this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      this.logger.error('Login Fail', error);
    })
  }

  isValidEmail(search: string): boolean {
    var serchfind: boolean;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(search);
    return serchfind
  }

  //Send OTP to user email and navigate to enter otp view
  sendOTPNextBtnClick() {
    switch (this.snclEmail) {
      case '':
      case null:
        this.err_msg.sncl_email_val_msg.isValid = true;
        this.err_msg.sncl_email_val_msg.msg = 'Please enter an Email address.';
        return;
        break;
      default:
        let emailSplitArr = this.snclEmail.split('@');
        let domain = emailSplitArr[emailSplitArr.length - 1];
        if (!this.isValidEmail(this.snclEmail)) {
          this.err_msg.sncl_email_val_msg.isValid = true;
          this.err_msg.sncl_email_val_msg.msg = 'Please enter a valid Email address.';
          return;
        }
        else if (domain.indexOf('snclavalin.com') < 0) {
          this.err_msg.sncl_email_val_msg.isValid = true;
          this.err_msg.sncl_email_val_msg.msg = 'Please enter SNC-Lavalin Email address.';
          return;
        }
        break;
    }
    this.err_msg.sncl_email_val_msg.isValid = false;
    this.err_msg.sncl_email_val_msg.msg = '';

    this.showLoader = true;
    let snclUserData = { Snclemail: this.snclEmail, Otp: this.snclLogInOtp, UserName: this.snclUser, IsOtpvalid: 'No' };
    this.ServiceService.sendOTPtoSNCL(snclUserData).subscribe((data: any[]) => {
      if (data['message'] == 'db_save_failed') {
        this.showPopupMsg('Error', 'Mail could not be sent to the entered Email Address!!!\nPlease try again.');
        this.enterSNCLOTP = false;
        this.snclUserLogin = false;
      }
      else {
        if (data['message'] == 'mail_failed') {
          this.showPopupMsg('Error', 'Mail could not be sent to the entered Email Address!!!\nPlease Check if the Email Address is correct.');
        }
        else {
          this.enterSNCLOTP = true;
          this.snclUserLogin = false;
        }
      }

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      if (error.status == 400) {
        this.router.navigate(["/globalerror"]);
        return
      }
      this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      this.logger.error('Send OTP Failed', error);

    });
  }

  //Navigate back to SNCL Login view
  backToSNCLLogIn() {
    this.enterSNCLOTP = false;
    this.snclUserLogin = true;
    this.snclUser = '';
    this.snclPwd = '';
    this.snclLogInOtp = null;
    this.snclEmail = '';
  }

  //Validate the otp and Navigate to SNCL Login View
  verifyOTPandGoHome() {
    switch (this.snclLogInOtp) {
      case 0:
      case null:
        this.err_msg.sncl_otp_val_msg.isValid = true;
        this.err_msg.sncl_otp_val_msg.msg = 'Please enter OTP.';
        return;
        break;
      default:
        let otpLen = this.snclLogInOtp.toString().length;
        if (this.snclLogInOtp < 0) {
          this.err_msg.sncl_otp_val_msg.isValid = true;
          this.err_msg.sncl_otp_val_msg.msg = 'OTP should be non-negative';
          return;
        }
        break;
    }
    this.err_msg.sncl_otp_val_msg.isValid = false;
    this.err_msg.sncl_otp_val_msg.msg = '';

    this.showLoader = true;
    let snclUserData = { Snclemail: this.snclEmail, Otp: this.snclLogInOtp, UserName: this.snclUser, IsOtpvalid: 'No' };
    this.ServiceService.postUserData(snclUserData).subscribe((data: any[]) => {
      if (data['message'] == 'not_able_to_verify') {
        this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
        this.backToSNCLLogIn();
      }
      else {
        if (data['message'] == 'user_doesnot_exist') {
          this.showPopupMsg('Error', 'User informtion is not saved.!!!\nPlease reenter SNCL credentials again.');
          this.backToSNCLLogIn();
        }
        else {
          if (data['message'] == 'otp_not_valid') {
            this.showPopupMsg('Error', 'OTP is not valid!!\nCheck the mail and re-enter valid OTP.');
          }
          else {
            localStorage.setItem('CurrentUser', this.snclEmail);
            localStorage.setItem('IsExternalUser', false.toString());
            this.setUserPreferences();
          }
        }
      }

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      if (error.status == 400) {
        this.router.navigate(["/globalerror"]);
        return
      }
      this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      this.logger.error('Post User Data', error);
    });
  }

  //Check for External User Authentication and the navigate to Home
  externalLoginBtnClick() {

    // localStorage.username =(<HTMLInputElement>document.getElementById("username")).value;// document.getElementById("username").nodeValue;
    //localStorage.password =(<HTMLInputElement>document.getElementById("password")).value;

    localStorage.setItem('myusername', (<HTMLInputElement>document.getElementById("username")).value);
    localStorage.setItem('mypassword', (<HTMLInputElement>document.getElementById("password")).value);

    //this.showLoader = true;
    switch (this.externalUser) {
      case '':
      case null:
        this.err_msg.ext_login_un_val_msg.isValid = true;
        this.err_msg.ext_login_un_val_msg.msg = 'Please enter an Email address.';
        return;
        break;
      default:
        if (!this.isValidEmail(this.externalUser)) {
          this.err_msg.ext_login_un_val_msg.isValid = true;
          this.err_msg.ext_login_un_val_msg.msg = 'Please enter a valid Email address.';
          return;
        }
        else {
          this.err_msg.ext_login_un_val_msg.isValid = false;
          this.err_msg.ext_login_un_val_msg.msg = '';
        }
        break;
    }

    switch (this.externalPwd) {
      case '':
      case null:
        this.err_msg.ext_login_pwd_val_msg.isValid = true;
        this.err_msg.ext_login_pwd_val_msg.msg = 'Please enter Password.';
        return;
        break;
      default:
        this.err_msg.ext_login_pwd_val_msg.isValid = false;
        this.err_msg.ext_login_pwd_val_msg.msg = '';
        break;
    }

    this.err_msg.ext_login_un_val_msg.isValid = false;
    this.err_msg.ext_login_un_val_msg.msg = '';
    this.err_msg.ext_login_pwd_val_msg.isValid = false;
    this.err_msg.ext_login_pwd_val_msg.msg = '';

    this.showLoader = true;
    let email = this.ServiceService.encrypt(this.externalUser);
    let pwd = this.ServiceService.encrypt(this.externalPwd);
    //FullName previously we send as blank, so for For captcha validation I am using. I no need
    //to create any variable or change model in external user
    let externalUserData = { EmailAddress: email, Password: pwd, FullName: this.CaptchaResponse, AddedOn: null };
    this.ServiceService.authoriseExternalUser(externalUserData).subscribe((data: any[]) => {
      let isPwdValid = this.ServiceService.decryptData(data['isPwdValid']) == 'true' ? true : false;
      let secretMsg = this.ServiceService.decryptData(data['isPwdValidMsg']);
      let validCheck = false;
      if (secretMsg.includes("true") && secretMsg.includes(this.externalUser.toLowerCase())) {
        validCheck = true;
      }

      if (data['message'] == 'something_wrong') {
        this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      }
      if (data['message'] == 'Already_LoggedIn') {
        this.showPopupMsg('Error', 'You are already Logged in!!!\nPlease try after 15 minutes.');
      }
      else if (data['message'] == 'lockout') {
        this.showPopupMsg('Error', 'Account has been locked!!!\nPlease reset your password.');
      }
      else if (!data['isUserExist']) {
        //this.showPopupMsg('Error', 'Sorry!!\nYou are not authorised for this site.');
        this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      }
      else if (data['isUserExist'] && !isPwdValid) {
        //this.showPopupMsg('Error', 'Either your username or password is invalid.\nPlease re-enter your credentials.');
        this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      }
      //else if (data['isUserExist'] && data['isPwdValid'] && this.ServiceService.decryptData(data['isPwdValidMsg'])=='User_Valid') {
      else if (data['isUserExist'] && isPwdValid && validCheck) {
        localStorage.setItem('CurrentUser', this.ServiceService.decryptData(data['userName']));
        localStorage.setItem('CurrentUserEmail', this.ServiceService.decryptData(data['emailId']));
        localStorage.setItem('IsExternalUser', true.toString());

        //this.setUserPreferences();

        this.router.navigateByUrl("/generatetoken");
      }
      else {
        this.showPopupMsg('Error', 'Sorry!!\nYou are not authorised for this site.');
      }
      this.showLoader = false;
      this.isCaptchaValidated = false;
      this.CaptchaResponse = "";
    }, error => {
      this.showLoader = false;
      this.isCaptchaValidated = false;
      this.CaptchaResponse = "";
      if (error.status == 400) {
        this.router.navigate(["/globalerror"]);
        return
      }
      this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      this.logger.error('External User Authorization Failed', error);
    })



  }

  //Navigate to Change PasswordView
  goToChgPwdView() {
    // this.translate.get('PwdChangeWarnMsg').subscribe((data:string)=> {this.wntToChgExtPwd = data;});
    if (localStorage.getItem('locale') == 'en') {
      if (confirm("You are about to change your password!!!\nDo you really want to do that?")) {
        this.wntToChgExtPwd = true;
      }
    }
    else {
      if (confirm("Vous êtes sur le point de changer votre mot de passe.!!!\nEst-ce vraiment ce que vous voulez faire?")) {
        this.wntToChgExtPwd = true;
      }
    }

  }

  //Cancel Change Password view
  cancelChgPwdView() {
    this.wntToChgExtPwd = false;
    this.wntToForgetExtPwd = false;
  }

  //Change the Password for external User
  ChgExtPwd() {
    switch (this.pwdChgEmail) {
      case '':
      case null:
        this.err_msg.ext_pwd_chg_email_val_msg.isValid = true;
        this.err_msg.ext_pwd_chg_email_val_msg.msg = 'Please enter an Email address.';
        return;
        break;
      default:
        if (!this.isValidEmail(this.pwdChgEmail)) {
          this.err_msg.ext_pwd_chg_email_val_msg.isValid = true;
          this.err_msg.ext_pwd_chg_email_val_msg.msg = 'Please enter a valid Email address.';
          return;
        }
        else {
          this.err_msg.ext_pwd_chg_email_val_msg.isValid = false;
          this.err_msg.ext_pwd_chg_email_val_msg.msg = '';
        }
        break;
    }

    switch (this.pwdChgOldPwd) {
      case '':
      case null:
        this.err_msg.ext_pwd_chg_oldpwd_val_msg.isValid = true;
        this.err_msg.ext_pwd_chg_oldpwd_val_msg.msg = 'Please enter Password.';
        return;
        break;
      default:
        this.err_msg.ext_pwd_chg_oldpwd_val_msg.isValid = false;
        this.err_msg.ext_pwd_chg_oldpwd_val_msg.msg = '';
        break;
    }

    switch (this.pwdChgNewPwd) {
      case '':
      case null:
        this.err_msg.ext_pwd_chg_newpwd_val_msg.isValid = true;
        this.err_msg.ext_pwd_chg_newpwd_val_msg.msg = 'Please enter Password.';
        return;
        break;
      default:
        this.err_msg.ext_pwd_chg_newpwd_val_msg.isValid = false;
        this.err_msg.ext_pwd_chg_newpwd_val_msg.msg = '';
        break;
    }

    switch (this.pwdChgNewReEnteredPwd) {
      case '':
      case null:
        this.err_msg.ext_pwd_chg_newreenteredpwd_val_msg.isValid = true;
        this.err_msg.ext_pwd_chg_newreenteredpwd_val_msg.msg = 'Please enter Password.';
        return;
        break;
      default:
        this.err_msg.ext_pwd_chg_newreenteredpwd_val_msg.isValid = false;
        this.err_msg.ext_pwd_chg_newreenteredpwd_val_msg.msg = '';
        break;
    }

    this.err_msg.ext_pwd_chg_email_val_msg.isValid = false;
    this.err_msg.ext_pwd_chg_email_val_msg.msg = '';
    this.err_msg.ext_pwd_chg_oldpwd_val_msg.isValid = false;
    this.err_msg.ext_pwd_chg_oldpwd_val_msg.msg = '';
    this.err_msg.ext_pwd_chg_newpwd_val_msg.isValid = false;
    this.err_msg.ext_pwd_chg_newpwd_val_msg.msg = '';
    this.err_msg.ext_pwd_chg_newreenteredpwd_val_msg.isValid = false;
    this.err_msg.ext_pwd_chg_newreenteredpwd_val_msg.msg = '';

    let passwordChgEmail = btoa(this.pwdChgEmail);
    let passwordChgOldPwd = btoa(this.pwdChgOldPwd);
    let passwordChgNewPwd = btoa(this.pwdChgNewPwd);
    let passwordChgNewReEnteredPwd = btoa(this.pwdChgNewReEnteredPwd);

    this.showLoader = true;
    let ChangePasswordModel = { UsrEmail: passwordChgEmail, OldPwd: passwordChgOldPwd, NewPwd: passwordChgNewPwd, ReenteredNewPwd: passwordChgNewReEnteredPwd };
    this.ServiceService.changeExtPwd(ChangePasswordModel).subscribe((data: any[]) => {
      if (data['message'] == 'Pwd_Changed') {
        this.showPopupMsg('Success', 'User Password updated successfully.');
        this.pwdChgEmail = '';
        this.pwdChgOldPwd = '';
        this.pwdChgNewPwd = '';
        this.pwdChgNewReEnteredPwd = '';
        this.wntToChgExtPwd = false;
      }
      else if (data['message'] == 'Pwd_Not_Changed') {
        this.showPopupMsg('Error', 'Password Could not be changed because of some technical reasons.\nPlease try again.');
      }
      else if (data['message'] == 'Pwds_Not_Matching') {
        this.showPopupMsg('Error', 'Reentered password does not match with the new password.');
      }
      else if (data['message'] == 'Old_Pwd_Invalid') {
        this.showPopupMsg('Error', 'Old password is not correct\nPlease reenter your old password.');
      }
      else if (data['message'] == 'No_Usr_Exist') {
        this.showPopupMsg('Error', 'Please check your email address.\nUser with this email address is not authorised for this website.');
      }
      else {
        this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      }

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      if (error.status == 400) {
        this.router.navigate(["/globalerror"]);
        return
      }
      this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      this.logger.error('Change External UserPwd Fails', error);
    })
  }

  ngOnInit() {
    var date = new Date();
    let local_date = moment.utc(date).format('YYYY-MM-DD');
    let local_time = moment.utc(date).format('HH:mm:ss');
    var expiryDate;
    var expiryTime;
    if (localStorage.getItem("tokenExpiry") != null && localStorage.getItem("tokenExpiry") != undefined) {
      expiryDate = localStorage.getItem("tokenExpiry").split('T')[0];
      expiryTime = localStorage.getItem("tokenExpiry").split('T')[1].split('Z')[0];
      // Check for Quick Booking
      if (localStorage.getItem("Clicked") == "Booking" && (local_date <= expiryDate) && (local_time <= expiryTime)) {
        this.ServiceService.getLastBookings(localStorage.getItem("CurrentUserEmail")).subscribe((data: any) => {
          localStorage.removeItem("Clicked");
          if (data == 100) {
            this.showMsgPopUp = true;
            this.translate.get('FirstTimeTitle').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('FirstTimeMsg').subscribe((data: string) => { this.msgBody = data; });
          }
          else if (data == 200) {
            this.showMsgPopUp = true;
            this.translate.get('DeskBooked').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('DeskBookedMsg').subscribe((data: string) => { this.msgBody = data; });
          }
          else if (data == 300) {
            this.showMsgPopUp = true;
            this.translate.get('BookingAlreadyExisMsg').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('SelectAnotherdateMsgQuickBook').subscribe((data: string) => { this.msgBody = data; });
          }
          else if (data == 500) {
            this.showMsgPopUp = true;
            this.translate.get('DeskBookingError').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('DeskAlreadyBookedMsg').subscribe((data: string) => { this.msgBody = data; });
          }
          else if (data == 600) {
            this.showMsgPopUp = true;
            this.translate.get('DeskBookingError').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('UnavailableData').subscribe((data: string) => { this.msgBody = data; });
          }
          else if (data.status == 800) {
            this.quickBookData = data;
            this.showLastReservation = true;
            this.translate.get('PreviousDeskBooked').subscribe((data: string) => { this.msgTitle = data; });
            this.translate.get('PreviousDeskDetails').subscribe((data1: string) => { this.msgBody = data1 + data.officeName + "," + data.floorName + "," + data.deskNumber; });
            this.translate.get('Cancel').subscribe((data: string) => { this.cancelBtnText = data; });
            this.translate.get('Rebook').subscribe((data: string) => { this.okBtnText = data; });
          }
        })
      }
      // Check for Quick Check In
      else if (localStorage.getItem("Clicked") == "CheckIn" && (local_date <= expiryDate) && (local_time <= expiryTime)) {
        this.quickCheckIn();
      }
      // Check for Quick Check Out
      else if (localStorage.getItem("Clicked") == "CheckOut" && (local_date <= expiryDate) && (local_time <= expiryTime)) {
          this.quickCheckOut();
      }
    }
    this.captchaKey = keys[0]['Captcha'];
    this.titleService.setTitle("Save My Seat - Login");
    localStorage.setItem('currentDomain', this.userDomain);
    if (localStorage.getItem('CurrentUserEmail') != null && localStorage.getItem('Clicked') != "Booking" && localStorage.getItem('Clicked') != "CheckIn"&& localStorage.getItem('Clicked') != "CheckOut")
      this.router.navigateByUrl('/home');
    if (localStorage.getItem('locale') == 'en') {
      this.logoClass = 'fl logoSaveseat'
    }
    else {
      this.logoClass = 'fl logoSaveseatfr'
    }

    // if (localStorage.getItem('CurrentUserEmail') != undefined && localStorage.getItem('CurrentUserEmail') != 'null' && localStorage.getItem('CurrentUserEmail') != 'undefined') {
    //   this.ServiceService.CheckIfAdminUser(localStorage.getItem('CurrentUserEmail')).subscribe((result: boolean) => {
    //     this.AllowAdmin = result;
    //   }, err => {
    //     this.AllowAdmin = false;
    //   });
    // }



    //localStorage.setItem('username', localStorage.username);

    if (localStorage.myusername != 'undefined') {
      this.externalUser = localStorage.myusername;
      this.externalPwd = localStorage.mypassword;
    }


  }

  public resolved(captchaResponse: string): void {
    this.isCaptchaValidated = true;
    this.CaptchaResponse = captchaResponse;
  }
  currentDomain(value) {
    localStorage.setItem('currentDomain', value);
    if (value == "external") {
      this.showQuickBook = false;
    }
    else {
      this.showQuickBook = true;
    }
  }
  showPopupMsg(msgTitle: string, msgDetail: string) {
    this.showMsgPopUpOld = true;
    this.popMsgTitle = msgTitle;
    this.popMsgDetail = msgDetail;
  }
  setUserPreferences() {
    this.ServiceService.getUserPreference().subscribe((userPrefs: any) => {
      if (userPrefs != undefined) {
        localStorage.setItem('SelectedCountry', userPrefs.selCountry);
        localStorage.setItem('OfficeID', userPrefs.selOfficeId);
        localStorage.setItem('FloorID', userPrefs.selFloorId);
        localStorage.setItem('ZoneId', userPrefs.selZoneId);
        localStorage.setItem('BUId', userPrefs.selBuid);
        localStorage.setItem('DeskId', userPrefs.deskId);
        localStorage.setItem('Purpose', userPrefs.purpose);
        localStorage.setItem('ShowName', userPrefs.showName);
        localStorage.setItem('IsHS', userPrefs.isHs);
        localStorage.setItem('IsFireWarden', userPrefs.isFireWarden);
      }
      this.router.navigateByUrl('/home');
    });
  }


  ForgotExtPwd() {
    switch (this.pwdChgEmail) {
      case '':
      case null:
        this.err_msg.ext_pwd_chg_email_val_msg.isValid = true;
        this.err_msg.ext_pwd_chg_email_val_msg.msg = 'Please enter an Email address.';
        return;
        break;
      default:
        if (!this.isValidEmail(this.pwdChgEmail)) {
          this.err_msg.ext_pwd_chg_email_val_msg.isValid = true;
          this.err_msg.ext_pwd_chg_email_val_msg.msg = 'Please enter a valid Email address.';
          return;
        }
        else {
          this.err_msg.ext_pwd_chg_email_val_msg.isValid = false;
          this.err_msg.ext_pwd_chg_email_val_msg.msg = '';
        }
        break;
    }

    this.err_msg.ext_pwd_chg_email_val_msg.isValid = false;
    this.err_msg.ext_pwd_chg_email_val_msg.msg = '';

    let passwordChgEmail = this.ServiceService.encrypt(this.pwdChgEmail);

    this.showLoader = true;
    let ChangePasswordModel = { UsrEmail: passwordChgEmail };
    this.ServiceService.ForgetExtPwd(ChangePasswordModel).subscribe((data: any[]) => {
      if (data['message'] == 'Mail_Sent') {
        this.showPopupMsg('Success', 'Mail sent Successfully. Kindly change your password from Change password');
        this.pwdChgEmail = '';
        this.pwdChgOldPwd = '';
        this.pwdChgNewPwd = '';
        this.pwdChgNewReEnteredPwd = '';
        this.wntToForgetExtPwd = false;
      }
      else {
        this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      }

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      if (error.status == 400) {
        this.router.navigate(["/globalerror"]);
        return
      }
      this.showPopupMsg('Error', 'Something went wrong!!!\nPlease try again.');
      this.logger.error('Change External UserPwd Fails', error);
    })
  }

  goToForgetPwdView() {
    // this.translate.get('PwdChangeWarnMsg').subscribe((data:string)=> {this.wntToChgExtPwd = data;});
    if (localStorage.getItem('locale') == 'en') {
      if (confirm("You are about to change your password!!!\nDo you really want to do that?")) {
        this.wntToForgetExtPwd = true;
      }
    }
    else {
      if (confirm("Vous êtes sur le point de changer votre mot de passe.!!!\nEst-ce vraiment ce que vous voulez faire?")) {
        this.wntToForgetExtPwd = true;
      }
    }

  }



  EDPMLogin() {
    // this.ServiceService.GenerateToken().subscribe(response => {
    //   const token = (<any>response).token;
    //   localStorage.setItem("token", token);
    this.tokenCheck();
    // }, err => {
    //   // this.invalidLogin = true;
    //   this.router.navigate(["/"]);
    // });
  }

  EDPMQuickBookLogin() {
    localStorage.setItem("Clicked", "Booking");
    window.location.href = environment.apiLoginURL;//('https://edpmauthforsms.azurewebsites.net');//'https://localhost:44385/';//
  }
  EDPMQuickBookCheckIn() {
    localStorage.setItem("Clicked", "CheckIn");
    this.tokenCheck();
  }
  EDPMQuickBookCheckOut() {
    localStorage.setItem("Clicked", "CheckOut");
    this.tokenCheck();
  }
  tokenCheck() {
    var date = new Date();
    let local_date = moment.utc(date).format('YYYY-MM-DD');
    let local_time = moment.utc(date).format('HH:mm:ss');
    var expiryDate;
    var expiryTime;
    if (localStorage.getItem("tokenExpiry") != null && localStorage.getItem("tokenExpiry") != undefined) {
      expiryDate = localStorage.getItem("tokenExpiry").split('T')[0];
      expiryTime = localStorage.getItem("tokenExpiry").split('T')[1].split('Z')[0];
      if ((local_date <= expiryDate) && (local_time <= expiryTime)) {
        if (localStorage.getItem("Clicked") == "CheckIn")
          this.quickCheckIn();
        else if (localStorage.getItem("Clicked") == "CheckOut")
          this.quickCheckOut();
        else
          this.router.navigate(['/home']);
      }
      else {
        window.location.href = environment.apiLoginURL;//('https://edpmauthforsms.azurewebsites.net');//'https://localhost:44385/';//
      }
    }
    else {
      window.location.href = environment.apiLoginURL;//('https://edpmauthforsms.azurewebsites.net');//'https://localhost:44385/';//
    }
  }

  //Quick CheckIn
  quickCheckIn() {
    this.ServiceService.getTodayCheckIn(localStorage.getItem("CurrentUserEmail")).subscribe((data: any) => {
      localStorage.removeItem("Clicked");
      if (data == 100) {
        this.showMsgPopUp = true;
        this.translate.get('NoFullDayBookingTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('NoFullDayBookingMsg').subscribe((data: string) => { this.msgBody = data; });
      }
      else if (data == 300) {
        this.showMsgPopUp = true;
        this.translate.get('CheckinMsgSt4').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('CheckInAlreadyExis').subscribe((data: string) => { this.msgBody = data; });
      }
      else if (data.status == 800) {
        this.quickBookData = data;
        this.showLastReservation = true;
        this.translate.get('CheckInTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('CheckInDetails').subscribe((data1: string) => { this.msgBody = data1 + data.officeName + "," + data.floorName + "," + data.deskNumber; });
        this.translate.get('Cancel').subscribe((data: string) => { this.cancelBtnText = data; });
        this.translate.get('CheckIn').subscribe((data: string) => { this.okBtnText = data; });
      }
    })
  }
  //Quick CheckOut
  quickCheckOut() {
    this.ServiceService.getTodayCheckOut(localStorage.getItem("CurrentUserEmail")).subscribe((data: any) => {
      localStorage.removeItem("Clicked");
      if (data == 100) {
        this.showMsgPopUp = true;
        this.translate.get('NoFullDayBookingTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('NoFullDayBookingMsgCheckOut').subscribe((data: string) => { this.msgBody = data; });
      }
      else if (data == 300) {
        this.showMsgPopUp = true;
        this.translate.get('CheckIn').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('CheckInFirst').subscribe((data: string) => { this.msgBody = data; });
      }
      else if (data.status == 800) {
        this.quickBookData = data;
        this.showLastReservation = true;
        this.translate.get('CheckOutTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('CheckOutDetails').subscribe((data1: string) => { this.msgBody = data1 + data.officeName + "," + data.floorName + "," + data.deskNumber; });
        this.translate.get('Cancel').subscribe((data: string) => { this.cancelBtnText = data; });
        this.translate.get('CheckOutTitle').subscribe((data: string) => { this.okBtnText = data; });
      }
    })
  }
}
