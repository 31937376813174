import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { ServiceService } from '../services/service.service'
import { userDataService } from '../services/data-share';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-delete-parking',
  templateUrl: './alert-delete-parking.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class AlertDeleteParkingComponent implements OnInit {
  @Input() redirectTo: string;
  @Input() msgTitle: string;
  @Input() msgBody: string;
  @Output() showPopUp = new EventEmitter();
  @Input() showCancelBtn: boolean = false;
  @Input() cancelRedirect: string;
  @Input() cancelBtnText: string = 'Cancel';
  @Input() okBtnText: string = 'OK';
  @Input() showMeeting: boolean;
  showDesk: string;

  constructor(private dataService: userDataService, public translate: TranslateService,
    private ServiceService: ServiceService, private router: Router) {
  }

  ngOnInit() {
  }

  formSubmit() {
    if (this.redirectTo != undefined) {
      var URL=this.router.url;
      if (this.router.url == "/cancellation" || this.router.url == "/bookingCancellation") {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([URL]);
        });
      }
      else {
        this.router.navigateByUrl(this.redirectTo);
      }
    } else {
      this.showPopUp.emit(false);
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });

    }
    this.ServiceService.cancelParkReservationViaBooking(localStorage.getItem('parkingMail'), localStorage.getItem('parkingDate')).subscribe((data: any) => {
      localStorage.removeItem('parkingMail');
      localStorage.removeItem('parkingDate');
    });
  }
  formCancel() {
    localStorage.removeItem('parkingMail');
    localStorage.removeItem('parkingDate');
    this.showPopUp.emit(false);
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });    

  }
  bookMeeting() {
    if (localStorage.getItem('CurrentBookingType') == "single") {
      localStorage.setItem("View", "Meeting");
      this.router.navigateByUrl('/selectseat');
      this.dataService.showDesk.next('No');
    }
    else {
      this.translate.get('MultipleMeeting').subscribe((data: string) => { alert(data); })
    }
  }

}
