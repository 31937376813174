import { Component, OnInit } from '@angular/core';
import { OfficeDTO } from '../Model/OfficeDTO';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ServiceService } from '../services/service.service';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-add-office',
  templateUrl: './add-office.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class AddOfficeComponent implements OnInit {

  public officeDTO: OfficeDTO;
  constructor(private service: ServiceService, public translate: TranslateService,
    private router: Router,public datepipe: DatePipe) {
  }

  //regexStr = '^[a-zA-Z0-9_-]+$';
  regexStr = '^[a-zA-Z 0-9_-]+$';
  regexemailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  onKeyPress(event) {
    let isnum = /^\d+$/.test(this.Name.value.trim());
    if(isnum)
    {
    this.IsOnlyNumberName=true;
    }
    else{this.IsOnlyNumberName=false; }
    if(/^\d+$/.test(this.City.value.trim()))
    {
    this.IsOnlyNumberCity=true;
    }
    else{this.IsOnlyNumberCity=false; }

    return new RegExp(this.regexStr).test(event.key);
  }
  blockPaste(event: ClipboardEvent) {
    this.validateFields(event);
  }
  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(/[^a-zA-Z0-9-_]/g, '');
    document.execCommand('insertHTML', false, pasteData);
  }
  showMsgPopUp: boolean = false;
  showErrPopUp: boolean = false;
  showLoader = false;
  PopUpMessage: string;
  msgTitle: string;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  multiEmailPattern = "^(?:[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}(?:,(?!$)|$))*$";
  AdminEmailPattern = "^(?:[a-z0-9._%+-]+@((atkinsglobal|snclgroup|snclavalin|fgould)\.com)(?:,(?!$)|$))*$";

  TimeZones = [
    {
      "value": 'UTC-12',
      "text": "(UTC -12:00) Eniwetok, Kwajalein"
    },
    {
      "value": 'UTC-11',
      "text": "(UTC -11:00) Midway Island, Samoa"
    },
    {
      "value": 'UTC-10',
      "text": "(UTC -10:00) Hawaii"
    },
    {
      "value": 'UTC-9',
      "text": "(UTC -9:00) Alaska"
    },
    {
      "value": 'UTC-8',
      "text": "(UTC -8:00) Pacific Time (US & Canada)"
    },
    {
      "value": 'UTC-7',
      "text": "(UTC -7:00) Mountain Time (US & Canada)"
    },
    {
      "value": 'UTC-6',
      "text": "(UTC -6:00) Central Time (US & Canada), Mexico City"
    },
    {
      "value": 'UTC-5',
      "text": "(UTC -5:00) Eastern Time (US & Canada), Bogota, Lima"
    },
    {
      "value": 'UTC-4',
      "text": "(UTC -4:00) Atlantic Time (Canada), Caracas, La Paz"
    },
    {
      "value": 'UTC-3.5',
      "text": "(UTC -3:30) Newfoundland"
    },
    {
      "value": 'UTC-3',
      "text": "(UTC -3:00) Brazil, Buenos Aires, Georgetown"
    },
    {
      "value": 'UTC-2',
      "text": "(UTC -2:00) Mid-Atlantic"
    },
    {
      "value": 'UTC-1',
      "text": "(UTC -1:00) Azores, Cape Verde Islands"
    },
    {
      "value": 'UTC+0',
      "text": "(UTC) Western Europe Time, London, Lisbon, Casablanca"
    },
    {
      "value": 'UTC+1',
      "text": "(UTC +1:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
      "value": 'UTC+2',
      "text": "(UTC +2:00) Kaliningrad, South Africa"
    },
    {
      "value": 'UTC+3',
      "text": "(UTC +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
    },
    {
      "value": 'UTC+3.5',
      "text": "(UTC +3:30) Tehran"
    },
    {
      "value": 'UTC+4',
      "text": "(UTC +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
    },
    {
      "value": 'UTC+4.5',
      "text": "(UTC +4:30) Kabul"
    },
    {
      "value": 'UTC+5',
      "text": "(UTC +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
    },
    {
      "value": 'UTC+5.5',
      "text": "(UTC +5:30) Bombay, Calcutta, Madras, New Delhi"
    },
    {
      "value": 'UTC+5.75',
      "text": "(UTC +5:45) Kathmandu"
    },
    {
      "value": 'UTC+6',
      "text": "(UTC +6:00) Almaty, Dhaka, Colombo"
    },
    {
      "value": 'UTC+7',
      "text": "(UTC +7:00) Bangkok, Hanoi, Jakarta"
    },
    {
      "value": 'UTC+8',
      "text": "(UTC +8:00) Beijing, Perth, Singapore, Hong Kong"
    },
    {
      "value": 'UTC+9',
      "text": "(UTC +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
    },
    {
      "value": 'UTC+9.5',
      "text": "(UTC +9:30) Adelaide, Darwin"
    },
    {
      "value": 'UTC+10',
      "text": "(UTC +10:00) Eastern Australia, Guam, Vladivostok"
    },
    {
      "value": 'UTC+11',
      "text": "(UTC +11:00) Magadan, Solomon Islands, New Caledonia"
    },
    {
      "value": 'UTC+12',
      "text": "(UTC +12:00) Auckland, Wellington, Fiji, Kamchatka"
    }
  ];

  OfficeForm = new FormGroup({
    Id: new FormControl(0),
    Name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    City: new FormControl('', Validators.required),
    Address: new FormControl(''),
    Country: new FormControl('', Validators.required),
    //Admin: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    Admin: new FormControl('', Validators.required),
    ContactDetails: new FormControl(''),
    // HrDetails: new FormControl('', Validators.pattern(this.emailPattern)),
    HrDetails: new FormControl('', Validators.required),
    ApprovalRequired: new FormControl(false, Validators.required),
    TimeZone: new FormControl('', Validators.required),
    HasParking: new FormControl(false, Validators.required),
    HasDesks: new FormControl(true, Validators.required),
    HasBus: new FormControl(false, Validators.required),
    HealthAssmentRequired: new FormControl(false, Validators.required),
    CommLanguage: new FormControl('', Validators.required),
    PurposesRefId: new FormControl(''),
    HasBkngLimit: new FormControl(false, Validators.required),
    IsGroupBu: new FormControl(false),
    IsAvailable: new FormControl(true, Validators.required),
    OutlookName: new FormControl('', [Validators.required, Validators.minLength(3)]),
    DateFormat: new FormControl('', [Validators.required, Validators.minLength(3)]),
    DashboardName: new FormControl('', [Validators.required, Validators.minLength(3)]),
    // RegionAdmin: new FormControl('', Validators.pattern(this.multiEmailPattern)),
    RegionAdmin: new FormControl(''),
    TotNoOfDesks: new FormControl(0, null),
    NoOfDesksForUse: new FormControl(0, [this.lowerThan('TotNoOfDesks')]),
    UserAssigned: new FormControl(0,),
    Announcement: new FormControl(''),
    AnnouncementFrom: new FormControl(this.datepipe.transform(new Date, 'yyyy-MM-dd')),
    AnnouncementTo: new FormControl(this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd')),
    PropertyId: new FormControl('')
  });
  get Name() { return this.OfficeForm.get('Name'); }
  get City() { return this.OfficeForm.get('City'); }
  get Address() { return this.OfficeForm.get('Address'); }
  get Country() { return this.OfficeForm.get('Country'); }
  get Admin() { return this.OfficeForm.get('Admin'); }
  get ContactDetails() { return this.OfficeForm.get('ContactDetails') }
  get HrDetails() { return this.OfficeForm.get('HrDetails') }
  get ApprovalRequired() { return this.OfficeForm.get('ApprovalRequired'); }
  get TimeZone() { return this.OfficeForm.get('TimeZone'); }
  get HasParking() { return this.OfficeForm.get('HasParking'); }
  get HasDesks() { return this.OfficeForm.get('HasDesks'); }
  get HasBus() { return this.OfficeForm.get('HasBus'); }
  get CommLanguage() { return this.OfficeForm.get('CommLanguage'); }
  get HasBkngLimit() { return this.OfficeForm.get('HasBkngLimit'); }
  get IsGroupBu() { return this.OfficeForm.get('IsGroupBu'); }
  get HealthAssmentRequired() { return this.OfficeForm.get('HealthAssmentRequired'); }
  get IsAvailable() { return this.OfficeForm.get('IsAvailable'); }
  get OutlookName() { return this.OfficeForm.get('OutlookName'); }
  get DateFormat() { return this.OfficeForm.get('DateFormat') }
  get DashboardName() { return this.OfficeForm.get('DashboardName') }
  get RegionAdmin() { return this.OfficeForm.get('RegionAdmin') }
  get TotNoOfDesks() { return this.OfficeForm.get('TotNoOfDesks') }
  get NoOfDesksForUse() { return this.OfficeForm.get('NoOfDesksForUse') }
  get UserAssigned() { return this.OfficeForm.get('UserAssigned') }
  get Announcement() { return this.OfficeForm.get('Announcement') }
  get AnnouncementFrom() { return this.OfficeForm.get('AnnouncementFrom') }
  get AnnouncementTo() { return this.OfficeForm.get('AnnouncementTo') }
  get PropertyId() { return this.OfficeForm.get('PropertyId') }

  addEditOrViewOffice: string;
  viewOrEditOfficeId: string;
  IsEditView: boolean;
  MandatoryView:boolean=false;
  IsOnlyNumberName:boolean;
  IsOnlyNumberCity:boolean;
  UserRegionList:any;
  givenText: string='';
  givenTextRegion: string='';
  givenTextAdmin: string='';
  UserDetails: any;
  displayUserName: string;
  displayUserEMail: string;
  displayPersonalDetails: boolean = false;
  today: Date;
  minDate: string;
  maxDate: string;
  currentDate: any;
  AnnouncementErrorFrom:boolean;
  AnnouncementErrorTo:boolean;
  AnnouncementErrorToGrater:boolean;


  ngOnInit() {
    this.currentDate = new Date();
    this.MandatoryView=true;
    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.today = localStorage.getItem('SelectedDateToBook') == undefined ? this.currentDate : localStorage.getItem('SelectedDateToBook');
    this.minDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.maxDate = this.datepipe.transform(this.CalculateDate(), 'yyyy-MM-dd');
    this.addEditOrViewOffice = localStorage.getItem('addEditOrViewOffice');
    if(!this.MandatoryView){
    localStorage.removeItem('addEditOrViewOffice');}
    this.viewOrEditOfficeId = localStorage.getItem('viewOrEditOfficeId');
    if(!this.MandatoryView){
    localStorage.removeItem('viewOrEditOfficeId');}

    if (this.addEditOrViewOffice == 'edit' || this.addEditOrViewOffice == 'add') {
      this.IsEditView = true;
    }
    else {
      this.IsEditView = false;
    }

    if (this.addEditOrViewOffice == 'edit' || this.addEditOrViewOffice == 'view') {
      this.GetOffice();
    }
    this.getAllUserAllowedRegion();
  }

  //Get the Office Data
  GetOffice() {
    this.service.getOfficeDataForOffice(this.viewOrEditOfficeId, localStorage.getItem('locale')).subscribe((data: any) => {
      this.Name.setValue(data.name);
      this.City.setValue(data.city);
      this.Address.setValue(data.address);
      this.Country.setValue(data.country);
      this.Admin.setValue(this.doLowercase(data.admin));
      this.ContactDetails.setValue(this.doLowercase(data.contactDetails));
      this.HrDetails.setValue(this.doLowercase(data.hrDetails));
      this.TimeZone.setValue(data.timeZone);
      this.CommLanguage.setValue(data.commLanguage);
      this.OutlookName.setValue(data.outlookName);
      this.DashboardName.setValue(data.dashboardName);
      this.DateFormat.setValue(data.dateFormat);
      this.ApprovalRequired.setValue(data.approvalRequired);
      this.HasParking.setValue(data.hasParking);
      this.HasDesks.setValue(data.hasDesks);
      this.HasBus.setValue(data.hasBus);
      this.IsAvailable.setValue(data.isAvailable);
      this.HealthAssmentRequired.setValue(data.healthAssmentRequired);
      this.HasBkngLimit.setValue(data.hasBkngLimit);
      this.IsGroupBu.setValue(data.isGroupBu);
      data.regionAdmin == null ? this.RegionAdmin.setValue('') : this.RegionAdmin.setValue(data.regionAdmin)
      data.totNoOfDesks == null ? this.TotNoOfDesks.setValue(0) : this.TotNoOfDesks.setValue(data.totNoOfDesks);
      data.noOfDesksForUse == null ? this.NoOfDesksForUse.setValue(0) : this.NoOfDesksForUse.setValue(data.noOfDesksForUse);
      data.userAssigned == null ? this.UserAssigned.setValue(0) : this.UserAssigned.setValue(data.userAssigned);
      this.Announcement.setValue(data.announcement);
      this.AnnouncementFrom.setValue(this.datepipe.transform(data.announcementFrom, 'yyyy-MM-dd'));
      this.AnnouncementTo.setValue(this.datepipe.transform(data.announcementTo, 'yyyy-MM-dd'));
      this.PropertyId.setValue(data.propertyId);

      //this.datepipe.transform(data.announcementFrom, 'yyyy-MM-dd')
      // this.AnnouncementFrom.setValue(data.announcementFrom);
      // this.AnnouncementTo.setValue(data.announcementTo);
    });
  }
  MandatoryPopup(){
    this.showErrPopUp = true;
          this.translate.get('Error').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('MandatoryFields').subscribe((data: string) => { this.PopUpMessage = data; });
          this.showLoader = false;
          this.MandatoryView=true;
  }

  submit() {
    console.log(this.OfficeForm.value);
          let isnum = /^\d+$/.test(this.Name.value.trim());
          //var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
          let isemailvalid=/\b[a-z0-9-_.]+@[a-z0-9-_.]+(\.[a-z0-9]+)+/i.test(this.Admin.value.trim());
          //bellow are validations......
          if(this.Name.value.trim()== '')
          {
            document.getElementById('Name').focus();
            this.Name.markAsTouched();
           this.MandatoryPopup();
            return;
          }
          //This will check if first character is blank or not
          if(Array.from(this.Name.value)[0]===' ')
          {
            document.getElementById('Name').focus();
            this.Name.markAsTouched();
            this.MandatoryPopup();
            return;
          }
          if(isnum)
          {
            this.IsOnlyNumberName=true;
            document.getElementById('Name').focus();
            this.Name.markAsTouched();
            this.MandatoryPopup();
          //  alert('Name can not be left blank');
            return;
          }
          if(!isemailvalid)
          {
            document.getElementById('Admin').focus();
            this.Admin.markAsTouched();
            this.MandatoryPopup();
          //  alert('Name can not be left blank');
            return;
          }
          if(/^\d+$/.test(this.City.value.trim()))
          {
            this.IsOnlyNumberCity=true;
            this.MandatoryPopup();
            return;
          }
          if(this.City.value.trim()== '')
          {
            this.City.markAsTouched();
            document.getElementById('City').focus();
            this.MandatoryPopup();
            return;
          }

          if(this.Country.value.trim()== ' Choose the Country'||this.Country.value.trim()=='')
          {
            this.Country.markAsTouched();
            document.getElementById('Country').focus();
            this.MandatoryPopup();
            return;
          }
          if(this.Admin.value.trim()== '')
          {
            document.getElementById('Admin').focus();
            this.Admin.markAsTouched();
            this.MandatoryPopup();
            return;
          }
          if(this.HrDetails.value.trim()== '')
          {
            this.HrDetails.markAsTouched();
            document.getElementById('HrDetails').focus();
            this.MandatoryPopup();
            return;
          }
          if(this.TimeZone.value.trim()== '')
          {
            this.TimeZone.markAsTouched();
            document.getElementById('TimeZone').focus();
            this.MandatoryPopup();
            return;
          }

          if(this.CommLanguage.value.trim()== '')
          {
            this.CommLanguage.markAsTouched();
            document.getElementById('CommLanguage').focus();
            this.MandatoryPopup();
            return;
          }
          if(this.OutlookName.value.trim()== '')
          {
            this.OutlookName.markAsTouched();
            document.getElementById('OutlookName').focus();
            this.MandatoryPopup();
            return;
          }
          if(this.DashboardName.value.trim()== '')
          {
            this.DashboardName.markAsTouched();
            document.getElementById('DashboardName').focus();
            this.MandatoryPopup();
            return;
          }
          if(this.DateFormat.value.trim()== '')
          {
            this.DateFormat.markAsTouched();
            document.getElementById('DateFormat').focus();
            this.MandatoryPopup();
            return;
          }
          if(this.Announcement.value!= '' && this.Announcement.value != null)
          {
            if(this.AnnouncementFrom.value==null || this.AnnouncementFrom.value=='')
            {
              this.AnnouncementFrom.markAsTouched();
              document.getElementById('AnnouncementFrom').focus();
              this.AnnouncementErrorFrom=true;
              this.MandatoryPopup();
              return;
            }
            else{this.AnnouncementErrorFrom=false;}
            if(this.AnnouncementTo.value==null || this.AnnouncementTo.value=='')
            {
              this.AnnouncementTo.markAsTouched();
              document.getElementById('AnnouncementTo').focus();
              this.MandatoryPopup();
              this.AnnouncementErrorTo=true;
              return;
            }
            else if(new Date(this.AnnouncementTo.value) < new Date(this.AnnouncementFrom.value))
            {
              this.AnnouncementTo.markAsTouched();
              document.getElementById('AnnouncementTo').focus();
              this.AnnouncementErrorTo=false;
              this.AnnouncementErrorToGrater=true;
              return;
            }
            else{this.AnnouncementErrorTo=false;}
          }
          if(this.AnnouncementFrom.value== '')
          {
            this.OfficeForm.controls['AnnouncementFrom'].setValue(null);
          }
          if(this.AnnouncementTo.value== '')
          {
            this.OfficeForm.controls['AnnouncementTo'].setValue(null);
          }
          if(this.PropertyId.value== '')
          {
            this.OfficeForm.controls['PropertyId'].setValue(null);

          }
          //---------------------------


    if (this.OfficeForm.status === 'VALID' && this.OfficeForm.value.Name != '') {
      this.showLoader = true;
      this.officeDTO = this.OfficeForm.value;
      if (this.addEditOrViewOffice == 'edit') {
        this.officeDTO.Id = Number(this.viewOrEditOfficeId);
      }




      if (this.officeDTO.Country == "United Kingdom") {
        this.officeDTO.PurposesRefId = 2;
      }
      else if (this.officeDTO.Country == "Canada") {

        this.officeDTO.PurposesRefId = 2;
      }
      else if (this.officeDTO.Country == "United States") {

        this.officeDTO.PurposesRefId = 1;
      }
      else if (this.officeDTO.Country == "South America") {

        this.officeDTO.PurposesRefId = 1;
      }
      else {
        this.officeDTO.PurposesRefId = 2;
      }



      this.service.AddOffice(this.officeDTO).subscribe((result: any) => {
        if(result==200)
        {
          this.showMsgPopUp = true;
          this.translate.get('OfficeSavedTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('OfficeSavedMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          this.showLoader = false;
        }
        else if(result==401)
        {
          this.showMsgPopUp = true;
          this.translate.get('DuplicateOfficeTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('DuplicateOfficeMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          this.showLoader = false;
        }
        else if(result==402)
        {
          this.showMsgPopUp = true;
          this.translate.get('DashboardName').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('DuplicateDashboardMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          this.showLoader = false;
        }
        else if(result==403)
        {
          this.showMsgPopUp = true;
          this.translate.get('DuplicatePropertyIdTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('DuplicatePropertyIdMsg').subscribe((data: string) => { this.PopUpMessage = data; });
          this.showLoader = false;
        }
        else
        {
          this.showLoader = false;
          this.showMsgPopUp = true;
          this.translate.get('InvalidDataTitle').subscribe((data: string) => { this.msgTitle = data; });
          this.translate.get('InvalidDataError').subscribe((data: string) => { this.PopUpMessage = data; });
        }
      }, error => {
        this.showLoader = false;
        this.showMsgPopUp = true;
        this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
        this.translate.get('TechErr').subscribe((data: string) => { this.PopUpMessage = data; });
      })
    }
    else {
      this.showErrPopUp = true;
      this.translate.get('SaveDetailsErrTitle').subscribe((data: string) => { this.msgTitle = data; });
      this.translate.get('FormError').subscribe((data: string) => { this.PopUpMessage = data; });
    }
  }

   doLowercase(string) {
    return (typeof string === 'string') ? string.toLowerCase() : string;
  }

  GoToOfficeDetailsView() {
    this.router.navigateByUrl('/officedetails');
  }

  get f() {
    return this.OfficeForm.controls;
  }
  lowerThan(field_name): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      if (control.value == 0 && control.root.value[field_name] == 0) {
        return null;
      } else {
        const input = control.value;
        const isLower = control.root.value[field_name] <= input;
        return isLower ? { 'lowerThan': { isLower } } : null;
      }
    };
  }


  changeCountry(e) {
    if(e.target.value == 'South America' || e.target.value=='United States'){
      this.HealthAssmentRequired.setValue(true);
    }
    else{
      this.HealthAssmentRequired.setValue(false);
    }
  }

//----------- User Allowed Region 18Nov2022
  getAllUserAllowedRegion()
  {
    this.service.getUserAllowedRegion().subscribe((data: any[]) => {
      this.UserRegionList =data;
      if(data.length>0)
      {
        this.UserRegionList =data.filter(x => x.langKey == localStorage.getItem('locale'));
      }
    });
  }

  search() {
    this.showLoader = true;
    this.service.getUserDetails(this.givenText).subscribe((data: any) => {
      this.showLoader = false;
      // this.UserDetails = data.filter(x => x.mail != null && x.mail != localStorage.getItem('CurrentUserEmail'));
      this.UserDetails = data.filter(x => x.mail != null);
    })
  }
  searchTest(val) {
    this.showLoader = true;
    this.service.getUserDetails(val).subscribe((data: any) => {
      this.showLoader = false;
      this.UserDetails = data.filter(x => x.mail != null && x.mail != localStorage.getItem('CurrentUserEmail'));
      //this.UserDetails = data.filter(x => x.mail != null);
    })
  }

  getUserDetail(value) {
    var selectedOtherUser = this.UserDetails.find(x => x.mail == value);
    this.displayUserName = selectedOtherUser.displayName;
    this.displayUserEMail = selectedOtherUser.mail;
    this.displayPersonalDetails = true;
  }

  CheckMail(event: Event): void {
    this.service.getUserDetails((event.target as HTMLInputElement).value).subscribe((data: any) => {

      this.UserDetails = data.filter(x => x.mail == (event.target as HTMLInputElement).value);
      if(this.UserDetails.length==0)
      {
        if((event.target as HTMLInputElement).id=='HrDetails')
        {
          this.givenText="";
        }
        if((event.target as HTMLInputElement).id=='RegionAdmin')
        {
          this.givenTextRegion="";
        }
        if((event.target as HTMLInputElement).id=='Admin')
        {
          this.givenTextAdmin="";
        }
      }
    })
  }
  CalculateDate() {
    var date = new Date();
    date.setDate(date.getDate() + 28);
    return date;
  }
  LoadSpacedBasedonLang() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

}
