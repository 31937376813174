import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { enGB, fr, enUS, ca, ptBR } from 'date-fns/locale'

@Pipe({ name: 'convertDate', pure: false })
export class ConvertDate implements PipeTransform {

  readonly langMap = new Map<string, Locale>([
    ['fr', fr],
    ['en', enGB],
    ['ptBR',ptBR]
  ]);

  constructor(public _translateSrvc: TranslateService) { }
  transform(value: string) {

    // https://github.com/date-fns/date-fns/issues/489
    function formatTheDateYall(date, localeLang) {
      const [year, month, day] = date.substr(0, 10).split('-')
      return format(new Date(
        year,
        (month - 1),
        day,
      ), formatter, { locale: localeLang })
    }

    let formatter: string = 'yyyy-MMM-dd';
    let localeLang = this.langMap.get(this._translateSrvc.currentLang);
    let resultDate = formatTheDateYall(value, localeLang);

    this._translateSrvc.onLangChange.subscribe((event: LangChangeEvent) => {
      localeLang = this.langMap.get(this._translateSrvc.currentLang);
      resultDate = formatTheDateYall(value, localeLang);
    });

    return resultDate;
  }
}
