import { Component, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { userDataService } from '../services/data-share';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';

@Component({
  selector: 'app-region-office-selector',
  templateUrl: './region-office-selector.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class RegionOfficeSelectorComponent implements OnInit {

  @Output() validateButton = new EventEmitter<boolean>();
  @Output() selectedCarParking = new EventEmitter<string>();
  @Output() ShowFullData = new EventEmitter();

  offices: any;
  floors: any;
  zones: any;
  businessUnits: any;
  AllbusinessUnits: any;
  selectedFloor: string;
  selectedZone: string;
  selectedBU: string;
  selectedOffice: any;
  hasParking: boolean;
  IsGroupedBU: any;
  hasBusinessUnits: boolean;
  showFloors: boolean = true;
  showMsgPopUp: boolean = false;
  msgTitle: string = null;
  PopUpMessage: string = null;
  RegionCountrySelect: boolean = true;
  deskTypes: any;
  selectedDeskType: string;
  showPopUp: boolean = false;
  IsAdminDeskConfiguration = false;
  FullData = false;
  data1: any;
  FullCountryList: any;
  selectedCountry: any = "Please";

  constructor(private ServiceService: ServiceService, private ngzone: NgZone, private router: Router
    , public translate: TranslateService) {
    this.selectedOffice = null;
    this.selectedFloor = null;
    this.selectedDeskType = null;
  }
  ngOnInit() {
    this.GetCountryList();
    this.selectedCountry = localStorage.getItem('SelectedCountry');
    this.selectedCountry = null;
  }
  NgOnIntFunctionality() {
    this.GetCountryList();
    this.selectedCountry = localStorage.getItem('SelectedCountry');
  }
  CheckValidateButton() {
    if (this.selectedOffice != undefined && this.selectedFloor != undefined)// && (this.selectedZone != undefined || this.selectedBU != undefined))
    {
      this.validateButton.emit(true);
      this.checkIfUserSelected();
    }
    else {
      this.validateButton.emit(false);
    }
  }
  GetCountryList() {
    this.ServiceService.getOption().subscribe((data: any[]) => {
      this.FullCountryList = data;
      //Swap Only in Reserved Country
      // if(localStorage.getItem('isCheckedIn')=='true')
      // {
      this.data1 = data.filter(x => x.country == localStorage.getItem('Country1'));
      this.data1 = this.data1.filter(x => x.langKey == localStorage.getItem('locale'));
      // }
      // else{
      // this.data1 = data.filter(x => x.langKey == localStorage.getItem('locale'));}
      //End
    }, err => {
      alert("something went wrong");
    });
  }

  changeCountry(option) {
    this.selectedCountry = option.target.value;
    //this.dataService.setSelectedCountry(this.selectedCountry);
    //Temporary Country Storing
    this.ServiceService.GetSelectedCountryName(this.selectedCountry).subscribe((data: any) => {
      var country = JSON.parse(data).filter(x => x.langKey == localStorage.getItem('locale'));
      localStorage.setItem('SelectedCountry', country[0].key);
    }, err => {
      localStorage.setItem('SelectedCountry', this.selectedCountry)
    });

    //Temporary Country Storing

    localStorage.setItem('OfficeID', "0");

    localStorage.setItem('GroupedBU', 'false');
    // get list of offcies for country
    let checkAdminOfficeIds: boolean = (this.router.url == '/deskConfiguration' ? true : false);
    this.IsAdminDeskConfiguration = checkAdminOfficeIds;
    // if(localStorage.getItem('isCheckedIn')=='true')
    //   {}
    //   else{localStorage.setItem('Office1','null')}
    this.ServiceService.getOfficesForSwap(this.selectedCountry, checkAdminOfficeIds, localStorage.getItem('Office1')).subscribe((data: any[]) => {
      if (data.length == 0) {
        this.msgTitle = 'Permission Restricted';
        this.PopUpMessage = 'Sorry, you dont have permission to access any of the offices in ' + localStorage.getItem('SelectedCountry') + '. Please select other country';
        this.showMsgPopUp = true;
        // alert("You dont have permission to update the offices in country, please select other counrty");
        // this.router.navigate(["/"]);
      }
      this.offices = data;

      if (data.length > 0) {//&& localStorage.getItem('OfficeID') != undefined
        let _offID = (parseInt(localStorage.getItem('OfficeID')) != 0 && parseInt(localStorage.getItem('OfficeID')) != undefined) ? parseInt(localStorage.getItem('OfficeID')) : 0;//parseInt(localStorage.getItem('OfficeID'));
        let _officeDetail = this.offices.find(x => x.id == _offID);
        if (_officeDetail != undefined) {
          this.hasParking = _officeDetail.hasParking;
          this.hasBusinessUnits = _officeDetail.hasBUs;
          this.IsGroupedBU = _officeDetail.isGroupBu.toString();
          localStorage.setItem('GroupedBU', this.IsGroupedBU);
          localStorage.setItem('OfficeName', _officeDetail.name);
          localStorage.setItem('CommsLang', _officeDetail.commLanguage);
          localStorage.setItem('DateFormat', _officeDetail.dateFormat);
          localStorage.setItem('HasParking', _officeDetail.hasParking);
        } else {
          let _officeDetail = this.offices.find(x => x.id == data[0].id);

          this.hasParking = _officeDetail.hasParking;
          this.hasBusinessUnits = _officeDetail.hasBUs;
          this.IsGroupedBU = _officeDetail.isGroupBu.toString();
          localStorage.setItem('GroupedBU', this.IsGroupedBU);
          localStorage.setItem('OfficeName', _officeDetail.name);
          localStorage.setItem('CommsLang', _officeDetail.commLanguage);
          localStorage.setItem('DateFormat', _officeDetail.dateFormat);
          localStorage.setItem('HasParking', _officeDetail.hasParking);
          // this.hasParking = false;
          // this.hasBusinessUnits = false;
          // this.IsGroupedBU = false;
          // localStorage.setItem('OfficeName', 'NA');
          // localStorage.setItem('CommsLang', "en-UK");
          // localStorage.setItem('DateFormat', "dd/MM/yyyy");
          // localStorage.setItem('HasParking', 'false');
        }

        (this.hasParking) ? localStorage.setItem("CarParkingSelection", 'true') : localStorage.setItem("CarParkingSelection", 'false');
        //get BU list
        if (this.hasBusinessUnits == true) {
          this.ServiceService.getBUs(parseInt(this.selectedOffice)).subscribe((data: any[]) => {
            if (localStorage.getItem('SwapType') == "Desk")
              data = data.filter(o => o.hasGym == false);
            else
              data = data.filter(o => o.hasGym == true);
            this.AllbusinessUnits = data;
            this.businessUnits = data.filter(x => x.langKey == localStorage.getItem('locale'));

            this.selectedZone = localStorage.getItem('ZoneId');
            this.selectedBU = localStorage.getItem('BUId');
            this.buSetter(this.selectedBU, false);
          });
        }
        else {
          localStorage.setItem('BUId', undefined);
        }
      }
    });
    //console.log(localStorage.getItem('RedirectFromParking'))
    this.selectedOffice = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
    //console.log(this.selectedOffice + 'Before Floor Call');
    // this.IsGroupedBU = this.offices.find(x => x.id == this.selectedOffice).isGroupBu;
    if (this.selectedOffice != undefined) {
      //get floors list
      this.ServiceService.getFloors(parseInt(this.selectedOffice), this.router.url).subscribe((data: any[]) => {
        if (localStorage.getItem('SwapType') == "Desk")
          data = data.filter(o => o.hasGym == false);
        else
          data = data.filter(o => o.hasGym == true);
        this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
        this.floorSetter(this.selectedFloor);

        var selFloorObj = this.floors.find(value => value.id === parseInt(this.selectedFloor));
        //get zones list
        if (selFloorObj.hasZones) {
          this.ServiceService.getZones(parseInt(this.selectedFloor)).subscribe((data: any[]) => {
            this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
            this.selectedZone = localStorage.getItem('ZoneId');
            this.zoneSetter(this.selectedZone);
          });
        }

      });
      this.selectedFloor = localStorage.getItem('FloorID');
    };
    this.IsGroupedBU = (localStorage.getItem('GroupedBU') == undefined) ? false : localStorage.getItem('GroupedBU');

    //--- DeskType Implement 11Aug2022 Get DeskTypes by Office Id

    if (this.selectedOffice != undefined) {
      this.ServiceService.getDeskTypes(parseInt(this.selectedOffice)).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.deskTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));
          // this.deskTypes = data;
          this.deskTypeSetter(this.selectedDeskType);

          var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(this.selectedDeskType));
        }
        else {
          this.deskTypes = data;
          this.deskTypeSetter(this.selectedDeskType);
        }
      });
      this.selectedDeskType = localStorage.getItem('DeskTypeID');
    }


    this.CheckValidateButton();
  }


  changeOffice(e) {
    this.RegionCountrySelect = false;
    var _offID = (e.target == undefined ? e : e.target.value);
    this.hasParking = this.offices.find(x => x.id == _offID).hasParking;

    //Set hasParking while changing Office
    localStorage.setItem('HasParking', this.offices.find(x => x.id == _offID).hasParking);
    //
    //set Language
    localStorage.setItem('CommsLang', this.offices.find(x => x.id == _offID).commLanguage);
    if (localStorage.getItem('CommsLang') == undefined || localStorage.getItem('CommsLang') == null) {
      localStorage.setItem('CommsLang', 'en-US');
    }
    //
    // this.IsGroupedBU = this.offices.find(x => x.id == _offID).isGroupBu;
    localStorage.setItem('OfficeName', this.offices.find(x => x.id == this.selectedOffice).name);
    localStorage.setItem('GroupedBU', this.offices.find(x => x.id == this.selectedOffice).isGroupBu);
    this.IsGroupedBU = localStorage.getItem('GroupedBU');
    this.ServiceService.getFloors(parseInt(_offID), this.router.url).subscribe((data: any[]) => {
      if (localStorage.getItem('SwapType') == "Desk")
        data = data.filter(o => o.hasGym == false);
      else
        data = data.filter(o => o.hasGym == true);
      this.selectedFloor = data[0].id;
      this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
      this.floorSetter(this.selectedFloor);

      var selFloorObj = this.floors.find(value => value.id === parseInt(this.selectedFloor));
      //get zones list
      if (selFloorObj.hasZones) {
        this.ServiceService.getZones(parseInt(this.selectedFloor)).subscribe((data: any[]) => {
          this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
          this.selectedZone = localStorage.getItem('ZoneId');
          this.zoneSetter(this.selectedZone);
        });
      }
      else {
        this.zones = null;
      }

    });
    (this.hasParking) ? this.selectedCarParking.emit('true') : this.selectedCarParking.emit('false');
    (this.hasParking) ? this.ngzone.run(() => { localStorage.setItem("CarParkingSelection", 'true') }) : this.ngzone.run(() => { localStorage.setItem("CarParkingSelection", 'false') });
    this.hasBusinessUnits = this.offices.find(x => x.id == _offID).hasBUs;

    if (this.hasBusinessUnits == true) {
      this.ServiceService.getBUs(_offID).subscribe((data: any[]) => {
        if (localStorage.getItem('SwapType') == "Desk")
          data = data.filter(o => o.hasGym == false);
        else
          data = data.filter(o => o.hasGym == true);
        this.selectedFloor = undefined;

        this.AllbusinessUnits = data;
        this.businessUnits = this.AllbusinessUnits.filter(x => x.langKey == localStorage.getItem('locale'));
        this.selectedBU = this.businessUnits[0].id;
        this.changeBU(this.businessUnits[0].id);
        this.checkIfUserSelected();
      });
    }
    else {
      localStorage.setItem('BUId', undefined);
      this.ServiceService.getFloors(_offID, this.router.url).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.showFloors = true;
          if (localStorage.getItem('SwapType') == "Desk")
            data = data.filter(o => o.hasGym == false);
          else
            data = data.filter(o => o.hasGym == true);
          this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
          localStorage.setItem('FloorID', this.floors[0].id);
          this.selectedFloor = localStorage.getItem('FloorID');
          localStorage.setItem("CarParkingSelection", 'false');
          this.changeFloors(this.floors[0].id);
          this.checkIfUserSelected();
        }
        else {
          this.showFloors = false;
        }
      });
    };
    localStorage.setItem('OfficeID', _offID);
    localStorage.setItem('ZoneId', undefined);

    //--- DeskType Implement 11Aug2022 Get DeskTypes by Office Id


    this.ServiceService.getDeskTypes(parseInt(_offID)).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.selectedDeskType = data[0].id;
        this.deskTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));
        // this.deskTypes = data;
        this.deskTypeSetter(this.selectedDeskType);

        var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(this.selectedDeskType));
      }
      else {
        this.deskTypes = data;
        this.deskTypeSetter(this.selectedDeskType);
      }
    });

  }

  changeFloors(e) {
    var floorid = (e.target == undefined ? e : e.target.value);
    this.floorSetter(floorid);

    this.ServiceService.getZones(floorid).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
        this.zoneSetter(this.zones[0].id);

      } else { this.zones = ''; }
    });
    // if (this.zones == undefined)
    localStorage.setItem('ZoneId', undefined);
  }

  floorSetter(floorid) {
    if (floorid != undefined) {
      this.selectedFloor = floorid;
      localStorage.setItem('FloorID', floorid);

      var selFloorObj = this.floors.find(value => value.id === parseInt(floorid));
      if (selFloorObj != undefined) {
        localStorage.setItem('SelectTypeofSeat', selFloorObj.hasDeskCategory);
        localStorage.setItem('FloorPlanPath', selFloorObj.floorPlanPath);
        localStorage.setItem('FullFloorPlanPath', selFloorObj.fullFloorplanPath);
        localStorage.setItem('ShowFullFloorplan', selFloorObj.showFullFloorplan);
      }
    }
  }

  zoneSetter(zoneid) {
    if (zoneid != undefined) {
      this.selectedZone = zoneid;
      localStorage.setItem('ZoneId', zoneid);

      var selZoneObj = this.zones.find(value => value.id === parseInt(zoneid));
      if (selZoneObj != undefined) {
        localStorage.setItem('FloorID', selZoneObj.floorId);
        localStorage.setItem('SelectTypeofSeat', selZoneObj.hasDeskCategory);
        localStorage.setItem('FloorPlanPath', selZoneObj.floorPlanPath);
        localStorage.setItem('FullFloorPlanPath', selZoneObj.fullFloorplanPath);
        localStorage.setItem('ShowFullFloorplan', selZoneObj.showFullFloorplan);
      }
    }
  }

  changeZones(e) {
    var zoneid = e.target.value;
    this.zoneSetter(zoneid);
  }

  changeBU(e) {
    var buid = (e.target == undefined ? e : e.target.value);
    this.buSetter(buid, true);
  }

  buSetter(buid, onchange: boolean) {
    localStorage.setItem('BUId', buid);
    if (localStorage.getItem('GroupedBU') == 'true' && !this.FullData) {
      this.ServiceService.getFloorsFromBU(parseInt(localStorage.getItem('OfficeID')), buid, this.router.url).subscribe((data: any[]) => {
        if (data.length > 0) {
          if (localStorage.getItem('SwapType') == "Desk")
            data = data.filter(o => o.hasGym == false);
          else
            data = data.filter(o => o.hasGym == true);
          this.floors = data.filter(x => x.langKey == localStorage.getItem('locale'));
          this.floorSetter(this.floors[0].id);
        }
        else { this.floors = data; }
      });
    }
    else {
      this.ServiceService.getZonesFromBU(parseInt(localStorage.getItem('OfficeID')), buid).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.zones = data.filter(x => x.langKey == localStorage.getItem('locale'));
          if (onchange) {
            this.zoneSetter(this.zones[0].id);
          }
          else {
            this.zoneSetter(this.selectedZone);
          }
        } else { this.zones = ''; }
      });
    }
  }
  ParkingSelection(value) {
    this.selectedCarParking.emit(value);
    localStorage.setItem("CarParkingSelection", value);
    this.ngzone.run(() => { localStorage.setItem("CarParkingSelection", value) });
  }

  LoadOfficesBasedonLang() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  checkIfUserSelected() {
    let bookingFor = localStorage.getItem('bookingFor') == undefined ? 'self' : localStorage.getItem('bookingFor');
    if (bookingFor == 'self') {
      this.validateButton.emit(true);
    } else {
      if (localStorage.getItem('selectedOtherUserEmail') != null) {
        this.validateButton.emit(true);
      } else {
        this.validateButton.emit(false);
      }
    }
  }

  deskTypeSetter(deskTypeid) {
    if (deskTypeid != undefined) {
      this.selectedDeskType = deskTypeid;
      localStorage.setItem('DeskTypeID', deskTypeid);

      var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(deskTypeid));
      if (selDeskTypeObj != undefined) {
        localStorage.setItem('SelectDeskType', selDeskTypeObj.Name);
      }
    }
  }

  AddDeskType() {
    this.showPopUp = true;
  }

  desktypeFormCheck(showMsgPopup: boolean) {
    this.showPopUp = false;
    if (showMsgPopup == true) {
      var _offID = parseInt(localStorage.getItem('OfficeID')) == 0 ? undefined : localStorage.getItem('OfficeID');
      if (_offID != undefined) {
        this.ServiceService.getDeskTypes(parseInt(_offID)).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.selectedDeskType = data[0].id;
            this.deskTypes = data.filter(x => x.langKey == localStorage.getItem('locale'));
            this.deskTypeSetter(this.selectedDeskType);

            var selDeskTypeObj = this.deskTypes.find(value => value.id === parseInt(this.selectedDeskType));
          }
          else {
            this.deskTypes = data;
            this.deskTypeSetter(this.selectedDeskType);
          }
        });
      }
    }
  }

  fetchCompleteData(fetchData) {
    if (fetchData) {
      this.FullData = true;
      this.ShowFullData.emit(true);
      this.changeOffice(parseInt(localStorage.getItem('OfficeID')));
    }
    else {
      this.FullData = false;
      this.ShowFullData.emit(false);
      this.changeOffice(parseInt(localStorage.getItem('OfficeID')));
    }
  }

}
