import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input, NgZone } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { userDataService } from '../services/data-share';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AzureBlobStorageService } from './../azure-blob-storage.service';
import keys from '../key.json';
const BOOKED_DESK_COLOR: string = "rgb(255,10,10)";
const DESK_OPACITY = "1.0";
const OLD_DESK_OPACITY = "0.377049";

@Component({
  selector: 'app-floorplan-mini',
  templateUrl: './floorplan-mini.component.html',
  styleUrls: ['../../assets/css/style.scss']
})
export class FloorplanMiniComponent implements OnInit {
  @Input() selectedReservation: any;
  // sas = "?sp=rl&st=2022-06-15T11:10:13Z&se=2022-12-31T18:29:59Z&spr=https&sv=2021-06-08&sr=c&sig=D6ejQa5J2lYYm%2BKxR0A79W%2BteJCpzCDucL78NWPJcO8%3D";
  //sas ="?sp=rwl&st=2023-01-02T05:03:02Z&se=2024-12-31T18:29:59Z&spr=https&sv=2021-06-08&sr=c&sig=qMmEdTPN1cxNg6QmqpFIPYQjtZAvRgPQaxSSFwA1rz8%3D";
  sas=keys[0]['sas'];

  selFloorPlanPath: any;
  allDesks:any;
  @ViewChild("svgfloorplan", { read: ElementRef }) svgfloorplan: ElementRef;
  showLoader: boolean;
  constructor(private ServiceService: ServiceService, private titleService: Title, private dataService: userDataService, private router: Router, public datepipe: DatePipe
    , private ngzone: NgZone, private dom: DomSanitizer, private blobService: AzureBlobStorageService) {
  }
  ngOnInit() {
    this.showLoader = true;
    this.blobService.downloadImage(this.sas, this.selectedReservation.floorPlanPath, blob => {
     let url = window.URL.createObjectURL(blob);
      this.ngzone.run(() => { this.selFloorPlanPath = this.dom.bypassSecurityTrustResourceUrl(url);this.showLoader = false; });
      
      // this.selFloorPlanPath = this.dom.bypassSecurityTrustResourceUrl('/assets/floorplans/'.concat(this.selectedReservation.floorPlanPath));
    });
  }

  applyEvents() {
    if (this.svgfloorplan == undefined) return;
    var newSVGFormat = this.selectedReservation.floorPlanPath.includes('COLOR');
    if (newSVGFormat) {
      if (this.svgfloorplan.nativeElement.contentDocument.getElementById("Bubbles") == null ||
        this.svgfloorplan.nativeElement.contentDocument.getElementById("Desks") == null) return;
    }

    let desk_marks;
    // let text_marks;
    if (newSVGFormat) {
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.getElementById("Bubbles").querySelectorAll('path') as NodeList);
      // text_marks = (this.svgfloorplan.nativeElement.contentDocument.getElementById("Desks").querySelectorAll('text') as NodeList);
    } else {
      desk_marks = (this.svgfloorplan.nativeElement.contentDocument.querySelectorAll('ellipse') as NodeList);
    }

    var i: number;
      this.ServiceService.getMeetingName(this.selectedReservation.deskID).subscribe((data: any) => {
        this.allDesks=data;
        for (i = 0; i < desk_marks.length; i++) {
          let desk_ell = desk_marks[i] as SVGPathElement;
          var desk_number = desk_ell.id.split('_')[1]
          var selDeskNumber = this.selectedReservation.deskNumber;
          if(this.selectedReservation.reservationType=="Meeting")
          {
            if(this.selectedReservation.meetingName==this.allDesks.filter(value => value.deskNumber === desk_number)[0].meetingName)
            {
              desk_ell.style.setProperty("fill", BOOKED_DESK_COLOR);
              if (newSVGFormat) {
                desk_ell.style.setProperty("opacity", DESK_OPACITY)
              } else {
                desk_ell.style.setProperty("opacity", OLD_DESK_OPACITY);
              }
            }
          }
          else if (desk_number == selDeskNumber) {
            desk_ell.style.setProperty("fill", BOOKED_DESK_COLOR);
            if (newSVGFormat) {
              desk_ell.style.setProperty("opacity", DESK_OPACITY)
            } else {
              desk_ell.style.setProperty("opacity", OLD_DESK_OPACITY);
            }
          }
        }
      })
  }

}
